/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Render the list of attributes as a "section" or as a list
 * @export
 */
export const ResourceLayoutSectionType = {
    Attributes: 'attributes',
    Section: 'section'
} as const;
export type ResourceLayoutSectionType = typeof ResourceLayoutSectionType[keyof typeof ResourceLayoutSectionType];


export function ResourceLayoutSectionTypeFromJSON(json: any): ResourceLayoutSectionType {
    return ResourceLayoutSectionTypeFromJSONTyped(json, false);
}

export function ResourceLayoutSectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceLayoutSectionType {
    return json as ResourceLayoutSectionType;
}

export function ResourceLayoutSectionTypeToJSON(value?: ResourceLayoutSectionType | null): any {
    return value as any;
}

