import { Position, internalsSymbol, type Node } from "reactflow";

// Returns the position (top, right, bottom or left) of handle that is closest to the other node
function getParams(nodeA: Node, nodeB: Node) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  if (centerA == null || centerB == null) {
    return [0, 0, Position.Top];
  }

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // When the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // When the vertical difference between the nodes is bigger, we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

function getHandleCoordsByPosition(node: Node, handlePosition: Position) {
  // All the handles are of source type, that's why we are using handleBounds.source
  const handle = node?.[internalsSymbol]?.handleBounds?.source?.find(
    (h) => h.position === handlePosition
  );

  const x = (node?.positionAbsolute?.x ?? 0) + (handle?.x ?? 0) + 5;
  const y = (node?.positionAbsolute?.y ?? 0) + (handle?.y ?? 0) + 5;

  return [x, y];
}

function getNodeCenter(node: Node) {
  if (node?.positionAbsolute && node?.width && node?.height) {
    return {
      x: node.positionAbsolute.x + node.width / 2,
      y: node.positionAbsolute.y + node.height / 2,
    };
  } else {
    return null;
  }
}

// Returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) needed to create an edge
export function getEdgeParams(source: Node, target: Node) {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}
