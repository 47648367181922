/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentTraitsDependenciesS3Inner
 */
export interface ComponentTraitsDependenciesS3Inner {
    /**
     * 
     * @type {string}
     * @memberof ComponentTraitsDependenciesS3Inner
     */
    bucket?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComponentTraitsDependenciesS3Inner
     */
    actions?: Array<string>;
}

/**
 * Check if a given object implements the ComponentTraitsDependenciesS3Inner interface.
 */
export function instanceOfComponentTraitsDependenciesS3Inner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesS3InnerFromJSON(json: any): ComponentTraitsDependenciesS3Inner {
    return ComponentTraitsDependenciesS3InnerFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesS3InnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependenciesS3Inner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bucket': !exists(json, 'bucket') ? undefined : json['bucket'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
    };
}

export function ComponentTraitsDependenciesS3InnerToJSON(value?: ComponentTraitsDependenciesS3Inner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bucket': value.bucket,
        'actions': value.actions,
    };
}

