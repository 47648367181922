/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VarSchema } from './VarSchema';
import {
    VarSchemaFromJSON,
    VarSchemaFromJSONTyped,
    VarSchemaToJSON,
} from './VarSchema';

/**
 * 
 * @export
 * @interface Variable
 */
export interface Variable {
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof Variable
     */
    defaultVal?: any;
    /**
     * 
     * @type {boolean}
     * @memberof Variable
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Variable
     */
    sensitive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Variable
     */
    label?: string;
    /**
     * 
     * @type {Array<VarSchema>}
     * @memberof Variable
     */
    schema?: Array<VarSchema>;
}

/**
 * Check if a given object implements the Variable interface.
 */
export function instanceOfVariable(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VariableFromJSON(json: any): Variable {
    return VariableFromJSONTyped(json, false);
}

export function VariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Variable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'defaultVal': !exists(json, 'defaultVal') ? undefined : json['defaultVal'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'schema': !exists(json, 'schema') ? undefined : ((json['schema'] as Array<any>).map(VarSchemaFromJSON)),
    };
}

export function VariableToJSON(value?: Variable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'description': value.description,
        'defaultVal': value.defaultVal,
        'required': value.required,
        'sensitive': value.sensitive,
        'label': value.label,
        'schema': value.schema === undefined ? undefined : ((value.schema as Array<any>).map(VarSchemaToJSON)),
    };
}

