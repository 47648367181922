/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomTemplateReqOverrideConfig
 */
export interface CustomTemplateReqOverrideConfig {
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateReqOverrideConfig
     */
    overriddenResourceType?: string;
    /**
     * 
     * @type {object}
     * @memberof CustomTemplateReqOverrideConfig
     */
    configMapping?: object;
}

/**
 * Check if a given object implements the CustomTemplateReqOverrideConfig interface.
 */
export function instanceOfCustomTemplateReqOverrideConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomTemplateReqOverrideConfigFromJSON(json: any): CustomTemplateReqOverrideConfig {
    return CustomTemplateReqOverrideConfigFromJSONTyped(json, false);
}

export function CustomTemplateReqOverrideConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomTemplateReqOverrideConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'overriddenResourceType': !exists(json, 'OverriddenResourceType') ? undefined : json['OverriddenResourceType'],
        'configMapping': !exists(json, 'configMapping') ? undefined : json['configMapping'],
    };
}

export function CustomTemplateReqOverrideConfigToJSON(value?: CustomTemplateReqOverrideConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OverriddenResourceType': value.overriddenResourceType,
        'configMapping': value.configMapping,
    };
}

