/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudProvider } from './CloudProvider';
import {
    CloudProviderFromJSON,
    CloudProviderFromJSONTyped,
    CloudProviderToJSON,
} from './CloudProvider';
import type { TargetCompute } from './TargetCompute';
import {
    TargetComputeFromJSON,
    TargetComputeFromJSONTyped,
    TargetComputeToJSON,
} from './TargetCompute';

/**
 * Metadata for the target compute
 * @export
 * @interface TargetComputeMetadata
 */
export interface TargetComputeMetadata {
    /**
     * 
     * @type {TargetCompute}
     * @memberof TargetComputeMetadata
     */
    id?: TargetCompute;
    /**
     * Display name shown to users in the user interface
     * @type {string}
     * @memberof TargetComputeMetadata
     */
    name?: string;
    /**
     * Is the target compute is supported by appCD? If false, it should not be a selctable option
     * @type {boolean}
     * @memberof TargetComputeMetadata
     */
    supported?: boolean;
    /**
     * Link to documentation for the target compute
     * @type {string}
     * @memberof TargetComputeMetadata
     */
    docLink?: string;
    /**
     * 
     * @type {CloudProvider}
     * @memberof TargetComputeMetadata
     */
    cloudProvider?: CloudProvider;
    /**
     * Link to the SVG for the target compute
     * @type {string}
     * @memberof TargetComputeMetadata
     */
    imageLink?: string;
}

/**
 * Check if a given object implements the TargetComputeMetadata interface.
 */
export function instanceOfTargetComputeMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TargetComputeMetadataFromJSON(json: any): TargetComputeMetadata {
    return TargetComputeMetadataFromJSONTyped(json, false);
}

export function TargetComputeMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetComputeMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : TargetComputeFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'supported': !exists(json, 'supported') ? undefined : json['supported'],
        'docLink': !exists(json, 'docLink') ? undefined : json['docLink'],
        'cloudProvider': !exists(json, 'cloudProvider') ? undefined : CloudProviderFromJSON(json['cloudProvider']),
        'imageLink': !exists(json, 'imageLink') ? undefined : json['imageLink'],
    };
}

export function TargetComputeMetadataToJSON(value?: TargetComputeMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': TargetComputeToJSON(value.id),
        'name': value.name,
        'supported': value.supported,
        'docLink': value.docLink,
        'cloudProvider': CloudProviderToJSON(value.cloudProvider),
        'imageLink': value.imageLink,
    };
}

