import { type ReactElement } from "react";

import { IconsList } from "../icons";

export enum BannerTypes {
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
  INFO = "info",
}

export const BannerColors = {
  [BannerTypes.SUCCESS]:
    "tw-bg-green-900 tw-text-green-200 tw-border-green-700",
  [BannerTypes.WARNING]:
    "tw-bg-yellow-900 tw-text-yellow-200 tw-border-yellow-700",
  [BannerTypes.DANGER]: "tw-bg-red-900 tw-text-red-200 tw-border-red-700",
  [BannerTypes.INFO]: "tw-bg-blue-900 tw-text-blue-200 tw-border-blue-700",
};

export const BannerIcons = {
  [BannerTypes.SUCCESS]: IconsList.CHECK_CIRCLE,
  [BannerTypes.WARNING]: IconsList.WARNING,
  [BannerTypes.DANGER]: IconsList.WARNING,
  [BannerTypes.INFO]: IconsList.INFO_CIRCLE,
};

export interface BannerProps {
  type: BannerTypes;
  title?: string;
  dismissible?: boolean;
  children?: ReactElement;
  dataTestId?: string;
  onDismiss?: () => void;
}
