import { getformValidationSchema, type FieldSchema } from "./form-validations";

type PropertiesType = { [key: string]: any };

const getDictionaryValuesToFormik = (value: any) => {
  return Object.entries(value).map((item: any) => {
    return { key: item[0], value: item[1] };
  });
};

const getListValuesToFormik = (value: any) => {
  return value.map((item: any) => {
    return { key: item };
  });
};

const getObjectListValuesToFormik = (
  value: PropertiesType[],
  schema: PropertiesType[]
) => {
  return value.map((valObj: PropertiesType) => {
    const updatedValObj: PropertiesType = {};
    Object.keys(valObj).forEach((objKey: string) => {
      const objKey_properties = schema?.find(
        (field: any) => field.key === objKey
      );
      if (Array.isArray(valObj[objKey])) {
        updatedValObj[objKey] = getListValuesToFormik(valObj[objKey]);
      } else if (objKey_properties?.type === "dictionary") {
        // Handling for dictionary type inside object_list
        updatedValObj[objKey] = getDictionaryValuesToFormik(valObj[objKey]);
      } else {
        updatedValObj[objKey] = valObj[objKey];
      }
    });
    return updatedValObj;
  });
};

const getDictionaryValuesToAttribute = (
  value: any,
  property: PropertiesType
) => {
  return Object.fromEntries(
    value?.map((item: PropertiesType) => {
      return [
        convertValuesToDataType(item.key, property?.validation?.dataType),
        convertValuesToDataType(item.value, property?.validation?.dataType),
      ];
    })
  );
};

const getListValuesToAttribute = (value: any, property: PropertiesType) => {
  return value.map((item: PropertiesType) => {
    return convertValuesToDataType(item.key, property?.validation?.dataType);
  });
};

const getObjectListValuestoAttribute = (
  value: any,
  schema: PropertiesType[]
) => {
  return value.map((valObj: PropertiesType) => {
    const updatedValObj: PropertiesType = {};

    Object.keys(valObj).forEach((objKey: string) => {
      const objKey_properties = schema?.find(
        (field: any) => field.key === objKey
      );

      if (objKey_properties) {
        if (Array.isArray(valObj[objKey])) {
          // Handling for dictionary type and list type inside object_list
          updatedValObj[objKey] =
            objKey_properties?.type === "dictionary"
              ? getDictionaryValuesToAttribute(
                  valObj[objKey],
                  objKey_properties
                )
              : getListValuesToAttribute(valObj[objKey], objKey_properties);
        } else {
          updatedValObj[objKey] = convertValuesToDataType(
            valObj[objKey],
            objKey_properties?.validation?.dataType
          );
        }
      }
    });
    return updatedValObj;
  });
};

export const attributePropertiesToFormikValues = (
  attributeProperties: any,
  attributeValues: any
) => {
  return attributeProperties?.reduce?.(
    (result: PropertiesType, property: PropertiesType) => {
      if (property.type === "dictionary") {
        result[property.key] = attributeValues[property.key]
          ? getDictionaryValuesToFormik(attributeValues[property.key])
          : [];
      } else if (
        property.type === "textarea_list" ||
        property.type === "text_list"
      ) {
        result[property.key] = attributeValues[property.key]
          ? getListValuesToFormik(attributeValues[property.key])
          : [];
      } else if (property.type === "object_list") {
        if (attributeValues[property.key]) {
          result[property.key] = getObjectListValuesToFormik(
            attributeValues[property.key],
            property.schema
          );
        } else {
          result[property.key] = [];
        }
      } else {
        result[property.key] = attributeValues[property.key];
      }

      return result;
    },
    {}
  );
};

export const formikValuesToAttributeProperties = (
  formikValues: any,
  attributeProperties: any
): PropertiesType => {
  const values: any = {};
  attributeProperties.forEach((property: PropertiesType) => {
    if (property.type === "dictionary" && formikValues[property.key] !== null) {
      values[property.key] = getDictionaryValuesToAttribute(
        formikValues[property.key],
        property
      );
    } else if (
      property.type === "textarea_list" ||
      property.type === "text_list"
    ) {
      values[property.key] = getListValuesToAttribute(
        formikValues[property.key],
        property
      );
    } else if (property.type === "object_list") {
      values[property.key] = getObjectListValuestoAttribute(
        formikValues[property.key],
        property.schema
      );
    } else {
      values[property.key] = convertValuesToDataType(
        formikValues[property.key],
        property?.validation?.dataType
      );
    }
  });

  return values;
};

export const formikValuesValidationSchema = (attributeProperties: any) => {
  const formFieldsSchema: FieldSchema[] = [];

  attributeProperties?.forEach?.((property: PropertiesType) => {
    if (property?.validation?.dataType) {
      if (property.type === "dictionary") {
        formFieldsSchema.push({
          field_name: property.key,
          field_type: "field_array",
          data_type: "object_array",
          required: property.validation?.required,
          meta_data: [
            {
              field_name: "key",
              field_type: "text",
              data_type: "string",
              required: true,
            },
            {
              field_name: "value",
              field_type: "text",
              data_type: "string",
              required: true,
            },
          ],
        });
      } else if (
        property.type === "textarea_list" ||
        property.type === "text_list"
      ) {
        formFieldsSchema.push({
          field_name: property.key,
          field_type: "field_array",
          data_type: "string_array",
          required: property.validation?.required,
          meta_data: [
            {
              field_name: "key",
              field_type: "text",
              data_type: property.validation.dataType,
              required: true,
            },
          ],
        });
      } else if (property.type === "object_list") {
        formFieldsSchema.push({
          field_name: property.key,
          field_type: "field_array",
          data_type: "object_array",
          required: property.validation?.required,
          meta_data: [
            ...property.schema.map((field: any) => {
              if (
                field.type === "textarea_list" ||
                field.type === "text_list" ||
                field.type === "dictionary"
              ) {
                return null;
              } else {
                return {
                  field_name: field.key,
                  field_type: "text",
                  data_type: field?.validation?.dataType,
                  required: field?.validation?.required,
                };
              }
            }),
          ],
        });
      } else {
        formFieldsSchema.push({
          field_name: property.key,
          field_type: property.type,
          data_type: property.validation.dataType,
          required: property.validation?.required,
        });
      }
    }
  });

  return getformValidationSchema(formFieldsSchema);
};

const convertValuesToDataType = (
  value: any,
  data_type?: "string" | "bool" | "int" | "date"
) => {
  if (data_type) {
    if (data_type === "string") {
      return value;
    } else if (data_type === "bool") {
      return value === "true" || value === true;
    } else if (data_type === "int") {
      return parseInt(value);
    } else if (data_type === "date") {
      return new Date(value);
    }
  }

  return value;
};
