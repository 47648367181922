import * as amplitude from "@amplitude/analytics-browser";

// To initialize Amplitude
export const initAmplitude = async (enabled: boolean) => {
  if (enabled) {
    await amplitude.init("c0c878cfe328c731038a7e6e35cb2c20", {
      serverUrl:
        typeof window !== "undefined"
          ? `${window.location.origin}/amplitude`
          : undefined,
      defaultTracking: {
        pageViews: {
          trackOn: () =>
            typeof window !== "undefined"
              ? !window.location.pathname.includes("login")
              : true,
        },
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
    }).promise;
  }
};

// To get amplitude session id
export const getAmplitudeSessionId = () => {
  return amplitude.getSessionId();
};

// To set UserId when user logs in
export const setAmplitudeUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

// To unset UserId when logging out user or login session is invalid
export const resetAmplitudeUser = () => {
  amplitude.reset();
};

// To track amplitude events along with event properties
export const trackAmplitudeEvent = (
  eventName: string,
  eventProperties?: { [key: string]: any }
) => {
  amplitude.logEvent(eventName, {
    custom_page_url: typeof window !== "undefined" ? window.location.href : "",
    ...(eventProperties ?? {}),
  });
};

export const checkIsProdEnv = () => {
  let isProd = false;
  // TODO: try to pass it as a param when it is implemented with SSR
  if (typeof window !== "undefined") {
    isProd = window.location.hostname === "cloud.stackgen.com";
  }
  return isProd;
};
