import { type GetPaginationFnParam } from "./pagination-types";

export const DOTS = "...";

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

/**
 * Generates a pagination range based on the given parameters.
 *
 * @param {GetPaginationFnParam} param - The pagination parameters.
 * @param {number} param.totalRecordCount - The total number of records.
 * @param {number} param.recordPerPage - The number of records per page.
 * @param {number} [param.siblingCount=1] - The number of sibling pages to show on each side.
 * @param {number} param.currentPage - The current page number.
 * @return {(number | string)[]} The pagination range.
 */
export const getPaginationRange = ({
  totalRecordCount,
  recordPerPage,
  siblingCount = 1,
  currentPage,
}: GetPaginationFnParam) => {
  const totalPageCount = Math.ceil(totalRecordCount / recordPerPage);

  // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
  const totalPageNumbers = siblingCount + 5;

  /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount)?.length > 0
      ? range(1, totalPageCount)
      : [1];
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(
    currentPage + siblingCount,
    totalPageCount
  );

  /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
  const showLeftDots = leftSiblingIndex > 2;
  const showRightDots = rightSiblingIndex < totalPageCount - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;

  if (!showLeftDots && showRightDots) {
    let leftItemCount = 3 + 2 * siblingCount;
    let leftRange = range(1, leftItemCount);
    return [...leftRange, DOTS, totalPageCount];
  } else if (showLeftDots && !showRightDots) {
    let rightItemCount = 3 + 2 * siblingCount;
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
    return [firstPageIndex, DOTS, ...rightRange];
  } else if (showLeftDots && showRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }
};
