import React from "react";
import type { ToastProps } from "./toast-types";

import { Icon, IconsList, IconSize, Button } from "../../components";

const Toast = ({ notifications = [], onDismissAll = () => {} }: ToastProps) => {
  return (
    <div className="tw-fixed tw-top-3 tw-right-3 tw-z-60">
      {notifications.length > 1 && (
        <div className="tw-flex tw-flex-row-reverse">
          <Button
            label="Clear All"
            variant="secondary"
            onClick={() => onDismissAll()}
            dataTestId="clear-all-toast"
          />
        </div>
      )}
      <div className="tw-overflow-y-auto tw-max-h-[94vh]">
        {notifications.map((notification, index) => {
          const borderColor =
            notification.type === "info"
              ? "tw-border-blue-500"
              : notification.type === "error"
              ? "tw-border-red-500"
              : notification.type === "success"
              ? "tw-border-green-500"
              : "tw-border-yellow-500";

          const textColor =
            notification.type === "info"
              ? "tw-text-blue-500"
              : notification.type === "error"
              ? "tw-text-red-500"
              : notification.type === "success"
              ? "tw-text-green-500"
              : "tw-text-yellow-500";

          const iconName =
            notification.type === "info"
              ? IconsList.INFO_CIRCLE
              : notification.type === "error"
              ? IconsList.WARNING
              : notification.type === "success"
              ? IconsList.CHECK_CIRCLE
              : IconsList.WARNING;

          return (
            <div
              key={`toast-${index}`}
              className={`tw-rounded-md tw-p-3 tw-border-l-3 ${borderColor} tw-mt-3 tw-bg-gray-700 tw-w-80 tw-h-fit tw-overflow-y-auto`}
              role="alert"
              data-testid={`toast-${index}`}
            >
              <div className="tw-flex tw-gap-2">
                <div className="tw-flex-none">
                  <Icon
                    name={iconName}
                    size={IconSize.lg}
                    className={`${textColor}`}
                  />
                </div>
                <div className="tw-grow">
                  <div className="tw-text-sm tw-font-semibold tw-text-gray-50">
                    {notification.title}
                  </div>
                  {notification.description && (
                    <div className="tw-mt-1 tw-text-xssm tw-text-gray-300">
                      {notification.description}
                    </div>
                  )}
                </div>
                {notification.dismissable && (
                  <div
                    className="tw-flex-none"
                    onClick={() => {
                      notification?.onDismiss?.(notification.id as string);
                    }}
                    data-testid={`dismiss-toast-${index}`}
                  >
                    <Icon
                      name={IconsList.CLOSE}
                      className={`tw-text-gray-300 tw-cursor-pointer`}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Toast;
