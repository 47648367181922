/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base component for a database connection.
 * @export
 * @interface ConnectionDetails
 */
export interface ConnectionDetails {
    /**
     * The database name. For Redis, this might be a number.
     * @type {string}
     * @memberof ConnectionDetails
     */
    database?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDetails
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDetails
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDetails
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDetails
     */
    port?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionDetails
     */
    tls?: boolean;
}

/**
 * Check if a given object implements the ConnectionDetails interface.
 */
export function instanceOfConnectionDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectionDetailsFromJSON(json: any): ConnectionDetails {
    return ConnectionDetailsFromJSONTyped(json, false);
}

export function ConnectionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'database': !exists(json, 'database') ? undefined : json['database'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'tls': !exists(json, 'tls') ? undefined : json['tls'],
    };
}

export function ConnectionDetailsToJSON(value?: ConnectionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'database': value.database,
        'username': value.username,
        'password': value.password,
        'host': value.host,
        'port': value.port,
        'tls': value.tls,
    };
}

