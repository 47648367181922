/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentTraitsDependenciesAwsSqsInner
 */
export interface ComponentTraitsDependenciesAwsSqsInner {
    /**
     * 
     * @type {string}
     * @memberof ComponentTraitsDependenciesAwsSqsInner
     */
    queue?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentTraitsDependenciesAwsSqsInner
     */
    keyType?: ComponentTraitsDependenciesAwsSqsInnerKeyTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComponentTraitsDependenciesAwsSqsInner
     */
    actions?: Array<string>;
}


/**
 * @export
 */
export const ComponentTraitsDependenciesAwsSqsInnerKeyTypeEnum = {
    Arn: 'ARN',
    Name: 'NAME',
    Url: 'URL'
} as const;
export type ComponentTraitsDependenciesAwsSqsInnerKeyTypeEnum = typeof ComponentTraitsDependenciesAwsSqsInnerKeyTypeEnum[keyof typeof ComponentTraitsDependenciesAwsSqsInnerKeyTypeEnum];


/**
 * Check if a given object implements the ComponentTraitsDependenciesAwsSqsInner interface.
 */
export function instanceOfComponentTraitsDependenciesAwsSqsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesAwsSqsInnerFromJSON(json: any): ComponentTraitsDependenciesAwsSqsInner {
    return ComponentTraitsDependenciesAwsSqsInnerFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesAwsSqsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependenciesAwsSqsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'keyType': !exists(json, 'keyType') ? undefined : json['keyType'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
    };
}

export function ComponentTraitsDependenciesAwsSqsInnerToJSON(value?: ComponentTraitsDependenciesAwsSqsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'queue': value.queue,
        'keyType': value.keyType,
        'actions': value.actions,
    };
}

