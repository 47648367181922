/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentTraits } from './ComponentTraits';
import {
    ComponentTraitsFromJSON,
    ComponentTraitsFromJSONTyped,
    ComponentTraitsToJSON,
} from './ComponentTraits';

/**
 * 
 * @export
 * @interface AppManifest
 */
export interface AppManifest {
    /**
     * Semver version format of the manifest
     * @type {string}
     * @memberof AppManifest
     */
    version?: string;
    /**
     * Graph to denote the intra-components dependencies.
     * Each entry `A -> [B, C, ...]` denotes a component named `A` as a consumer of components `[B, C, ...]`, 
     * defined as `A` containing at least one egress call matching at least one ingress endpoint in each component
     * `[B, C, ...]`.
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AppManifest
     */
    graph?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {{ [key: string]: ComponentTraits; }}
     * @memberof AppManifest
     */
    components?: { [key: string]: ComponentTraits; };
}

/**
 * Check if a given object implements the AppManifest interface.
 */
export function instanceOfAppManifest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppManifestFromJSON(json: any): AppManifest {
    return AppManifestFromJSONTyped(json, false);
}

export function AppManifestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppManifest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'graph': !exists(json, 'graph') ? undefined : json['graph'],
        'components': !exists(json, 'components') ? undefined : (mapValues(json['components'], ComponentTraitsFromJSON)),
    };
}

export function AppManifestToJSON(value?: AppManifest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'graph': value.graph,
        'components': value.components === undefined ? undefined : (mapValues(value.components, ComponentTraitsToJSON)),
    };
}

