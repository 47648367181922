/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionType } from './QuestionType';
import {
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';

/**
 * 
 * @export
 * @interface AnswerRequestResponse
 */
export interface AnswerRequestResponse {
    /**
     * 
     * @type {QuestionType}
     * @memberof AnswerRequestResponse
     */
    dataType?: QuestionType;
    /**
     * 
     * @type {any}
     * @memberof AnswerRequestResponse
     */
    value?: any;
}

/**
 * Check if a given object implements the AnswerRequestResponse interface.
 */
export function instanceOfAnswerRequestResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnswerRequestResponseFromJSON(json: any): AnswerRequestResponse {
    return AnswerRequestResponseFromJSONTyped(json, false);
}

export function AnswerRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerRequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataType': !exists(json, 'dataType:') ? undefined : QuestionTypeFromJSON(json['dataType:']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AnswerRequestResponseToJSON(value?: AnswerRequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataType:': QuestionTypeToJSON(value.dataType),
        'value': value.value,
    };
}

