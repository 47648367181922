/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMe200ResponseOrgsInner
 */
export interface GetMe200ResponseOrgsInner {
    /**
     * Organization ID
     * @type {string}
     * @memberof GetMe200ResponseOrgsInner
     */
    id: string;
    /**
     * Organization name
     * @type {string}
     * @memberof GetMe200ResponseOrgsInner
     */
    name: string;
    /**
     * Role of the user in the organization
     * @type {string}
     * @memberof GetMe200ResponseOrgsInner
     */
    roleUuid: string;
    /**
     * Scopes of the user in the organization
     * @type {Array<string>}
     * @memberof GetMe200ResponseOrgsInner
     */
    scopes: Array<string>;
}

/**
 * Check if a given object implements the GetMe200ResponseOrgsInner interface.
 */
export function instanceOfGetMe200ResponseOrgsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "roleUuid" in value;
    isInstance = isInstance && "scopes" in value;

    return isInstance;
}

export function GetMe200ResponseOrgsInnerFromJSON(json: any): GetMe200ResponseOrgsInner {
    return GetMe200ResponseOrgsInnerFromJSONTyped(json, false);
}

export function GetMe200ResponseOrgsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMe200ResponseOrgsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'roleUuid': json['role_uuid'],
        'scopes': json['scopes'],
    };
}

export function GetMe200ResponseOrgsInnerToJSON(value?: GetMe200ResponseOrgsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'role_uuid': value.roleUuid,
        'scopes': value.scopes,
    };
}

