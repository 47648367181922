import type { ReactNode } from "react";

export enum TooltipPositions {
  TOP = "top",
  TOP_START = "top-start",
  TOP_END = "top-end",
  RIGHT = "right",
  RIGHT_START = "right-start",
  RIGHT_END = "right-end",
  BOTTOM = "bottom",
  BOTTOM_START = "bottom-start",
  BOTTOM_END = "bottom-end",
  LEFT = "left",
  LEFT_START = "left-start",
  LEFT_END = "left-end",
}

export type TooltipProps = {
  children: ReactNode;
  title?: string;
  position?: TooltipPositions;
  offset?: number; // space between element and tooltip
  float?: boolean; // float tooltip along with cursor
  className?: string;
};
