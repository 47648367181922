/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportedTfDataType
 */
export interface SupportedTfDataType {
    /**
     * 
     * @type {string}
     * @memberof SupportedTfDataType
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedTfDataType
     */
    uiComponent?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedTfDataType
     */
    validationType?: string;
}

/**
 * Check if a given object implements the SupportedTfDataType interface.
 */
export function instanceOfSupportedTfDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupportedTfDataTypeFromJSON(json: any): SupportedTfDataType {
    return SupportedTfDataTypeFromJSONTyped(json, false);
}

export function SupportedTfDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedTfDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'uiComponent': !exists(json, 'uiComponent') ? undefined : json['uiComponent'],
        'validationType': !exists(json, 'validationType') ? undefined : json['validationType'],
    };
}

export function SupportedTfDataTypeToJSON(value?: SupportedTfDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'uiComponent': value.uiComponent,
        'validationType': value.validationType,
    };
}

