/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupportedResourceType } from './SupportedResourceType';
import {
    SupportedResourceTypeFromJSON,
    SupportedResourceTypeFromJSONTyped,
    SupportedResourceTypeToJSON,
} from './SupportedResourceType';

/**
 * 
 * @export
 * @interface ProviderWiseSupportedResourceType
 */
export interface ProviderWiseSupportedResourceType {
    /**
     * 
     * @type {Array<SupportedResourceType>}
     * @memberof ProviderWiseSupportedResourceType
     */
    aws?: Array<SupportedResourceType>;
    /**
     * 
     * @type {Array<SupportedResourceType>}
     * @memberof ProviderWiseSupportedResourceType
     */
    azurerm?: Array<SupportedResourceType>;
    /**
     * 
     * @type {Array<SupportedResourceType>}
     * @memberof ProviderWiseSupportedResourceType
     */
    gcp?: Array<SupportedResourceType>;
    /**
     * 
     * @type {Array<SupportedResourceType>}
     * @memberof ProviderWiseSupportedResourceType
     */
    helm?: Array<SupportedResourceType>;
    /**
     * 
     * @type {Array<SupportedResourceType>}
     * @memberof ProviderWiseSupportedResourceType
     */
    general?: Array<SupportedResourceType>;
    /**
     * 
     * @type {Array<SupportedResourceType>}
     * @memberof ProviderWiseSupportedResourceType
     */
    resourcePacks?: Array<SupportedResourceType>;
}

/**
 * Check if a given object implements the ProviderWiseSupportedResourceType interface.
 */
export function instanceOfProviderWiseSupportedResourceType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProviderWiseSupportedResourceTypeFromJSON(json: any): ProviderWiseSupportedResourceType {
    return ProviderWiseSupportedResourceTypeFromJSONTyped(json, false);
}

export function ProviderWiseSupportedResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderWiseSupportedResourceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aws': !exists(json, 'aws') ? undefined : ((json['aws'] as Array<any>).map(SupportedResourceTypeFromJSON)),
        'azurerm': !exists(json, 'azurerm') ? undefined : ((json['azurerm'] as Array<any>).map(SupportedResourceTypeFromJSON)),
        'gcp': !exists(json, 'gcp') ? undefined : ((json['gcp'] as Array<any>).map(SupportedResourceTypeFromJSON)),
        'helm': !exists(json, 'helm') ? undefined : ((json['helm'] as Array<any>).map(SupportedResourceTypeFromJSON)),
        'general': !exists(json, 'general') ? undefined : ((json['general'] as Array<any>).map(SupportedResourceTypeFromJSON)),
        'resourcePacks': !exists(json, 'resourcePacks') ? undefined : ((json['resourcePacks'] as Array<any>).map(SupportedResourceTypeFromJSON)),
    };
}

export function ProviderWiseSupportedResourceTypeToJSON(value?: ProviderWiseSupportedResourceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aws': value.aws === undefined ? undefined : ((value.aws as Array<any>).map(SupportedResourceTypeToJSON)),
        'azurerm': value.azurerm === undefined ? undefined : ((value.azurerm as Array<any>).map(SupportedResourceTypeToJSON)),
        'gcp': value.gcp === undefined ? undefined : ((value.gcp as Array<any>).map(SupportedResourceTypeToJSON)),
        'helm': value.helm === undefined ? undefined : ((value.helm as Array<any>).map(SupportedResourceTypeToJSON)),
        'general': value.general === undefined ? undefined : ((value.general as Array<any>).map(SupportedResourceTypeToJSON)),
        'resourcePacks': value.resourcePacks === undefined ? undefined : ((value.resourcePacks as Array<any>).map(SupportedResourceTypeToJSON)),
    };
}

