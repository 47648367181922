import React, {
  forwardRef,
  type FC,
  type ChangeEvent,
  useEffect,
  useState,
} from "react";
import { Field, FieldMetaProps, type FieldProps } from "formik";
import { Icon, IconsList, IconSize } from "../../components";
import { isFunction } from "../../utils";
import { type TextBoxProps } from "./text-box-types";
import { BackgroundVariant, LabelVariant } from "./form-elements-types";

/**
 * Renders a custom text box input component.
 * @component
 *
 * - @param {string} name - The name of the text box.
 * - @param {string} [type="text"] - The type of the text box.
 * - @param {string} [label] - The label for the text box.
 * - @param {string | ReactElement} [description] - The description for the text box.
 * - @param {string} [placeholder] - The placeholder text for the text box.
 * - @param {string} [className=""] - The CSS class for the text box.
 * - @param {ReactElement} [prefix] - The prefix element for the text box.
 * - @param {ReactElement} [suffix] - The suffix element for the text box.
 * - @param {string} [subLabel=""] - The sub-label for the text box.
 * - @param {boolean} [disabled=false] - Specifies whether the text box is disabled or not.
 * - @param {boolean} [isClearable=false] - Specifies whether the text box is clearable or not.
 * - @param {boolean} [isOptional=false] - Specifies whether the text box is optional or not.
 * - @param {keyof typeof BackgroundVariant} [backgroundVariant="gray-900"] - The background variant of the text box.
 * - @param {boolean} [allowPasswordVisibilityToggle=false] - Specifies whether the text box allows showing password or not.
 * - @param {Date} [maxDate] - The maximum date for the text box in YYYY-MM-DD format.
 * - @param {Date} [minDate] - The minumum date for the text box in YYYY-MM-DD format.
 * - @param {function} [onChange] - The callback function called when the value of the text box changes.
 * - @param {string} [dataTestId] - The data test id for the text box.
 * - @param {boolean} [required] - Specifies whether the text box is required or not(to show required * mark).
 * - @param {string | ReactElement} [labelSuffix] - The suffix for the label.
 * - @param {function} [labelSuffixAction] - The action to be performed on clicking the label suffix.
 *
 * @returns {JSX.Element} The rendered TextBox component.
 */
const TextBox = forwardRef<HTMLInputElement, TextBoxProps>(
  (
    {
      name,
      type = "text",
      label,
      description,
      placeholder,
      className = "",
      prefix,
      suffix,
      subLabel = "",
      disabled = false,
      isClearable = false,
      isOptional,
      backgroundVariant = "gray-900",
      labelVariant = "white-semibold",
      allowPasswordVisibilityToggle = false,
      maxDate,
      minDate,
      dataTestId,
      required,
      onChange,
      labelSuffix,
      labelSuffixAction,
    }: TextBoxProps,
    ref
  ) => {
    const [inputType, setInputType] = useState(type);
    useEffect(() => {
      setInputType(type);
    }, [type]);

    const LabelComponent: FC<void> = () => (
      <>
        {(label || subLabel) && (
          <div className="tw-flex tw-items-center tw-justify-between">
            {
              <>
                {label && (
                  <label
                    className={`tw-text-xssm ${
                      disabled
                        ? LabelVariant["gray400-semibold"]
                        : LabelVariant[labelVariant] ?? ""
                    }`}
                    htmlFor={name}
                    data-testid={`${dataTestId}-text-box-label`}
                  >
                    {label}
                    {isOptional ? (
                      <span
                        className={` tw-font-normal ${
                          disabled ? "tw-text-gray-400" : "tw-text-gray-200"
                        }`}
                        data-testid={`${dataTestId}-text-box-optional-sub-label`}
                      >
                        {" "}
                        (Optional)
                      </span>
                    ) : (
                      required && <span className="tw-text-red-400"> *</span>
                    )}
                  </label>
                )}
                {labelSuffix && (
                  <label
                    className="tw-cursor-pointer"
                    data-testid={`textbox-labelsuffix-${dataTestId}`}
                    onClick={() => {
                      labelSuffixAction &&
                        isFunction(labelSuffixAction) &&
                        labelSuffixAction();
                    }}
                  >
                    {labelSuffix}
                  </label>
                )}
                {subLabel && (
                  <label
                    className={`tw-text-xs tw-italic ${
                      disabled ? "tw-text-gray-400" : "tw-text-white"
                    }`}
                    data-testid={`${dataTestId}-text-box-sub-label`}
                  >
                    {subLabel}
                  </label>
                )}
              </>
            }
          </div>
        )}
      </>
    );

    const FooterComponent: FC<FieldMetaProps<any>> = (meta) => (
      <div>
        {meta.touched && meta.error ? (
          <div
            className="tw-text-xs tw-text-red-400"
            data-testid={`${dataTestId}-text-box-error`}
          >
            {meta.error}
          </div>
        ) : null}
        {description && (
          <p
            className={`tw-text-xs tw-break-all ${
              disabled ? "tw-text-gray-400" : "tw-text-gray-300"
            }`}
            data-testid={`${dataTestId}-text-box-description`}
          >
            {description}
          </p>
        )}
      </div>
    );

    return (
      <Field name={name}>
        {({ form, field, meta }: FieldProps) => {
          return (
            <div className="tw-flex tw-flex-col tw-gap-1">
              {LabelComponent()}
              <div className="tw-relative tw-h-fit">
                {prefix && (
                  <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-p-2">
                    {prefix}
                  </div>
                )}

                <input
                  {...field}
                  value={field.value ?? ""}
                  ref={ref}
                  type={inputType}
                  placeholder={placeholder ?? undefined}
                  className={`tw-block tw-w-full tw-text-sm tw-py-1.25 tw-ring-inset tw-rounded-md tw-p-2.5 tw-placeholder-gray-400 focus:tw-ring-0.5 placeholder:tw-font-normal ${
                    prefix ? "tw-pl-8" : ""
                  } ${suffix ? "tw-pr-11" : ""} ${
                    disabled
                      ? `tw-bg-gray-500 tw-bg-opacity-20 tw-text-gray-400 tw-placeholder-gray-500 tw-border-none tw-cursor-not-allowed ${
                          meta.touched && meta.error ? "error-field" : ""
                        }`
                      : `tw-border tw-text-gray-100 tw-placeholder-gray-400  ${
                          meta.touched && meta.error
                            ? "tw-border-red-400 error-field"
                            : "tw-border-gray-600 hover:tw-border-gray-500"
                        } ${BackgroundVariant[backgroundVariant] ?? ""}`
                  } ${className ? className : ""}`}
                  onChange={(e) => {
                    form.setFieldValue(name, e.target.value);
                    isFunction(onChange) && onChange?.(e);
                  }}
                  disabled={disabled}
                  max={inputType === "date" ? maxDate : undefined}
                  min={inputType === "date" ? minDate : undefined}
                  data-testid={`${dataTestId}-text-box`}
                  autoComplete="off" // TEMPORARY: disabling autocomplete since we don't have control over its stylings
                />

                {isClearable
                  ? !!field?.value && ( // show cancel icon if textbox is not empty and isClearable is true
                      <div
                        onClick={() => {
                          // clearing out input text on cancel click
                          form.setFieldValue(name, "");
                          const event = {
                            target: { value: "" },
                            currentTarget: { value: "" },
                            type: "change",
                          };
                          isFunction(onChange) &&
                            onChange?.(event as ChangeEvent<HTMLInputElement>);
                        }}
                        className="tw-z-10 tw-self-center tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3"
                        data-testid={`${dataTestId}-text-box-clear-button`}
                      >
                        <Icon
                          name={IconsList.CLOSE}
                          size={IconSize.sm}
                          className="tw-text-gray-400 tw-cursor-pointer hover:tw-text-gray-300"
                        />
                      </div>
                    )
                  : allowPasswordVisibilityToggle
                  ? type === "password" && ( // show cancel icon if textbox is not empty and isClearable is true
                      <div
                        onClick={() => {
                          setInputType((prevType) =>
                            prevType === "password" ? "text" : "password"
                          );
                        }}
                        className="tw-self-center tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3"
                        role="button"
                        data-testid={`${dataTestId}-text-box-toggle-password-button`}
                      >
                        <Icon
                          name={
                            inputType === "password"
                              ? IconsList.EYE_ALT
                              : IconsList.EYE_SLASH_ALT
                          }
                          size={IconSize.sm}
                          className="tw-text-gray-400 tw-cursor-pointer hover:tw-text-gray-300"
                        />
                      </div>
                    )
                  : null}

                {suffix && (
                  <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
                    {suffix}
                  </div>
                )}
              </div>
              {FooterComponent(meta)}
            </div>
          );
        }}
      </Field>
    );
  }
);

export default TextBox;
