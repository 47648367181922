/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Current status of the appStack. When completed, Topology will be available. This value is updated asynchronously by the service
 * @export
 */
export const Status = {
    InProgress: 'in-progress',
    Failed: 'failed',
    Completed: 'completed'
} as const;
export type Status = typeof Status[keyof typeof Status];


export function StatusFromJSON(json: any): Status {
    return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
    return json as Status;
}

export function StatusToJSON(value?: Status | null): any {
    return value as any;
}

