/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Port
 */
export interface Port {
    /**
     * 
     * @type {number}
     * @memberof Port
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof Port
     */
    transport?: PortTransportEnum;
}


/**
 * @export
 */
export const PortTransportEnum = {
    Tcp: 'TCP',
    Udp: 'UDP'
} as const;
export type PortTransportEnum = typeof PortTransportEnum[keyof typeof PortTransportEnum];


/**
 * Check if a given object implements the Port interface.
 */
export function instanceOfPort(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function PortFromJSON(json: any): Port {
    return PortFromJSONTyped(json, false);
}

export function PortFromJSONTyped(json: any, ignoreDiscriminator: boolean): Port {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'transport': !exists(json, 'transport') ? undefined : json['transport'],
    };
}

export function PortToJSON(value?: Port | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'transport': value.transport,
    };
}

