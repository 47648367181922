import type { Node } from "reactflow";
import type {
  SupportedResourceType,
  Topology,
  TopologyResourceConnection,
} from "../apis/topology";
import type {
  Resource,
  ResourceConnection,
  TopologyErrorsType,
} from "../components/topology-graph/topology-graph-types";
import { generateEdgeId } from "../components/topology-graph/utils/topology-utils";
import { resourcePackIdentifier } from "../config/constants";

export const updateResourceDataInTopology = (
  resources: any,
  resourceToUpdateId: string,
  updatedResourceData: any,
  replace: boolean = false
) => {
  resources.every((resource: any, resource_index: number) => {
    if (resource.id === resourceToUpdateId) {
      if (replace) {
        resources[resource_index] = updatedResourceData;
      } else {
        resources[resource_index] = {
          ...resources[resource_index],
          ...updatedResourceData,
        };
      }

      return false;
    } else if (resource?.children?.length > 0) {
      resources[resource_index] = {
        ...resources[resource_index],
        children: updateResourceDataInTopology(
          resources[resource_index].children,
          resourceToUpdateId,
          updatedResourceData,
          replace
        ),
      };
    }

    return true;
  });

  return resources;
};

export const findResourceInTopology = (
  resources: any,
  resourceIdtoFind: string
) => {
  let resource: any = null;

  for (let i = 0; i < resources.length; i++) {
    if (resources[i].id === resourceIdtoFind) {
      resource = resources[i];
      break;
    } else if (resources[i]?.children?.length > 0) {
      resource = findResourceInTopology(
        resources[i].children,
        resourceIdtoFind
      );
      if (resource) {
        break;
      }
    }
  }

  return resource;
};

export const addResourceInTopology = (resources: any, resourceToAdd: any) => {
  resources.forEach((resource: any, resource_index: number) => {
    if (resource.id === resourceToAdd.group) {
      resources[resource_index] = {
        ...resources[resource_index],
        children: [...resources[resource_index].children, resourceToAdd],
      };
    } else if (resource?.children?.length > 0) {
      resources[resource_index] = {
        ...resources[resource_index],
        children: addResourceInTopology(
          resources[resource_index].children,
          resourceToAdd
        ),
      };
    }
  });

  return resources;
};

export const findChildNodeAbsolutePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((n) => n.id === currentNode.parentNode);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findChildNodeAbsolutePosition(parentNode, allNodes);

  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

export const traverseEachResource = async (
  resources: any,
  currentResourceCallback: (resource: any) => any
) => {
  for (const resource of resources) {
    await currentResourceCallback(resource);
    if (resource?.children?.length > 0) {
      await traverseEachResource(resource?.children, currentResourceCallback);
    }
  }
};

export const traverseAndUpdateEachResource = (
  resources: any,
  updateResourceDataCallback: (resource: any) => any
) => {
  for (let i = 0; i < resources?.length; i++) {
    resources[i] = updateResourceDataCallback(resources[i]);
    if (resources[i]?.children?.length > 0) {
      resources[i] = {
        ...resources[i],
        children: traverseAndUpdateEachResource(
          resources[i].children,
          updateResourceDataCallback
        ),
      };
    }
  }

  return resources;
};

// extract custom, built-in, data resource providers and resource packs since supported_resource_type api returns combined list.
export const getResourcesByCategory: (
  resourceListForProvider: SupportedResourceType[] | undefined
) => {
  builtInResources: SupportedResourceType[];
  customResources: SupportedResourceType[];
  dataResources: SupportedResourceType[];
  resourcePacks: SupportedResourceType[];
} = (resourceListForProvider) => {
  if (!resourceListForProvider) {
    return {
      builtInResources: [],
      customResources: [],
      dataResources: [],
      resourcePacks: [],
    };
  }
  const { builtInResources, customResources, dataResources, resourcePacks } = (
    resourceListForProvider || []
  ).reduce<{
    builtInResources: SupportedResourceType[];
    customResources: SupportedResourceType[];
    dataResources: SupportedResourceType[];
    resourcePacks: SupportedResourceType[];
  }>(
    (acc, resource) => {
      if (resource.isCustom) {
        acc.customResources.push(resource);
      } else if (resource.resourceType?.split("_")[0] === "data") {
        acc.dataResources.push(resource);
      } else if (resource.resourceType === resourcePackIdentifier) {
        acc.resourcePacks.push(resource);
      } else {
        acc.builtInResources.push(resource);
      }
      return acc;
    },
    {
      builtInResources: [],
      customResources: [],
      dataResources: [],
      resourcePacks: [],
    }
  );

  return { builtInResources, customResources, dataResources, resourcePacks };
};

export const processGraphData = (
  graphData: Topology,
  selected_node?: any,
  hideUnSupportedResources: boolean = false,
  unsupportedResourceTypes: string[] = [],
  showTopologyDiff: boolean = false,
  topologyErrors?: TopologyErrorsType | undefined,
  group_resources_expanded: any = {},
  updateGroupResourcesExpanded?: any,
  onEdgeConnectionTypeClick?: (
    selectedConnectionTypeData: any
  ) => Promise<void>,
  setUnsupportedResourcesExists?: any,
  restrictedResources?: { [key: string]: SupportedResourceType },
  setRestrictedResourcesInTopology?: any
) => {
  const temp_graph_data: {
    resources: Resource[];
    resourceConnections: { [key: string]: ResourceConnection };
  } = { resources: [], resourceConnections: {} };

  const restrictedResourcesInTopology: { [resourceId: string]: Resource } = {};

  let unsupportedResourcesCount = 0;

  graphData?.resources?.forEach((resource: any) => {
    const isRestrictedResource =
      restrictedResources?.[resource?.resourceType as string] !== undefined;
    if (isRestrictedResource) {
      restrictedResourcesInTopology[resource?.id as string] = resource;
    }
    if (
      resource?.isGroup &&
      resource?.groupType &&
      resource?.resourceType !== "group"
    ) {
      temp_graph_data.resources?.push({
        ...resource,
        selected: selected_node?.id === resource?.id,
        hidden:
          hideUnSupportedResources &&
          unsupportedResourceTypes.includes(resource?.resourceType),
        errors: showTopologyDiff
          ? undefined
          : topologyErrors?.resourcesWithErrors?.[resource?.id as string],
        external_resource: resource?.resourceType === "aws_external",
        isRestricted: isRestrictedResource,
        children:
          resource?.children?.length > 0
            ? traverseAndUpdateEachResource(
                resource?.children,
                (child_resource: any) => {
                  const isRestrictedChildResource =
                    restrictedResources?.[
                      child_resource?.resourceType as string
                    ] !== undefined;
                  if (isRestrictedChildResource) {
                    restrictedResourcesInTopology[
                      child_resource?.id as string
                    ] = child_resource;
                  }
                  return {
                    ...child_resource,
                    selected: selected_node?.id === child_resource?.id,
                    hidden:
                      hideUnSupportedResources &&
                      unsupportedResourceTypes.includes(
                        child_resource?.resourceType
                      ),
                    errors: showTopologyDiff
                      ? undefined
                      : topologyErrors?.resourcesWithErrors?.[
                          child_resource?.id as string
                        ],
                    external_resource:
                      child_resource?.resourceType === "aws_external",
                    isRestricted: isRestrictedChildResource,
                  };
                }
              )
            : undefined,
      });
    } else if (resource?.resourceType === "group") {
      temp_graph_data.resources?.push({
        ...resource,
        expanded: showTopologyDiff
          ? true
          : group_resources_expanded[resource.id as string] ?? true,
        updateExpanded: function (expanded: boolean) {
          if (!showTopologyDiff) {
            updateGroupResourcesExpanded((prev: any) => ({
              ...prev,
              [resource.id as string]: expanded,
            }));
          }
        },
        selected: selected_node?.id === resource?.id,
        isRestricted: isRestrictedResource,
        children:
          resource?.children?.length > 0
            ? traverseAndUpdateEachResource(
                resource?.children,
                (child_resource: any) => {
                  const isRestrictedChildResource =
                    restrictedResources?.[
                      child_resource?.resourceType as string
                    ] !== undefined;
                  if (isRestrictedChildResource) {
                    restrictedResourcesInTopology[
                      child_resource?.id as string
                    ] = child_resource;
                  }

                  return {
                    ...child_resource,
                    // Hide child node if group node is collapsed
                    hidden: showTopologyDiff
                      ? false
                      : group_resources_expanded[child_resource?.group] ===
                        undefined
                      ? false
                      : !group_resources_expanded[child_resource?.group],
                    selected: selected_node?.id === child_resource?.id,
                    errors: showTopologyDiff
                      ? undefined
                      : topologyErrors?.resourcesWithErrors?.[
                          child_resource?.id as string
                        ],
                    external_resource:
                      child_resource?.resourceType === "aws_external",
                    isRestricted: isRestrictedChildResource,
                  };
                }
              )
            : undefined,
      });
    } else {
      temp_graph_data.resources?.push({
        ...resource,
        selected: selected_node?.id === resource?.id,
        hidden:
          hideUnSupportedResources &&
          unsupportedResourceTypes.includes(resource?.resourceType),
        errors: showTopologyDiff
          ? undefined
          : topologyErrors?.resourcesWithErrors?.[resource?.id as string],
        external_resource: resource?.resourceType === "aws_external",
        isRestricted: isRestrictedResource,
      });
    }

    // Update list of restricted resources in the topology
    setRestrictedResourcesInTopology?.(restrictedResourcesInTopology);

    // Count unsupported resources in topology
    if (unsupportedResourceTypes.includes(resource?.resourceType)) {
      unsupportedResourcesCount++;
    }
  });

  graphData?.resourceConnections?.forEach(
    (
      resource_connection: TopologyResourceConnection & {
        diffType?: "created" | "deleted";
        hidden?: boolean;
      }
    ) => {
      if (
        resource_connection.sourceResourceId &&
        resource_connection.targetResourceId
      ) {
        const edgeId = generateEdgeId(
          resource_connection.sourceResourceId,
          resource_connection.targetResourceId
        );
        let edgeIsHidden = false;

        // Check if any edge is inside the group node and group is not expanded
        const findSourceResource = findResourceInTopology(
          temp_graph_data?.resources,
          resource_connection?.sourceResourceId
        );
        const findTargetResource = findResourceInTopology(
          temp_graph_data?.resources,
          resource_connection?.targetResourceId
        );
        if (
          findSourceResource &&
          findTargetResource &&
          (findSourceResource?.hidden || findTargetResource?.hidden)
        ) {
          edgeIsHidden = true;
        }

        // Group edges based on common source and target resources, and show only one edge with multiple connection types
        if (temp_graph_data.resourceConnections[edgeId]) {
          temp_graph_data.resourceConnections[edgeId].data = {
            ...temp_graph_data.resourceConnections[edgeId].data,
            hidden:
              temp_graph_data.resourceConnections[edgeId].data.hidden ||
              resource_connection.hidden ||
              edgeIsHidden,
            connectionTypes: [
              ...temp_graph_data.resourceConnections[edgeId].data
                .connectionTypes,
              {
                ...resource_connection,
                onConnectionTypeClick: (connection_data: any) => {
                  if (onEdgeConnectionTypeClick) {
                    onEdgeConnectionTypeClick(connection_data);
                  }
                },
                selected_resource_id: selected_node?.id,
              },
            ],
          };
        } else {
          temp_graph_data.resourceConnections[edgeId] = {
            id: edgeId,
            sourceResourceId: resource_connection.sourceResourceId,
            targetResourceId: resource_connection.targetResourceId,
            data: {
              hidden: edgeIsHidden || resource_connection.hidden,
              connectionTypes: [
                {
                  ...resource_connection,
                  onConnectionTypeClick: (connection_data: any) => {
                    if (onEdgeConnectionTypeClick) {
                      onEdgeConnectionTypeClick(connection_data);
                    }
                  },
                  selected_resource_id: selected_node?.id,
                },
              ],
            },
          };
        }
      }
    }
  );

  if (setUnsupportedResourcesExists) {
    if (unsupportedResourcesCount > 0) {
      setUnsupportedResourcesExists(true);
    } else {
      setUnsupportedResourcesExists(false);
    }
  }

  return temp_graph_data;
};

// return the x & y coordinates of center of topology
export const getTopologyCenter = ({
  height,
  width,
  transform,
}: {
  height: number;
  width: number;
  transform: number[];
}) => {
  const [transformX, transformY, zoomLevel] = transform;
  const zoomMultiplier = 1 / zoomLevel;
  const centerX = -transformX * zoomMultiplier + (width * zoomMultiplier) / 2;
  const centerY = -transformY * zoomMultiplier + (height * zoomMultiplier) / 2;

  return {
    x: centerX,
    y: centerY,
  };
};

/**
 * Checks if a given node is a group node.
 * A node is considered a group node if it is of type "nestedGroupNode" (eg: vpc) or "customGroupNode",
 * or if it has a parent node (eg workload)
 */
export const isGroupNode: (node?: Node) => boolean = (node) => {
  if (!node) {
    return false;
  }
  return (
    node.type === "nestedGroupNode" ||
    node.type === "customGroupNode" ||
    !!node.parentNode
  );
};
