/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgMembersInner } from './OrgMembersInner';
import {
    OrgMembersInnerFromJSON,
    OrgMembersInnerFromJSONTyped,
    OrgMembersInnerToJSON,
} from './OrgMembersInner';

/**
 * 
 * @export
 * @interface Org
 */
export interface Org {
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Org
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof Org
     */
    createdAt?: Date;
    /**
     * 
     * @type {Array<OrgMembersInner>}
     * @memberof Org
     */
    members?: Array<OrgMembersInner>;
}

/**
 * Check if a given object implements the Org interface.
 */
export function instanceOfOrg(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrgFromJSON(json: any): Org {
    return OrgFromJSONTyped(json, false);
}

export function OrgFromJSONTyped(json: any, ignoreDiscriminator: boolean): Org {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(OrgMembersInnerFromJSON)),
    };
}

export function OrgToJSON(value?: Org | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString().substring(0,10)),
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(OrgMembersInnerToJSON)),
    };
}

