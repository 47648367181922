/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * UUID of the user
     * @type {string}
     * @memberof User
     */
    userId?: string;
    /**
     * Email of the user
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * Name of the user
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * Avatar of the user
     * @type {string}
     * @memberof User
     */
    avatar: string;
    /**
     * Company of the user
     * @type {string}
     * @memberof User
     */
    company?: string;
    /**
     * Provider of the user
     * @type {string}
     * @memberof User
     */
    loggedInUsing?: string;
    /**
     * External user Id
     * @type {string}
     * @memberof User
     */
    externalUserID?: string;
    /**
     * Role UUID of the user
     * @type {string}
     * @memberof User
     */
    role: string;
    /**
     * Scopes of the user
     * @type {Array<string>}
     * @memberof User
     */
    scopes: Array<string>;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "scopes" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'email': json['email'],
        'name': json['name'],
        'avatar': json['avatar'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'loggedInUsing': !exists(json, 'loggedInUsing') ? undefined : json['loggedInUsing'],
        'externalUserID': !exists(json, 'externalUserID') ? undefined : json['externalUserID'],
        'role': json['role'],
        'scopes': json['scopes'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'email': value.email,
        'name': value.name,
        'avatar': value.avatar,
        'company': value.company,
        'loggedInUsing': value.loggedInUsing,
        'externalUserID': value.externalUserID,
        'role': value.role,
        'scopes': value.scopes,
    };
}

