import React, { type FC } from "react";

import { type TimelineItem, type TimelineProps } from "./timeline-types";

import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
} from "../../components";

const Timeline: FC<TimelineProps> = ({ timelineItems }) => {
  return (
    <div className="tw-flex tw-flex-col">
      {timelineItems.map((timelineItem: TimelineItem, index: number) => {
        return (
          <div
            className={`tw-flex tw-relative tw-pb-3 ${
              index !== timelineItems.length - 1
                ? "tw-border-l tw-border-l-gray-300 tw-border-dashed"
                : ""
            } `}
            key={`timeline_item_${index}`}
            data-testid={`timeline-item-${index}`}
          >
            <div className="tw-ring-gray-800 tw-ring-4 tw-flex tw-justify-center tw-items-center tw-absolute tw-top-1 tw--left-1.5">
              <Icon
                name={IconsList.ALT_CIRCLE_CHECK}
                size={IconSize.xs}
                className=" tw-text-gray-50"
              />
            </div>
            <div className="tw-pl-4 tw-text-sm tw-text-gray-50">
              <div
                className=" tw-mb-3"
                data-testid={`teimline-item-title-${index}`}
              >
                {timelineItem.title}
              </div>
              <div className="tw-flex tw-flex-col tw-gap-3">
                {timelineItem?.items?.map((item, index) => {
                  return (
                    <div
                      className="tw-flex tw-items-center tw-gap-3 tw-ml-3"
                      key={`item${index}`}
                      data-testid={`item-${index}`}
                    >
                      {item?.iconImageUrl ? (
                        <img
                          className="tw-h-5"
                          src={item?.iconImageUrl}
                          alt={`${timelineItem.title}_icon`}
                        />
                      ) : Object.values(IconsList).includes(
                          item.icon as IconsList
                        ) ? (
                        <Icon
                          name={item.icon as IconsList}
                          size={item.iconSize ? item.iconSize : IconSize.lg}
                          className={`tw-fill-gray-50 ${item.iconClass ?? ""}`}
                        />
                      ) : Object.values(AWSIconsList).includes(
                          item.icon as AWSIconsList
                        ) ? (
                        <AWSIcon
                          name={item.icon as AWSIconsList}
                          size={IconSize.sm}
                        /> // TODO: remove this logic once all icons are combined in all files
                      ) : (
                        "-"
                      )}
                      <div data-testid={`item-label-${index}`}>
                        {item.label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
