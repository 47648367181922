/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserOrgRoleRequest
 */
export interface UpdateUserOrgRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOrgRoleRequest
     */
    roleId?: string;
    /**
     * uuid of the users to be updated
     * @type {Array<string>}
     * @memberof UpdateUserOrgRoleRequest
     */
    userId?: Array<string>;
}

/**
 * Check if a given object implements the UpdateUserOrgRoleRequest interface.
 */
export function instanceOfUpdateUserOrgRoleRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserOrgRoleRequestFromJSON(json: any): UpdateUserOrgRoleRequest {
    return UpdateUserOrgRoleRequestFromJSONTyped(json, false);
}

export function UpdateUserOrgRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserOrgRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'role_id') ? undefined : json['role_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function UpdateUserOrgRoleRequestToJSON(value?: UpdateUserOrgRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role_id': value.roleId,
        'user_id': value.userId,
    };
}

