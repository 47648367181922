import { ReactElement } from "react";
import { IaCFileTypes } from "../../config/constants";
import { BackgroundVariant, LabelVariant } from "./form-elements-types";

export interface TextAreaProps {
  name: string;
  label?: string;
  description?: string | ReactElement;
  placeholder?: string;
  className?: string;
  subLabel?: string;
  disabled?: boolean;
  isOptional?: boolean;
  maxCharacterLength?: number;
  backgroundVariant?: keyof typeof BackgroundVariant;
  labelVariant?: keyof typeof LabelVariant;
  rows?: number;
  editorLanguage?: IaCFileTypes;
  dataTestId: string;
  required?: boolean;
  labelSuffix?: string | ReactElement;
  labelSuffixAction?: () => void;
}

export enum BackgroundVariantValues {
  "gray-700" = "#1D232A",
  "gray-800" = "#101317",
  "gray-900" = "#0A0C0F",
}
