/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Commit } from './Commit';
import {
    CommitFromJSON,
    CommitFromJSONTyped,
    CommitToJSON,
} from './Commit';

/**
 * 
 * @export
 * @interface Branch
 */
export interface Branch {
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Branch
     */
    path?: string;
    /**
     * 
     * @type {Commit}
     * @memberof Branch
     */
    lastCommit?: Commit;
}

/**
 * Check if a given object implements the Branch interface.
 */
export function instanceOfBranch(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BranchFromJSON(json: any): Branch {
    return BranchFromJSONTyped(json, false);
}

export function BranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Branch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'lastCommit': !exists(json, 'lastCommit') ? undefined : CommitFromJSON(json['lastCommit']),
    };
}

export function BranchToJSON(value?: Branch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'path': value.path,
        'lastCommit': CommitToJSON(value.lastCommit),
    };
}

