import { atom } from "jotai";

import { ErrorHandler, troubleshootingApiObject } from "../utils";

import { updateNotificationsAtom } from "./page.store";
import {
  errorRequestIdsAtom,
  setRequestIdFromResponseAtom,
} from "./logger.store";
import { productVersionAtom, userLoginStatusAtom } from "./auth.store";

export const showHelpDrawerAtom = atom(false);
export const toggleHelpDrawerAtom = atom(null, (get, set, state: boolean) => {
  set(showHelpDrawerAtom, state);
});

export const reportBugAtom = atom(
  null,
  async (get, set, description: string) => {
    try {
      const errorRequestIds = await get(errorRequestIdsAtom);
      await troubleshootingApiObject.postTroubleshooting({
        type: "bug_report",
        version: JSON.stringify(get(productVersionAtom) ?? ""),
        comment: description,
        requestIds: errorRequestIds ?? [],
        userEmail: get(userLoginStatusAtom)?.email ?? "",
      });
      set(updateNotificationsAtom, {
        type: "success",
        title: "Bug report submitted",
        description: "Thank you for reporting the bug.",
        dismissable: true,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "Failed to submit the bug",
        description:
          "An error occured while submitting the bug. Please try again later.",
        dismissable: true,
      });
    }
  }
);

export const submitFeedbackAtom = atom(
  null,
  async (get, set, description: string, rating: string) => {
    try {
      await troubleshootingApiObject.postTroubleshooting({
        type: "feedback",
        version: JSON.stringify(get(productVersionAtom) ?? ""),
        comment: description,
        reaction: `${rating}`,
        userEmail: get(userLoginStatusAtom)?.email ?? "",
      });
      set(updateNotificationsAtom, {
        type: "success",
        title: "Feedback submitted",
        description: "Thank you for sharing your feedback.",
        dismissable: true,
      });
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "Failed to submit the feedback",
        description:
          "An error occured while submitting the feedback. Please try again after later.",
        dismissable: true,
      });
    }
  }
);

export const submitTroubleshootingPackageAtom = atom(
  null,
  async (get, set, comment: string) => {
    try {
      const errorRequestIds = await get(errorRequestIdsAtom);
      await troubleshootingApiObject.postTroubleshooting({
        type: "troubleshooting",
        version: JSON.stringify(get(productVersionAtom) ?? ""),
        comment: comment,
        requestIds: errorRequestIds ?? [],
        userEmail: get(userLoginStatusAtom)?.email ?? "",
      });
      if (errorRequestIds?.length > 0) {
        set(errorRequestIdsAtom, []);
      }
      set(updateNotificationsAtom, {
        type: "success",
        title: "Troubleshooting package submitted",
        description:
          "Successfully sent the troubleshooting package. We will contact you if we need further information.",
        dismissable: true,
      });
    } catch (error) {
      console.log(error);
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: "Failed to submit the troubleshooting package",
        description:
          "An error occured while submitting the troubleshooting package. Please try again later.",
        dismissable: true,
      });
    }
  }
);
