/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface FTUXQuestionnaireRequest {
    body: object;
}

/**
 * 
 */
export class FtuxApi extends runtime.BaseAPI {

    /**
     * Update FTUX questionnaire in Hubspot
     */
    async fTUXQuestionnaireRaw(requestParameters: FTUXQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling fTUXQuestionnaire.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ftux/questionnaire`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update FTUX questionnaire in Hubspot
     */
    async fTUXQuestionnaire(requestParameters: FTUXQuestionnaireRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.fTUXQuestionnaireRaw(requestParameters, initOverrides);
    }

}
