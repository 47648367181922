/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectionType } from './ConnectionType';
import {
    ConnectionTypeFromJSON,
    ConnectionTypeFromJSONTyped,
    ConnectionTypeToJSON,
} from './ConnectionType';

/**
 * 
 * @export
 * @interface SupportedConnectionType
 */
export interface SupportedConnectionType {
    /**
     * 
     * @type {ConnectionType}
     * @memberof SupportedConnectionType
     */
    type?: ConnectionType;
    /**
     * 
     * @type {string}
     * @memberof SupportedConnectionType
     */
    crossReferenceId?: string;
}

/**
 * Check if a given object implements the SupportedConnectionType interface.
 */
export function instanceOfSupportedConnectionType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupportedConnectionTypeFromJSON(json: any): SupportedConnectionType {
    return SupportedConnectionTypeFromJSONTyped(json, false);
}

export function SupportedConnectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedConnectionType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : ConnectionTypeFromJSON(json['type']),
        'crossReferenceId': !exists(json, 'crossReferenceId') ? undefined : json['crossReferenceId'],
    };
}

export function SupportedConnectionTypeToJSON(value?: SupportedConnectionType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ConnectionTypeToJSON(value.type),
        'crossReferenceId': value.crossReferenceId,
    };
}

