import {
  Configuration as IACGenConfiguration,
  PolicyApi,
  V1CustomResourceTemplateApi,
  V1ImportApi,
  V1ResourceApi,
  V1ResourceConnectionApi,
  V1ResourceIamRestrictionPoliciesApi,
  V1ResourceMappingOverridePoliciesApi,
  V1ResourcePacksApi,
  V1ResourceRestrictionsApi,
  V1TopologyApi,
} from "../apis/topology";

import {
  APIKeyApi,
  AppStacksApi,
  Configuration as AppStacksConfiguration,
  AuthenticationApi,
  FtuxApi,
  GovernanceApi,
  HealthApi,
  LabelsApi,
  OrgManagementApi,
  ReposApi,
  RoleManagementApi,
  SCMConnectorApi,
  TroubleshootingApi,
  UsersApi,
} from "../apis/appcd";

import {
  ExportApi,
  ConfigApi as ExporterConfigAPI,
  Configuration as ExporterConfiguration,
} from "../apis/exporter";

import {
  RecommendationsApi,
  Configuration as ViraConfiguration,
} from "../apis/vira";

// APIs from appcd repository
export const appStacksApiObj = new AppStacksApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const reposApiObj = new ReposApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const authenticationApiObject = new AuthenticationApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const labelsApiObject = new LabelsApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const scmConnectorApiObject = new SCMConnectorApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const troubleshootingApiObject = new TroubleshootingApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const healthApiObject = new HealthApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const tokenApiObject = new APIKeyApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const ftuxApiObject = new FtuxApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const usersApiObject = new UsersApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const roleApiObject = new RoleManagementApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const teamApiObject = new OrgManagementApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);
export const governanceApiObject = new GovernanceApi(
  new AppStacksConfiguration({ basePath: "/appcd/api/v1" })
);

// APIs from iac-gen repository
export const v1TopologyApiObj = new V1TopologyApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const v1ResourceApiObj = new V1ResourceApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const v1ResourceConnectionApiObj = new V1ResourceConnectionApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const appStacksPolicyApiObject = new PolicyApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const v1ImportApiObject = new V1ImportApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const v1CustomResourceTemplateApiObj = new V1CustomResourceTemplateApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const v1ResourcePackApiObj = new V1ResourcePacksApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const V1ResourceRestrictionsApiObj = new V1ResourceRestrictionsApi(
  new IACGenConfiguration({ basePath: "/iac-gen" })
);
export const V1ResourceMappingOverridePoliciesApiObj =
  new V1ResourceMappingOverridePoliciesApi(
    new IACGenConfiguration({ basePath: "/iac-gen" })
  );
export const V1ResourceIamRestrictionPoliciesApiObj =
  new V1ResourceIamRestrictionPoliciesApi(
    new IACGenConfiguration({ basePath: "/iac-gen" })
  );

// APIs from appcd-vira repository
export const recommendationsApiObj = new RecommendationsApi(
  new ViraConfiguration({ basePath: "/vira" })
);

// APIs for exporter repository
export const exporterApiObj = new ExportApi(
  new ExporterConfiguration({ basePath: "/exporter/api/v1" })
);
export const exporterConfigApiObj = new ExporterConfigAPI(
  new ExporterConfiguration({ basePath: "/exporter/api/v1" })
);
