import React, { type FC, useState, useEffect } from "react";
import { Icon, IconsList, Tooltip } from "../";
import { copyToClipboard } from "../../utils";
import { CopyToClipboardProps } from "./copy-to-clipboard-types";

const CopyToClipboard: FC<CopyToClipboardProps> = ({
  text,
  dataTestId,
  iconClass = "",
}) => {
  const [copyToClipboardClicked, setCopyToClipboardClicked] = useState(false);
  useEffect(() => {
    if (copyToClipboardClicked) {
      setTimeout(() => {
        setCopyToClipboardClicked(false); // reset to copy icon after 2 second when copy to clipboard is clicked
      }, 2000);
    }
  }, [copyToClipboardClicked]);

  return (
    <div
      className="tw-cursor-pointer tw-text-gray-100 hover:tw-text-white"
      onClick={() => {
        copyToClipboard(text ?? "");
        setCopyToClipboardClicked(true);
      }}
      data-testid={`copy-to-clipboard-${dataTestId}`}
    >
      <Tooltip title={copyToClipboardClicked ? "Copied" : "Copy"}>
        <Icon
          name={copyToClipboardClicked ? IconsList.CHECK : IconsList.FILE_COPY}
          className={copyToClipboardClicked ? "tw-text-green-500" : iconClass}
        />
      </Tooltip>
    </div>
  );
};

export default CopyToClipboard;
