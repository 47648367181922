/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetTopologyDiffRequest,
  NewTopologyRequest,
  Topology,
  TopologyDiff,
  TopologyResourceWithMisconfigurations,
  TopologyTfVars,
} from '../models/index';
import {
    GetTopologyDiffRequestFromJSON,
    GetTopologyDiffRequestToJSON,
    NewTopologyRequestFromJSON,
    NewTopologyRequestToJSON,
    TopologyFromJSON,
    TopologyToJSON,
    TopologyDiffFromJSON,
    TopologyDiffToJSON,
    TopologyResourceWithMisconfigurationsFromJSON,
    TopologyResourceWithMisconfigurationsToJSON,
    TopologyTfVarsFromJSON,
    TopologyTfVarsToJSON,
} from '../models/index';

export interface CreateTopologyRequest {
    newTopologyRequest: NewTopologyRequest;
    orgId?: string;
}

export interface GetAllTFVarsDataRequest {
    topologyId: string;
    orgId?: string;
}

export interface GetGlobalResourceTagsRequest {
    topologyId: string;
    orgId?: string;
}

export interface GetResourceTypeMappingRequest {
    topologyId: string;
    orgId?: string;
}

export interface GetTopologyDiffOperationRequest {
    getTopologyDiffRequest: GetTopologyDiffRequest;
    orgId?: string;
}

export interface GetTopologyRepresentationalDataRequest {
    appstackId: string;
    orgId?: string;
}

export interface OverrideResourceTypeMappingRequest {
    topologyId: string;
    body: object;
    orgId?: string;
}

export interface UpdateGlobalResourceTagsRequest {
    topologyId: string;
    body: object;
    orgId?: string;
}

export interface V1DeleteTopologyRequest {
    topologyId: string;
    orgId?: string;
}

export interface V1ExportTopologyRequest {
    topologyId: string;
    orgId?: string;
}

export interface V1GetTopologyRequest {
    topologyId: string;
    orgId?: string;
}

export interface V1GetTopologyFromAppstackIdRequest {
    orgId?: string;
    appstackId?: string;
}

export interface V1ValidateTopologyRequest {
    topologyId: string;
    orgId?: string;
}

/**
 * 
 */
export class V1TopologyApi extends runtime.BaseAPI {

    /**
     * Create topology for appStack given an app manifest
     * Create topology for appStack
     */
    async createTopologyRaw(requestParameters: CreateTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Topology>> {
        if (requestParameters.newTopologyRequest === null || requestParameters.newTopologyRequest === undefined) {
            throw new runtime.RequiredError('newTopologyRequest','Required parameter requestParameters.newTopologyRequest was null or undefined when calling createTopology.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewTopologyRequestToJSON(requestParameters.newTopologyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyFromJSON(jsonValue));
    }

    /**
     * Create topology for appStack given an app manifest
     * Create topology for appStack
     */
    async createTopology(requestParameters: CreateTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Topology> {
        const response = await this.createTopologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All tfvars declared in the topology
     */
    async getAllTFVarsDataRaw(requestParameters: GetAllTFVarsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TopologyTfVars>>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling getAllTFVarsData.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/get-all-tfvars`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopologyTfVarsFromJSON));
    }

    /**
     * Get All tfvars declared in the topology
     */
    async getAllTFVarsData(requestParameters: GetAllTFVarsDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TopologyTfVars>> {
        const response = await this.getAllTFVarsDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch global resource tags for a topology
     * Get global resource tags
     */
    async getGlobalResourceTagsRaw(requestParameters: GetGlobalResourceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling getGlobalResourceTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-tags`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fetch global resource tags for a topology
     * Get global resource tags
     */
    async getGlobalResourceTags(requestParameters: GetGlobalResourceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getGlobalResourceTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch resource type mapping for a topology
     * Get resource type mapping
     */
    async getResourceTypeMappingRaw(requestParameters: GetResourceTypeMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling getResourceTypeMapping.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/override-resource-type-mapping`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fetch resource type mapping for a topology
     * Get resource type mapping
     */
    async getResourceTypeMapping(requestParameters: GetResourceTypeMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getResourceTypeMappingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get topology diff
     */
    async getTopologyDiffRaw(requestParameters: GetTopologyDiffOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyDiff>> {
        if (requestParameters.getTopologyDiffRequest === null || requestParameters.getTopologyDiffRequest === undefined) {
            throw new runtime.RequiredError('getTopologyDiffRequest','Required parameter requestParameters.getTopologyDiffRequest was null or undefined when calling getTopologyDiff.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topology-diff`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetTopologyDiffRequestToJSON(requestParameters.getTopologyDiffRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyDiffFromJSON(jsonValue));
    }

    /**
     * Get topology diff
     */
    async getTopologyDiff(requestParameters: GetTopologyDiffOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyDiff> {
        const response = await this.getTopologyDiffRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get representational data for topology
     */
    async getTopologyRepresentationalDataRaw(requestParameters: GetTopologyRepresentationalDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.appstackId === null || requestParameters.appstackId === undefined) {
            throw new runtime.RequiredError('appstackId','Required parameter requestParameters.appstackId was null or undefined when calling getTopologyRepresentationalData.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/appstacks/{appstackId}/representational-data`.replace(`{${"appstackId"}}`, encodeURIComponent(String(requestParameters.appstackId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get representational data for topology
     */
    async getTopologyRepresentationalData(requestParameters: GetTopologyRepresentationalDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getTopologyRepresentationalDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * to change resource template from system defined to custom
     */
    async overrideResourceTypeMappingRaw(requestParameters: OverrideResourceTypeMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling overrideResourceTypeMapping.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling overrideResourceTypeMapping.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/override-resource-type-mapping`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * to change resource template from system defined to custom
     */
    async overrideResourceTypeMapping(requestParameters: OverrideResourceTypeMappingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.overrideResourceTypeMappingRaw(requestParameters, initOverrides);
    }

    /**
     * Update global resource tags for a topology
     * Update global resource tags
     */
    async updateGlobalResourceTagsRaw(requestParameters: UpdateGlobalResourceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling updateGlobalResourceTags.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateGlobalResourceTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-tags`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Update global resource tags for a topology
     * Update global resource tags
     */
    async updateGlobalResourceTags(requestParameters: UpdateGlobalResourceTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.updateGlobalResourceTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete topology by id
     * delete topology by id
     */
    async v1DeleteTopologyRaw(requestParameters: V1DeleteTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling v1DeleteTopology.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete topology by id
     * delete topology by id
     */
    async v1DeleteTopology(requestParameters: V1DeleteTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v1DeleteTopologyRaw(requestParameters, initOverrides);
    }

    /**
     * Generate and export IaC files for the topology
     */
    async v1ExportTopologyRaw(requestParameters: V1ExportTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling v1ExportTopology.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/export`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate and export IaC files for the topology
     */
    async v1ExportTopology(requestParameters: V1ExportTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.v1ExportTopologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fetch current topology by id
     * Get topology by id
     */
    async v1GetTopologyRaw(requestParameters: V1GetTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Topology>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling v1GetTopology.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyFromJSON(jsonValue));
    }

    /**
     * fetch current topology by id
     * Get topology by id
     */
    async v1GetTopology(requestParameters: V1GetTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Topology> {
        const response = await this.v1GetTopologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get topology by appStack id
     */
    async v1GetTopologyFromAppstackIdRaw(requestParameters: V1GetTopologyFromAppstackIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Topology>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.appstackId !== undefined) {
            queryParameters['appstackId'] = requestParameters.appstackId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyFromJSON(jsonValue));
    }

    /**
     * Get topology by appStack id
     */
    async v1GetTopologyFromAppstackId(requestParameters: V1GetTopologyFromAppstackIdRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Topology> {
        const response = await this.v1GetTopologyFromAppstackIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate topology
     */
    async v1ValidateTopologyRaw(requestParameters: V1ValidateTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TopologyResourceWithMisconfigurations>>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling v1ValidateTopology.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/validate`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopologyResourceWithMisconfigurationsFromJSON));
    }

    /**
     * Validate topology
     */
    async v1ValidateTopology(requestParameters: V1ValidateTopologyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TopologyResourceWithMisconfigurations>> {
        const response = await this.v1ValidateTopologyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
