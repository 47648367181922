import React from "react";
import type { ColumnType } from "./table-types";

const Column = ({
  column_data,
  dataTestId,
}: {
  column_data: ColumnType;
  dataTestId: string;
}) => {
  return (
    <td
      className={`tw-px-4 tw-py-5 ${
        column_data.className ? column_data.className : ""
      }`}
      data-testid={dataTestId}
    >
      {column_data.data}
    </td>
  );
};

export default Column;
