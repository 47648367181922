import React from "react";
import { FieldArray } from "formik";

import {
  Button,
  IconButton,
  SelectBox,
  TextBox,
  TextArea,
  Icon,
  IconsList,
  IconSize,
} from "../../components";
import { InputArrayProps } from "./input-array-types";
import { IaCFileTypes } from "../../config/constants";
import { isFunction } from "../../utils";

const InputArray = ({
  name,
  value,
  fields,
  label,
  description,
  className = "",
  error,
  touched,
  fieldBackgroundVariant = "gray-800",
  nested = false,
  required,
  labelSuffix,
  labelSuffixAction,
  dataTestId,
}: InputArrayProps) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers: any) => (
        <div className={className} data-testid={`input-array-${dataTestId}`}>
          <div className="tw-flex tw-items-center tw-justify-between">
            {label && (
              <label
                htmlFor={name}
                className="tw-block tw-mb-2 tw-text-xssm tw-font-semibold tw-text-white"
                data-testid={`input-array-${dataTestId}-label`}
              >
                {label} {required && <span className="tw-text-red-500">*</span>}
              </label>
            )}
            {labelSuffix && (
              <label
                className="tw-cursor-pointer"
                data-testid={`input-array-${dataTestId}-labelSuffix`}
                onClick={() => {
                  labelSuffixAction &&
                    isFunction(labelSuffixAction) &&
                    labelSuffixAction();
                }}
              >
                {labelSuffix}
              </label>
            )}
          </div>
          {description && (
            <p className="tw-mb-2 tw-text-xssm tw-text-gray-200 tw-break-all">
              {description}
            </p>
          )}
          {value?.map?.((item, index: number) => (
            <div
              key={`item_${index}`}
              className="tw-border tw-border-gray-600 tw-rounded-md tw-px-3 tw-pt-3 tw-mb-3"
            >
              <div className="tw-mb-2 tw-flex tw-justify-end">
                <div
                  className="tw-text-gray-400 tw-cursor-pointer hover:tw-text-gray-300"
                  onClick={() => arrayHelpers.remove(index)}
                  data-testid={`${dataTestId}-delete-item-${index}`}
                >
                  <Icon name={IconsList.DELETE} size={IconSize.sm} />
                </div>
              </div>
              {fields.map((field, i) => {
                if (field.type === "text") {
                  return (
                    <div className="tw-mb-2" key={`field_${i}`}>
                      <TextBox
                        name={`${name}.${index}.${field.name}`}
                        description={field?.description}
                        label={field?.attributes?.label as string}
                        backgroundVariant={fieldBackgroundVariant}
                        dataTestId={`${dataTestId}-${index}-${field.name}-attribute-text`}
                      />
                    </div>
                  );
                } else if (
                  field.type === "select" &&
                  field?.attributes?.items
                ) {
                  return (
                    <div className="tw-mb-2" key={`field_${i}`}>
                      <SelectBox
                        name={`${name}.${index}.${field.name}`}
                        description={field?.description}
                        label={field?.attributes?.label as string}
                        options={field?.attributes?.items}
                        backgroundVariant={fieldBackgroundVariant}
                        dataTestId={`${dataTestId}-${index}-${field.name}-attribute-select`}
                      />
                    </div>
                  );
                } else if (field.type === "textarea") {
                  return (
                    <div className="tw-mb-2" key={`field_${i}`}>
                      <TextArea
                        name={`${name}.${index}.${field.name}`}
                        description={field?.description}
                        label={field?.attributes?.label as string}
                        backgroundVariant={fieldBackgroundVariant}
                        dataTestId={`${dataTestId}-${index}-${field.name}-attribute-textarea-1`}
                      />
                    </div>
                  );
                } else if (field.type in IaCFileTypes) {
                  return (
                    <TextArea
                      name={`${name}.${index}.${field.name}`}
                      label={field?.attributes?.label as string}
                      backgroundVariant={fieldBackgroundVariant}
                      description={field?.description}
                      editorLanguage={field.type as IaCFileTypes}
                      dataTestId={`${dataTestId}-${index}-${field.name}-attribute-textarea-2`}
                    />
                  );
                } else if (field.type === "text_list") {
                  return (
                    <div className="tw-mb-2" key={`field_${i}`}>
                      <InputArray
                        name={`${name}.${index}.${field.name}`}
                        label={field?.attributes?.label as string}
                        description={field?.description}
                        fields={[
                          {
                            name: "key",
                            type: "text",
                          },
                        ]}
                        value={item[field.name]}
                        nested={true}
                        fieldBackgroundVariant={fieldBackgroundVariant}
                        dataTestId={`${dataTestId}_text_list.${index}.${field.name}`}
                      />
                    </div>
                  );
                } else if (field.type === "dictionary") {
                  return (
                    <div className="tw-mb-2" key={`field_${i}`}>
                      <InputArray
                        name={`${name}.${index}.${field.name}`}
                        label={field?.attributes?.label as string}
                        description={field?.description}
                        fields={[
                          {
                            name: "key",
                            type: "text",
                          },
                          {
                            name: "value",
                            type: "text",
                          },
                        ]}
                        value={item[field.name]}
                        nested={true}
                        fieldBackgroundVariant={fieldBackgroundVariant}
                        dataTestId={`${dataTestId}_dictionary.${index}.${field.name}`}
                      />
                    </div>
                  );
                }
              })}
              {!nested && (
                <hr className="tw-h-px tw-mb-2 tw-border-0 tw-bg-gray-700"></hr>
              )}
            </div>
          ))}
          {error && touched ? (
            <div className="error-field tw-mb-2 tw-text-xs tw-text-red-400">
              {error}
            </div>
          ) : null}
          <div className={`${nested ? "tw-pb-2" : ""}`}>
            <Button
              variant={nested ? "secondary" : "primary"}
              label="Add new item"
              size="sm"
              onClick={() => {
                arrayHelpers.push({
                  ...fields?.reduce?.(
                    (result: { [key: string]: any }, field) => {
                      result[field.name] = "";
                      return result;
                    },
                    {}
                  ),
                });
              }}
              dataTestId={`${dataTestId}-add-new-item`}
            />
          </div>
        </div>
      )}
    />
  );
};

export default InputArray;
