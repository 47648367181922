import React, { type FC } from "react";

import { Icon, IconSize, IconsList } from "../../../../components";
import type { ResourcePackDrawerHeaderProps } from "./resource-pack-types";

const ResourcePackDrawerHeader: FC<ResourcePackDrawerHeaderProps> = ({
  onClose,
  isCreate,
}) => (
  <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between tw-border-b tw-border-gray-700">
    <div className="tw-flex tw-items-center tw-gap-3">
      <Icon name={IconsList.RESOURCE_PACK} size={IconSize.md} />
      <span className="tw-text-white tw-text-base tw-font-semibold ">
        {`${isCreate ? "Save as" : "Edit"} Resource Pack`}
      </span>
    </div>
    <div
      className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
      onClick={() => onClose()}
      data-testid="resource-pack-drawer-close"
    >
      <Icon name={IconsList.CLOSE} />
    </div>
  </div>
);

export default ResourcePackDrawerHeader;
