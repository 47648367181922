/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectionType } from './ConnectionType';
import {
    ConnectionTypeFromJSON,
    ConnectionTypeFromJSONTyped,
    ConnectionTypeToJSON,
} from './ConnectionType';

/**
 * 
 * @export
 * @interface NewTopologyResourceConnectionRequest
 */
export interface NewTopologyResourceConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof NewTopologyResourceConnectionRequest
     */
    sourceResourceId: string;
    /**
     * 
     * @type {string}
     * @memberof NewTopologyResourceConnectionRequest
     */
    targetResourceId: string;
    /**
     * 
     * @type {ConnectionType}
     * @memberof NewTopologyResourceConnectionRequest
     */
    connectionType?: ConnectionType;
    /**
     * 
     * @type {string}
     * @memberof NewTopologyResourceConnectionRequest
     */
    crossReferenceId?: string;
    /**
     * 
     * @type {object}
     * @memberof NewTopologyResourceConnectionRequest
     */
    _configuration?: object;
}

/**
 * Check if a given object implements the NewTopologyResourceConnectionRequest interface.
 */
export function instanceOfNewTopologyResourceConnectionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceResourceId" in value;
    isInstance = isInstance && "targetResourceId" in value;

    return isInstance;
}

export function NewTopologyResourceConnectionRequestFromJSON(json: any): NewTopologyResourceConnectionRequest {
    return NewTopologyResourceConnectionRequestFromJSONTyped(json, false);
}

export function NewTopologyResourceConnectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewTopologyResourceConnectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceResourceId': json['sourceResourceId'],
        'targetResourceId': json['targetResourceId'],
        'connectionType': !exists(json, 'connectionType') ? undefined : ConnectionTypeFromJSON(json['connectionType']),
        'crossReferenceId': !exists(json, 'crossReferenceId') ? undefined : json['crossReferenceId'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
    };
}

export function NewTopologyResourceConnectionRequestToJSON(value?: NewTopologyResourceConnectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceResourceId': value.sourceResourceId,
        'targetResourceId': value.targetResourceId,
        'connectionType': ConnectionTypeToJSON(value.connectionType),
        'crossReferenceId': value.crossReferenceId,
        'configuration': value._configuration,
    };
}

