import React, { useState, useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";

import { viraActivationHookAtom } from "../../stores/page.store";
import {
  fetchRecommendationsAtom,
  saveQuestionResponseAtom,
} from "../../stores/vira.store";
import type { Hook, Question, Questionnaire } from "../../apis/vira";

import {
  ViraBubble,
  ViraQuestion,
  ViraAnswerStatus,
  ViraBubbleConfig,
  type AnswerCallback,
} from "./";

const Vira = () => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [answerStatus, setAnswerStatus] = useState<ViraAnswerStatus>(
    ViraAnswerStatus.UNANSWERED
  );
  const [showBubble, setShowBubble] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionList, setQuestionList] = useState([] as Question[]);
  const [viraActivationHook, setViraActivationHook] = useAtom(
    viraActivationHookAtom
  );
  const fetchRecommendations = useSetAtom(fetchRecommendationsAtom);
  const saveQuestionResponse = useSetAtom(saveQuestionResponseAtom);

  useEffect(() => {
    switch (viraActivationHook.hook) {
      case "APPSTACK_REPO_SELECTION":
      case "DURING_ANALYSIS":
        fetchQuestions({ hook: viraActivationHook.hook });
        break;
      // other case which required additional info to pass to the API
      default:
        break;
    }
  }, [viraActivationHook]);

  useEffect(() => {
    if (!showBubble && answerStatus === ViraAnswerStatus.DEFAULT) {
      const timer = setTimeout(() => {
        setAnswerStatus(ViraAnswerStatus.ANSWERED);
      }, 2000);
      return () => clearTimeout(timer);
    } else if (showBubble) {
      setAnswerStatus(ViraAnswerStatus.DEFAULT);
      setTimeout(() => {
        setShowQuestion(true);
      }, 1500);
    }
  }, [showBubble, answerStatus]);

  const fetchQuestions = async (reqBody: { hook: Hook }) => {
    const resp = (await fetchRecommendations({
      questionnaireRequest: reqBody,
    })) as Questionnaire;
    if (resp) {
      setQuestionList(resp.questions);
      setShowBubble(true);
    }
  };

  const saveQuestionResponseHandler = async ({
    question,
    answer,
  }: AnswerCallback) => {
    await saveQuestionResponse({
      answerRequest: {
        questionId: question?.id,
        response: { dataType: question.type, value: answer },
      },
    });
  };

  const handleQuestionSkip = () => {
    setAnswerStatus(ViraAnswerStatus.DEFAULT);
    askNextQuestion();
  };
  const handleQuestionAnswered = async (response: AnswerCallback) => {
    await saveQuestionResponseHandler(response);
    setAnswerStatus(ViraAnswerStatus.ANSWERED);
    askNextQuestion();
  };

  const askNextQuestion = () => {
    const newQuestionIndex = currentQuestionIndex + 1;
    setCurrentQuestionIndex(newQuestionIndex);
    if (newQuestionIndex < questionList.length) {
      setShowQuestion(false);
      // ask another question after 500ms
      setTimeout(() => {
        setShowQuestion(true);
      }, 500);
    } else {
      cleanupStates();
    }
  };

  const cleanupStates = () => {
    setShowQuestion(false);
    setShowBubble(false);
    setCurrentQuestionIndex(0);
    setQuestionList([]);
    // wait for bubble scale down animation completion
    setTimeout(() => {
      setViraActivationHook({ hook: null });
    }, 2000);
  };

  return (
    <div className="tw-relative tw-text-white">
      <div
        className={`tw-absolute ${
          showBubble ? "tw-block" : "tw-animate-scale-down-hide"
        } ${
          viraActivationHook?.hook &&
          ViraBubbleConfig[viraActivationHook.hook]?.positionClass
            ? ViraBubbleConfig[viraActivationHook.hook].positionClass
            : "tw-bottom-8 tw-right-8"
        }`}
      >
        <ViraBubble answerStatus={answerStatus} showBubble={showBubble} />
        {showQuestion && (
          <div className="tw-flex tw-flex-col tw-absolute tw-z-60 tw--bottom-1 tw--right-1 tw-rounded-lg tw-text-white tw-border-2 tw-border-transparent tw-animate-vira-question-ring tw-w-max">
            <ViraQuestion
              onSkip={handleQuestionSkip}
              onAnswer={handleQuestionAnswered}
              question={questionList[currentQuestionIndex]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Vira;
