/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AzureIam } from './AzureIam';
import {
    AzureIamFromJSON,
    AzureIamFromJSONTyped,
    AzureIamToJSON,
} from './AzureIam';

/**
 * 
 * @export
 * @interface ComponentTraitsDependenciesAzureServiceBusInner
 */
export interface ComponentTraitsDependenciesAzureServiceBusInner {
    /**
     * Name of the topic
     * @type {string}
     * @memberof ComponentTraitsDependenciesAzureServiceBusInner
     */
    topic?: string;
    /**
     * Name of the topic
     * @type {string}
     * @memberof ComponentTraitsDependenciesAzureServiceBusInner
     */
    queue?: string;
    /**
     * Connection string of the Service Bus
     * @type {string}
     * @memberof ComponentTraitsDependenciesAzureServiceBusInner
     */
    connectionString?: string;
    /**
     * 
     * @type {AzureIam}
     * @memberof ComponentTraitsDependenciesAzureServiceBusInner
     */
    iam?: AzureIam;
}

/**
 * Check if a given object implements the ComponentTraitsDependenciesAzureServiceBusInner interface.
 */
export function instanceOfComponentTraitsDependenciesAzureServiceBusInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesAzureServiceBusInnerFromJSON(json: any): ComponentTraitsDependenciesAzureServiceBusInner {
    return ComponentTraitsDependenciesAzureServiceBusInnerFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesAzureServiceBusInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependenciesAzureServiceBusInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'queue': !exists(json, 'queue') ? undefined : json['queue'],
        'connectionString': !exists(json, 'connectionString') ? undefined : json['connectionString'],
        'iam': !exists(json, 'iam') ? undefined : AzureIamFromJSON(json['iam']),
    };
}

export function ComponentTraitsDependenciesAzureServiceBusInnerToJSON(value?: ComponentTraitsDependenciesAzureServiceBusInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': value.topic,
        'queue': value.queue,
        'connectionString': value.connectionString,
        'iam': AzureIamToJSON(value.iam),
    };
}

