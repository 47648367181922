import React, { type FC } from "react";
import { type DialogProps, DialogSize } from "./dialog-types";
import { Icon, IconsList } from "..";

/**
 * Renders a Dialog component with customizable title, body, and footer content.
 *
 * - @param {DialogProps} props - The properties for configuring the Dialog component.
 * - @param {string} props.title - The title of the Dialog.
 * - @param {string} props.bodyText - The body text of the Dialog.
 * - @param {ReactNode} props.titleContent - The JSX content for the title of the Dialog.
 * - @param {ReactNode} props.bodyContent - The JSX content for the body of the Dialog.
 * - @param {ReactNode} props.footerContent - The JSX content for the footer of the Dialog.
 * - @param {string} [props.size="sm"] - The size of the Dialog. Defaults to "sm".
 * - @param {boolean} [props.showFooterDivider=false] - Whether to show a border at the top of footer. Defaults to false.
 * - @param {boolean} [props.backgroundBlur=false] - Whether to apply background blur. Defaults to false.
 * - @param {string} props.dataTestId - The data-testid of the Dialog.
 * - @param {function} props.onClose - The function to call when the close button is clicked. Defaults to an empty function.
 * - @param {boolean} [props.showHeaderCloseButton=true] - Whether to show the close button in the header. Defaults to true.
 * - @param {string} [props.bodyWrapperClassName] - The class name for the body wrapper.
 * - @return {JSX.Element} - The rendered Dialog component.
 */
const Dialog: FC<DialogProps> = ({
  title,
  bodyText,
  titleContent,
  bodyContent,
  footerContent,
  size = "sm",
  dataTestId,
  onClose = () => {},
  showHeaderCloseButton = false,
  showFooterDivider = false,
  backgroundBlur = false,
  bodyWrapperClassName,
}) => {
  return (
    <div
      className={`tw-w-screen tw-h-screen tw-overflow-hidden tw-bg-red-100 tw-bg-opacity-10 tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-flex tw-items-center tw-justify-center ${
        backgroundBlur ? "tw-backdrop-blur-sm" : ""
      }`}
    >
      <div
        className={`tw-border tw-bg-gray-800 tw-border-gray-700 tw-rounded-md tw-flex tw-flex-col ${DialogSize[size]}`}
      >
        {/* Dialog Header */}
        <div
          className={`tw-p-4 ${
            showHeaderCloseButton ? "tw-flex tw-justify-between" : ""
          }`}
        >
          <span
            className="tw-text-white tw-text-base tw-font-semibold"
            data-testid={`${dataTestId}-dialog-title`}
          >
            {titleContent ? titleContent : title ?? ""}
          </span>
          {showHeaderCloseButton && (
            <div
              onClick={onClose}
              data-testid={`${dataTestId}-dialog-close`}
              className="tw-text-gray-300 hover:tw-text-gray-200 tw-cursor-pointer"
            >
              <Icon name={IconsList.CLOSE} />
            </div>
          )}
        </div>
        {/* Dialog Body */}
        <div
          className={`tw-p-4 ${
            bodyWrapperClassName ? bodyWrapperClassName : ""
          }`}
        >
          <span
            className="tw-text-white tw-text-sm tw-font-normal"
            data-testid={`${dataTestId}-dialog-body`}
          >
            {bodyContent ? bodyContent : bodyText ?? ""}
          </span>
        </div>
        {/* Dialog Footer */}
        {footerContent && (
          <div
            className={`tw-p-4 ${
              showFooterDivider ? "tw-border-t tw-border-gray-600" : ""
            }`}
            data-testid={`${dataTestId}-dialog-footer`}
          >
            {footerContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dialog;
