/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateResourceConnectionRequest
 */
export interface UpdateResourceConnectionRequest {
    /**
     * 
     * @type {object}
     * @memberof UpdateResourceConnectionRequest
     */
    _configuration?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateResourceConnectionRequest
     */
    tfVars?: object;
}

/**
 * Check if a given object implements the UpdateResourceConnectionRequest interface.
 */
export function instanceOfUpdateResourceConnectionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateResourceConnectionRequestFromJSON(json: any): UpdateResourceConnectionRequest {
    return UpdateResourceConnectionRequestFromJSONTyped(json, false);
}

export function UpdateResourceConnectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResourceConnectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'tfVars': !exists(json, 'tfVars') ? undefined : json['tfVars'],
    };
}

export function UpdateResourceConnectionRequestToJSON(value?: UpdateResourceConnectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configuration': value._configuration,
        'tfVars': value.tfVars,
    };
}

