import React from "react";

import { Icon, IconSize } from "../../components";

import {
  IconButtonProps,
  IconButtonSize,
  ButtonVariant,
  IconVariant,
  OutlinedIconButtonVariant,
} from "./button-types";

const IconButton = ({
  icon,
  variant = "primary",
  size = "md",
  type = "button",
  disabled = false,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  className = "",
  outlined = false,
  dataTestId,
}: IconButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${outlined && variant === "secondary" // remove this condition once we have outlined design for all variants
          ? OutlinedIconButtonVariant[variant]
          : ButtonVariant[variant]
        } ${disabled ? "tw-opacity-50 tw-cursor-not-allowed" : ""} ${IconButtonSize[size]
      } tw-rounded-md hover:tw-opacity-90 ${className}`}
      data-testid={dataTestId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon
        name={icon}
        size={IconSize[size]}
        className={`${IconVariant[variant]}`}
      />
    </button>
  );
};

export default IconButton;
