import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import * as yup from "yup";
import type { ProviderWiseSupportedResourceType } from "../../../../../apis/topology";
import {
  ResourceProvidersMap,
  validCustomHelmFileExtensions,
} from "../../../../../config/constants";
import {
  saveCustomHelmChartAtom,
  topologyResourcesListAtom,
} from "../../../../../stores/topology.store";
import {
  getResourcesByCategory,
  isValidFileType,
  trackAmplitudeEvent,
} from "../../../../../utils";
import {
  CustomHelmChartFields,
  CustomHelmLocations,
  type CustomHelmForm,
} from "../custom-helm-types";

export const useCustomHelmResource = (onClose: (success: boolean) => void) => {
  const saveCustomHelmChart = useSetAtom(saveCustomHelmChartAtom);
  const topologyResourcesList = useAtomValue(topologyResourcesListAtom);

  const handleCreationModeSubmission = useCallback(
    async (customHelmReq: CustomHelmForm, resetForm: () => void) => {
      const responseObj = await saveCustomHelmChart({
        name: customHelmReq[CustomHelmChartFields.CHART_NAME],
        repository: customHelmReq[CustomHelmChartFields.CHART_REPOSITORY],
        chartZip: customHelmReq[CustomHelmChartFields.CHART_ZIP]?.[0], // currently we support only one helm chart upload
      });
      if (responseObj) {
        trackAmplitudeEvent("custom_helm_chart_add_success", {
          uuid: responseObj?.id as string,
        });
        resetForm();
        onClose(true);
      } else {
        trackAmplitudeEvent("custom_helm_chart_add_fail", {});
      }
    },
    [onClose, saveCustomHelmChart]
  );

  const onCustomHelmChartSubmit = useCallback(
    async (
      values: CustomHelmForm,
      setSubmitting: (isSubmitting: boolean) => void,
      resetForm: () => void
    ) => {
      setSubmitting(true);
      await handleCreationModeSubmission(values, resetForm);
      setSubmitting(false);
    },
    [handleCreationModeSubmission]
  );

  const existingCustomHelmResources = useMemo(() => {
    const { customResources } = getResourcesByCategory(
      topologyResourcesList[
        ResourceProvidersMap.helm as keyof ProviderWiseSupportedResourceType
      ]
    );
    return customResources ?? [];
  }, [topologyResourcesList]);

  const getValidationSchema = useCallback(() => {
    return yup.object().shape({
      [CustomHelmChartFields.CHART_NAME]: yup
        .string()
        .notOneOf(
          existingCustomHelmResources.map((resource) => resource.label),
          "Helm chart name is already in use"
        )
        .required("Helm chart name is required"),
      [CustomHelmChartFields.CHART_ZIP]: yup.lazy((_, { context }) => {
        if (
          context[CustomHelmChartFields.CHART_LOCATION] ===
          CustomHelmLocations.LOCAL
        ) {
          return yup
            .array()
            .of(yup.mixed())
            .test("file-required", "Helm chart zip is required", (files) => {
              return files && files.length > 0;
            })
            .test("file-length", "Only one file is supported", (files) => {
              return files && files.length <= 1;
            })
            .test("is-valid-type", "Not a valid file type", (files) => {
              if (files && files.length > 0) {
                const file = files[0];
                return isValidFileType(file, validCustomHelmFileExtensions);
              }
              return true;
            });
        }
        return yup.mixed().notRequired();
      }),
      [CustomHelmChartFields.CHART_REPOSITORY]: yup.lazy((_, { context }) => {
        if (
          context[CustomHelmChartFields.CHART_LOCATION] ===
          CustomHelmLocations.REPO
        ) {
          return yup
            .string()
            .url("Helm repository url is invalid")
            .required("Helm repository url is required");
        }
        return yup.string().notRequired();
      }),
    });
  }, []);

  return { onCustomHelmChartSubmit, getValidationSchema };
};
