/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NewTopologyResourceConnectionRequest,
  SupportedConnectionType,
  TopologyResourceConnection,
  UpdateResourceConnectionRequest,
} from '../models/index';
import {
    NewTopologyResourceConnectionRequestFromJSON,
    NewTopologyResourceConnectionRequestToJSON,
    SupportedConnectionTypeFromJSON,
    SupportedConnectionTypeToJSON,
    TopologyResourceConnectionFromJSON,
    TopologyResourceConnectionToJSON,
    UpdateResourceConnectionRequestFromJSON,
    UpdateResourceConnectionRequestToJSON,
} from '../models/index';

export interface CreateResourceConnectionRequest {
    topologyId: string;
    newTopologyResourceConnectionRequest: NewTopologyResourceConnectionRequest;
    orgId?: string;
}

export interface DeleteResourceAttributeConnectionRequest {
    topologyId: string;
    newTopologyResourceConnectionRequest: NewTopologyResourceConnectionRequest;
    orgId?: string;
}

export interface DeleteResourceConnectionRequest {
    topologyId: string;
    resourceConnectionId: string;
    orgId?: string;
}

export interface GetResourceConnectionByIdRequest {
    topologyId: string;
    resourceConnectionId: string;
    orgId?: string;
}

export interface GetSupportedConnectionTypesRequest {
    sourceResourceID: string;
    targetResourceID: string;
    orgId?: string;
}

export interface UpdateResourceConnectionOperationRequest {
    topologyId: string;
    resourceConnectionId: string;
    updateResourceConnectionRequest: UpdateResourceConnectionRequest;
    orgId?: string;
}

export interface ValidateDeleteResourceRequest {
    topologyId: string;
    deletionType: ValidateDeleteResourceDeletionTypeEnum;
    id: string;
    orgId?: string;
}

/**
 * 
 */
export class V1ResourceConnectionApi extends runtime.BaseAPI {

    /**
     * Creates a resource connection for a topology
     * Create resource connection for topology
     */
    async createResourceConnectionRaw(requestParameters: CreateResourceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceConnection>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling createResourceConnection.');
        }

        if (requestParameters.newTopologyResourceConnectionRequest === null || requestParameters.newTopologyResourceConnectionRequest === undefined) {
            throw new runtime.RequiredError('newTopologyResourceConnectionRequest','Required parameter requestParameters.newTopologyResourceConnectionRequest was null or undefined when calling createResourceConnection.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-connections`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewTopologyResourceConnectionRequestToJSON(requestParameters.newTopologyResourceConnectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceConnectionFromJSON(jsonValue));
    }

    /**
     * Creates a resource connection for a topology
     * Create resource connection for topology
     */
    async createResourceConnection(requestParameters: CreateResourceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceConnection> {
        const response = await this.createResourceConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete resource attribute connections from the topology
     * Delete resource attribute connections from topology
     */
    async deleteResourceAttributeConnectionRaw(requestParameters: DeleteResourceAttributeConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling deleteResourceAttributeConnection.');
        }

        if (requestParameters.newTopologyResourceConnectionRequest === null || requestParameters.newTopologyResourceConnectionRequest === undefined) {
            throw new runtime.RequiredError('newTopologyResourceConnectionRequest','Required parameter requestParameters.newTopologyResourceConnectionRequest was null or undefined when calling deleteResourceAttributeConnection.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-attribute-connections`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewTopologyResourceConnectionRequestToJSON(requestParameters.newTopologyResourceConnectionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete resource attribute connections from the topology
     * Delete resource attribute connections from topology
     */
    async deleteResourceAttributeConnection(requestParameters: DeleteResourceAttributeConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResourceAttributeConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a resource connection from the topology
     * Delete resource connection from topology
     */
    async deleteResourceConnectionRaw(requestParameters: DeleteResourceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling deleteResourceConnection.');
        }

        if (requestParameters.resourceConnectionId === null || requestParameters.resourceConnectionId === undefined) {
            throw new runtime.RequiredError('resourceConnectionId','Required parameter requestParameters.resourceConnectionId was null or undefined when calling deleteResourceConnection.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-connections/{resourceConnectionId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceConnectionId"}}`, encodeURIComponent(String(requestParameters.resourceConnectionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a resource connection from the topology
     * Delete resource connection from topology
     */
    async deleteResourceConnection(requestParameters: DeleteResourceConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResourceConnectionRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch topology resource connection by ID
     * Get topology resource connection by id
     */
    async getResourceConnectionByIdRaw(requestParameters: GetResourceConnectionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceConnection>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling getResourceConnectionById.');
        }

        if (requestParameters.resourceConnectionId === null || requestParameters.resourceConnectionId === undefined) {
            throw new runtime.RequiredError('resourceConnectionId','Required parameter requestParameters.resourceConnectionId was null or undefined when calling getResourceConnectionById.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-connections/{resourceConnectionId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceConnectionId"}}`, encodeURIComponent(String(requestParameters.resourceConnectionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceConnectionFromJSON(jsonValue));
    }

    /**
     * Fetch topology resource connection by ID
     * Get topology resource connection by id
     */
    async getResourceConnectionById(requestParameters: GetResourceConnectionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceConnection> {
        const response = await this.getResourceConnectionByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List supported connection types
     */
    async getSupportedConnectionTypesRaw(requestParameters: GetSupportedConnectionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SupportedConnectionType>>> {
        if (requestParameters.sourceResourceID === null || requestParameters.sourceResourceID === undefined) {
            throw new runtime.RequiredError('sourceResourceID','Required parameter requestParameters.sourceResourceID was null or undefined when calling getSupportedConnectionTypes.');
        }

        if (requestParameters.targetResourceID === null || requestParameters.targetResourceID === undefined) {
            throw new runtime.RequiredError('targetResourceID','Required parameter requestParameters.targetResourceID was null or undefined when calling getSupportedConnectionTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.sourceResourceID !== undefined) {
            queryParameters['sourceResourceID'] = requestParameters.sourceResourceID;
        }

        if (requestParameters.targetResourceID !== undefined) {
            queryParameters['targetResourceID'] = requestParameters.targetResourceID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/supported-connection-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SupportedConnectionTypeFromJSON));
    }

    /**
     * List supported connection types
     */
    async getSupportedConnectionTypes(requestParameters: GetSupportedConnectionTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SupportedConnectionType>> {
        const response = await this.getSupportedConnectionTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the resource connections configuration
     * Update topology resource connection by ID
     */
    async updateResourceConnectionRaw(requestParameters: UpdateResourceConnectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceConnection>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling updateResourceConnection.');
        }

        if (requestParameters.resourceConnectionId === null || requestParameters.resourceConnectionId === undefined) {
            throw new runtime.RequiredError('resourceConnectionId','Required parameter requestParameters.resourceConnectionId was null or undefined when calling updateResourceConnection.');
        }

        if (requestParameters.updateResourceConnectionRequest === null || requestParameters.updateResourceConnectionRequest === undefined) {
            throw new runtime.RequiredError('updateResourceConnectionRequest','Required parameter requestParameters.updateResourceConnectionRequest was null or undefined when calling updateResourceConnection.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-connections/{resourceConnectionId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceConnectionId"}}`, encodeURIComponent(String(requestParameters.resourceConnectionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateResourceConnectionRequestToJSON(requestParameters.updateResourceConnectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceConnectionFromJSON(jsonValue));
    }

    /**
     * Update the resource connections configuration
     * Update topology resource connection by ID
     */
    async updateResourceConnection(requestParameters: UpdateResourceConnectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceConnection> {
        const response = await this.updateResourceConnectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate resource or resource connection before deletion
     * Validate resource or resource connection before deletion
     */
    async validateDeleteResourceRaw(requestParameters: ValidateDeleteResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TopologyResourceConnection>>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling validateDeleteResource.');
        }

        if (requestParameters.deletionType === null || requestParameters.deletionType === undefined) {
            throw new runtime.RequiredError('deletionType','Required parameter requestParameters.deletionType was null or undefined when calling validateDeleteResource.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateDeleteResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.deletionType !== undefined) {
            queryParameters['deletionType'] = requestParameters.deletionType;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/validate-delete`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopologyResourceConnectionFromJSON));
    }

    /**
     * Validate resource or resource connection before deletion
     * Validate resource or resource connection before deletion
     */
    async validateDeleteResource(requestParameters: ValidateDeleteResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TopologyResourceConnection>> {
        const response = await this.validateDeleteResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ValidateDeleteResourceDeletionTypeEnum = {
    Resource: 'resource',
    ResourceConnection: 'resource-connection'
} as const;
export type ValidateDeleteResourceDeletionTypeEnum = typeof ValidateDeleteResourceDeletionTypeEnum[keyof typeof ValidateDeleteResourceDeletionTypeEnum];
