import { format } from "date-fns";
import fuzzysort from "fuzzysort";
import {
  SCMProviderDetailsMapping,
  defaultGitIconURL,
} from "../config/constants";
import { Repository } from "../apis/appcd";
import { IaCFileTypes } from "../config/constants";

import { IconsList } from "../components";
import type { FormikErrors } from "formik";

export const isFunction = (fn: any): boolean => {
  return typeof fn === "function";
};

export const getFormattedDate = (
  date?: Date | string,
  formatType: string = "PPpp" // default format if not provided during function call
): string => {
  try {
    if (date) {
      return format(new Date(date), formatType);
    } else {
      return "N/A";
    }
  } catch (error) {
    return "N/A";
  }
};

/**
 * Calculates the time ago from a given date.
 *
 * @param {Date} date - The date to calculate the time ago from.
 * @return {string} The time ago in a human-readable format.
 */
export const getTimeAgo = (date: Date): string => {
  if (!(date instanceof Date && !isNaN(date.getTime()))) {
    return date?.toString() || "";
  }

  const seconds: number = Math.floor(
    (new Date().getTime() - date.getTime()) / 1000
  );

  const intervals: { [key: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (let interval in intervals) {
    const intervalInSeconds: number = intervals[interval];
    const intervalCount: number = Math.floor(seconds / intervalInSeconds);

    if (intervalCount >= 1) {
      return `${intervalCount} ${interval}${intervalCount > 1 ? "s" : ""} ago`;
    }
  }

  return "Just now";
};

// TODO: Replace this with a library(like underscore)
export const debounce = (func: (...args: any) => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;

  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

export const groupBy = (data: any[], key: string) => {
  if (!data || !Array.isArray(data) || !key) {
    return {};
  }

  return data.reduce(function (acc, obj) {
    if (typeof obj !== "object" || obj === null) {
      return acc;
    }

    const property = obj?.[key];
    if (property === null || property === undefined) {
      return acc;
    }

    if (!acc?.[property]) {
      acc[property] = [];
    }
    acc[property].push(obj);
    return acc;
  }, {});
};

/**
 * Copies the given text to the clipboard using the Clipboard API if available,
 * otherwise falls back to a manual method for older browsers.
 *
 * @param {string} textToCopy - the text to be copied to the clipboard
 * @return {void}
 */
export const copyToClipboard = (textToCopy: string) => {
  if (navigator?.clipboard) {
    navigator.clipboard.writeText(textToCopy);
  } else {
    // Fallback for older browsers
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }
};

/**
 * Convert the first letter of each word in the input string to uppercase and the remaining letters to lowercase.
 *
 * @param {string} str - the input string
 * @return {string} the modified string with capitalized words
 */
export const toCapitalize = (str: string = "") => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
};

/**
 * Checks if the given element or any of its parents includes a specific id related to React Select.
 *
 * @param {HTMLElement} element - The HTML element to start the search from.
 * @param {string} parentId - The id to look for in the parent elements. Default is "react-select".
 * @param {number} parentsToLookFor - The number of parent levels to check. Default is 3.
 * @param {boolean} strict - To check for exact Id and not an includes check. Default is true.
 * @return {boolean} true if the element or its parent has the specified id, false otherwise.
 */
export const hasParentWithId = (
  element: HTMLElement,
  parentId = "",
  parentsToLookFor = 3, // check for only 3 levels to avoid too many checks in the tree,
  strict = true
) => {
  let parent = element.parentElement;
  let level = 0;
  if (parentId) {
    while (parent && level < parentsToLookFor) {
      level++;
      if (strict ? parent.id === parentId : parent.id.includes(parentId)) {
        return true;
      }
      parent = parent.parentElement;
    }
    return false;
  } else {
    return false;
  }
};

export const getSCMProviderIconUrl = (
  provider: string,
  repositoryInfo?: Repository
) => {
  if (SCMProviderDetailsMapping[provider]?.iconUrl) {
    return SCMProviderDetailsMapping[provider]?.iconUrl;
  } else if (repositoryInfo?.url) {
    const provider = identifyProviderByUrl(repositoryInfo.url);
    return provider
      ? SCMProviderDetailsMapping[provider]?.iconUrl
      : defaultGitIconURL; // default git icon incase of unknown provider found
  } else {
    return defaultGitIconURL;
  }
};

/**
 * Identifies the provider by the given URL.
 *
 * @param {string} url - The URL to identify the provider from.
 * @return {string} The identifier for the provider.
 */
export const identifyProviderByUrl = (url: string) => {
  const githubPattern = /https?:\/\/github\.com/;
  const gitlabPattern = /https?:\/\/gitlab\.com/;
  const bitbucketPattern = /https?:\/\/bitbucket\.org/;
  if (githubPattern.test(url)) {
    return "public_github";
  } else if (gitlabPattern.test(url)) {
    return "public_gitlab";
  } else if (bitbucketPattern.test(url)) {
    return "public_bitbucket";
  }
  return null;
};

/**
 * Extracts the file extension from the given filename.
 *
 * @param {string} fileName - Filename for which the extension is to be extracted.
 */
export const getFileExtensionFromFilename = (fileName: string) => {
  return fileName?.split(".")?.pop() ?? fileName;
};

/**
 * Returns the file type for given filename.
 *
 * @param {string} fileName - Filename for which the type is to be extracted.
 */
export const getFileType = (fileName: string) => {
  const fileType = getFileExtensionFromFilename(fileName);

  return IaCFileTypes[fileType as keyof typeof IaCFileTypes] ?? fileType;
};

/**
 * Returns the icon name for given file type.
 *
 * @param {FileTypes} fileType - File type for which the icon name has to be returned.
 */
export const getFileIcon = (fileType: IaCFileTypes) => {
  switch (fileType) {
    case IaCFileTypes.tf:
      return IconsList.TERRAFORM;
    case IaCFileTypes.hcl:
      return IconsList.TERRAFORM;
    case IaCFileTypes.tfstate:
      return IconsList.TERRAFORM;
    case IaCFileTypes.yml:
      return IconsList.YAML_FILE;
    case IaCFileTypes.yaml:
      return IconsList.YAML_FILE;
    case IaCFileTypes.json:
      return IconsList.JSON_FILE;
    case IaCFileTypes.sh:
      return IconsList.SHELL_FILE;
    case IaCFileTypes.md:
      return IconsList.FILE;
    default:
      return IconsList.FILE;
  }
};

/**
 * Retrieves a nested value from an object based on an array of keys.
 *
 * @param {any} nestedObj - The nested object to retrieve the value from.
 * @param {any[]} pathArr - An array of keys representing the path to the desired value.
 * @return {any} The nested value if found, otherwise undefined.
 */
export const getNestedObject = (nestedObj: any, pathArr: any[]) => {
  return pathArr.reduce(
    (obj: { [x: string]: any }, key: string | number) =>
      obj && obj[key] !== "undefined" ? obj[key] : undefined,
    nestedObj
  );
};

/**
 * Performs a fuzzy search on the given data array based on the specified key.
 *
 * @param {string} searchTerm - the term to search for
 * @param {any[]} data - the array of object to search within
 * @param {string[]} keys - list of keys to perform the search on
 * @return {any[]} the filtered array of data that matches the fuzzy search
 */
export const fuzzySearch = (
  searchTerm: string,
  data: any[],
  keys: string[]
) => {
  if (searchTerm) {
    return fuzzysort.go(searchTerm, data, { keys: keys }).map((res) => res.obj);
  } else {
    return data;
  }
};

/**
 * Checks if the input string is a single word with more than a specified number of letters.
 *
 * @param {string} inputString - The string to be checked.
 * @param {number} lettersCap - The maximum number of letters allowed in the word.
 * @return {boolean} Returns true if the input string is a single word with more than the specified number of letters, otherwise returns false.
 */
export const isLongSingleWord = (inputString: string, lettersCap: number) => {
  if (!inputString) return false;
  return (
    inputString &&
    !/\s/.test(inputString ?? "") &&
    inputString?.length > lettersCap
  );
};

export type SetFieldValue<T> = (
  field: keyof T,
  value: T[keyof T],
  shouldValidate?: boolean | undefined
) => Promise<void | FormikErrors<T>>;
