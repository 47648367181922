import React, { type FC } from "react";
import {
  SpinnerType,
  type SpinnerProps,
  SpinnerVariant,
} from "./spinner-types";

// TODO as more customizable props as and when needed for variants
const Spinner: FC<SpinnerProps> = ({
  className = "",
  dataTestId,
  variant = "light",
  type = SpinnerType.RING,
}) => {
  return type === SpinnerType.RING ? (
    <svg
      {...(dataTestId ? { "data-testid": dataTestId } : {})}
      className={`tw-animate-spin tw-h-5 tw-w-5 ${SpinnerVariant[variant]} ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="tw-opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="tw-opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  ) : (
    <span className="tw-flex tw-justify-between tw-w-7 tw-items-center tw-h-3">
      {[...Array(7)].map((_, i) => (
        <span
          key={i}
          className={`tw-w-0.5 tw-h-2 tw-bg-gray-200 tw-animate-loader-height-pulse tw-rounded-md ${className}`}
          style={{ animationDelay: `${i * 0.7}s` }}
        />
      ))}
    </span>
  );
};

export default Spinner;
