/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolicyFilters
 */
export interface PolicyFilters {
    /**
     * 
     * @type {string}
     * @memberof PolicyFilters
     */
    provider?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyFilters
     */
    benchmarks?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyFilters
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyFilters
     */
    severities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyFilters
     */
    resourceTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolicyFilters
     */
    types?: Array<string>;
}

/**
 * Check if a given object implements the PolicyFilters interface.
 */
export function instanceOfPolicyFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicyFiltersFromJSON(json: any): PolicyFilters {
    return PolicyFiltersFromJSONTyped(json, false);
}

export function PolicyFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'benchmarks': !exists(json, 'benchmarks') ? undefined : json['benchmarks'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'severities': !exists(json, 'severities') ? undefined : json['severities'],
        'resourceTypes': !exists(json, 'resourceTypes') ? undefined : json['resourceTypes'],
        'types': !exists(json, 'types') ? undefined : json['types'],
    };
}

export function PolicyFiltersToJSON(value?: PolicyFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': value.provider,
        'benchmarks': value.benchmarks,
        'categories': value.categories,
        'severities': value.severities,
        'resourceTypes': value.resourceTypes,
        'types': value.types,
    };
}

