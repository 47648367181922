import React, { type FC } from "react";
import { Drawer, Icon, IconsList } from "../../components";
import ResourceTagsForm from "./resource-tags-form";
import type { GlobalResourceTagsDrawerProps } from "./global-resource-tags-types";

const GlobalResourceTagsDrawer: FC<GlobalResourceTagsDrawerProps> = ({
  open,
  initialTags,
  onClose,
  onSubmit,
}) => {
  return (
    <Drawer
      open={open}
      size="sm"
      dataTestId="resource-tags-drawer"
      isFullScreenDrawer={false}
    >
      <div className="tw-h-full tw-relative tw-bg-gray-800 ">
        <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between ">
          <div className="tw-flex tw-items-center tw-gap-2">
            <span className="tw-text-white tw-text-base tw-font-semibold tw-flex tw-gap-2 tw-items-center">
              <Icon name={IconsList.TAG} />
              <span>Edit Resource Tags</span>
            </span>
          </div>
          <div
            className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
            onClick={onClose}
            data-testid="custom-resource-drawer-close"
          >
            <Icon name={IconsList.CLOSE} />
          </div>
        </div>
        <div>
          <p className="tw-text-xssm tw-font-normal tw-text-gray-200 tw-px-4 tw-pt-5">
            Instance of this resource created using StackGen-generated IaC will
            be tagged with the identifiers you provide below.
          </p>
        </div>
        <div>
          <ResourceTagsForm
            onClose={onClose}
            onSubmit={onSubmit}
            initialTags={initialTags}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default GlobalResourceTagsDrawer;
