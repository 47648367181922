/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceConfigOptionDependentAttributesInnerLinkAttributesAction } from './ResourceConfigOptionDependentAttributesInnerLinkAttributesAction';
import {
    ResourceConfigOptionDependentAttributesInnerLinkAttributesActionFromJSON,
    ResourceConfigOptionDependentAttributesInnerLinkAttributesActionFromJSONTyped,
    ResourceConfigOptionDependentAttributesInnerLinkAttributesActionToJSON,
} from './ResourceConfigOptionDependentAttributesInnerLinkAttributesAction';

/**
 * 
 * @export
 * @interface ResourceConfigOptionDependentAttributesInnerLinkAttributes
 */
export interface ResourceConfigOptionDependentAttributesInnerLinkAttributes {
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOptionDependentAttributesInnerLinkAttributes
     */
    label?: string;
    /**
     * 
     * @type {ResourceConfigOptionDependentAttributesInnerLinkAttributesAction}
     * @memberof ResourceConfigOptionDependentAttributesInnerLinkAttributes
     */
    action?: ResourceConfigOptionDependentAttributesInnerLinkAttributesAction;
}

/**
 * Check if a given object implements the ResourceConfigOptionDependentAttributesInnerLinkAttributes interface.
 */
export function instanceOfResourceConfigOptionDependentAttributesInnerLinkAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceConfigOptionDependentAttributesInnerLinkAttributesFromJSON(json: any): ResourceConfigOptionDependentAttributesInnerLinkAttributes {
    return ResourceConfigOptionDependentAttributesInnerLinkAttributesFromJSONTyped(json, false);
}

export function ResourceConfigOptionDependentAttributesInnerLinkAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceConfigOptionDependentAttributesInnerLinkAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'action': !exists(json, 'action') ? undefined : ResourceConfigOptionDependentAttributesInnerLinkAttributesActionFromJSON(json['action']),
    };
}

export function ResourceConfigOptionDependentAttributesInnerLinkAttributesToJSON(value?: ResourceConfigOptionDependentAttributesInnerLinkAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'action': ResourceConfigOptionDependentAttributesInnerLinkAttributesActionToJSON(value.action),
    };
}

