import React, { type FC } from "react";
import { Form, Formik } from "formik";

import { Button, Icon, IconSize, IconsList } from "../../components";
import type { ViraQuestionLayoutProps } from "./";
import { isFunction } from "../../utils";

const ViraQuestionLayout: FC<ViraQuestionLayoutProps> = ({
  question,
  questionBodyRenderer,
  onClose,
  onAnswer,
}) => {
  return (
    <div className="tw-px-4 tw-pt-5 tw-pb-4">
      <div className="tw-flex tw-justify-between tw-gap-2">
        <p className="tw-mb-5 tw-text-sm tw-font-medium">{question.title}</p>
        <div
          className="tw-text-gray-200 hover:tw-text-gray-100 tw-cursor-pointer"
          data-testid={`skip-vira-${question.type}-question`}
          onClick={onClose}
        >
          <Icon name={IconsList.CLOSE} size={IconSize.md} />
        </div>
      </div>
      {onAnswer ? (
        <Formik
          initialValues={{ answer: question.type === "RANGE" ? [30] : "" }}
          onSubmit={(values) => {
            onAnswer({
              question,
              answer:
                question.type === "RANGE" ? values.answer[0] : values.answer,
            });
          }}
        >
          {({ values }) => {
            return (
              <Form>
                {questionBodyRenderer && isFunction(questionBodyRenderer)
                  ? questionBodyRenderer(values)
                  : null}
                <div className="tw-flex tw-gap-2">
                  <Button
                    label="Skip"
                    variant="secondary2"
                    onClick={onClose}
                    className="tw-flex-1"
                    dataTestId={`skip-vira-${question.type}-question`}
                  />
                  <Button
                    label="Done"
                    variant="primary"
                    type="submit"
                    className="tw-flex-1"
                    dataTestId={`done-vira-${question.type}-question`}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : questionBodyRenderer && isFunction(questionBodyRenderer) ? (
        questionBodyRenderer()
      ) : null}
    </div>
  );
};

export default ViraQuestionLayout;
