/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Org } from './Org';
import {
    OrgFromJSON,
    OrgFromJSONTyped,
    OrgToJSON,
} from './Org';

/**
 * 
 * @export
 * @interface ListOrganizations200Response
 */
export interface ListOrganizations200Response {
    /**
     * 
     * @type {Array<Org>}
     * @memberof ListOrganizations200Response
     */
    organizations?: Array<Org>;
}

/**
 * Check if a given object implements the ListOrganizations200Response interface.
 */
export function instanceOfListOrganizations200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListOrganizations200ResponseFromJSON(json: any): ListOrganizations200Response {
    return ListOrganizations200ResponseFromJSONTyped(json, false);
}

export function ListOrganizations200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOrganizations200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizations': !exists(json, 'organizations') ? undefined : ((json['organizations'] as Array<any>).map(OrgFromJSON)),
    };
}

export function ListOrganizations200ResponseToJSON(value?: ListOrganizations200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizations': value.organizations === undefined ? undefined : ((value.organizations as Array<any>).map(OrgToJSON)),
    };
}

