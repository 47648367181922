import type { TopologyResourceConnection } from "../../../../apis/topology";
import type { ResourceConnectionDetails } from "./resource-pack-types";

export const getResourceConnectionFromConnectionDetails: (
  connections: ResourceConnectionDetails[]
) => TopologyResourceConnection[] = (connections) => {
  const connectionsPayload: TopologyResourceConnection[] = [];
  for (const connection of connections) {
    if (connection.data?.hidden) {
      for (const { data } of connection.data.connectionTypes) {
        connectionsPayload.push({
          sourceResourceId: connection?.sourceResourceId,
          targetResourceId: connection?.targetResourceId,
          data,
          hidden: true,
        });
      }
    } else if (Array.isArray(connection?.data?.connectionTypes)) {
      for (const { data } of connection.data.connectionTypes) {
        connectionsPayload.push({
          sourceResourceId: connection?.source,
          targetResourceId: connection.target,
          data,
        });
      }
    }
  }
  return connectionsPayload;
};
