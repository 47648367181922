/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuntimeDetails
 */
export interface RuntimeDetails {
    /**
     * The runtime name
     * @type {string}
     * @memberof RuntimeDetails
     */
    name: RuntimeDetailsNameEnum;
    /**
     * The runtime version
     * @type {string}
     * @memberof RuntimeDetails
     */
    version?: string;
}


/**
 * @export
 */
export const RuntimeDetailsNameEnum = {
    Nodejs: 'NODEJS',
    Java: 'JAVA',
    Python: 'PYTHON',
    Dotnetcore: 'DOTNETCORE',
    Dotnet: 'DOTNET',
    Go: 'GO',
    Ruby: 'RUBY'
} as const;
export type RuntimeDetailsNameEnum = typeof RuntimeDetailsNameEnum[keyof typeof RuntimeDetailsNameEnum];


/**
 * Check if a given object implements the RuntimeDetails interface.
 */
export function instanceOfRuntimeDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function RuntimeDetailsFromJSON(json: any): RuntimeDetails {
    return RuntimeDetailsFromJSONTyped(json, false);
}

export function RuntimeDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuntimeDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function RuntimeDetailsToJSON(value?: RuntimeDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'version': value.version,
    };
}

