/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyResourceConnection } from './TopologyResourceConnection';
import {
    TopologyResourceConnectionFromJSON,
    TopologyResourceConnectionFromJSONTyped,
    TopologyResourceConnectionToJSON,
} from './TopologyResourceConnection';

/**
 * 
 * @export
 * @interface SupportedResourceType
 */
export interface SupportedResourceType {
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    resourceType?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    iacType?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    templateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedResourceType
     */
    isGroup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    groupType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedResourceType
     */
    isCustom?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedResourceType
     */
    isResourcePack?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    resourcePackID?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    createdBy?: string;
    /**
     * 
     * @type {Array<TopologyResourceConnection>}
     * @memberof SupportedResourceType
     */
    packConnections?: Array<TopologyResourceConnection>;
    /**
     * 
     * @type {any}
     * @memberof SupportedResourceType
     */
    packResources?: any;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    shareWith?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    creatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportedResourceType
     */
    packDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedResourceType
     */
    isRestricted?: boolean;
}

/**
 * Check if a given object implements the SupportedResourceType interface.
 */
export function instanceOfSupportedResourceType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupportedResourceTypeFromJSON(json: any): SupportedResourceType {
    return SupportedResourceTypeFromJSONTyped(json, false);
}

export function SupportedResourceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedResourceType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'iacType': !exists(json, 'iacType') ? undefined : json['iacType'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'isGroup': !exists(json, 'isGroup') ? undefined : json['isGroup'],
        'groupType': !exists(json, 'groupType') ? undefined : json['groupType'],
        'isCustom': !exists(json, 'isCustom') ? undefined : json['isCustom'],
        'isResourcePack': !exists(json, 'isResourcePack') ? undefined : json['isResourcePack'],
        'resourcePackID': !exists(json, 'resourcePackID') ? undefined : json['resourcePackID'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'packConnections': !exists(json, 'packConnections') ? undefined : ((json['packConnections'] as Array<any>).map(TopologyResourceConnectionFromJSON)),
        'packResources': !exists(json, 'packResources') ? undefined : json['packResources'],
        'shareWith': !exists(json, 'shareWith') ? undefined : json['shareWith'],
        'creatorName': !exists(json, 'creatorName') ? undefined : json['creatorName'],
        'packDescription': !exists(json, 'packDescription') ? undefined : json['packDescription'],
        'isRestricted': !exists(json, 'isRestricted') ? undefined : json['isRestricted'],
    };
}

export function SupportedResourceTypeToJSON(value?: SupportedResourceType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceType': value.resourceType,
        'iacType': value.iacType,
        'label': value.label,
        'templateId': value.templateId,
        'isGroup': value.isGroup,
        'groupType': value.groupType,
        'isCustom': value.isCustom,
        'isResourcePack': value.isResourcePack,
        'resourcePackID': value.resourcePackID,
        'createdBy': value.createdBy,
        'packConnections': value.packConnections === undefined ? undefined : ((value.packConnections as Array<any>).map(TopologyResourceConnectionToJSON)),
        'packResources': value.packResources,
        'shareWith': value.shareWith,
        'creatorName': value.creatorName,
        'packDescription': value.packDescription,
        'isRestricted': value.isRestricted,
    };
}

