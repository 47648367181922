import React, { type FC } from "react";
import { Icon, IconSize, IconsList } from "../icons";
import { FlowSelectionProps } from "./flow-selection-types";
import { trackAmplitudeEvent } from "../../utils";
import { Tag } from "../../components";

const FlowSelection: FC<FlowSelectionProps> = ({
  title,
  onClose = () => {},
  onFlowClick = (id: string) => {},
  flowOptions,
}) => {
  return (
    <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-bg-gray-800 tw-border tw-border-gray-700 tw-rounded-md tw-p-6 tw-justify-between tw-items-center">
      <button
        onClick={onClose}
        className="tw-self-end"
        data-testid="create-appstack-cancel-action"
      >
        <Icon
          name={IconsList.CLOSE}
          size={IconSize.lg}
          className="tw-text-gray-300 hover:tw-text-gray-200 tw-cursor-pointer"
        />
      </button>
      <div className="tw-flex tw-flex-col tw-gap-5 tw-overflow-auto">
        <span className="tw-text-gray-200 tw-font-semibold tw-mb-8 tw-text-xl tw-self-center">
          {title}
        </span>
        {flowOptions.map((flow) => (
          <div
            key={flow.id}
            className={`tw-flex tw-px-10 tw-py-8 tw-rounded-md tw-bg-gray-700 tw-border tw-border-gray-600 tw-gap-4 tw-items-center tw-max-w-3xl ${
              flow.disabled
                ? "tw-cursor-not-allowed tw-opacity-40"
                : "tw-cursor-pointer hover:tw-bg-gray-600 hover:tw-border-gray-500"
            }`}
            onClick={() => {
              if (!flow.disabled) {
                if (flow.analyticsEventId) {
                  trackAmplitudeEvent(flow.analyticsEventId);
                }
                onFlowClick(flow.id);
              }
            }}
            data-testid={`flow-selection-${flow.id}`}
          >
            <div>
              <Icon
                name={flow.icon}
                size={IconSize["4xl"]}
                className="tw-text-gray-300"
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <span className="tw-text-blue-500 tw-font-semibold tw-text-base tw-flex tw-gap-2 tw-items-center">
                {flow.title}{" "}
                {flow.isNew && (
                  <Tag
                    title="New"
                    size="md"
                    className="tw-bg-purple-800 tw-text-purple-100 tw-border-purple-500"
                  />
                )}
              </span>
              <span className="tw-text-white-100 tw-font-normal tw-text-base">
                {flow.description}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default FlowSelection;
