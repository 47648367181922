/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceUpdateAction } from './ResourceUpdateAction';
import {
    ResourceUpdateActionFromJSON,
    ResourceUpdateActionFromJSONTyped,
    ResourceUpdateActionToJSON,
} from './ResourceUpdateAction';

/**
 * 
 * @export
 * @interface UpdateResourceRequest
 */
export interface UpdateResourceRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceRequest
     */
    resourceId?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateResourceRequest
     */
    _configuration?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateResourceRequest
     */
    tfVars?: object;
    /**
     * 
     * @type {ResourceUpdateAction}
     * @memberof UpdateResourceRequest
     */
    action?: ResourceUpdateAction;
    /**
     * 
     * @type {string}
     * @memberof UpdateResourceRequest
     */
    sourceGroupID?: string;
}

/**
 * Check if a given object implements the UpdateResourceRequest interface.
 */
export function instanceOfUpdateResourceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateResourceRequestFromJSON(json: any): UpdateResourceRequest {
    return UpdateResourceRequestFromJSONTyped(json, false);
}

export function UpdateResourceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateResourceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'tfVars': !exists(json, 'tfVars') ? undefined : json['tfVars'],
        'action': !exists(json, 'action') ? undefined : ResourceUpdateActionFromJSON(json['action']),
        'sourceGroupID': !exists(json, 'sourceGroupID') ? undefined : json['sourceGroupID'],
    };
}

export function UpdateResourceRequestToJSON(value?: UpdateResourceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceId': value.resourceId,
        'configuration': value._configuration,
        'tfVars': value.tfVars,
        'action': ResourceUpdateActionToJSON(value.action),
        'sourceGroupID': value.sourceGroupID,
    };
}

