/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudProvider } from './CloudProvider';
import {
    CloudProviderFromJSON,
    CloudProviderFromJSONTyped,
    CloudProviderToJSON,
} from './CloudProvider';

/**
 * 
 * @export
 * @interface CloudProviderMetadata
 */
export interface CloudProviderMetadata {
    /**
     * 
     * @type {CloudProvider}
     * @memberof CloudProviderMetadata
     */
    id?: CloudProvider;
    /**
     * Friendly name of the cloud provider
     * @type {string}
     * @memberof CloudProviderMetadata
     */
    name?: string;
    /**
     * Link to the cloud provider documentation
     * @type {string}
     * @memberof CloudProviderMetadata
     */
    docLink?: string;
    /**
     * Indicates if the cloud provider is supported
     * @type {boolean}
     * @memberof CloudProviderMetadata
     */
    supported?: boolean;
    /**
     * Icon of the cloud provider
     * @type {string}
     * @memberof CloudProviderMetadata
     */
    imageLink?: string;
}

/**
 * Check if a given object implements the CloudProviderMetadata interface.
 */
export function instanceOfCloudProviderMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CloudProviderMetadataFromJSON(json: any): CloudProviderMetadata {
    return CloudProviderMetadataFromJSONTyped(json, false);
}

export function CloudProviderMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CloudProviderMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : CloudProviderFromJSON(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'docLink': !exists(json, 'docLink') ? undefined : json['docLink'],
        'supported': !exists(json, 'supported') ? undefined : json['supported'],
        'imageLink': !exists(json, 'imageLink') ? undefined : json['imageLink'],
    };
}

export function CloudProviderMetadataToJSON(value?: CloudProviderMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': CloudProviderToJSON(value.id),
        'name': value.name,
        'docLink': value.docLink,
        'supported': value.supported,
        'imageLink': value.imageLink,
    };
}

