import React, { type FC } from "react";

import { ViraQuestionLayout, type ViraTextQuestionProps } from "../";
import { TextArea } from "../../../components";

const ViraTextQuestion: FC<ViraTextQuestionProps> = ({
  question,
  onSkip,
  onAnswer,
}) => {
  return (
    <ViraQuestionLayout
      question={question}
      onClose={onSkip}
      onAnswer={onAnswer}
      questionBodyRenderer={() => (
        <div className="tw-mb-4">
          <TextArea
            name="answer"
            rows={3}
            dataTestId="vira-text-question"
            placeholder="Enter description here"
            backgroundVariant="gray-800"
          />
        </div>
      )}
    />
  );
};

export default ViraTextQuestion;
