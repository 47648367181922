import { useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useState } from "react";
import { type Edge, type Node, Panel, useOnSelectionChange } from "reactflow";
import { IconButton, IconsList, Tooltip } from "../../../components";
import {
  activeResourcePackAtom,
  processedGraphDataAtom,
} from "../../../stores/topology.store";
import { trackAmplitudeEvent } from "../../../utils";
import { TooltipPositions } from "../../tooltip/tooltip.types";

interface TopologyToolbarProps {
  onSave: () => void;
}

const TopologyToolbar: React.FC<TopologyToolbarProps> = ({ onSave }) => {
  const setActiveResourcePack = useSetAtom(activeResourcePackAtom);
  const processedGraphData = useAtomValue(processedGraphDataAtom);

  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);
  const [selectedEdges, setSelectedEdges] = useState<Edge[]>([]);

  const onChange = useCallback(
    ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => {
      setSelectedNodes(
        nodes.map((node) => ({
          ...node,
          selected: false,
          ...node.data,
        }))
      );
      setSelectedEdges(
        edges.map((edge) => ({
          ...edge,
          selected: false,
        }))
      );
    },
    []
  );

  useOnSelectionChange({
    onChange,
  });

  const onSaveResourcePack = useCallback(() => {
    /** Resources
     * 1. Filter out the children nodes which are part of the selected parent nodes
     */
    const parentResources = selectedNodes.filter((node) => !node.parentNode);

    /** Connections
     * 1. Get all selected edges
     * 2. Get all hidden connections of the selected nodes (eg. vpc-subnet, aws-region)
     */
    const selectedConnections = selectedEdges.map((edge) => ({
      ...edge,
      selected: false,
    }));

    const hiddenResourceConnections = Object.values(
      processedGraphData?.resourceConnections || {}
    ).filter((connection) =>
      selectedNodes.some(
        (node) =>
          (node.id === connection.sourceResourceId ||
            node.id === connection.targetResourceId) &&
          connection.data.hidden
      )
    );

    const hiddenConnections = hiddenResourceConnections.map((connection) => ({
      ...connection,
      data: { ...connection.data, hidden: true },
      selected: false,
      hidden: true,
    }));

    setActiveResourcePack({
      resources: parentResources.map((resource) => ({
        ...resource,
        selected: false,
      })),
      connections: [...selectedConnections, ...hiddenConnections],
    });
    trackAmplitudeEvent("resource_pack_try_add");
    onSave();
  }, [selectedNodes, selectedEdges]);

  return (
    <Panel position={"top-center"}>
      <div
        className={`tw-flex tw-gap-1 tw-p-1 tw-rounded-lg ${
          selectedNodes.length > 1 ? "tw-border tw-border-gray-500" : ""
        }`}
      >
        {selectedNodes.length > 1 && (
          <Tooltip
            title="Save as resource pack"
            position={TooltipPositions.BOTTOM_END}
          >
            <IconButton
              icon={IconsList.SAVE}
              dataTestId={"resource-pack-save"}
              variant="secondary2"
              className="tw-bg-transparent"
              size="sm"
              outlined
              onClick={onSaveResourcePack}
            />
          </Tooltip>
        )}
      </div>
    </Panel>
  );
};

export default TopologyToolbar;
