import React, { useState } from "react";
import type { SupportedResourceType } from "../../../apis/topology";
import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
} from "../../../components";
import type { Resource } from "../topology-graph-types";

const RestrictedResourcesDrawer = ({
  restrictedResources,
  restrictedResourcesInTopology,
  onPanelClose,
}: {
  restrictedResources: {
    [key: string]: SupportedResourceType;
  };
  restrictedResourcesInTopology: {
    [resourceId: string]: Resource;
  };
  onPanelClose: () => void;
}) => {
  const [
    restrictedResourcesInTopologyExpanded,
    setRestrictedResourcesInTopologyExpanded,
  ] = useState(true);
  const [restrictedResourcesExpanded, setRestrictedResourcesExpanded] =
    useState(false);

  return (
    <div className="tw-h-full tw-relative tw-bg-gray-800">
      <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-center tw-gap-3">
          <div className="tw-text-gray-200">
            <Icon name={IconsList.CIRCLE_STOP_RESTRICTED} size={IconSize.lg} />
          </div>
          <span className="tw-text-white tw-text-base tw-font-semibold">
            Restricted Resources
          </span>
        </div>
        <div
          className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
          onClick={onPanelClose}
          data-testid="custom-resource-drawer-close"
        >
          <Icon name={IconsList.CLOSE} />
        </div>
      </div>
      <div className="tw-p-4">
        {restrictedResourcesInTopology &&
          Object.keys(restrictedResourcesInTopology).length > 0 && (
            <div>
              <div
                className="tw-flex tw-gap-1 tw-cursor-pointer"
                onClick={() =>
                  setRestrictedResourcesInTopologyExpanded(
                    (restrictedResourcesInTopologyExpanded) =>
                      !restrictedResourcesInTopologyExpanded
                  )
                }
                data-testid="expand-collapse-restricted-resources-in-topology"
              >
                <span className="tw-text-gray-200">
                  <Icon
                    name={
                      restrictedResourcesInTopologyExpanded
                        ? IconsList.CARET_DOWN
                        : IconsList.CARET_RIGHT
                    }
                  />
                </span>
                <span className="tw-text-sm tw-text-gray-50 tw-font-semibold">
                  Restricted resources in your topology
                </span>
              </div>
              {restrictedResourcesInTopologyExpanded && (
                <div className="tw-ml-6">
                  <div className="tw-mt-2 tw-text-gray-200 tw-text-xssm">
                    Please delete or replace them
                  </div>
                  <div className="tw-mt-3 tw-flex tw-flex-col tw-gap-3">
                    {Object.values(restrictedResourcesInTopology).map(
                      (resource) => (
                        <div
                          key={resource.id}
                          className="tw-flex tw-gap-2 tw-items-center tw-rounded-md"
                        >
                          <AWSIcon
                            name={
                              AWSIconsList[
                                resource.resourceType as keyof typeof AWSIconsList
                              ]
                            }
                            alt={resource.label || resource.resourceTypeLabel}
                            fallbackPlaceholder={resource.resourceType}
                            className="tw-rounded"
                          />
                          <span className="tw-text-xs tw-text-gray-50">
                            {resource.label || resource.resourceTypeLabel}
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              <hr className="tw-h-px tw-my-4 tw-border-0 tw-bg-gray-600"></hr>
            </div>
          )}
        {restrictedResources && Object.keys(restrictedResources).length > 0 && (
          <div>
            <div
              className="tw-flex tw-gap-1 tw-cursor-pointer"
              onClick={() =>
                setRestrictedResourcesExpanded(
                  (restrictedResourcesExpanded) => !restrictedResourcesExpanded
                )
              }
              data-testid="expand-collapse-restricted-resources"
            >
              <span className="tw-text-gray-200">
                <Icon
                  name={
                    restrictedResourcesExpanded
                      ? IconsList.CARET_DOWN
                      : IconsList.CARET_RIGHT
                  }
                />
              </span>
              <span className="tw-text-sm tw-text-gray-50 tw-font-semibold">
                All restricted resources
              </span>
            </div>
            {restrictedResourcesExpanded && (
              <div className="tw-ml-6">
                <div className="tw-mt-2 tw-text-gray-200 tw-text-xssm">
                  Please contact the administrator to remove the restriction.
                </div>
                <div className="tw-mt-3 tw-flex tw-flex-col tw-gap-3">
                  {Object.values(restrictedResources).map((resource) => (
                    <div
                      key={resource.resourceType}
                      className="tw-flex tw-gap-2 tw-items-center tw-rounded-md"
                    >
                      <AWSIcon
                        name={
                          AWSIconsList[
                            resource.resourceType as keyof typeof AWSIconsList
                          ]
                        }
                        alt={resource.label}
                        fallbackPlaceholder={resource.resourceType}
                        className="tw-rounded"
                      />
                      <span className="tw-text-xs tw-text-gray-50">
                        {resource.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RestrictedResourcesDrawer;
