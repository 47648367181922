/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProvidersResponse
 */
export interface ProvidersResponse {
    /**
     * Name of the provider
     * @type {string}
     * @memberof ProvidersResponse
     */
    name?: string;
    /**
     * Type of the provider
     * @type {string}
     * @memberof ProvidersResponse
     */
    type?: string;
    /**
     * checks if the provider is configured
     * @type {boolean}
     * @memberof ProvidersResponse
     */
    configured?: boolean;
    /**
     * Link to create a token for the provider
     * @type {string}
     * @memberof ProvidersResponse
     */
    tokenLink?: string;
}

/**
 * Check if a given object implements the ProvidersResponse interface.
 */
export function instanceOfProvidersResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProvidersResponseFromJSON(json: any): ProvidersResponse {
    return ProvidersResponseFromJSONTyped(json, false);
}

export function ProvidersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProvidersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'configured': !exists(json, 'configured') ? undefined : json['configured'],
        'tokenLink': !exists(json, 'token_link') ? undefined : json['token_link'],
    };
}

export function ProvidersResponseToJSON(value?: ProvidersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'configured': value.configured,
        'token_link': value.tokenLink,
    };
}

