/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceLayoutSection } from './ResourceLayoutSection';
import {
    ResourceLayoutSectionFromJSON,
    ResourceLayoutSectionFromJSONTyped,
    ResourceLayoutSectionToJSON,
} from './ResourceLayoutSection';

/**
 * 
 * @export
 * @interface TopologyResourceConfigLayout
 */
export interface TopologyResourceConfigLayout {
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigLayout
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigLayout
     */
    label?: string;
    /**
     * 
     * @type {Array<ResourceLayoutSection>}
     * @memberof TopologyResourceConfigLayout
     */
    sections?: Array<ResourceLayoutSection>;
}

/**
 * Check if a given object implements the TopologyResourceConfigLayout interface.
 */
export function instanceOfTopologyResourceConfigLayout(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyResourceConfigLayoutFromJSON(json: any): TopologyResourceConfigLayout {
    return TopologyResourceConfigLayoutFromJSONTyped(json, false);
}

export function TopologyResourceConfigLayoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceConfigLayout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(ResourceLayoutSectionFromJSON)),
    };
}

export function TopologyResourceConfigLayoutToJSON(value?: TopologyResourceConfigLayout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'label': value.label,
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(ResourceLayoutSectionToJSON)),
    };
}

