import React, { useState, useRef, useCallback, useEffect } from "react";

const useStateWithCallbackLazy = <T>(initialValue: T) => {
  const callbackRef = useRef<((newValue: T) => void) | null>(null);
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(value);
      callbackRef.current = null;
    }
  }, [value]);

  const setValueWithCallback = useCallback(
    (newValue: T, callback: (newValue: T) => void) => {
      callbackRef.current = callback;
      setValue(newValue);
    },
    []
  );

  return [value, setValueWithCallback] as const;
};

export default useStateWithCallbackLazy;
