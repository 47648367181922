/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Repository
 */
export interface Repository {
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    namespace?: string;
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    branch?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Repository
     */
    archived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Repository
     */
    _private?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Repository
     */
    onboarded?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    description?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof Repository
     */
    language?: { [key: string]: number; };
    /**
     * Time the repository was last updated
     * @type {Date}
     * @memberof Repository
     */
    lastUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Repository
     */
    url: string;
}

/**
 * Check if a given object implements the Repository interface.
 */
export function instanceOfRepository(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function RepositoryFromJSON(json: any): Repository {
    return RepositoryFromJSONTyped(json, false);
}

export function RepositoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Repository {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'namespace': !exists(json, 'namespace') ? undefined : json['namespace'],
        'branch': !exists(json, 'branch') ? undefined : json['branch'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        '_private': !exists(json, 'private') ? undefined : json['private'],
        'onboarded': !exists(json, 'onboarded') ? undefined : json['onboarded'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'name': json['name'],
        'url': json['url'],
    };
}

export function RepositoryToJSON(value?: Repository | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'namespace': value.namespace,
        'branch': value.branch,
        'archived': value.archived,
        'private': value._private,
        'onboarded': value.onboarded,
        'description': value.description,
        'language': value.language,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
        'id': value.id,
        'provider': value.provider,
        'name': value.name,
        'url': value.url,
    };
}

