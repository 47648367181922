/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentTraitsDependenciesAwsDynamodbInner } from './ComponentTraitsDependenciesAwsDynamodbInner';
import {
    ComponentTraitsDependenciesAwsDynamodbInnerFromJSON,
    ComponentTraitsDependenciesAwsDynamodbInnerFromJSONTyped,
    ComponentTraitsDependenciesAwsDynamodbInnerToJSON,
} from './ComponentTraitsDependenciesAwsDynamodbInner';
import type { ComponentTraitsDependenciesAwsKinesisInner } from './ComponentTraitsDependenciesAwsKinesisInner';
import {
    ComponentTraitsDependenciesAwsKinesisInnerFromJSON,
    ComponentTraitsDependenciesAwsKinesisInnerFromJSONTyped,
    ComponentTraitsDependenciesAwsKinesisInnerToJSON,
} from './ComponentTraitsDependenciesAwsKinesisInner';
import type { ComponentTraitsDependenciesAwsKmsInner } from './ComponentTraitsDependenciesAwsKmsInner';
import {
    ComponentTraitsDependenciesAwsKmsInnerFromJSON,
    ComponentTraitsDependenciesAwsKmsInnerFromJSONTyped,
    ComponentTraitsDependenciesAwsKmsInnerToJSON,
} from './ComponentTraitsDependenciesAwsKmsInner';
import type { ComponentTraitsDependenciesAwsSnsInner } from './ComponentTraitsDependenciesAwsSnsInner';
import {
    ComponentTraitsDependenciesAwsSnsInnerFromJSON,
    ComponentTraitsDependenciesAwsSnsInnerFromJSONTyped,
    ComponentTraitsDependenciesAwsSnsInnerToJSON,
} from './ComponentTraitsDependenciesAwsSnsInner';
import type { ComponentTraitsDependenciesAwsSqsInner } from './ComponentTraitsDependenciesAwsSqsInner';
import {
    ComponentTraitsDependenciesAwsSqsInnerFromJSON,
    ComponentTraitsDependenciesAwsSqsInnerFromJSONTyped,
    ComponentTraitsDependenciesAwsSqsInnerToJSON,
} from './ComponentTraitsDependenciesAwsSqsInner';
import type { ComponentTraitsDependenciesAwsSsmParameterInner } from './ComponentTraitsDependenciesAwsSsmParameterInner';
import {
    ComponentTraitsDependenciesAwsSsmParameterInnerFromJSON,
    ComponentTraitsDependenciesAwsSsmParameterInnerFromJSONTyped,
    ComponentTraitsDependenciesAwsSsmParameterInnerToJSON,
} from './ComponentTraitsDependenciesAwsSsmParameterInner';
import type { ComponentTraitsDependenciesAzureEventHubInner } from './ComponentTraitsDependenciesAzureEventHubInner';
import {
    ComponentTraitsDependenciesAzureEventHubInnerFromJSON,
    ComponentTraitsDependenciesAzureEventHubInnerFromJSONTyped,
    ComponentTraitsDependenciesAzureEventHubInnerToJSON,
} from './ComponentTraitsDependenciesAzureEventHubInner';
import type { ComponentTraitsDependenciesAzureKeyVaultKeyInner } from './ComponentTraitsDependenciesAzureKeyVaultKeyInner';
import {
    ComponentTraitsDependenciesAzureKeyVaultKeyInnerFromJSON,
    ComponentTraitsDependenciesAzureKeyVaultKeyInnerFromJSONTyped,
    ComponentTraitsDependenciesAzureKeyVaultKeyInnerToJSON,
} from './ComponentTraitsDependenciesAzureKeyVaultKeyInner';
import type { ComponentTraitsDependenciesAzureKeyVaultSecretInner } from './ComponentTraitsDependenciesAzureKeyVaultSecretInner';
import {
    ComponentTraitsDependenciesAzureKeyVaultSecretInnerFromJSON,
    ComponentTraitsDependenciesAzureKeyVaultSecretInnerFromJSONTyped,
    ComponentTraitsDependenciesAzureKeyVaultSecretInnerToJSON,
} from './ComponentTraitsDependenciesAzureKeyVaultSecretInner';
import type { ComponentTraitsDependenciesAzureServiceBusInner } from './ComponentTraitsDependenciesAzureServiceBusInner';
import {
    ComponentTraitsDependenciesAzureServiceBusInnerFromJSON,
    ComponentTraitsDependenciesAzureServiceBusInnerFromJSONTyped,
    ComponentTraitsDependenciesAzureServiceBusInnerToJSON,
} from './ComponentTraitsDependenciesAzureServiceBusInner';
import type { ComponentTraitsDependenciesAzureStorageContainerInner } from './ComponentTraitsDependenciesAzureStorageContainerInner';
import {
    ComponentTraitsDependenciesAzureStorageContainerInnerFromJSON,
    ComponentTraitsDependenciesAzureStorageContainerInnerFromJSONTyped,
    ComponentTraitsDependenciesAzureStorageContainerInnerToJSON,
} from './ComponentTraitsDependenciesAzureStorageContainerInner';
import type { ComponentTraitsDependenciesAzureStorageQueueInner } from './ComponentTraitsDependenciesAzureStorageQueueInner';
import {
    ComponentTraitsDependenciesAzureStorageQueueInnerFromJSON,
    ComponentTraitsDependenciesAzureStorageQueueInnerFromJSONTyped,
    ComponentTraitsDependenciesAzureStorageQueueInnerToJSON,
} from './ComponentTraitsDependenciesAzureStorageQueueInner';
import type { ComponentTraitsDependenciesS3Inner } from './ComponentTraitsDependenciesS3Inner';
import {
    ComponentTraitsDependenciesS3InnerFromJSON,
    ComponentTraitsDependenciesS3InnerFromJSONTyped,
    ComponentTraitsDependenciesS3InnerToJSON,
} from './ComponentTraitsDependenciesS3Inner';
import type { Database } from './Database';
import {
    DatabaseFromJSON,
    DatabaseFromJSONTyped,
    DatabaseToJSON,
} from './Database';
import type { RedisConnection } from './RedisConnection';
import {
    RedisConnectionFromJSON,
    RedisConnectionFromJSONTyped,
    RedisConnectionToJSON,
} from './RedisConnection';

/**
 * 
 * @export
 * @interface ComponentTraitsDependencies
 */
export interface ComponentTraitsDependencies {
    /**
     * 
     * @type {Array<RedisConnection>}
     * @memberof ComponentTraitsDependencies
     */
    redis?: Array<RedisConnection>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAwsDynamodbInner>}
     * @memberof ComponentTraitsDependencies
     */
    awsDynamodb?: Array<ComponentTraitsDependenciesAwsDynamodbInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAwsKmsInner>}
     * @memberof ComponentTraitsDependencies
     */
    awsKms?: Array<ComponentTraitsDependenciesAwsKmsInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAwsSnsInner>}
     * @memberof ComponentTraitsDependencies
     */
    awsSns?: Array<ComponentTraitsDependenciesAwsSnsInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAwsSqsInner>}
     * @memberof ComponentTraitsDependencies
     */
    awsSqs?: Array<ComponentTraitsDependenciesAwsSqsInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAwsSsmParameterInner>}
     * @memberof ComponentTraitsDependencies
     */
    awsSsmParameter?: Array<ComponentTraitsDependenciesAwsSsmParameterInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesS3Inner>}
     * @memberof ComponentTraitsDependencies
     */
    s3?: Array<ComponentTraitsDependenciesS3Inner>;
    /**
     * 
     * @type {Array<Database>}
     * @memberof ComponentTraitsDependencies
     */
    database?: Array<Database>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAzureEventHubInner>}
     * @memberof ComponentTraitsDependencies
     */
    azureEventHub?: Array<ComponentTraitsDependenciesAzureEventHubInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAzureKeyVaultKeyInner>}
     * @memberof ComponentTraitsDependencies
     */
    azureKeyVaultKey?: Array<ComponentTraitsDependenciesAzureKeyVaultKeyInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAzureKeyVaultSecretInner>}
     * @memberof ComponentTraitsDependencies
     */
    azureKeyVaultSecret?: Array<ComponentTraitsDependenciesAzureKeyVaultSecretInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAzureServiceBusInner>}
     * @memberof ComponentTraitsDependencies
     */
    azureServiceBus?: Array<ComponentTraitsDependenciesAzureServiceBusInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAzureStorageContainerInner>}
     * @memberof ComponentTraitsDependencies
     */
    azureStorageContainer?: Array<ComponentTraitsDependenciesAzureStorageContainerInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAzureStorageQueueInner>}
     * @memberof ComponentTraitsDependencies
     */
    azureStorageQueue?: Array<ComponentTraitsDependenciesAzureStorageQueueInner>;
    /**
     * 
     * @type {Array<ComponentTraitsDependenciesAwsKinesisInner>}
     * @memberof ComponentTraitsDependencies
     */
    awsKinesis?: Array<ComponentTraitsDependenciesAwsKinesisInner>;
}

/**
 * Check if a given object implements the ComponentTraitsDependencies interface.
 */
export function instanceOfComponentTraitsDependencies(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesFromJSON(json: any): ComponentTraitsDependencies {
    return ComponentTraitsDependenciesFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependencies {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redis': !exists(json, 'redis') ? undefined : ((json['redis'] as Array<any>).map(RedisConnectionFromJSON)),
        'awsDynamodb': !exists(json, 'awsDynamodb') ? undefined : ((json['awsDynamodb'] as Array<any>).map(ComponentTraitsDependenciesAwsDynamodbInnerFromJSON)),
        'awsKms': !exists(json, 'awsKms') ? undefined : ((json['awsKms'] as Array<any>).map(ComponentTraitsDependenciesAwsKmsInnerFromJSON)),
        'awsSns': !exists(json, 'awsSns') ? undefined : ((json['awsSns'] as Array<any>).map(ComponentTraitsDependenciesAwsSnsInnerFromJSON)),
        'awsSqs': !exists(json, 'awsSqs') ? undefined : ((json['awsSqs'] as Array<any>).map(ComponentTraitsDependenciesAwsSqsInnerFromJSON)),
        'awsSsmParameter': !exists(json, 'awsSsmParameter') ? undefined : ((json['awsSsmParameter'] as Array<any>).map(ComponentTraitsDependenciesAwsSsmParameterInnerFromJSON)),
        's3': !exists(json, 's3') ? undefined : ((json['s3'] as Array<any>).map(ComponentTraitsDependenciesS3InnerFromJSON)),
        'database': !exists(json, 'database') ? undefined : ((json['database'] as Array<any>).map(DatabaseFromJSON)),
        'azureEventHub': !exists(json, 'azureEventHub') ? undefined : ((json['azureEventHub'] as Array<any>).map(ComponentTraitsDependenciesAzureEventHubInnerFromJSON)),
        'azureKeyVaultKey': !exists(json, 'azureKeyVaultKey') ? undefined : ((json['azureKeyVaultKey'] as Array<any>).map(ComponentTraitsDependenciesAzureKeyVaultKeyInnerFromJSON)),
        'azureKeyVaultSecret': !exists(json, 'azureKeyVaultSecret') ? undefined : ((json['azureKeyVaultSecret'] as Array<any>).map(ComponentTraitsDependenciesAzureKeyVaultSecretInnerFromJSON)),
        'azureServiceBus': !exists(json, 'azureServiceBus') ? undefined : ((json['azureServiceBus'] as Array<any>).map(ComponentTraitsDependenciesAzureServiceBusInnerFromJSON)),
        'azureStorageContainer': !exists(json, 'azureStorageContainer') ? undefined : ((json['azureStorageContainer'] as Array<any>).map(ComponentTraitsDependenciesAzureStorageContainerInnerFromJSON)),
        'azureStorageQueue': !exists(json, 'azureStorageQueue') ? undefined : ((json['azureStorageQueue'] as Array<any>).map(ComponentTraitsDependenciesAzureStorageQueueInnerFromJSON)),
        'awsKinesis': !exists(json, 'awsKinesis') ? undefined : ((json['awsKinesis'] as Array<any>).map(ComponentTraitsDependenciesAwsKinesisInnerFromJSON)),
    };
}

export function ComponentTraitsDependenciesToJSON(value?: ComponentTraitsDependencies | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redis': value.redis === undefined ? undefined : ((value.redis as Array<any>).map(RedisConnectionToJSON)),
        'awsDynamodb': value.awsDynamodb === undefined ? undefined : ((value.awsDynamodb as Array<any>).map(ComponentTraitsDependenciesAwsDynamodbInnerToJSON)),
        'awsKms': value.awsKms === undefined ? undefined : ((value.awsKms as Array<any>).map(ComponentTraitsDependenciesAwsKmsInnerToJSON)),
        'awsSns': value.awsSns === undefined ? undefined : ((value.awsSns as Array<any>).map(ComponentTraitsDependenciesAwsSnsInnerToJSON)),
        'awsSqs': value.awsSqs === undefined ? undefined : ((value.awsSqs as Array<any>).map(ComponentTraitsDependenciesAwsSqsInnerToJSON)),
        'awsSsmParameter': value.awsSsmParameter === undefined ? undefined : ((value.awsSsmParameter as Array<any>).map(ComponentTraitsDependenciesAwsSsmParameterInnerToJSON)),
        's3': value.s3 === undefined ? undefined : ((value.s3 as Array<any>).map(ComponentTraitsDependenciesS3InnerToJSON)),
        'database': value.database === undefined ? undefined : ((value.database as Array<any>).map(DatabaseToJSON)),
        'azureEventHub': value.azureEventHub === undefined ? undefined : ((value.azureEventHub as Array<any>).map(ComponentTraitsDependenciesAzureEventHubInnerToJSON)),
        'azureKeyVaultKey': value.azureKeyVaultKey === undefined ? undefined : ((value.azureKeyVaultKey as Array<any>).map(ComponentTraitsDependenciesAzureKeyVaultKeyInnerToJSON)),
        'azureKeyVaultSecret': value.azureKeyVaultSecret === undefined ? undefined : ((value.azureKeyVaultSecret as Array<any>).map(ComponentTraitsDependenciesAzureKeyVaultSecretInnerToJSON)),
        'azureServiceBus': value.azureServiceBus === undefined ? undefined : ((value.azureServiceBus as Array<any>).map(ComponentTraitsDependenciesAzureServiceBusInnerToJSON)),
        'azureStorageContainer': value.azureStorageContainer === undefined ? undefined : ((value.azureStorageContainer as Array<any>).map(ComponentTraitsDependenciesAzureStorageContainerInnerToJSON)),
        'azureStorageQueue': value.azureStorageQueue === undefined ? undefined : ((value.azureStorageQueue as Array<any>).map(ComponentTraitsDependenciesAzureStorageQueueInnerToJSON)),
        'awsKinesis': value.awsKinesis === undefined ? undefined : ((value.awsKinesis as Array<any>).map(ComponentTraitsDependenciesAwsKinesisInnerToJSON)),
    };
}

