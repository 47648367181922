/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ComponentTraitsDependencies } from './ComponentTraitsDependencies';
import {
    ComponentTraitsDependenciesFromJSON,
    ComponentTraitsDependenciesFromJSONTyped,
    ComponentTraitsDependenciesToJSON,
} from './ComponentTraitsDependencies';
import type { EnvironmentVariable } from './EnvironmentVariable';
import {
    EnvironmentVariableFromJSON,
    EnvironmentVariableFromJSONTyped,
    EnvironmentVariableToJSON,
} from './EnvironmentVariable';
import type { HttpEgress } from './HttpEgress';
import {
    HttpEgressFromJSON,
    HttpEgressFromJSONTyped,
    HttpEgressToJSON,
} from './HttpEgress';
import type { HttpIngress } from './HttpIngress';
import {
    HttpIngressFromJSON,
    HttpIngressFromJSONTyped,
    HttpIngressToJSON,
} from './HttpIngress';
import type { Indicator } from './Indicator';
import {
    IndicatorFromJSON,
    IndicatorFromJSONTyped,
    IndicatorToJSON,
} from './Indicator';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import type { Port } from './Port';
import {
    PortFromJSON,
    PortFromJSONTyped,
    PortToJSON,
} from './Port';
import type { RuntimeDetails } from './RuntimeDetails';
import {
    RuntimeDetailsFromJSON,
    RuntimeDetailsFromJSONTyped,
    RuntimeDetailsToJSON,
} from './RuntimeDetails';

/**
 * Traits associated with a component
 * @export
 * @interface ComponentTraits
 */
export interface ComponentTraits {
    /**
     * Name of the component
     * @type {string}
     * @memberof ComponentTraits
     */
    name?: string;
    /**
     * 
     * @type {Language}
     * @memberof ComponentTraits
     */
    languageFrontend?: Language;
    /**
     * 
     * @type {RuntimeDetails}
     * @memberof ComponentTraits
     */
    runtime?: RuntimeDetails;
    /**
     * Indicators of libraries, frameworks, and services that the analyzer found reference to in the source repository
     * @type {Array<Indicator>}
     * @memberof ComponentTraits
     */
    indicators?: Array<Indicator>;
    /**
     * DTR information where the images are stored
     * @type {string}
     * @memberof ComponentTraits
     */
    imageRegistry?: string;
    /**
     * Image tag to be used
     * @type {string}
     * @memberof ComponentTraits
     */
    imageTag?: string;
    /**
     * 
     * @type {Port}
     * @memberof ComponentTraits
     */
    serverPort?: Port;
    /**
     * 
     * @type {Array<Port>}
     * @memberof ComponentTraits
     */
    ports?: Array<Port>;
    /**
     * 
     * @type {Array<EnvironmentVariable>}
     * @memberof ComponentTraits
     */
    env?: Array<EnvironmentVariable>;
    /**
     * All the egress information that analyzer could find in the source repository
     * @type {Array<HttpEgress>}
     * @memberof ComponentTraits
     */
    httpEgress?: Array<HttpEgress>;
    /**
     * 
     * @type {Array<HttpIngress>}
     * @memberof ComponentTraits
     */
    httpIngress?: Array<HttpIngress>;
    /**
     * Top-level IAM actions required by the component. These should be one of the iam_actions defined in iam.yaml
     * @type {Array<string>}
     * @memberof ComponentTraits
     */
    iamActions?: Array<string>;
    /**
     * 
     * @type {ComponentTraitsDependencies}
     * @memberof ComponentTraits
     */
    dependencies?: ComponentTraitsDependencies;
}

/**
 * Check if a given object implements the ComponentTraits interface.
 */
export function instanceOfComponentTraits(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsFromJSON(json: any): ComponentTraits {
    return ComponentTraitsFromJSONTyped(json, false);
}

export function ComponentTraitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraits {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'languageFrontend': !exists(json, 'languageFrontend') ? undefined : LanguageFromJSON(json['languageFrontend']),
        'runtime': !exists(json, 'runtime') ? undefined : RuntimeDetailsFromJSON(json['runtime']),
        'indicators': !exists(json, 'indicators') ? undefined : ((json['indicators'] as Array<any>).map(IndicatorFromJSON)),
        'imageRegistry': !exists(json, 'imageRegistry') ? undefined : json['imageRegistry'],
        'imageTag': !exists(json, 'imageTag') ? undefined : json['imageTag'],
        'serverPort': !exists(json, 'serverPort') ? undefined : PortFromJSON(json['serverPort']),
        'ports': !exists(json, 'ports') ? undefined : ((json['ports'] as Array<any>).map(PortFromJSON)),
        'env': !exists(json, 'env') ? undefined : ((json['env'] as Array<any>).map(EnvironmentVariableFromJSON)),
        'httpEgress': !exists(json, 'httpEgress') ? undefined : ((json['httpEgress'] as Array<any>).map(HttpEgressFromJSON)),
        'httpIngress': !exists(json, 'httpIngress') ? undefined : ((json['httpIngress'] as Array<any>).map(HttpIngressFromJSON)),
        'iamActions': !exists(json, 'iamActions') ? undefined : json['iamActions'],
        'dependencies': !exists(json, 'dependencies') ? undefined : ComponentTraitsDependenciesFromJSON(json['dependencies']),
    };
}

export function ComponentTraitsToJSON(value?: ComponentTraits | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'languageFrontend': LanguageToJSON(value.languageFrontend),
        'runtime': RuntimeDetailsToJSON(value.runtime),
        'indicators': value.indicators === undefined ? undefined : ((value.indicators as Array<any>).map(IndicatorToJSON)),
        'imageRegistry': value.imageRegistry,
        'imageTag': value.imageTag,
        'serverPort': PortToJSON(value.serverPort),
        'ports': value.ports === undefined ? undefined : ((value.ports as Array<any>).map(PortToJSON)),
        'env': value.env === undefined ? undefined : ((value.env as Array<any>).map(EnvironmentVariableToJSON)),
        'httpEgress': value.httpEgress === undefined ? undefined : ((value.httpEgress as Array<any>).map(HttpEgressToJSON)),
        'httpIngress': value.httpIngress === undefined ? undefined : ((value.httpIngress as Array<any>).map(HttpIngressToJSON)),
        'iamActions': value.iamActions,
        'dependencies': ComponentTraitsDependenciesToJSON(value.dependencies),
    };
}

