import React from "react";

import { Icon, IconsList, Button } from "../../../components";

import { type PropertiesType } from "../topology-graph-types";

import { ValidateDeleteResourceDeletionTypeEnum } from "../../../apis/topology";

const DeleteResourceAndConnectionConfirmation = ({
  onPanelClose,
  data,
}: {
  onPanelClose: () => void;
  data: PropertiesType | null;
}) => {
  return (
    <div className="tw-bg-gray-800 tw-min-h-full">
      <div className="tw-flex tw-gap-3 tw-justify-between tw-items-center tw-p-4">
        <div className="tw-flex tw-gap-2 tw-items-center tw-grow">
          <div
            onClick={onPanelClose}
            className="tw-basis-6 tw-text-gray-300 hover:tw-text-gray-200 tw-cursor-pointer"
            data-testid="close-delete-confirmation-drawer"
          >
            <Icon name={IconsList.ARROW_LEFT} />
          </div>
          <span className="tw-font-semibold tw-text-white tw-text-base">
            Delete {data?.deleteLabel} ?
          </span>
        </div>
        <div className="tw-flex tw-gap-3">
          <div
            onClick={onPanelClose}
            className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
            data-testid="close-delete-confirmation-drawer"
          >
            <Icon name={IconsList.CLOSE} />
          </div>
        </div>
      </div>
      <div className="tw-p-4">
        <div className="tw-text-sm tw-text-gray-50 tw-pb-4">
          {data?.deletionType ===
          ValidateDeleteResourceDeletionTypeEnum.Resource
            ? `Are you sure to delete the ${data?.sourceResource?.resourceTypeLabel}?`
            : `Are you sure to delete the connection between ${data?.sourceResource?.resourceTypeLabel} and ${data?.targetResource?.resourceTypeLabel}?`}
        </div>
        <div className="tw-text-sm tw-text-gray-50">
          <span className="tw-font-semibold">Note:</span> Deleting the{" "}
          {data?.deletionType ===
          ValidateDeleteResourceDeletionTypeEnum.Resource
            ? data?.sourceResource?.resourceTypeLabel
            : `connection between ${data?.sourceResource?.resourceTypeLabel} and ${data?.targetResource?.resourceTypeLabel}`}{" "}
          will remove the{" "}
          <span className="tw-font-semibold">
            {data?.totalHighlightedConnections} highlighted resource connections
          </span>{" "}
          of the 'IAM' type.
        </div>
      </div>
      <div className="tw-sticky tw-bottom-0 tw-bg-gray-800 tw-p-4 tw-flex-col tw-border-t tw-border-gray-700">
        <div className="tw-flex tw-gap-2 tw-items-center">
          <div className="tw-basis-1/2">
            <Button
              variant="danger"
              onClick={data?.onConfirmDelete}
              label={"Yes, Delete"}
              className="tw-w-full"
              dataTestId="confirm-delete-resource-and-connection"
            />
          </div>
          <div className="tw-basis-1/2">
            <Button
              variant="secondary"
              type="button"
              label={"Cancel"}
              className="tw-w-full"
              onClick={data?.onCancelDelete}
              dataTestId="cancel-delete-resource-and-connection"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteResourceAndConnectionConfirmation;
