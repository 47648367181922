import React, { type ReactNode, useCallback, useState } from "react";
import {
  type NodeProps,
  NodeToolbar,
  Position,
  useOnSelectionChange,
  useReactFlow,
  type Node,
} from "reactflow";
import { IconButton, IconsList, Tooltip } from "../../../components";
import { TooltipPositions } from "../../tooltip/tooltip.types";

interface CustomNodeToolbarProps {
  node: NodeProps;
}

const CustomNodeToolbar = ({ node }: CustomNodeToolbarProps) => {
  const reactFlow = useReactFlow();
  const [showToolbar, setShowToolbar] = useState(node.selected);

  const onChange = useCallback(
    ({ nodes }: { nodes: Node[] }) => {
      setShowToolbar(nodes.length <= 1 && node.selected);
    },
    [node.selected]
  );

  useOnSelectionChange({
    onChange,
  });

  const onDeleteNode = useCallback(() => {
    reactFlow.deleteElements({
      nodes: [node],
    });
  }, [reactFlow, node]);

  return (
    <NodeToolbar isVisible={showToolbar} position={Position.Top} align={"end"}>
      <div
        className={
          "tw-flex tw-gap-1 tw-p-1 tw-rounded-lg tw-border tw-border-gray-500"
        }
      >
        <Tooltip title="Delete" position={TooltipPositions.TOP}>
          <IconButton
            icon={IconsList.DELETE}
            dataTestId={"node-delete"}
            variant="secondary2"
            className="tw-bg-transparent"
            size="sm"
            outlined
            onClick={onDeleteNode}
          />
        </Tooltip>
      </div>
    </NodeToolbar>
  );
};

export default CustomNodeToolbar;
