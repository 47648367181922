/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetMe200Response,
  UpdateMeRequest,
  User,
} from '../models/index';
import {
    GetMe200ResponseFromJSON,
    GetMe200ResponseToJSON,
    UpdateMeRequestFromJSON,
    UpdateMeRequestToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface CallbackRequest {
    oauthProvider: string;
}

export interface LoginRequest {
    oauthProvider: string;
}

export interface UpdateMeOperationRequest {
    updateMeRequest: UpdateMeRequest;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * Callback from the oAuth Provider
     */
    async callbackRaw(requestParameters: CallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.oauthProvider === null || requestParameters.oauthProvider === undefined) {
            throw new runtime.RequiredError('oauthProvider','Required parameter requestParameters.oauthProvider was null or undefined when calling callback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/callback/{oauthProvider}`.replace(`{${"oauthProvider"}}`, encodeURIComponent(String(requestParameters.oauthProvider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback from the oAuth Provider
     */
    async callback(requestParameters: CallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.callbackRaw(requestParameters, initOverrides);
    }

    /**
     * Get the current user
     */
    async getMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetMe200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMe200ResponseFromJSON(jsonValue));
    }

    /**
     * Get the current user
     */
    async getMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetMe200Response> {
        const response = await this.getMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Lists the currently configured providers
     */
    async getProvidersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/providers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Lists the currently configured providers
     */
    async getProviders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getProvidersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Log in using the oAuth Provider
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.oauthProvider === null || requestParameters.oauthProvider === undefined) {
            throw new runtime.RequiredError('oauthProvider','Required parameter requestParameters.oauthProvider was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/login/{oauthProvider}`.replace(`{${"oauthProvider"}}`, encodeURIComponent(String(requestParameters.oauthProvider))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Log in using the oAuth Provider
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loginRaw(requestParameters, initOverrides);
    }

    /**
     * Log out the current user
     */
    async logoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Log out the current user
     */
    async logout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(initOverrides);
    }

    /**
     * Probe to get user info
     */
    async probeMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/me`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Probe to get user info
     */
    async probeMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.probeMeRaw(initOverrides);
    }

    /**
     * Update the current user name or company
     */
    async updateMeRaw(requestParameters: UpdateMeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.updateMeRequest === null || requestParameters.updateMeRequest === undefined) {
            throw new runtime.RequiredError('updateMeRequest','Required parameter requestParameters.updateMeRequest was null or undefined when calling updateMe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/me`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMeRequestToJSON(requestParameters.updateMeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update the current user name or company
     */
    async updateMe(requestParameters: UpdateMeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.updateMeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
