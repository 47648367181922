/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Governance,
  GovernanceAssignment,
  GovernanceAssignmentDescriptive,
  GovernanceAssignmentRequired,
  GovernanceConfigCreate,
  GovernanceVersion,
  OwnerType,
} from '../models/index';
import {
    GovernanceFromJSON,
    GovernanceToJSON,
    GovernanceAssignmentFromJSON,
    GovernanceAssignmentToJSON,
    GovernanceAssignmentDescriptiveFromJSON,
    GovernanceAssignmentDescriptiveToJSON,
    GovernanceAssignmentRequiredFromJSON,
    GovernanceAssignmentRequiredToJSON,
    GovernanceConfigCreateFromJSON,
    GovernanceConfigCreateToJSON,
    GovernanceVersionFromJSON,
    GovernanceVersionToJSON,
    OwnerTypeFromJSON,
    OwnerTypeToJSON,
} from '../models/index';

export interface CreateGovernanceRequest {
    governanceConfigCreate: GovernanceConfigCreate;
}

export interface CreateGovernanceVersionRequest {
    governanceId: string;
    governanceConfigCreate: GovernanceConfigCreate;
}

export interface DeleteGovernanceRequest {
    governanceId: string;
}

export interface DeleteGovernanceAssignmentRequest {
    governanceVersionId: string;
    governanceAssignmentRequired: GovernanceAssignmentRequired;
}

export interface DeleteGovernanceVersionRequest {
    governanceVersionUuid: string;
}

export interface GetApplicableGovernanceVersionRequest {
    ownerId: string;
    ownerType: OwnerType;
}

export interface GetGovernanceRequest {
    ownerId: string;
    ownerType: OwnerType;
}

export interface GetGovernanceAssignmentsRequest {
    governanceVersionId: string;
    ownerId?: string;
    ownerType?: OwnerType;
}

export interface GetGovernanceVersionRequest {
    governanceVersionId: string;
}

export interface UpdateGovernanceAssignmentsRequest {
    governanceVersionId: string;
    governanceAssignmentRequired: GovernanceAssignmentRequired;
}

/**
 * 
 */
export class GovernanceApi extends runtime.BaseAPI {

    /**
     * Create governance configuration
     */
    async createGovernanceRaw(requestParameters: CreateGovernanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Governance>> {
        if (requestParameters.governanceConfigCreate === null || requestParameters.governanceConfigCreate === undefined) {
            throw new runtime.RequiredError('governanceConfigCreate','Required parameter requestParameters.governanceConfigCreate was null or undefined when calling createGovernance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/governance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GovernanceConfigCreateToJSON(requestParameters.governanceConfigCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceFromJSON(jsonValue));
    }

    /**
     * Create governance configuration
     */
    async createGovernance(requestParameters: CreateGovernanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Governance> {
        const response = await this.createGovernanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create a new version of an existing governance configuration
     */
    async createGovernanceVersionRaw(requestParameters: CreateGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GovernanceVersion>> {
        if (requestParameters.governanceId === null || requestParameters.governanceId === undefined) {
            throw new runtime.RequiredError('governanceId','Required parameter requestParameters.governanceId was null or undefined when calling createGovernanceVersion.');
        }

        if (requestParameters.governanceConfigCreate === null || requestParameters.governanceConfigCreate === undefined) {
            throw new runtime.RequiredError('governanceConfigCreate','Required parameter requestParameters.governanceConfigCreate was null or undefined when calling createGovernanceVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.governanceId !== undefined) {
            queryParameters['governanceId'] = requestParameters.governanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/governance/version`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GovernanceConfigCreateToJSON(requestParameters.governanceConfigCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceVersionFromJSON(jsonValue));
    }

    /**
     * create a new version of an existing governance configuration
     */
    async createGovernanceVersion(requestParameters: CreateGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GovernanceVersion> {
        const response = await this.createGovernanceVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete governance config, deleting a governance config requires all version of the config to be have their assignments removed
     */
    async deleteGovernanceRaw(requestParameters: DeleteGovernanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.governanceId === null || requestParameters.governanceId === undefined) {
            throw new runtime.RequiredError('governanceId','Required parameter requestParameters.governanceId was null or undefined when calling deleteGovernance.');
        }

        const queryParameters: any = {};

        if (requestParameters.governanceId !== undefined) {
            queryParameters['governanceId'] = requestParameters.governanceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete governance config, deleting a governance config requires all version of the config to be have their assignments removed
     */
    async deleteGovernance(requestParameters: DeleteGovernanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGovernanceRaw(requestParameters, initOverrides);
    }

    /**
     * Delete governance version assignments associated with an owner
     */
    async deleteGovernanceAssignmentRaw(requestParameters: DeleteGovernanceAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.governanceVersionId === null || requestParameters.governanceVersionId === undefined) {
            throw new runtime.RequiredError('governanceVersionId','Required parameter requestParameters.governanceVersionId was null or undefined when calling deleteGovernanceAssignment.');
        }

        if (requestParameters.governanceAssignmentRequired === null || requestParameters.governanceAssignmentRequired === undefined) {
            throw new runtime.RequiredError('governanceAssignmentRequired','Required parameter requestParameters.governanceAssignmentRequired was null or undefined when calling deleteGovernanceAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/governance/{governanceVersionId}/assignments`.replace(`{${"governanceVersionId"}}`, encodeURIComponent(String(requestParameters.governanceVersionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: GovernanceAssignmentRequiredToJSON(requestParameters.governanceAssignmentRequired),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete governance version assignments associated with an owner
     */
    async deleteGovernanceAssignment(requestParameters: DeleteGovernanceAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGovernanceAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete governance config version
     */
    async deleteGovernanceVersionRaw(requestParameters: DeleteGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.governanceVersionUuid === null || requestParameters.governanceVersionUuid === undefined) {
            throw new runtime.RequiredError('governanceVersionUuid','Required parameter requestParameters.governanceVersionUuid was null or undefined when calling deleteGovernanceVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.governanceVersionUuid !== undefined) {
            queryParameters['governanceVersionUuid'] = requestParameters.governanceVersionUuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/version`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete governance config version
     */
    async deleteGovernanceVersion(requestParameters: DeleteGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteGovernanceVersionRaw(requestParameters, initOverrides);
    }

    /**
     * return a governance version that should be applied given the owner and owner type. This request should be used prior to creating an appstack to walk the chain of governance configuration to determine the version that should be applied.
     * Get list of governance configuration assignments
     */
    async getApplicableGovernanceVersionRaw(requestParameters: GetApplicableGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GovernanceAssignmentDescriptive>> {
        if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
            throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getApplicableGovernanceVersion.');
        }

        if (requestParameters.ownerType === null || requestParameters.ownerType === undefined) {
            throw new runtime.RequiredError('ownerType','Required parameter requestParameters.ownerType was null or undefined when calling getApplicableGovernanceVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerId !== undefined) {
            queryParameters['ownerId'] = requestParameters.ownerId;
        }

        if (requestParameters.ownerType !== undefined) {
            queryParameters['ownerType'] = requestParameters.ownerType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/version/assignment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceAssignmentDescriptiveFromJSON(jsonValue));
    }

    /**
     * return a governance version that should be applied given the owner and owner type. This request should be used prior to creating an appstack to walk the chain of governance configuration to determine the version that should be applied.
     * Get list of governance configuration assignments
     */
    async getApplicableGovernanceVersion(requestParameters: GetApplicableGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GovernanceAssignmentDescriptive> {
        const response = await this.getApplicableGovernanceVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of governance congiguration with their associated versions
     */
    async getGovernanceRaw(requestParameters: GetGovernanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Governance>>> {
        if (requestParameters.ownerId === null || requestParameters.ownerId === undefined) {
            throw new runtime.RequiredError('ownerId','Required parameter requestParameters.ownerId was null or undefined when calling getGovernance.');
        }

        if (requestParameters.ownerType === null || requestParameters.ownerType === undefined) {
            throw new runtime.RequiredError('ownerType','Required parameter requestParameters.ownerType was null or undefined when calling getGovernance.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerId !== undefined) {
            queryParameters['ownerId'] = requestParameters.ownerId;
        }

        if (requestParameters.ownerType !== undefined) {
            queryParameters['ownerType'] = requestParameters.ownerType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GovernanceFromJSON));
    }

    /**
     * Get list of governance congiguration with their associated versions
     */
    async getGovernance(requestParameters: GetGovernanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Governance>> {
        const response = await this.getGovernanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of governance configuration assignments
     */
    async getGovernanceAssignmentsRaw(requestParameters: GetGovernanceAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GovernanceAssignment>>> {
        if (requestParameters.governanceVersionId === null || requestParameters.governanceVersionId === undefined) {
            throw new runtime.RequiredError('governanceVersionId','Required parameter requestParameters.governanceVersionId was null or undefined when calling getGovernanceAssignments.');
        }

        const queryParameters: any = {};

        if (requestParameters.ownerId !== undefined) {
            queryParameters['ownerId'] = requestParameters.ownerId;
        }

        if (requestParameters.ownerType !== undefined) {
            queryParameters['ownerType'] = requestParameters.ownerType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/{governanceVersionId}/assignments`.replace(`{${"governanceVersionId"}}`, encodeURIComponent(String(requestParameters.governanceVersionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GovernanceAssignmentFromJSON));
    }

    /**
     * Get list of governance configuration assignments
     */
    async getGovernanceAssignments(requestParameters: GetGovernanceAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GovernanceAssignment>> {
        const response = await this.getGovernanceAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get governance congiguration version
     */
    async getGovernanceVersionRaw(requestParameters: GetGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GovernanceVersion>> {
        if (requestParameters.governanceVersionId === null || requestParameters.governanceVersionId === undefined) {
            throw new runtime.RequiredError('governanceVersionId','Required parameter requestParameters.governanceVersionId was null or undefined when calling getGovernanceVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.governanceVersionId !== undefined) {
            queryParameters['governanceVersionId'] = requestParameters.governanceVersionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/governance/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GovernanceVersionFromJSON(jsonValue));
    }

    /**
     * Get governance congiguration version
     */
    async getGovernanceVersion(requestParameters: GetGovernanceVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GovernanceVersion> {
        const response = await this.getGovernanceVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update governance version assignments
     */
    async updateGovernanceAssignmentsRaw(requestParameters: UpdateGovernanceAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.governanceVersionId === null || requestParameters.governanceVersionId === undefined) {
            throw new runtime.RequiredError('governanceVersionId','Required parameter requestParameters.governanceVersionId was null or undefined when calling updateGovernanceAssignments.');
        }

        if (requestParameters.governanceAssignmentRequired === null || requestParameters.governanceAssignmentRequired === undefined) {
            throw new runtime.RequiredError('governanceAssignmentRequired','Required parameter requestParameters.governanceAssignmentRequired was null or undefined when calling updateGovernanceAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/governance/{governanceVersionId}/assignments`.replace(`{${"governanceVersionId"}}`, encodeURIComponent(String(requestParameters.governanceVersionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GovernanceAssignmentRequiredToJSON(requestParameters.governanceAssignmentRequired),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update governance version assignments
     */
    async updateGovernanceAssignments(requestParameters: UpdateGovernanceAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateGovernanceAssignmentsRaw(requestParameters, initOverrides);
    }

}
