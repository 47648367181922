/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddRepositoriesRequestRepositoriesInner } from './AddRepositoriesRequestRepositoriesInner';
import {
    AddRepositoriesRequestRepositoriesInnerFromJSON,
    AddRepositoriesRequestRepositoriesInnerFromJSONTyped,
    AddRepositoriesRequestRepositoriesInnerToJSON,
} from './AddRepositoriesRequestRepositoriesInner';
import type { TokenMetadata } from './TokenMetadata';
import {
    TokenMetadataFromJSON,
    TokenMetadataFromJSONTyped,
    TokenMetadataToJSON,
} from './TokenMetadata';

/**
 * 
 * @export
 * @interface AddRepositoriesRequest
 */
export interface AddRepositoriesRequest {
    /**
     * Token to access the repositories in the list. If no token, appCD assumes these are public repositories
     * @type {string}
     * @memberof AddRepositoriesRequest
     */
    token?: string;
    /**
     * 
     * @type {Array<TokenMetadata>}
     * @memberof AddRepositoriesRequest
     */
    metadata?: Array<TokenMetadata>;
    /**
     * 
     * @type {Array<AddRepositoriesRequestRepositoriesInner>}
     * @memberof AddRepositoriesRequest
     */
    repositories?: Array<AddRepositoriesRequestRepositoriesInner>;
}

/**
 * Check if a given object implements the AddRepositoriesRequest interface.
 */
export function instanceOfAddRepositoriesRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddRepositoriesRequestFromJSON(json: any): AddRepositoriesRequest {
    return AddRepositoriesRequestFromJSONTyped(json, false);
}

export function AddRepositoriesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddRepositoriesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'metadata': !exists(json, 'metadata') ? undefined : ((json['metadata'] as Array<any>).map(TokenMetadataFromJSON)),
        'repositories': !exists(json, 'repositories') ? undefined : ((json['repositories'] as Array<any>).map(AddRepositoriesRequestRepositoriesInnerFromJSON)),
    };
}

export function AddRepositoriesRequestToJSON(value?: AddRepositoriesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'metadata': value.metadata === undefined ? undefined : ((value.metadata as Array<any>).map(TokenMetadataToJSON)),
        'repositories': value.repositories === undefined ? undefined : ((value.repositories as Array<any>).map(AddRepositoriesRequestRepositoriesInnerToJSON)),
    };
}

