import React, { type FC } from "react";
import { Field, FieldProps } from "formik";

import { CheckboxProps } from "./checkbox.types";

/**
 * Generates a checkbox component.
 *
 * - @param {CheckboxProps} props - The properties passed to the component.
 * - @param {string} props.name - The name of the checkbox.
 * - @param {string | number} props.value - The value of the checkbox.
 * - @param {string} props.className - The class name of the checkbox.
 * - @param {string} props.id - The ID of the checkbox.
 * - @param {boolean} props.disabled - The disabled state of the checkbox.
 * - @param {string} props.dataTestId - The data-testid of the checkbox.
 * - @param {Function} props.onClick - The onClick event handler of the checkbox.
 * - @return {ReactNode} The rendered checkbox component.
 */
const Checkbox: FC<CheckboxProps> = ({
  name,
  value,
  className,
  id,
  disabled,
  dataTestId,
  onClick = () => {},
  onInput = () => {},
}) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        const isChecked = Array.isArray(field.value)
          ? field.value.includes(value)
          : field.value ?? false;
        return (
          <input
            {...field}
            checked={isChecked}
            type="checkbox"
            id={id}
            className={`tw-rounded tw-bg-transparent tw-border tw-border-gray-500 focus:tw-ring-offset-transparent focus:tw-ring-0 focus:tw-ring-transparent focus:tw-shadow-none ${
              disabled
                ? "tw-cursor-not-allowed tw-opacity-50"
                : "tw-cursor-pointer hover:tw-border-gray-400"
            } ${className}`}
            value={value}
            disabled={disabled}
            style={
              isChecked
                ? {
                    backgroundImage:
                      "url('data:image/svg+xml,%3csvg viewBox=%220 0 16 16%22 fill=%22black%22 xmlns=%22http://www.w3.org/2000/svg%22%3e%3cpath d=%22M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%22/%3e%3c/svg%3e')",
                  } // overriding default white color checkmark with black checkmark svg
                : {}
            }
            onClick={onClick}
            data-testid={dataTestId}
            onInput={onInput}
          />
        );
      }}
    </Field>
  );
};
export default Checkbox;
