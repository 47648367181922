/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerType } from './OwnerType';
import {
    OwnerTypeFromJSON,
    OwnerTypeFromJSONTyped,
    OwnerTypeToJSON,
} from './OwnerType';

/**
 * The Owner entity the tartgetId's correspond to
 * @export
 * @interface GovernanceAssignmentRequired
 */
export interface GovernanceAssignmentRequired {
    /**
     * The UUID of the governance version entry
     * @type {string}
     * @memberof GovernanceAssignmentRequired
     */
    governanceVersionUuid: string;
    /**
     * The UUIDs of the target entity
     * @type {Array<string>}
     * @memberof GovernanceAssignmentRequired
     */
    ownerUuids: Array<string>;
    /**
     * 
     * @type {OwnerType}
     * @memberof GovernanceAssignmentRequired
     */
    ownerType: OwnerType;
}

/**
 * Check if a given object implements the GovernanceAssignmentRequired interface.
 */
export function instanceOfGovernanceAssignmentRequired(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "governanceVersionUuid" in value;
    isInstance = isInstance && "ownerUuids" in value;
    isInstance = isInstance && "ownerType" in value;

    return isInstance;
}

export function GovernanceAssignmentRequiredFromJSON(json: any): GovernanceAssignmentRequired {
    return GovernanceAssignmentRequiredFromJSONTyped(json, false);
}

export function GovernanceAssignmentRequiredFromJSONTyped(json: any, ignoreDiscriminator: boolean): GovernanceAssignmentRequired {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'governanceVersionUuid': json['governanceVersionUuid'],
        'ownerUuids': json['ownerUuids'],
        'ownerType': OwnerTypeFromJSON(json['ownerType']),
    };
}

export function GovernanceAssignmentRequiredToJSON(value?: GovernanceAssignmentRequired | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'governanceVersionUuid': value.governanceVersionUuid,
        'ownerUuids': value.ownerUuids,
        'ownerType': OwnerTypeToJSON(value.ownerType),
    };
}

