import React, {
  type FC,
  type MouseEvent,
  useMemo,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useSetAtom } from "jotai";

import {
  Banner,
  Button,
  Checkbox,
  Dialog,
  Icon,
  IconsList,
  ScrollToError,
  TextBox,
} from "../../../../components";
import type {
  TopologyResourceConfigValidation,
  TopologyResourceWithPosition,
  TopologyTfVars,
} from "../../../../apis/topology";
import ResourcePropertiesFormElements from "../../utils/resource-properties-form-elements";
import type { ConfigureTFVarsDialogProps } from "./configure-tf-vars-types";
import ConfigureTFVarsDialogSideNavItem from "./configure-tf-vars-dialog-side-nav-item";
import {
  getAllTFVarsDataAtom,
  updateTopologyResourcesPropertiesAtom,
} from "../../../../stores/topology.store";
import {
  attributePropertiesToFormikValues,
  formikValuesToAttributeProperties,
  formikValuesValidationSchema,
  traverseEachResource,
} from "../../../../utils";
import { updateNotificationsAtom } from "../../../../stores/page.store";
import { BannerTypes } from "../../../banner/banner-types";
import { getTfVarsNameVsDataTypeMap } from "../../utils/topology-utils";

const ConfigureTFVarsDialog: FC<ConfigureTFVarsDialogProps> = ({
  resources = [],
  resourcesWithErrors = {},
  resourcePropertiesTemplates,
  topologyId,
  onClose,
  onSubmit,
}) => {
  const [activeResource, setActiveResource] =
    React.useState<TopologyResourceWithPosition>(
      {} as TopologyResourceWithPosition
    );
  const [collapsedAttributeKeyList, setCollapsedAttributeKeyList] =
    React.useState<string[]>([]);
  const updateTopologyResourcesProperties = useSetAtom(
    updateTopologyResourcesPropertiesAtom
  );
  const updateNotifications = useSetAtom(updateNotificationsAtom);
  const [resourcesToFix, setResourcesToFix] = useState<
    (TopologyResourceWithPosition & { configuration: any })[]
  >([]);
  const [topologyTfVarsNameVsDataTypeMap, setTopologyTfVarsNameVsDataTypeMap] =
    useState<Record<string, any>>({});
  const getAllTFVarsData = useSetAtom(getAllTFVarsDataAtom);

  useEffect(() => {
    const fetchAllTopologyTfVars = async () => {
      const allTFVarsData = await getAllTFVarsData(topologyId);
      const topologyTfVarsNameVsDataTypeMap = getTfVarsNameVsDataTypeMap(
        allTFVarsData as TopologyTfVars[]
      );
      setTopologyTfVarsNameVsDataTypeMap(topologyTfVarsNameVsDataTypeMap);
    };

    fetchAllTopologyTfVars();
  }, []);

  // get resources with errors and select first resource as default if no resource is selected
  useEffect(() => {
    const runEffect = async () => {
      const resourcesToFixList: (TopologyResourceWithPosition & {
        configuration: any;
      })[] = [];
      let firstResource: TopologyResourceWithPosition | null = null;

      await traverseEachResource(resources, (resource) => {
        if ((resourcesWithErrors?.[resource.id]?.validationErrors ?? 0) > 0) {
          resourcesToFixList.push(resource);
          if (!firstResource) {
            firstResource = resource;
          }
        }
      });

      // select first resource as default
      setActiveResource(
        Object.keys(activeResource).length === 0 && firstResource
          ? firstResource
          : activeResource
      );
      // deferring this in order to avoid side effects of data not being loaded in some scenarios
      setTimeout(() => {
        setResourcesToFix(resourcesToFixList);
      }, 0);
    };
    runEffect();
  }, [resourcesWithErrors, resources]);

  // set form's initial value
  const formInitialValue = useMemo(() => {
    return resourcesToFix?.reduce((acc, resource) => {
      const resourceId = resource?.id as string;
      const resourceType = resource?.resourceType as string;
      const errorFields = resourcesWithErrors[resourceId]?.errorFields;
      const attributeTemplate = resourcePropertiesTemplates[resourceType];

      // Create a map of attributes by their key
      const attributesMap = new Map(
        attributeTemplate?.attributes?.map((attr) => [attr.key, attr])
      );

      if (errorFields) {
        acc[resourceId] = errorFields.map((attribute) => {
          const activeAttributeDetails = attributesMap.get(
            attribute.key
          ) as TopologyResourceConfigValidation;
          const key = attribute?.key?.match(/^[^\[]*/)?.[0] ?? attribute?.key;

          return {
            key: key,
            isSelected: true,
            label: activeAttributeDetails?.label,
            tfVarName: key,
            dataType: activeAttributeDetails?.validation?.dataType,
          };
        });
      }
      acc[resourceId] = {
        attributes: acc[resourceId] || [],
        properties: attributePropertiesToFormikValues(
          attributeTemplate?.attributes,
          resource?._configuration
        ),
      };
      return acc;
    }, {} as Record<string, any>);
  }, [resourcesToFix]);

  const handleSubmit = useCallback(
    async (
      submittedValues: Record<string, any> = {},
      setSubmitting: (isSubmitting: boolean) => void
    ) => {
      setSubmitting(true);
      const resourceMapToUpdate: Record<string, string> = {};
      let tfVarWithError = null;
      const submittedTfNameVsDataTypeMap: Record<string, string> = {};

      for (const [key, value] of Object.entries(submittedValues)) {
        const targetResource = resourcesToFix.find(
          (resource) => resource?.id === key
        );
        if (!targetResource) continue;
        targetResource["_configuration"] =
          { ...targetResource.configuration, ...value.properties } || {};

        const attributeTemplate =
          resourcePropertiesTemplates[targetResource.resourceType];
        const selectedAttributes =
          value?.attributes?.filter(
            (attr: { isSelected: any }) => attr.isSelected
          ) || [];

        for (const attr of selectedAttributes) {
          // if any tf var name error found then don't compute further
          if (!tfVarWithError) {
            const tfVarName = topologyTfVarsNameVsDataTypeMap?.[attr.tfVarName];
            const submittedTfVarName =
              submittedTfNameVsDataTypeMap[attr.tfVarName];

            // if tf var name already exists in topology resources with different data type then show error
            if (tfVarName && tfVarName !== attr.dataType) {
              tfVarWithError = {
                key: attr.tfVarName,
                label: attr.label,
              };
              break;
            } else if (
              // if tf var name already exists in submitted response with different data type then show error
              submittedTfVarName &&
              submittedTfVarName !== attr.dataType
            ) {
              tfVarWithError = {
                key: attr.tfVarName,
                label: attr.label,
              };
              break;
            }
            submittedTfNameVsDataTypeMap[attr.tfVarName] = attr.dataType;
            const currentResource: any = resourceMapToUpdate[key] || {
              resourceId: targetResource.id,
              _configuration: formikValuesToAttributeProperties(
                targetResource._configuration,
                attributeTemplate?.attributes
              ),
              tfVars: targetResource.tfVars ?? {},
            };

            currentResource.tfVars[attr.key] = attr.tfVarName;
            resourceMapToUpdate[key] = currentResource;
          }
        }
      }

      // if any tf var has error then don't proceed further
      if (tfVarWithError) {
        updateNotifications({
          type: "error",
          title: `Error saving TF Var`,
          description: `${tfVarWithError.key} used for ${tfVarWithError.label} is already used as TF-Var name in another resource with different data type.`,
          dismissable: true,
        });
        setSubmitting(false);
        return;
      }

      const resourceListToUpdate: any = Object.values(resourceMapToUpdate);
      const isSuccess = await updateTopologyResourcesProperties({
        topologyId,
        updateResourceRequest: resourceListToUpdate,
      });
      if (isSuccess) {
        updateNotifications({
          type: "success",
          title: "Converted to TF Vars",
          description:
            "Required attributes have been converted to TF Vars successfully.",
          dismissable: true,
        });
      }

      setSubmitting(false);
      onSubmit();
    },
    [
      resourcesToFix,
      resourcePropertiesTemplates,
      updateTopologyResourcesProperties,
      updateNotifications,
      topologyId,
      onSubmit,
      topologyTfVarsNameVsDataTypeMap,
    ]
  );

  const createValidationSchema = useCallback(
    (formValues: Record<string, any>) => {
      const schema: Record<string, any> = {};

      for (const resourceId of Object.keys(formValues)) {
        const targetResource = resourcesToFix.find(
          (r) => r?.id === resourceId
        ) as TopologyResourceWithPosition;
        const attributeTemplate =
          resourcePropertiesTemplates[targetResource?.resourceType as string];
        const propertiesSchema = attributeTemplate.attributes.map((attr) => {
          return {
            ...attr,
            validation: {
              ...attr.validation,
              required: false, // Default value is not a required field
            },
          };
        });

        schema[resourceId] = Yup.object().shape({
          attributes: Yup.array().of(
            Yup.lazy((attribute: Record<string, string | boolean>) =>
              Yup.object().shape({
                key: Yup.string(),
                isSelected: Yup.boolean(),
                label: Yup.string(),
                tfVarName: attribute.isSelected
                  ? Yup.string().required("Variable name is required")
                  : Yup.string(),
              })
            )
          ),
          properties:
            formikValuesValidationSchema(propertiesSchema) ??
            Yup.object().shape({}),
        });
      }

      return Yup.object().shape(schema);
    },
    [resourcesToFix, resourcePropertiesTemplates, formikValuesValidationSchema]
  );

  return (
    <Dialog
      title="Covert Required Attributes to TF Variables"
      showHeaderCloseButton
      onClose={onClose}
      bodyWrapperClassName="!tw-p-0"
      bodyContent={
        <div className=" tw-w-208 tw-border-y tw-border-gray-700 tw-h-150">
          <Formik
            initialValues={formInitialValue}
            onSubmit={(
              submittedValues: Record<string, any>,
              { setSubmitting }
            ) => {
              handleSubmit(submittedValues, setSubmitting);
            }}
            validationSchema={createValidationSchema(formInitialValue)}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form>
                <div className="tw-flex tw-h-150 tw-flex-col tw-relative">
                  <div className="tw-flex tw-flex-1 tw-overflow-auto">
                    <div className="tw-w-75 tw-border-r tw-border-gray-700 tw-flex tw-flex-col tw-overflow-auto">
                      <ScrollToError />
                      {resourcesToFix.map((resource) => (
                        <Fragment key={resource?.id}>
                          <ConfigureTFVarsDialogSideNavItem
                            resource={resource as TopologyResourceWithPosition}
                            resourcesWithErrors={
                              resourcesWithErrors[resource?.id as string]
                            }
                            isSelected={activeResource?.id === resource?.id}
                            hasError={!!errors[resource?.id as string]}
                            onClick={() =>
                              setActiveResource(
                                resource as TopologyResourceWithPosition
                              )
                            }
                          />
                        </Fragment>
                      ))}
                    </div>
                    <div className="tw-flex tw-flex-1 tw-p-5 tw-flex-col tw-gap-5 tw-overflow-auto">
                      <p className="tw-text-sm tw-font-normal">
                        Following attributes of the{" "}
                        <span className="tw-font-semibold">
                          {activeResource?.label ||
                            activeResource?.resourceTypeLabel ||
                            ""}
                        </span>{" "}
                        are required attributes. You can use TF variables for
                        those attributes.
                      </p>

                      {!!errors[activeResource?.id as string] && (
                        <Banner
                          type={BannerTypes.DANGER}
                          dataTestId="resource-attributes-error-banner"
                        >
                          <span className="tw-text-sm tw-font-normal">
                            There are errors in{" "}
                            <button type="submit" className="tw-underline">
                              some of the fields
                            </button>
                            .
                          </span>
                        </Banner>
                      )}
                      {values[activeResource?.id as string]?.attributes
                        ?.length > 0 && (
                        <>
                          {values[
                            activeResource?.id as string
                          ]?.attributes?.map(
                            (formFieldObj: any, index: number) => {
                              const accordionId = `${activeResource?.id}_${formFieldObj.key}`;
                              const attributeTemplate =
                                resourcePropertiesTemplates[
                                  activeResource.resourceType
                                ];
                              const activeAttributeDetails =
                                attributeTemplate?.attributes?.find(
                                  (attr: any) => attr.key === formFieldObj.key
                                ) as TopologyResourceConfigValidation;
                              return (
                                <div
                                  className="tw-border tw-border-gray-600 tw-rounded-md tw-px-3"
                                  key={formFieldObj.key}
                                >
                                  <div
                                    className="tw-py-4 tw-flex tw-justify-between tw-cursor-pointer"
                                    data-testid={`toggle-attribute-${formFieldObj.key}`}
                                    role="button"
                                    onClick={() => {
                                      if (
                                        collapsedAttributeKeyList.includes(
                                          accordionId
                                        )
                                      ) {
                                        setCollapsedAttributeKeyList(
                                          collapsedAttributeKeyList.filter(
                                            (key) => key !== accordionId
                                          )
                                        );
                                      } else {
                                        setCollapsedAttributeKeyList([
                                          ...collapsedAttributeKeyList,
                                          accordionId,
                                        ]);
                                      }
                                    }}
                                  >
                                    <div className="tw-font-normal tw-flex tw-items-center tw-gap-2">
                                      <Checkbox
                                        id={`${activeResource?.id}.attributes.${index}.isSelected`}
                                        name={`${activeResource?.id}.attributes.${index}.isSelected`}
                                        onClick={(
                                          e: MouseEvent<HTMLInputElement>
                                        ) => {
                                          e.stopPropagation();
                                        }}
                                        dataTestId={`tf_var_attribute_${activeResource?.id}_${formFieldObj.key}_checkbox`}
                                      />
                                      <div>
                                        Attribute:{" "}
                                        <span className="tw-font-semibold">
                                          {formFieldObj.label}
                                        </span>
                                      </div>
                                    </div>
                                    <Icon
                                      className="tw-text-gray-50"
                                      name={
                                        collapsedAttributeKeyList.includes(
                                          accordionId
                                        )
                                          ? IconsList.CARET_DOWN
                                          : IconsList.CARET_UP
                                      }
                                    />
                                  </div>
                                  {!collapsedAttributeKeyList.includes(
                                    accordionId
                                  ) && (
                                    <div className="tw-pb-4">
                                      <TextBox
                                        name={`${activeResource?.id}.attributes.${index}.tfVarName`}
                                        label={"Variable Name"}
                                        placeholder={"Enter variable name"}
                                        required={true}
                                        dataTestId={`tf_var_attribute_${activeResource?.id}_${formFieldObj.key}label`}
                                        backgroundVariant="gray-800"
                                      />
                                      <div className="tw-mt-3">
                                        <ResourcePropertiesFormElements
                                          resource_attribute_obj={{
                                            ...activeAttributeDetails,
                                            label: "Default Value",
                                            validation: {
                                              ...activeAttributeDetails?.validation,
                                              required: false,
                                            },
                                            key: `${activeResource?.id}.properties.${formFieldObj.key}`,
                                          }}
                                          values={values}
                                          errors={errors}
                                          touched={touched}
                                          showTFVarsDialog={() => {}}
                                          hideTFVar={true}
                                          setFieldValue={setFieldValue}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-end tw-gap-3 tw-bg-gray-800 tw-p-4 tw-border-t tw-border-gray-600">
                    <Button
                      variant="primary"
                      label={
                        isSubmitting ? "Converting..." : "Convert to TF Vars"
                      }
                      leftIcon={isSubmitting ? IconsList.SPINNER : undefined}
                      type="submit"
                      dataTestId="convert-to-tf-vars-button"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
      size="md"
      dataTestId="configure-tf-vars-dialog"
    />
  );
};

export default ConfigureTFVarsDialog;
