/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnswerRequest,
  GetRecommendations400Response,
  Questionnaire,
  QuestionnaireRequest,
} from '../models/index';
import {
    AnswerRequestFromJSON,
    AnswerRequestToJSON,
    GetRecommendations400ResponseFromJSON,
    GetRecommendations400ResponseToJSON,
    QuestionnaireFromJSON,
    QuestionnaireToJSON,
    QuestionnaireRequestFromJSON,
    QuestionnaireRequestToJSON,
} from '../models/index';

export interface GetRecommendationsRequest {
    questionnaireRequest: QuestionnaireRequest;
}

export interface RecommendationResponseRequest {
    answerRequest: AnswerRequest;
}

/**
 * 
 */
export class RecommendationsApi extends runtime.BaseAPI {

    /**
     * Get recommendations
     */
    async getRecommendationsRaw(requestParameters: GetRecommendationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Questionnaire>> {
        if (requestParameters.questionnaireRequest === null || requestParameters.questionnaireRequest === undefined) {
            throw new runtime.RequiredError('questionnaireRequest','Required parameter requestParameters.questionnaireRequest was null or undefined when calling getRecommendations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/recommendations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionnaireRequestToJSON(requestParameters.questionnaireRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QuestionnaireFromJSON(jsonValue));
    }

    /**
     * Get recommendations
     */
    async getRecommendations(requestParameters: GetRecommendationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Questionnaire> {
        const response = await this.getRecommendationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond to recommendation questions by ID
     */
    async recommendationResponseRaw(requestParameters: RecommendationResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.answerRequest === null || requestParameters.answerRequest === undefined) {
            throw new runtime.RequiredError('answerRequest','Required parameter requestParameters.answerRequest was null or undefined when calling recommendationResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/recommendations/response`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnswerRequestToJSON(requestParameters.answerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Respond to recommendation questions by ID
     */
    async recommendationResponse(requestParameters: RecommendationResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.recommendationResponseRaw(requestParameters, initOverrides);
    }

}
