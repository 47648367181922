/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppStackConfigCorePolicySettings } from './AppStackConfigCorePolicySettings';
import {
    AppStackConfigCorePolicySettingsFromJSON,
    AppStackConfigCorePolicySettingsFromJSONTyped,
    AppStackConfigCorePolicySettingsToJSON,
} from './AppStackConfigCorePolicySettings';
import type { CloudProvider } from './CloudProvider';
import {
    CloudProviderFromJSON,
    CloudProviderFromJSONTyped,
    CloudProviderToJSON,
} from './CloudProvider';
import type { IacType } from './IacType';
import {
    IacTypeFromJSON,
    IacTypeFromJSONTyped,
    IacTypeToJSON,
} from './IacType';
import type { TargetCompute } from './TargetCompute';
import {
    TargetComputeFromJSON,
    TargetComputeFromJSONTyped,
    TargetComputeToJSON,
} from './TargetCompute';

/**
 * 
 * @export
 * @interface AppStackConfigCore
 */
export interface AppStackConfigCore {
    /**
     * 
     * @type {TargetCompute}
     * @memberof AppStackConfigCore
     */
    targetCompute: TargetCompute;
    /**
     * 
     * @type {Array<IacType>}
     * @memberof AppStackConfigCore
     */
    targetIac: Array<IacType>;
    /**
     * 
     * @type {AppStackConfigCorePolicySettings}
     * @memberof AppStackConfigCore
     */
    policySettings?: AppStackConfigCorePolicySettings;
    /**
     * 
     * @type {CloudProvider}
     * @memberof AppStackConfigCore
     */
    provider?: CloudProvider;
}

/**
 * Check if a given object implements the AppStackConfigCore interface.
 */
export function instanceOfAppStackConfigCore(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "targetCompute" in value;
    isInstance = isInstance && "targetIac" in value;

    return isInstance;
}

export function AppStackConfigCoreFromJSON(json: any): AppStackConfigCore {
    return AppStackConfigCoreFromJSONTyped(json, false);
}

export function AppStackConfigCoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStackConfigCore {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetCompute': TargetComputeFromJSON(json['targetCompute']),
        'targetIac': ((json['targetIac'] as Array<any>).map(IacTypeFromJSON)),
        'policySettings': !exists(json, 'policySettings') ? undefined : AppStackConfigCorePolicySettingsFromJSON(json['policySettings']),
        'provider': !exists(json, 'provider') ? undefined : CloudProviderFromJSON(json['provider']),
    };
}

export function AppStackConfigCoreToJSON(value?: AppStackConfigCore | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetCompute': TargetComputeToJSON(value.targetCompute),
        'targetIac': ((value.targetIac as Array<any>).map(IacTypeToJSON)),
        'policySettings': AppStackConfigCorePolicySettingsToJSON(value.policySettings),
        'provider': CloudProviderToJSON(value.provider),
    };
}

