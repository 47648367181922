/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GovernanceRules } from './GovernanceRules';
import {
    GovernanceRulesFromJSON,
    GovernanceRulesFromJSONTyped,
    GovernanceRulesToJSON,
} from './GovernanceRules';
import type { OwnerType } from './OwnerType';
import {
    OwnerTypeFromJSON,
    OwnerTypeFromJSONTyped,
    OwnerTypeToJSON,
} from './OwnerType';

/**
 * 
 * @export
 * @interface GovernanceAssignment
 */
export interface GovernanceAssignment {
    /**
     * The UUID of the governance version entry.
     * @type {string}
     * @memberof GovernanceAssignment
     */
    governanceVersionUuid: string;
    /**
     * The UUID of the owner to which the assigned governance configuration version will apply.
     * @type {string}
     * @memberof GovernanceAssignment
     */
    ownerUuid: string;
    /**
     * 
     * @type {OwnerType}
     * @memberof GovernanceAssignment
     */
    ownerType: OwnerType;
    /**
     * 
     * @type {GovernanceRules}
     * @memberof GovernanceAssignment
     */
    governanceRules: GovernanceRules;
}

/**
 * Check if a given object implements the GovernanceAssignment interface.
 */
export function instanceOfGovernanceAssignment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "governanceVersionUuid" in value;
    isInstance = isInstance && "ownerUuid" in value;
    isInstance = isInstance && "ownerType" in value;
    isInstance = isInstance && "governanceRules" in value;

    return isInstance;
}

export function GovernanceAssignmentFromJSON(json: any): GovernanceAssignment {
    return GovernanceAssignmentFromJSONTyped(json, false);
}

export function GovernanceAssignmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): GovernanceAssignment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'governanceVersionUuid': json['governanceVersionUuid'],
        'ownerUuid': json['ownerUuid'],
        'ownerType': OwnerTypeFromJSON(json['ownerType']),
        'governanceRules': GovernanceRulesFromJSON(json['governanceRules']),
    };
}

export function GovernanceAssignmentToJSON(value?: GovernanceAssignment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'governanceVersionUuid': value.governanceVersionUuid,
        'ownerUuid': value.ownerUuid,
        'ownerType': OwnerTypeToJSON(value.ownerType),
        'governanceRules': GovernanceRulesToJSON(value.governanceRules),
    };
}

