import React, { useCallback, type FC, useMemo } from "react";
import { InitialsLogoProps } from "./initials-logo-types";
import { IconSize } from "../icons";
import { providersPrefixesForPolicyFilter } from "../../config/constants";

const InitialsLogo: FC<InitialsLogoProps> = ({
  label,
  size = IconSize.md,
  className,
  trimProviderPrefix = false, // trims provider initials from label
}) => {
  const colorPalette = [
    "tw-from-blue-500 tw-to-blue-700",
    "tw-from-green-500 tw-to-green-700",
    "tw-from-red-500 tw-to-red-700",
    "tw-from-rose-500 tw-to-rose-700",
    "tw-from-indigo-500 tw-to-indigo-700",
    "tw-from-purple-500 tw-to-purple-700",
    "tw-from-pink-500 tw-to-pink-700",
    "tw-from-violet-500 tw-to-violet-700",
    "tw-from-green-500 tw-to-green-700",
    "tw-from-teal-500 tw-to-teal-700",
    "tw-from-emerald-500 tw-to-emerald-700",
  ];

  // calculate color from given palette based on initials
  const getColorClassForInitials = useCallback((initials: string): string => {
    let sum = 0;
    for (let i = 0; i < initials.length; i++) {
      sum += initials.charCodeAt(i);
    }
    const colorIndex = sum % colorPalette.length;
    return colorPalette[colorIndex];
  }, []);

  const initials = useMemo(() => {
    let processedLabel = label;

    if (trimProviderPrefix) {
      for (const prefix of providersPrefixesForPolicyFilter) {
        if (processedLabel.startsWith(prefix)) {
          processedLabel = processedLabel.slice(prefix.length);
          break;
        }
      }
    }
    const words = processedLabel.split(" ");
    let wordInitials = words[0].substring(0, 1).toUpperCase();
    if (words.length > 1) {
      wordInitials += words[1].substring(0, 1).toUpperCase();
    } else if (words[0].length > 1) {
      wordInitials += words[0].substring(1, 2).toUpperCase();
    }
    return wordInitials;
  }, [label, trimProviderPrefix]);

  return (
    <div
      className={`tw-flex tw-justify-center tw-items-center tw-text-white tw-bg-gradient-to-b tw-text-xs tw-font-semibold ${getColorClassForInitials(
        initials
      )} ${size} ${className}`}
      data-testid={`initials-logo-${label}`}
    >
      {initials}
    </div>
  );
};

export default InitialsLogo;
