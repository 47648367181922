/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResourcePackRequest,
  SaveResourcePack200Response,
} from '../models/index';
import {
    ResourcePackRequestFromJSON,
    ResourcePackRequestToJSON,
    SaveResourcePack200ResponseFromJSON,
    SaveResourcePack200ResponseToJSON,
} from '../models/index';

export interface DeleteMultipleResourcePacksRequest {
    requestBody: Array<string>;
}

export interface DeleteResourcePackRequest {
    resourcePackID: string;
}

export interface SaveResourcePackRequest {
    resourcePackRequest: ResourcePackRequest;
}

export interface UpdateResourcePackRequest {
    resourcePackID: string;
    resourcePackRequest: ResourcePackRequest;
}

/**
 * 
 */
export class V1ResourcePacksApi extends runtime.BaseAPI {

    /**
     * Delete various resource packs
     */
    async deleteMultipleResourcePacksRaw(requestParameters: DeleteMultipleResourcePacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteMultipleResourcePacks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resource-packs`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete various resource packs
     */
    async deleteMultipleResourcePacks(requestParameters: DeleteMultipleResourcePacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMultipleResourcePacksRaw(requestParameters, initOverrides);
    }

    /**
     * Delete one resource pack
     */
    async deleteResourcePackRaw(requestParameters: DeleteResourcePackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourcePackID === null || requestParameters.resourcePackID === undefined) {
            throw new runtime.RequiredError('resourcePackID','Required parameter requestParameters.resourcePackID was null or undefined when calling deleteResourcePack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-packs/{resourcePackID}`.replace(`{${"resourcePackID"}}`, encodeURIComponent(String(requestParameters.resourcePackID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete one resource pack
     */
    async deleteResourcePack(requestParameters: DeleteResourcePackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResourcePackRaw(requestParameters, initOverrides);
    }

    /**
     * Saves a resource pack from a topology
     */
    async saveResourcePackRaw(requestParameters: SaveResourcePackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SaveResourcePack200Response>> {
        if (requestParameters.resourcePackRequest === null || requestParameters.resourcePackRequest === undefined) {
            throw new runtime.RequiredError('resourcePackRequest','Required parameter requestParameters.resourcePackRequest was null or undefined when calling saveResourcePack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resource-packs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePackRequestToJSON(requestParameters.resourcePackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SaveResourcePack200ResponseFromJSON(jsonValue));
    }

    /**
     * Saves a resource pack from a topology
     */
    async saveResourcePack(requestParameters: SaveResourcePackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SaveResourcePack200Response> {
        const response = await this.saveResourcePackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update one specific resource pack
     */
    async updateResourcePackRaw(requestParameters: UpdateResourcePackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourcePackRequest>> {
        if (requestParameters.resourcePackID === null || requestParameters.resourcePackID === undefined) {
            throw new runtime.RequiredError('resourcePackID','Required parameter requestParameters.resourcePackID was null or undefined when calling updateResourcePack.');
        }

        if (requestParameters.resourcePackRequest === null || requestParameters.resourcePackRequest === undefined) {
            throw new runtime.RequiredError('resourcePackRequest','Required parameter requestParameters.resourcePackRequest was null or undefined when calling updateResourcePack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resource-packs/{resourcePackID}`.replace(`{${"resourcePackID"}}`, encodeURIComponent(String(requestParameters.resourcePackID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePackRequestToJSON(requestParameters.resourcePackRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourcePackRequestFromJSON(jsonValue));
    }

    /**
     * Update one specific resource pack
     */
    async updateResourcePack(requestParameters: UpdateResourcePackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourcePackRequest> {
        const response = await this.updateResourcePackRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
