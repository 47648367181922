/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SourceRef } from './SourceRef';
import {
    SourceRefFromJSON,
    SourceRefFromJSONTyped,
    SourceRefToJSON,
} from './SourceRef';

/**
 * 
 * @export
 * @interface ComponentCreate
 */
export interface ComponentCreate {
    /**
     * Descriptive name of component. Must not contain any spaces and must be URL-safe
     * @type {string}
     * @memberof ComponentCreate
     */
    name: string;
    /**
     * 
     * @type {SourceRef}
     * @memberof ComponentCreate
     */
    source: SourceRef;
    /**
     * Version of the analyzer used
     * @type {string}
     * @memberof ComponentCreate
     */
    analyzerVersion?: string;
    /**
     * Repository path that contains the source code to be analyzed. If a path is not provided, the root of the repository will be used.
     * @type {string}
     * @memberof ComponentCreate
     */
    analysisPath?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentCreate
     */
    language?: string;
    /**
     * Relative path from the source code root to the Docker file to analyze.
     * @type {string}
     * @memberof ComponentCreate
     */
    containerPath?: string;
    /**
     * Fully qualified reference to your container. Should include registry, repository and tag
     * @type {string}
     * @memberof ComponentCreate
     */
    containerImageReference?: string;
}

/**
 * Check if a given object implements the ComponentCreate interface.
 */
export function instanceOfComponentCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function ComponentCreateFromJSON(json: any): ComponentCreate {
    return ComponentCreateFromJSONTyped(json, false);
}

export function ComponentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'source': SourceRefFromJSON(json['source']),
        'analyzerVersion': !exists(json, 'analyzerVersion') ? undefined : json['analyzerVersion'],
        'analysisPath': !exists(json, 'analysisPath') ? undefined : json['analysisPath'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'containerPath': !exists(json, 'containerPath') ? undefined : json['containerPath'],
        'containerImageReference': !exists(json, 'containerImageReference') ? undefined : json['containerImageReference'],
    };
}

export function ComponentCreateToJSON(value?: ComponentCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'source': SourceRefToJSON(value.source),
        'analyzerVersion': value.analyzerVersion,
        'analysisPath': value.analysisPath,
        'language': value.language,
        'containerPath': value.containerPath,
        'containerImageReference': value.containerImageReference,
    };
}

