/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Hook } from './Hook';
import {
    HookFromJSON,
    HookFromJSONTyped,
    HookToJSON,
} from './Hook';
import type { Question } from './Question';
import {
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './Question';

/**
 * 
 * @export
 * @interface Questionnaire
 */
export interface Questionnaire {
    /**
     * 
     * @type {Hook}
     * @memberof Questionnaire
     */
    hook: Hook;
    /**
     * 
     * @type {string}
     * @memberof Questionnaire
     */
    title: string;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Questionnaire
     */
    questions: Array<Question>;
}

/**
 * Check if a given object implements the Questionnaire interface.
 */
export function instanceOfQuestionnaire(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hook" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "questions" in value;

    return isInstance;
}

export function QuestionnaireFromJSON(json: any): Questionnaire {
    return QuestionnaireFromJSONTyped(json, false);
}

export function QuestionnaireFromJSONTyped(json: any, ignoreDiscriminator: boolean): Questionnaire {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hook': HookFromJSON(json['hook']),
        'title': json['title'],
        'questions': ((json['questions'] as Array<any>).map(QuestionFromJSON)),
    };
}

export function QuestionnaireToJSON(value?: Questionnaire | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hook': HookToJSON(value.hook),
        'title': value.title,
        'questions': ((value.questions as Array<any>).map(QuestionToJSON)),
    };
}

