import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import * as yup from "yup";
import {
  activeResourcePackAtom,
  saveResourcePackAtom,
  topologyResourcesListAtom,
  updateResourcePacksAtom,
} from "../../../../../stores/topology.store";
import {
  getResourcesByCategory,
  trackAmplitudeEvent,
} from "../../../../../utils";
import {
  ResourcePackFields,
  shareWithOptions,
  type ResourceConnectionDetails,
  type ResourcePackForm,
} from "../resource-pack-types";

import type { TargetCompute } from "../../../../../apis/appcd";

import { ResourceProvidersMap } from "../../../../../config/constants";
import { userLoginStatusAtom } from "../../../../../stores/auth.store";
import { getResourceConnectionFromConnectionDetails } from "../resource-pack-utils";

export const useResourcePackResource = (
  onClose: (success: boolean) => void,
  targetCompute: TargetCompute
) => {
  const saveResourcePack = useSetAtom(saveResourcePackAtom);
  const updateResourcePack = useSetAtom(updateResourcePacksAtom);
  const userDetails = useAtomValue(userLoginStatusAtom);
  const activeResourcePack = useAtomValue(activeResourcePackAtom);
  const topologyResourcesList = useAtomValue(topologyResourcesListAtom);

  const handleSubmit = useCallback(
    async (resourcePackReq: ResourcePackForm, resetForm: () => void) => {
      if (activeResourcePack) {
        const resourcePackRequest = {
          name: resourcePackReq[ResourcePackFields.PACK_NAME],
          shareWith: resourcePackReq[ResourcePackFields.SHARE_WITH],
          resources: activeResourcePack.resources,
          connections: getResourceConnectionFromConnectionDetails(
            activeResourcePack.connections as ResourceConnectionDetails[]
          ),
          deploymentType: targetCompute,
          creatorName: userDetails.name,
          description: resourcePackReq[ResourcePackFields.DESCRIPTION],
        };

        if (activeResourcePack?.resourcePackID) {
          const response = await updateResourcePack({
            resourcePackID: activeResourcePack?.resourcePackID,
            resourcePackRequest,
          });
          if (response) {
            trackAmplitudeEvent("resource_pack_update_success", {
              name: response.name,
            });
            resetForm();
          } else {
            trackAmplitudeEvent("resource_pack_update_fail", {});
          }
        } else {
          const response = await saveResourcePack({
            resourcePackRequest,
          });
          if (response) {
            trackAmplitudeEvent("resource_pack_add_success", {
              uuid: response.id,
              name: response.name,
            });
            resetForm();
          } else {
            trackAmplitudeEvent("resource_pack_add_fail", {});
          }
        }
        onClose(true);
      }
    },
    [onClose, saveResourcePack, activeResourcePack]
  );

  const onResourcePackSubmit = useCallback(
    async (
      values: ResourcePackForm,
      setSubmitting: (isSubmitting: boolean) => void,
      resetForm: () => void
    ) => {
      setSubmitting(true);
      await handleSubmit(values, resetForm);
      setSubmitting(false);
    },
    [handleSubmit]
  );

  const existingResourcePacks = useMemo(() => {
    const { resourcePacks } = getResourcesByCategory(
      topologyResourcesList[ResourceProvidersMap.resourcePacks]
    );
    return resourcePacks ?? [];
  }, [topologyResourcesList]);

  const getValidationSchema = useCallback(() => {
    return yup.object().shape({
      [ResourcePackFields.PACK_NAME]: yup
        .string()
        .notOneOf(
          existingResourcePacks.map(({ label }) => label),
          "Resource pack name is already in use"
        )
        .required("Resource pack name is required"),
      [ResourcePackFields.SHARE_WITH]: yup
        .string()
        .required("Share with is required"),
    });
  }, [existingResourcePacks]);

  const resourcePackFieldOptions = useMemo(() => {
    return {
      shareWith: shareWithOptions,
      activeResourcePack,
    };
  }, [activeResourcePack]);

  return {
    onResourcePackSubmit,
    getValidationSchema,
    options: resourcePackFieldOptions,
    name: activeResourcePack?.name ?? "",
    description: activeResourcePack?.description ?? "",
    shareWith: activeResourcePack?.shareWith ?? "",
    isCreate: Boolean(!activeResourcePack?.resourcePackID),
  };
};
