/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AppStack,
  AppStackCreate,
  AppStackMetadata,
  AppStackUpload,
  DeleteAppStackRequest,
} from '../models/index';
import {
    AppStackFromJSON,
    AppStackToJSON,
    AppStackCreateFromJSON,
    AppStackCreateToJSON,
    AppStackMetadataFromJSON,
    AppStackMetadataToJSON,
    AppStackUploadFromJSON,
    AppStackUploadToJSON,
    DeleteAppStackRequestFromJSON,
    DeleteAppStackRequestToJSON,
} from '../models/index';

export interface ArchiveAppStackRequest {
    uuid: string;
    orgId?: string;
}

export interface ArchiveAppStacksByNameRequest {
    name: string;
    orgId?: string;
}

export interface CreateAppStackVersionRequest {
    name: string;
    appStackCreate: AppStackCreate;
    orgId?: string;
}

export interface CreateAppStacksRequest {
    appStackCreate: AppStackCreate;
    orgId?: string;
}

export interface DeleteAppStackRequest {
    uuid: string;
    orgId?: string;
}

export interface DeleteAppStacksRequest {
    orgId?: string;
    deleteAppStackRequest?: DeleteAppStackRequest;
}

export interface GetAppStackRequest {
    uuid: string;
    orgId?: string;
}

export interface GetAppStackPoliciesRequest {
    uuid: string;
    orgId?: string;
}

export interface GetAppStacksRequest {
    name?: string;
    includeMetadata?: boolean;
    orgId?: string;
}

export interface GetLatestAppStackVersionRequest {
    uuid: string;
    orgId?: string;
}

export interface GetPreviousAppStackRequest {
    uuid: string;
    orgId?: string;
}

export interface UnarchiveAppStackRequest {
    uuid: string;
    orgId?: string;
}

export interface UnarchiveAppStacksByNameRequest {
    name: string;
    orgId?: string;
}

export interface UpdateAppStackLabelsRequest {
    uuid: string;
    requestBody: Array<string>;
    orgId?: string;
}

export interface UploadAppStackRequest {
    appStackUpload: AppStackUpload;
    orgId?: string;
}

export interface UploadAppStackVersionRequest {
    name: string;
    appStackUpload: AppStackUpload;
    orgId?: string;
}

/**
 * 
 */
export class AppStacksApi extends runtime.BaseAPI {

    /**
     * Archive an appStack by its UUID
     */
    async archiveAppStackRaw(requestParameters: ArchiveAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling archiveAppStack.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}/archive`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Archive an appStack by its UUID
     */
    async archiveAppStack(requestParameters: ArchiveAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.archiveAppStackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Archive all appStacks with provided name
     */
    async archiveAppStacksByNameRaw(requestParameters: ArchiveAppStacksByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppStack>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling archiveAppStacksByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/archive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppStackFromJSON));
    }

    /**
     * Archive all appStacks with provided name
     */
    async archiveAppStacksByName(requestParameters: ArchiveAppStacksByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppStack>> {
        const response = await this.archiveAppStacksByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new version of an appStack
     */
    async createAppStackVersionRaw(requestParameters: CreateAppStackVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling createAppStackVersion.');
        }

        if (requestParameters.appStackCreate === null || requestParameters.appStackCreate === undefined) {
            throw new runtime.RequiredError('appStackCreate','Required parameter requestParameters.appStackCreate was null or undefined when calling createAppStackVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appstacks/{name}/version`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppStackCreateToJSON(requestParameters.appStackCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Create a new version of an appStack
     */
    async createAppStackVersion(requestParameters: CreateAppStackVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.createAppStackVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new appStack and initial version
     */
    async createAppStacksRaw(requestParameters: CreateAppStacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.appStackCreate === null || requestParameters.appStackCreate === undefined) {
            throw new runtime.RequiredError('appStackCreate','Required parameter requestParameters.appStackCreate was null or undefined when calling createAppStacks.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appstacks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppStackCreateToJSON(requestParameters.appStackCreate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Create a new appStack and initial version
     */
    async createAppStacks(requestParameters: CreateAppStacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.createAppStacksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an appStack identified by its UUID.
     * Delete an appStack by its UUID
     */
    async deleteAppStackRaw(requestParameters: DeleteAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteAppStack.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an appStack identified by its UUID.
     * Delete an appStack by its UUID
     */
    async deleteAppStack(requestParameters: DeleteAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAppStackRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes all AppStacks with provided names or UUIDs.
     * Delete all appStack by names or UUIDs
     */
    async deleteAppStacksRaw(requestParameters: DeleteAppStacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appstacks`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAppStackRequestToJSON(requestParameters.deleteAppStackRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes all AppStacks with provided names or UUIDs.
     * Delete all appStack by names or UUIDs
     */
    async deleteAppStacks(requestParameters: DeleteAppStacksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAppStacksRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve an appStack by its UUID
     */
    async getAppStackRaw(requestParameters: GetAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getAppStack.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Retrieve an appStack by its UUID
     */
    async getAppStack(requestParameters: GetAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.getAppStackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of policy IDs associated with an appStack
     */
    async getAppStackPoliciesRaw(requestParameters: GetAppStackPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Set<string>>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getAppStackPolicies.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}/policies`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get list of policy IDs associated with an appStack
     */
    async getAppStackPolicies(requestParameters: GetAppStackPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Set<string>> {
        const response = await this.getAppStackPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all AppStacks
     */
    async getAppStacksRaw(requestParameters: GetAppStacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppStack>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.includeMetadata !== undefined) {
            queryParameters['includeMetadata'] = requestParameters.includeMetadata;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppStackFromJSON));
    }

    /**
     * Get all AppStacks
     */
    async getAppStacks(requestParameters: GetAppStacksRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppStack>> {
        const response = await this.getAppStacksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Metadata for additional information on creating an appStack
     */
    async getAppStacksEnumMetadataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStackMetadata>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/metadata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackMetadataFromJSON(jsonValue));
    }

    /**
     * Get Metadata for additional information on creating an appStack
     */
    async getAppStacksEnumMetadata(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStackMetadata> {
        const response = await this.getAppStacksEnumMetadataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get latest versions of an appStack with a \'successful\' status
     */
    async getLatestAppStackVersionRaw(requestParameters: GetLatestAppStackVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getLatestAppStackVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}/latest`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get latest versions of an appStack with a \'successful\' status
     */
    async getLatestAppStackVersion(requestParameters: GetLatestAppStackVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getLatestAppStackVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get previous versions of an appStack with a \'successful\' status
     */
    async getPreviousAppStackRaw(requestParameters: GetPreviousAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getPreviousAppStack.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}/previous`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get previous versions of an appStack with a \'successful\' status
     */
    async getPreviousAppStack(requestParameters: GetPreviousAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getPreviousAppStackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unarchive an appStack by its UUID
     */
    async unarchiveAppStackRaw(requestParameters: UnarchiveAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling unarchiveAppStack.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/{uuid}/unarchive`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Unarchive an appStack by its UUID
     */
    async unarchiveAppStack(requestParameters: UnarchiveAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.unarchiveAppStackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Unarchive all appStacks with provided name
     */
    async unarchiveAppStacksByNameRaw(requestParameters: UnarchiveAppStacksByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppStack>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling unarchiveAppStacksByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/appstacks/unarchive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppStackFromJSON));
    }

    /**
     * Unarchive all appStacks with provided name
     */
    async unarchiveAppStacksByName(requestParameters: UnarchiveAppStacksByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppStack>> {
        const response = await this.unarchiveAppStacksByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update labels for an appStack by its UUID
     */
    async updateAppStackLabelsRaw(requestParameters: UpdateAppStackLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateAppStackLabels.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling updateAppStackLabels.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appstacks/{uuid}/labels`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Update labels for an appStack by its UUID
     */
    async updateAppStackLabels(requestParameters: UpdateAppStackLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.updateAppStackLabelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload an appStack with the Manifest and Component Traits already defined
     */
    async uploadAppStackRaw(requestParameters: UploadAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.appStackUpload === null || requestParameters.appStackUpload === undefined) {
            throw new runtime.RequiredError('appStackUpload','Required parameter requestParameters.appStackUpload was null or undefined when calling uploadAppStack.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appstacks/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppStackUploadToJSON(requestParameters.appStackUpload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Upload an appStack with the Manifest and Component Traits already defined
     */
    async uploadAppStack(requestParameters: UploadAppStackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.uploadAppStackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new version of an appStack
     */
    async uploadAppStackVersionRaw(requestParameters: UploadAppStackVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppStack>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling uploadAppStackVersion.');
        }

        if (requestParameters.appStackUpload === null || requestParameters.appStackUpload === undefined) {
            throw new runtime.RequiredError('appStackUpload','Required parameter requestParameters.appStackUpload was null or undefined when calling uploadAppStackVersion.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/appstacks/{name}/version/upload`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppStackUploadToJSON(requestParameters.appStackUpload),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppStackFromJSON(jsonValue));
    }

    /**
     * Create a new version of an appStack
     */
    async uploadAppStackVersion(requestParameters: UploadAppStackVersionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppStack> {
        const response = await this.uploadAppStackVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
