/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GovernanceAssignment } from './GovernanceAssignment';
import {
    GovernanceAssignmentFromJSON,
    GovernanceAssignmentFromJSONTyped,
    GovernanceAssignmentToJSON,
} from './GovernanceAssignment';
import type { OwnerType } from './OwnerType';
import {
    OwnerTypeFromJSON,
    OwnerTypeFromJSONTyped,
    OwnerTypeToJSON,
} from './OwnerType';
import type { TargetCompute } from './TargetCompute';
import {
    TargetComputeFromJSON,
    TargetComputeFromJSONTyped,
    TargetComputeToJSON,
} from './TargetCompute';
import type { UserCore } from './UserCore';
import {
    UserCoreFromJSON,
    UserCoreFromJSONTyped,
    UserCoreToJSON,
} from './UserCore';

/**
 * 
 * @export
 * @interface GovernanceVersion
 */
export interface GovernanceVersion {
    /**
     * List of target compute that can be used when creating an appstack. If null or empty, any target compute will be allowed to be set.
     * @type {Set<TargetCompute>}
     * @memberof GovernanceVersion
     */
    allowedTargetCompute?: Set<TargetCompute>;
    /**
     * List of security policies that must be set and can only be set, if null, any security policies will be allowed to be set.
     * @type {Set<string>}
     * @memberof GovernanceVersion
     */
    requiredSecurityPolicies?: Set<string>;
    /**
     * List of resources policies that will limit the avialble IaC resources for an appstack. If null or empty, no restrictions will be applied.
     * @type {Set<string>}
     * @memberof GovernanceVersion
     */
    customResourceRestrictionPolicies?: Set<string>;
    /**
     * List of custom iam policies that will be required if the resource is used.  Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceVersion
     */
    customIamPolicies?: Set<string>;
    /**
     * List of resource mapping policies that will apply to topology resources when creating an appstack. Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceVersion
     */
    customResourceMappingPolicies?: Set<string>;
    /**
     * List of custom security policies that will be required if the resource is used. Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceVersion
     */
    customResourceSecurityPolicies?: Set<string>;
    /**
     * The UUID of the owner of the governance configuration
     * @type {string}
     * @memberof GovernanceVersion
     */
    owner: string;
    /**
     * 
     * @type {OwnerType}
     * @memberof GovernanceVersion
     */
    ownerType: OwnerType;
    /**
     * The name of the governance entry
     * @type {string}
     * @memberof GovernanceVersion
     */
    name: string;
    /**
     * The description of the governance entry
     * @type {string}
     * @memberof GovernanceVersion
     */
    description: string;
    /**
     * The version of the governance entry.
     * @type {number}
     * @memberof GovernanceVersion
     */
    version: number;
    /**
     * The UUID of the governance version entry.
     * @type {string}
     * @memberof GovernanceVersion
     */
    versionUuid: string;
    /**
     * The date and time the governance entry was created.
     * @type {Date}
     * @memberof GovernanceVersion
     */
    createdAt?: Date;
    /**
     * The UUID of the user creating the governance entry.
     * @type {string}
     * @memberof GovernanceVersion
     */
    createdBy?: string;
    /**
     * 
     * @type {UserCore}
     * @memberof GovernanceVersion
     */
    createdByUser?: UserCore;
    /**
     * 
     * @type {Array<GovernanceAssignment>}
     * @memberof GovernanceVersion
     */
    governanceVersionAssignments?: Array<GovernanceAssignment>;
}

/**
 * Check if a given object implements the GovernanceVersion interface.
 */
export function instanceOfGovernanceVersion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "ownerType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "versionUuid" in value;

    return isInstance;
}

export function GovernanceVersionFromJSON(json: any): GovernanceVersion {
    return GovernanceVersionFromJSONTyped(json, false);
}

export function GovernanceVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GovernanceVersion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedTargetCompute': !exists(json, 'allowedTargetCompute') ? undefined : (new Set((json['allowedTargetCompute'] as Array<any>).map(TargetComputeFromJSON))),
        'requiredSecurityPolicies': !exists(json, 'requiredSecurityPolicies') ? undefined : json['requiredSecurityPolicies'],
        'customResourceRestrictionPolicies': !exists(json, 'customResourceRestrictionPolicies') ? undefined : json['customResourceRestrictionPolicies'],
        'customIamPolicies': !exists(json, 'customIamPolicies') ? undefined : json['customIamPolicies'],
        'customResourceMappingPolicies': !exists(json, 'customResourceMappingPolicies') ? undefined : json['customResourceMappingPolicies'],
        'customResourceSecurityPolicies': !exists(json, 'customResourceSecurityPolicies') ? undefined : json['customResourceSecurityPolicies'],
        'owner': json['owner'],
        'ownerType': OwnerTypeFromJSON(json['ownerType']),
        'name': json['name'],
        'description': json['description'],
        'version': json['version'],
        'versionUuid': json['versionUuid'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdByUser': !exists(json, 'createdByUser') ? undefined : UserCoreFromJSON(json['createdByUser']),
        'governanceVersionAssignments': !exists(json, 'governanceVersionAssignments') ? undefined : ((json['governanceVersionAssignments'] as Array<any>).map(GovernanceAssignmentFromJSON)),
    };
}

export function GovernanceVersionToJSON(value?: GovernanceVersion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedTargetCompute': value.allowedTargetCompute === undefined ? undefined : (Array.from(value.allowedTargetCompute as Set<any>).map(TargetComputeToJSON)),
        'requiredSecurityPolicies': value.requiredSecurityPolicies === undefined ? undefined : Array.from(value.requiredSecurityPolicies as Set<any>),
        'customResourceRestrictionPolicies': value.customResourceRestrictionPolicies === undefined ? undefined : Array.from(value.customResourceRestrictionPolicies as Set<any>),
        'customIamPolicies': value.customIamPolicies === undefined ? undefined : Array.from(value.customIamPolicies as Set<any>),
        'customResourceMappingPolicies': value.customResourceMappingPolicies === undefined ? undefined : Array.from(value.customResourceMappingPolicies as Set<any>),
        'customResourceSecurityPolicies': value.customResourceSecurityPolicies === undefined ? undefined : Array.from(value.customResourceSecurityPolicies as Set<any>),
        'owner': value.owner,
        'ownerType': OwnerTypeToJSON(value.ownerType),
        'name': value.name,
        'description': value.description,
        'version': value.version,
        'versionUuid': value.versionUuid,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'createdByUser': UserCoreToJSON(value.createdByUser),
        'governanceVersionAssignments': value.governanceVersionAssignments === undefined ? undefined : ((value.governanceVersionAssignments as Array<any>).map(GovernanceAssignmentToJSON)),
    };
}

