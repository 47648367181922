import React, { useEffect, useRef, useState, type FC } from "react";

import {
  TagSize,
  TagBaseClass,
  TagProps,
  TagVariantClass,
  TagVariant,
  TagVariantDisabledClass,
} from "./tag.types";
import { Icon, IconSize, IconsList, Tooltip } from "../../components";
import { isFunction } from "../../utils";

/**
 * Renders a Tag component.
 *
 * - @param {TagProps} props - The props for the Tag component.
 * - @param {TagSize} props.size - The size of the Tag. Default is TagSize.lg.
 * - @param {string} props.title - The title of the Tag. Default is "Tag".
 * - @param {boolean} props.dismissible - Whether the Tag is dismissible. Default is false.
 * - @param {boolean} props.disabled - Whether the Tag is disabled. Default is false.
 * - @param {string} props.className - The class names to overwrite some colors.
 * - @param {string} props.showTextEllipsis - Whether to show ellipsis text when text is too long. Default is false.
 * - @param {(title: string) => void} props.onDismiss - The function to call when the Tag is dismissed.
 * - @return {JSX.Element} The rendered Tag component.
 */
const Tag: FC<TagProps> = ({
  size = "lg",
  title,
  dismissible = false,
  disabled = false,
  variant = TagVariant.DEFAULT,
  className = "",
  showTextEllipsis = false,
  dataTestId = "",
  onDismiss,
}) => {
  const tagTextRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (
      tagTextRef.current &&
      (tagTextRef.current as HTMLElement).scrollWidth >
        (tagTextRef.current as HTMLElement).clientWidth
    ) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [title]);

  return (
    <div
      className={`tw-w-fit tw-flex tw-gap-2 tw-rounded-md tw-border tw-items-center tw-h-fit ${className} ${
        TagBaseClass[TagSize[size]]
      } ${
        disabled
          ? "tw-border-gray-600 tw-bg-gray-700 tw-pointer-events-none " +
            TagVariantDisabledClass[variant]
          : TagVariantClass[variant]
      } `}
      data-testid={dataTestId}
    >
      {showTextEllipsis ? (
        <span
          className="tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-max-w-20" // adding text ellipses for text that is too long
          ref={tagTextRef}
        >
          {showTooltip ? (
            <Tooltip title={title as string}>{title}</Tooltip>
          ) : (
            <span>{title}</span>
          )}
        </span>
      ) : (
        <span>{title}</span>
      )}

      {dismissible && (
        <span
          onClick={() => isFunction(onDismiss) && onDismiss?.(title as string)}
          className={disabled ? "tw-pointer-events-none" : "tw-cursor-pointer"}
          data-testid={`${dataTestId}-tag-close-button`}
        >
          <Icon
            name={IconsList.CLOSE}
            size={IconSize.xs}
            className="hover:tw-text-white"
          />
        </span>
      )}
    </div>
  );
};

export default Tag;
