import React, { type FC } from "react";
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";
import { generateUUIdV4 } from "../../utils";
import { TooltipPositions, type TooltipProps } from "./tooltip.types";

const Tooltip: FC<TooltipProps> = ({
  children,
  title,
  position = TooltipPositions.TOP,
  offset = 10,
  float = false,
  className = "",
}) => {
  const tooltipId = generateUUIdV4();
  return (
    <>
      <a
        data-tooltip-id={tooltipId}
        data-tooltip-place={position as PlacesType}
        data-tooltip-offset={offset}
        data-tooltip-position-strategy="fixed"
        data-tooltip-float={float}
      >
        {children}
      </a>
      <ReactTooltip
        id={tooltipId}
        noArrow
        disableStyleInjection
        className={`tw-z-70 ${className}`}
      >
        <p className="tw-z-70 tw-bg-gray-600 tw-text-xssm tw-px-2 tw-py-1 tw-text-gray-100 tw-rounded-md tw-w-fit tw-shadow-sm">
          {title}
        </p>
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
