import { FetchError, RequiredError, ResponseError } from "../apis/appcd";
import {
  captureSentryException,
  goToLogin,
  HTTP_STATUS_CODES_TO_CAPTURE_IN_SENTRY,
} from "./";

export const ErrorHandler = async (
  error: ResponseError | RequiredError | FetchError | any
): Promise<any> => {
  let errObj = error;
  const errorName = error?.name;

  if (errorName === "ResponseError") {
    let isErrorObjUpdated = false;
    if (error?.response?.status === 404) {
      errObj = { code: 404, msg: "Not Found" };
      isErrorObjUpdated = true;
    } else if (error?.response?.status === 500) {
      errObj = {
        msg: "Internal Server Error",
        extras: ["Something went wrong, please try later."],
      };
      isErrorObjUpdated = true;
    } else if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      window !== undefined &&
      !window.location.pathname.includes("/login")
    ) {
      goToLogin(); // logout user if user session is expired
    }
    if (!isErrorObjUpdated) {
      const err_resp = await (error as ResponseError).response.json();
      errObj = err_resp;
    }
  } else if (errorName === "RequiredError") {
    errObj = (error as RequiredError).field;
  } else if (errorName === "FetchError") {
    errObj = (error as FetchError).cause;
  } else {
    console.error(error);
    errObj = error;
  }
  // capture all errors without status code or with status codes mentioned in HTTP_STATUS_CODES_TO_CAPTURE_IN_SENTRY
  if (
    !error?.response?.status ||
    HTTP_STATUS_CODES_TO_CAPTURE_IN_SENTRY?.includes(error?.response?.status)
  ) {
    captureSentryException(error, {
      tags: {
        requestId: error.response.headers.get("x-request-id") || "NULL",
        errCode: errObj?.errCode || "NULL",
        status: error?.response?.status || "NULL",
      },
      extra: {
        ...errObj,
      },
    });
  }
  return errObj;
};
