import React, { type FC } from "react";
import { IconProps, IconSize } from "../../components/icons/icon-types";
import IconsSprite from "./icons-sprite.svg";

const Icon: FC<IconProps> = ({
  name,
  size = IconSize.md,
  className = "",
  alt,
  dataTestId,
}) => {
  const icons_props = { alt: alt ?? name, className: `${size} ${className}` };

  if (name) {
    return (
      <svg
        {...icons_props}
        {...(dataTestId ? { "data-testid": dataTestId } : {})}
      >
        <use xlinkHref={`${IconsSprite}#${name}`} {...icons_props} />
      </svg>
    );
  } else {
    return null;
  }
};

export default Icon;
