import { Form, Formik } from "formik";
import React, { type FC } from "react";

import { Banner, Drawer, ScrollToError } from "../../../../components";

import { BannerTypes } from "../../../banner/banner-types";
import { useResourcePackResource } from "./hooks/useResourcePack";
import ResourcePackDrawerFooter from "./resource-pack-footer";
import ResourcePackDrawerForm from "./resource-pack-form";
import ResourcePackDrawerHeader from "./resource-pack-header";
import ResourcePackPreview from "./resource-pack-preview";
import {
  ResourcePackFields,
  shareWithOptions,
  type ResourcePackDrawerProps,
} from "./resource-pack-types";

const ResourcePackDrawer: FC<ResourcePackDrawerProps> = ({
  onClose,
  targetCompute,
}) => {
  const {
    onResourcePackSubmit,
    getValidationSchema,
    options,
    name,
    isCreate,
    description,
  } = useResourcePackResource(onClose, targetCompute);
  return (
    <Drawer open={true} size="smsmmd" dataTestId="resource-pack-drawer">
      <Formik
        initialValues={{
          [ResourcePackFields.PACK_NAME]: name,
          [ResourcePackFields.DESCRIPTION]: description,
          [ResourcePackFields.SHARE_WITH]: shareWithOptions[0].value,
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          onResourcePackSubmit(values, setSubmitting, resetForm);
        }}
        validationSchema={getValidationSchema()}
        enableReinitialize
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <Form className="tw-h-full tw-bg-gray-900 tw-flex tw-flex-col">
            <ResourcePackDrawerHeader onClose={onClose} isCreate={isCreate} />
            <div className="tw-p-4 tw-flex-1 tw-flex tw-flex-col tw-gap-5">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <span className="tw-text-gray-200 tw-text-xssm tw-font-normal">
                  Preview
                </span>
                {options.activeResourcePack && (
                  <div className="tw-border tw-border-gray-500 tw-rounded-md tw-w-full tw-h-48">
                    <ResourcePackPreview
                      id={"resource-pack-form"}
                      resourcePack={options.activeResourcePack}
                      processResourceConnections={!isCreate}
                    />
                  </div>
                )}
              </div>
              <ScrollToError />
              <div className="tw-flex tw-flex-col tw-flex-1 tw-justify-between tw-bg-gray-900">
                <ResourcePackDrawerForm
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  options={options}
                />
                <Banner
                  title="Note: Please ensure that all attribute values have been reviewed. Additionally, edit or delete any attribute values in this pack to make the resource pack reusable."
                  type={BannerTypes.INFO}
                  dataTestId="resource-pack-info"
                />
              </div>
            </div>
            <ResourcePackDrawerFooter
              onClose={onClose}
              isSubmitting={isSubmitting}
              isCreate={isCreate}
            />
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default ResourcePackDrawer;
