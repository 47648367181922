/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerType } from './OwnerType';
import {
    OwnerTypeFromJSON,
    OwnerTypeFromJSONTyped,
    OwnerTypeToJSON,
} from './OwnerType';
import type { TargetCompute } from './TargetCompute';
import {
    TargetComputeFromJSON,
    TargetComputeFromJSONTyped,
    TargetComputeToJSON,
} from './TargetCompute';

/**
 * 
 * @export
 * @interface GovernanceConfigCreate
 */
export interface GovernanceConfigCreate {
    /**
     * List of target compute that can be used when creating an appstack. If null or empty, any target compute will be allowed to be set.
     * @type {Set<TargetCompute>}
     * @memberof GovernanceConfigCreate
     */
    allowedTargetCompute?: Set<TargetCompute>;
    /**
     * List of security policies that must be set and can only be set, if null, any security policies will be allowed to be set.
     * @type {Set<string>}
     * @memberof GovernanceConfigCreate
     */
    requiredSecurityPolicies?: Set<string>;
    /**
     * List of resources policies that will limit the avialble IaC resources for an appstack. If null or empty, no restrictions will be applied.
     * @type {Set<string>}
     * @memberof GovernanceConfigCreate
     */
    customResourceRestrictionPolicies?: Set<string>;
    /**
     * List of custom iam policies that will be required if the resource is used.  Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceConfigCreate
     */
    customIamPolicies?: Set<string>;
    /**
     * List of resource mapping policies that will apply to topology resources when creating an appstack. Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceConfigCreate
     */
    customResourceMappingPolicies?: Set<string>;
    /**
     * List of custom security policies that will be required if the resource is used. Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceConfigCreate
     */
    customResourceSecurityPolicies?: Set<string>;
    /**
     * The UUID of the owner of the governance configuration
     * @type {string}
     * @memberof GovernanceConfigCreate
     */
    owner: string;
    /**
     * 
     * @type {OwnerType}
     * @memberof GovernanceConfigCreate
     */
    ownerType: OwnerType;
    /**
     * The name of the governance entry
     * @type {string}
     * @memberof GovernanceConfigCreate
     */
    name: string;
    /**
     * The description of the governance entry
     * @type {string}
     * @memberof GovernanceConfigCreate
     */
    description: string;
}

/**
 * Check if a given object implements the GovernanceConfigCreate interface.
 */
export function instanceOfGovernanceConfigCreate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "ownerType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function GovernanceConfigCreateFromJSON(json: any): GovernanceConfigCreate {
    return GovernanceConfigCreateFromJSONTyped(json, false);
}

export function GovernanceConfigCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): GovernanceConfigCreate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedTargetCompute': !exists(json, 'allowedTargetCompute') ? undefined : (new Set((json['allowedTargetCompute'] as Array<any>).map(TargetComputeFromJSON))),
        'requiredSecurityPolicies': !exists(json, 'requiredSecurityPolicies') ? undefined : json['requiredSecurityPolicies'],
        'customResourceRestrictionPolicies': !exists(json, 'customResourceRestrictionPolicies') ? undefined : json['customResourceRestrictionPolicies'],
        'customIamPolicies': !exists(json, 'customIamPolicies') ? undefined : json['customIamPolicies'],
        'customResourceMappingPolicies': !exists(json, 'customResourceMappingPolicies') ? undefined : json['customResourceMappingPolicies'],
        'customResourceSecurityPolicies': !exists(json, 'customResourceSecurityPolicies') ? undefined : json['customResourceSecurityPolicies'],
        'owner': json['owner'],
        'ownerType': OwnerTypeFromJSON(json['ownerType']),
        'name': json['name'],
        'description': json['description'],
    };
}

export function GovernanceConfigCreateToJSON(value?: GovernanceConfigCreate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedTargetCompute': value.allowedTargetCompute === undefined ? undefined : (Array.from(value.allowedTargetCompute as Set<any>).map(TargetComputeToJSON)),
        'requiredSecurityPolicies': value.requiredSecurityPolicies === undefined ? undefined : Array.from(value.requiredSecurityPolicies as Set<any>),
        'customResourceRestrictionPolicies': value.customResourceRestrictionPolicies === undefined ? undefined : Array.from(value.customResourceRestrictionPolicies as Set<any>),
        'customIamPolicies': value.customIamPolicies === undefined ? undefined : Array.from(value.customIamPolicies as Set<any>),
        'customResourceMappingPolicies': value.customResourceMappingPolicies === undefined ? undefined : Array.from(value.customResourceMappingPolicies as Set<any>),
        'customResourceSecurityPolicies': value.customResourceSecurityPolicies === undefined ? undefined : Array.from(value.customResourceSecurityPolicies as Set<any>),
        'owner': value.owner,
        'ownerType': OwnerTypeToJSON(value.ownerType),
        'name': value.name,
        'description': value.description,
    };
}

