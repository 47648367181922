import React, { type FC } from "react";

import { ViraQuestionLayout, type ViraSelectQuestionProps } from "../";
import { SelectBox } from "../../../components";

const ViraSelectQuestion: FC<ViraSelectQuestionProps> = ({
  question,
  onSkip,
  onAnswer,
}) => {
  return (
    <ViraQuestionLayout
      question={question}
      onClose={onSkip}
      onAnswer={onAnswer}
      questionBodyRenderer={() => (
        <div className="tw-mb-4">
          <SelectBox
            name="answer"
            dataTestId="vira-select-question"
            options={
              question?.options?.map((option) => ({
                label: option.label as string,
                value: option.value as string,
              })) ?? []
            }
            backgroundVariant="gray-800"
            placeholder="Search or select from the list"
          />
        </div>
      )}
    />
  );
};

export default ViraSelectQuestion;
