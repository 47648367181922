/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiKeyInfo,
  ApiKeySettings,
  DeleteApiKeyRequest,
  SessionInfo,
} from '../models/index';
import {
    ApiKeyInfoFromJSON,
    ApiKeyInfoToJSON,
    ApiKeySettingsFromJSON,
    ApiKeySettingsToJSON,
    DeleteApiKeyRequestFromJSON,
    DeleteApiKeyRequestToJSON,
    SessionInfoFromJSON,
    SessionInfoToJSON,
} from '../models/index';

export interface CreateApiKeyRequest {
    apiKeySettings: ApiKeySettings;
}

export interface DeleteApiKeyOperationRequest {
    deleteApiKeyRequest: DeleteApiKeyRequest;
}

export interface RegenerateApiKeyRequest {
    apiKeySettings: ApiKeySettings;
}

/**
 * 
 */
export class APIKeyApi extends runtime.BaseAPI {

    /**
     * Create a new ApiKey
     */
    async createApiKeyRaw(requestParameters: CreateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiKeyInfo>> {
        if (requestParameters.apiKeySettings === null || requestParameters.apiKeySettings === undefined) {
            throw new runtime.RequiredError('apiKeySettings','Required parameter requestParameters.apiKeySettings was null or undefined when calling createApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/apikey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiKeySettingsToJSON(requestParameters.apiKeySettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyInfoFromJSON(jsonValue));
    }

    /**
     * Create a new ApiKey
     */
    async createApiKey(requestParameters: CreateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiKeyInfo> {
        const response = await this.createApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the current session
     */
    async deleteApiKeyRaw(requestParameters: DeleteApiKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteApiKeyRequest === null || requestParameters.deleteApiKeyRequest === undefined) {
            throw new runtime.RequiredError('deleteApiKeyRequest','Required parameter requestParameters.deleteApiKeyRequest was null or undefined when calling deleteApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/apikey`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteApiKeyRequestToJSON(requestParameters.deleteApiKeyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the current session
     */
    async deleteApiKey(requestParameters: DeleteApiKeyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApiKeyRaw(requestParameters, initOverrides);
    }

    /**
     * Get the current session
     */
    async getApiKeysRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SessionInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/apikey`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SessionInfoFromJSON));
    }

    /**
     * Get the current session
     */
    async getApiKeys(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SessionInfo>> {
        const response = await this.getApiKeysRaw(initOverrides);
        return await response.value();
    }

    /**
     * Regenerate provided ApiKey with new TTL
     */
    async regenerateApiKeyRaw(requestParameters: RegenerateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiKeyInfo>> {
        if (requestParameters.apiKeySettings === null || requestParameters.apiKeySettings === undefined) {
            throw new runtime.RequiredError('apiKeySettings','Required parameter requestParameters.apiKeySettings was null or undefined when calling regenerateApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/apikey/regenerate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiKeySettingsToJSON(requestParameters.apiKeySettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiKeyInfoFromJSON(jsonValue));
    }

    /**
     * Regenerate provided ApiKey with new TTL
     */
    async regenerateApiKey(requestParameters: RegenerateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiKeyInfo> {
        const response = await this.regenerateApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
