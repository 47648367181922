import React, { type FC } from "react";
import { AvatarSize } from "./avatar-types";

export interface AvatarProps {
  title: string;
  imageUrl?: string;
  size?: keyof typeof AvatarSize;
  className?: string;
}

const Avatar: FC<AvatarProps> = ({
  title,
  imageUrl,
  size = "md",
  className = "",
}) => {
  const sizeClass = AvatarSize[size];

  return imageUrl ? (
    <span
      className={`tw-flex tw-justify-center tw-items-center tw-rounded-full ${sizeClass} ${className}`}
    >
      <img
        className={`tw-rounded-full ${sizeClass}`}
        src={imageUrl}
        alt={`${title}_profile_image`}
      />
    </span>
  ) : (
    <span
      className={`tw-flex tw-justify-center tw-items-center tw-text-white tw-bg-purple-700 tw-rounded-full tw-font-semibold ${sizeClass} ${className}`}
    >
      {title?.[0]?.toUpperCase()}
    </span>
  );
};

export default Avatar;
