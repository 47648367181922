module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.13.1_gatsby@5.13.7_@swc+core@1.7.24_@swc+helpers@0.4.36__babel-eslint@_ucs7en5xn25qxkzvrwshqqonly/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_@swc+core@1.7.24_@swc+helpers@0.4.36__babel-eslin_khp5otxutxb76rwhnyo64jilfy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.svg","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"dfd1430161d9a6bca3ba867565a34fe0"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_@swc+core@1.7.24_@swc+helpers@0.4.36__ba_54u73nhleicvyvbr73xwqpi7xi/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TP8KSWH9","includeInDevelopment":false,"routeChangeEventName":"PAGE_VIEWED","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_@swc+core@1.7.24_@swc+helpers@0.4.36__babel-eslint@10.1.0_eslint@7.32.0__esbuil_n2otali7hf3pkoofzdoih2k5vi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
