/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentTraitsDependenciesAwsKmsInner
 */
export interface ComponentTraitsDependenciesAwsKmsInner {
    /**
     * 
     * @type {string}
     * @memberof ComponentTraitsDependenciesAwsKmsInner
     */
    keyId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComponentTraitsDependenciesAwsKmsInner
     */
    actions?: Array<string>;
}

/**
 * Check if a given object implements the ComponentTraitsDependenciesAwsKmsInner interface.
 */
export function instanceOfComponentTraitsDependenciesAwsKmsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesAwsKmsInnerFromJSON(json: any): ComponentTraitsDependenciesAwsKmsInner {
    return ComponentTraitsDependenciesAwsKmsInnerFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesAwsKmsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependenciesAwsKmsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keyId': !exists(json, 'keyId') ? undefined : json['keyId'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
    };
}

export function ComponentTraitsDependenciesAwsKmsInnerToJSON(value?: ComponentTraitsDependenciesAwsKmsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyId': value.keyId,
        'actions': value.actions,
    };
}

