/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceOutputConfig
 */
export interface ResourceOutputConfig {
    /**
     * 
     * @type {string}
     * @memberof ResourceOutputConfig
     */
    key?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceOutputConfig
     */
    sensitive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResourceOutputConfig
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOutputConfig
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOutputConfig
     */
    type?: string;
}

/**
 * Check if a given object implements the ResourceOutputConfig interface.
 */
export function instanceOfResourceOutputConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceOutputConfigFromJSON(json: any): ResourceOutputConfig {
    return ResourceOutputConfigFromJSONTyped(json, false);
}

export function ResourceOutputConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceOutputConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ResourceOutputConfigToJSON(value?: ResourceOutputConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'sensitive': value.sensitive,
        'value': value.value,
        'description': value.description,
        'type': value.type,
    };
}

