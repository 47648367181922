import React, { type FC } from "react";
import { Field, type FieldProps } from "formik";
import { Range } from "react-range";
import { SliderProps } from "./slider-types";

const Slider: FC<SliderProps> = ({
  name,
  step = 1,
  min = 0,
  max = 100,
  dataTestId,
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <div data-testid={dataTestId}>
          <Range
            step={step}
            min={min}
            max={max}
            values={field.value}
            onChange={(values) => form.setFieldValue(name, values)}
            renderTrack={({ props, children }) => (
              // Moved this styling to parent - when needed
              <div
                {...props}
                className="tw-h-3 tw-bg-gray-400 tw-rounded-full tw-relative"
              >
                <div
                  className="tw-absolute tw-left-0 tw-h-full tw-bg-blue-500 tw-rounded-full"
                  style={{ width: `${field.value[0]}%` }}
                />
                {children}
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              // Moved this styling to parent - when needed
              <div
                {...props}
                className={`tw-w-6 tw-h-6 tw-bg-white tw-rounded-full ${
                  isDragged ? "tw-shadow-lg tw-ring-white focus:tw-ring-1" : ""
                }`}
              />
            )}
          />
        </div>
      )}
    </Field>
  );
};

export default Slider;
