import React, { useCallback, useMemo, useRef } from "react";
import { Icon, IconSize, IconsList } from "../../components";
import FileList from "./file-dropzone/file-list";
import type { FileDropzoneProps } from "./file-dropzone/file-dropzone-types";
import useFileDropzone from "./file-dropzone/hooks/useFileDropzone";
import FileSelect from "./file-select";

const FileDropzone: React.FC<FileDropzoneProps> = ({
  onChangeFiles,
  className = "",
  customUploadContainer,
  isOptional,
  initialFiles,
  error,
  showErrors = true,
  label,
  name,
  required,
  ...props
}) => {
  const {
    uploadedFiles,
    isDragging,
    handleFileChange,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    removeFile,
  } = useFileDropzone(onChangeFiles, initialFiles);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const renderUploadContainer = () => {
    if (uploadedFiles.length > 0) {
      return <FileList files={uploadedFiles} onDelete={removeFile} />;
    }

    return customUploadContainer ? (
      customUploadContainer
    ) : (
      <div className="tw-flex tw-h-50 tw-gap-2 tw-items-center">
        <Icon name={IconsList.FILE} size={IconSize.lg} />{" "}
        <span className="tw-text-gray-400 tw-text-sm">
          {isDragging
            ? "Drop the file here"
            : `Select or drop ${props.validFileExtensions
                ?.map((ext) => `.${ext}`)
                .join(", ")} file`}
        </span>
      </div>
    );
  };

  const containerClassName = useMemo(() => {
    const borderClass =
      error && showErrors
        ? "tw-border-red-400"
        : isDragging
        ? "tw-border-blue-400"
        : "tw-border-gray-500";

    return `tw-border tw-border-dashed tw-p-4 tw-rounded-md tw-flex tw-flex-col tw-items-center tw-justify-center tw-cursor-pointer tw-bg-gray-800 ${borderClass} ${className}`;
  }, [error, showErrors, isDragging, className]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {label && (
        <label
          htmlFor={name}
          className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-white"
        >
          {label}
          {isOptional ? (
            <span className={"tw-font-normal tw-text-gray-200"}>
              (Optional)
            </span>
          ) : (
            required && <span className="tw-text-red-400"> *</span>
          )}
        </label>
      )}
      <div
        className={containerClassName}
        onClick={handleClick}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <FileSelect
          ref={fileInputRef}
          name={name}
          className="tw-hidden"
          onChange={handleFileChange}
          showErrors={false}
          {...props}
        />
        {renderUploadContainer()}
      </div>
      {error && showErrors ? (
        <>
          <div className="tw-mt-0.5 tw-text-xs tw-text-red-400">{error}</div>
        </>
      ) : null}
    </div>
  );
};

export default FileDropzone;
