import React, { memo, type ReactNode } from "react";
import type { NodeProps } from "reactflow";
import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
  Tooltip,
} from "../../../components";
import { TooltipPositions } from "../../tooltip/tooltip.types";
import CustomNodeToolbar from "./custom-node-toolbar";

const NestedGroupNode = (node: NodeProps) => {
  const getResourceWithTooltip = (nestedGroupNodeDiv: ReactNode) => {
    if (node.data?.isRestricted) {
      return (
        <Tooltip
          title={
            "This resource is restricted. Please contact the administrator to add support, or delete or replace the resource."
          }
          position={TooltipPositions.TOP_START}
        >
          {nestedGroupNodeDiv}
        </Tooltip>
      );
    } else {
      return nestedGroupNodeDiv;
    }
  };

  return (
    <>
      {/* <NodeResizer isVisible={node.selected} /> */}
      <CustomNodeToolbar node={node} />
      {getResourceWithTooltip(
        <div
          className={`tw-flex tw-justify-between tw-gap-3 tw-p-3 tw-bg-purple-400 tw-bg-opacity-5 tw-rounded-t-md`}
          data-testid={`group-node-${node?.data?.id}`}
        >
          <div className="tw-flex tw-gap-2 tw-items-center tw-h-fit">
            <div className="tw-basis-7">
              <AWSIcon
                name={
                  AWSIconsList[node?.data?.icon as keyof typeof AWSIconsList]
                }
                alt={node?.data?.label || node?.data?.resourceTypeLabel}
                size={IconSize.xl}
                fallbackPlaceholder={node?.data?.resourceType}
                className="tw-rounded"
              />
            </div>
            <div>
              <div
                className={`tw-text-sm tw-font-semibold tw-break-all ${
                  node.data?.isRestricted ? "tw-text-gray-400" : "tw-text-white"
                }`}
              >
                {node?.data?.label || node?.data?.resourceTypeLabel}
              </div>
              {node?.data?.label && (
                <div
                  className={`tw-text-xxsxs tw-font-normal tw-break-all ${
                    node.data?.isRestricted
                      ? "tw-text-gray-400"
                      : "tw-text-gray-200"
                  }`}
                >
                  {node?.data?.resourceTypeLabel}
                </div>
              )}
            </div>
            {node?.data?.external_resource && (
              <div className="tw-absolute tw-px-1.5 tw-py-1 tw-text-xxsxs tw-italic tw-text-gray-100 tw-right-px tw-top-px tw-bg-gray-600 tw-rounded-se-md tw-rounded-es-md">
                External
              </div>
            )}
          </div>
          <div className="tw-basis-4 tw-h-fit">
            {node.data?.isRestricted ? (
              <Icon
                name={IconsList.CIRCLE_STOP_RESTRICTED}
                className="tw-text-yellow-700"
              />
            ) : node.data?.errors?.validationErrors ? (
              <Icon
                name={IconsList.INFO_CIRCLE}
                className="tw-text-red-500"
                size={IconSize.sm}
              />
            ) : node.data?.errors?.policyViolationErrors ? (
              <Icon
                name={IconsList.SHIELD_EXCLAMATION}
                className="tw-text-yellow-500"
                size={IconSize.sm}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default memo(NestedGroupNode);
