/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnswerRequestResponse } from './AnswerRequestResponse';
import {
    AnswerRequestResponseFromJSON,
    AnswerRequestResponseFromJSONTyped,
    AnswerRequestResponseToJSON,
} from './AnswerRequestResponse';
import type { Hook } from './Hook';
import {
    HookFromJSON,
    HookFromJSONTyped,
    HookToJSON,
} from './Hook';

/**
 * 
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    questionId?: number;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    appstackId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    topologyId?: string;
    /**
     * 
     * @type {Hook}
     * @memberof AnswerRequest
     */
    hook?: Hook;
    /**
     * 
     * @type {AnswerRequestResponse}
     * @memberof AnswerRequest
     */
    response?: AnswerRequestResponse;
}

/**
 * Check if a given object implements the AnswerRequest interface.
 */
export function instanceOfAnswerRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnswerRequestFromJSON(json: any): AnswerRequest {
    return AnswerRequestFromJSONTyped(json, false);
}

export function AnswerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'appstackId': !exists(json, 'appstackId') ? undefined : json['appstackId'],
        'topologyId': !exists(json, 'topologyId') ? undefined : json['topologyId'],
        'hook': !exists(json, 'hook') ? undefined : HookFromJSON(json['hook']),
        'response': !exists(json, 'response') ? undefined : AnswerRequestResponseFromJSON(json['response']),
    };
}

export function AnswerRequestToJSON(value?: AnswerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'appstackId': value.appstackId,
        'topologyId': value.topologyId,
        'hook': HookToJSON(value.hook),
        'response': AnswerRequestResponseToJSON(value.response),
    };
}

