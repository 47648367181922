import { Form, Formik } from "formik";
import React, { type FC } from "react";

import { Drawer, ScrollToError } from "../../../../components";

import CustomHelmDrawerFooter from "./custom-helm-drawer-footer";
import CustomHelmDrawerForm from "./custom-helm-drawer-form";
import CustomHelmDrawerHeader from "./custom-helm-drawer-header";
import {
  CustomHelmLocations,
  type CustomHelmChartDrawerProps,
} from "./custom-helm-types";
import { useCustomHelmResource } from "./hooks/useCustomHelmResource";

const CustomHelmResourceDrawer: FC<CustomHelmChartDrawerProps> = ({
  open,
  onClose,
}) => {
  const { onCustomHelmChartSubmit, getValidationSchema } =
    useCustomHelmResource(onClose);

  return (
    <Drawer open={open} size="smmd" dataTestId="custom-helm-resource-drawer">
      <div className="tw-bg-gray-900 tw-h-full">
        <CustomHelmDrawerHeader onClose={onClose} />
        {open && (
          <Formik
            initialValues={{
              name: "",
              chartZip: [],
              location: CustomHelmLocations.LOCAL,
              repository: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              onCustomHelmChartSubmit(values, setSubmitting, resetForm);
            }}
            validationSchema={getValidationSchema()}
            enableReinitialize
          >
            {({ isSubmitting, values, errors, setFieldValue }) => (
              <Form>
                <ScrollToError />
                <div className="tw-flex tw-flex-col tw-justify-between tw-bg-gray-900">
                  <CustomHelmDrawerForm
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                  <CustomHelmDrawerFooter
                    onClose={onClose}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Drawer>
  );
};

export default CustomHelmResourceDrawer;
