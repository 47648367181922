export enum TagSize {
  "sm" = "sm",
  "md" = "md",
  "lg" = "lg",
}
export enum TagVariant {
  "DEFAULT" = "DEFAULT",
  "SOLID" = "SOLID",
}

export const TagVariantClass = {
  [TagVariant.DEFAULT]: "tw-border-gray-500 tw-bg-gray-700 tw-text-gray-200",
  [TagVariant.SOLID]: "tw-border-gray-500 tw-bg-gray-600 tw-text-gray-100",
};

export const TagVariantDisabledClass = {
  [TagVariant.DEFAULT]: "tw-text-gray-500",
  [TagVariant.SOLID]: "tw-text-gray-400",
};

export const TagBaseClass = {
  [TagSize.sm]: "tw-text-xxsxs tw-px-1.5 tw-py-px",
  [TagSize.md]: "tw-text-xs tw-px-1.5 tw-py-px",
  [TagSize.lg]: "tw-text-xs tw-px-2 tw-py-0.5",
};

export interface TagProps {
  title: string | number;
  size?: keyof typeof TagSize;
  dismissible?: boolean;
  disabled?: boolean;
  variant?: TagVariant;
  className?: string;
  showTextEllipsis?: boolean;
  dataTestId?: string;
  onDismiss?: (title: string) => void;
}
