import React, { type FC } from "react";

import { ViraQuestionLayout, type ViraBooleanQuestionProps } from "../";

const ViraBooleanQuestion: FC<ViraBooleanQuestionProps> = ({
  question,
  onSkip,
  onAnswer,
}) => {
  return (
    <ViraQuestionLayout
      question={question}
      onClose={onSkip}
      questionBodyRenderer={() => (
        <div className="tw-text-sm">
          {question?.options && question?.options.length && (
            <div className="tw-flex tw-gap-2 tw-flex-col">
              {question.options.map((option) => (
                <p
                  key={option.value}
                  onClick={() => onAnswer({ question, answer: option.value })}
                  className="tw-text-xssm tw-py-2.5 tw-px-3 tw-rounded-md tw-bg-gray-500 tw-border tw-border-gray-400 tw-cursor-pointer hover:tw-border-gray-300"
                  data-testid={`${option.value}-vira-boolean-question-option`}
                >
                  {option.label}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default ViraBooleanQuestion;
