import React, {
  type DragEvent,
  memo,
  type MouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useAtom, useAtomValue, useSetAtom } from "jotai";

import type {
  Connection,
  Edge,
  Node,
  OnConnectStartParams,
  ReactFlowInstance,
  XYPosition,
} from "reactflow";
import ReactFlow, {
  addEdge,
  Background,
  BackgroundVariant,
  ConnectionMode,
  Panel,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStore,
  useStoreApi,
} from "reactflow";
import "reactflow/dist/style.css";

import {
  type CustomResourceTemplate,
  type ProviderWiseSupportedResourceType,
  type SupportedConnectionType,
  type SupportedResourceType,
  type Topology,
  type TopologyResourceConnection,
  type TopologyResourceWithMisconfigurations,
  type TopologyResourceWithPosition,
  ValidateDeleteResourceDeletionTypeEnum,
} from "../../apis/topology";
import {
  edgeTypes,
  nodeTypes,
  type PropertiesType,
  type Resource,
  type ResourceErrors,
  SelectedResourceType,
  type TopologyGraphProps,
  TopologyPageReferrer,
} from "./topology-graph-types";

import getELKGraphPositions from "./utils/elk-graph-positions";
import { generateEdgeId } from "./utils/topology-utils";

import {
  CompareTopology,
  ConfigureTFVarsDialog,
  CustomResourceDrawer,
  DeleteResourceAndConnectionConfirmation,
  FixMissingAttributesDrawer,
  LLMPrompt,
  ResourceOverrideDialog,
  ResourcePackDrawer,
  ResourcePropertiesForm,
  SupportedResourceList,
  TopologyErrorDrawer,
  TopologyToolbar,
} from "./";

import type { TargetCompute } from "../../apis/appcd";
import {
  Banner,
  Button,
  Dialog,
  Drawer,
  Icon,
  IconSize,
  IconsList,
  Spinner,
  Tag,
} from "../../components";
import {
  resourcePackIdentifier,
  ResourceProvidersMap,
  targetComputeResourceTypes,
  unsupportedResourceTypes,
} from "../../config/constants";
import { useKeyboardZoom } from "../../hooks";
import { enabledFeaturesAtom } from "../../stores/auth.store";
import {
  deleteCustomResourceTemplateAtom,
  fetchResourceTypeMappingsAtom,
  getCustomTemplateByResourceIdAtom,
  processedGraphDataAtom,
  topologyPageMetaAtom,
  updateResourceTypeMappingsAtom,
  validateDeleteAtom,
} from "../../stores/topology.store";
import {
  findChildNodeAbsolutePosition,
  findResourceInTopology,
  getTopologyCenter,
  isFunction,
  isGroupNode,
  processGraphData,
  trackAmplitudeEvent,
} from "../../utils";
import { BannerTypes } from "../banner/banner-types";
import GlobalResourceTagsDrawer from "../global-resource-tags/global-resource-tags-drawer";
import RestrictedResourcesDrawer from "./drawers/restricted-resources-list";
import { TopologyErrorDrawerTypeEnum } from "./drawers/topology-error/topology-error-types";
import CustomHelmResourceDrawer from "./features/custom-helm/custom-helm-resource-drawer";

const TopologyGraph = ({
  showTopologyDiff,
  setShowTopologyDiff,
  getTopologyDiff,
  appStackVersions,
  graphData,
  resourcesList,
  resourcePropertiesTemplates,
  fetchResourcePropertiesTemplate,
  fetchResourcePropertiesValues,
  fetchResourceConnectionPropertiesValues,
  fetchResourceConnectionTypes,
  updateResourcePropertiesValues,
  updateResourceConnectionPropertiesValues,
  updateResourcePosition,
  updateBulkResourcePosition,
  createTopologyResource,
  createTopologyResourceConnection,
  deleteTopologyResource,
  deleteTopologyResourceConnection,
  topologyErrors,
  validateTopology,
  refetchTopologyGraphData,
  provider,
  refetchResourcesList = () => {},
  showGlobalResourceTagsDrawer,
  setShowGlobalResourceTagsDrawer,
  globalResourceTags,
  onSubmitGlobalResourceTags,
  markResourceAsExternal,
}: TopologyGraphProps) => {
  const { setViewport, zoomIn, zoomOut, fitView, screenToFlowPosition } =
    useReactFlow();
  const [showResourcePackDrawer, setShowResourcePackDrawer] = useState(false);
  useKeyboardZoom({ onZoomIn: zoomIn, onZoomOut: zoomOut });
  const currentZoomLevel = useStore((store) => store.transform[2]);
  const store = useStoreApi();
  const isMaxZoomLevel = useStore(
    (store) => store.transform[2] === store.maxZoom
  );
  const isMinZoomLevel = useStore(
    (store) => store.transform[2] === store.minZoom
  );

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);
  const [resources, setResources, onResourcesChange] = useNodesState<Node[]>(
    []
  );
  const [resourceConnections, setResourceConnections] = useEdgesState<Edge[]>(
    []
  );
  const getCustomTemplateByResourceId = useSetAtom(
    getCustomTemplateByResourceIdAtom
  );
  const deleteCustomResourceTemplate = useSetAtom(
    deleteCustomResourceTemplateAtom
  );
  const [topologyPageMeta, setTopologyPageMeta] = useAtom(topologyPageMetaAtom);

  const fetchResourceTypeMappings = useSetAtom(fetchResourceTypeMappingsAtom);
  const updateResourceTypeMappings = useSetAtom(updateResourceTypeMappingsAtom);

  const validateDelete = useSetAtom(validateDeleteAtom);
  const setProcessedGraphData = useSetAtom(processedGraphDataAtom);

  const enabledFeatures = useAtomValue(enabledFeaturesAtom);

  const defaultShowResourcePropertiesForm = {
    show: false,
    defaultTabId: "details",
    showValidationErrors: false,
  };

  const [showResourcesList, setShowResourcesList] = useState(false);
  const [showResourcePropertiesForm, setShowResourcePropertiesForm] = useState(
    defaultShowResourcePropertiesForm
  );
  const [selected_node, setSelectedNode] =
    useState<TopologyResourceWithMisconfigurations | null>(null);
  const [selected_edge, setSelectedEdge] = useState<Edge | null>(null);
  const [group_resources_expanded, updateGroupResourcesExpanded] = useState<{
    [resource_id: string]: boolean;
  }>({});
  const [connectionLineStyle, setConnectionLineStyle] = useState({});
  const [unsupportedResourcesExists, setUnsupportedResourcesExists] =
    useState(false);
  const [hideUnSupportedResources, setHideUnSupportedResources] =
    useState(false);
  const [activeResourceTab, setActiveResourceTab] = useState(
    "" as keyof ProviderWiseSupportedResourceType
  );
  const [showCustomResourceDrawer, setShowCustomResourceDrawer] =
    useState(false);
  const [showCustomHelmResourceDrawer, setShowCustomHelmResourceDrawer] =
    useState(false);
  const [showCustomResourceDeleteDialog, setShowCustomResourceDeleteDialog] =
    useState(false);
  const [showConfigureTFVarsDialog, setShowConfigureTFVarsDialog] =
    useState(false);
  const [showMissingAttributesDrawer, setShowMissingAttributesDrawer] =
    useState(false);
  const [topologyErrorDrawer, setTopologyErrorDrawer] = useState({
    show: false,
    resourceId: "",
    type: TopologyErrorDrawerTypeEnum.ACTION,
  });
  const [restrictedResources, setRestrictedResources] = useState<{
    [key: string]: SupportedResourceType;
  }>({});
  const [restrictedResourcesInTopology, setRestrictedResourcesInTopology] =
    useState<{
      [resourceId: string]: Resource;
    }>({});
  const [showRestrictedResourcesDrawer, setShowRestrictedResourcesDrawer] =
    useState(false);

  const [activeCustomResourceForAction, setActiveCustomResourceForAction] =
    useState<SupportedResourceType>({});
  const [
    activeCustomResourceTemplateForAction,
    setActiveCustomResourceTemplateForAction,
  ] = useState<CustomResourceTemplate | null>(null);
  const [resourceTypeMappings, setResourceTypeMappings] =
    useState<PropertiesType | null>(null);
  const [
    deleteResourceAndConnectionDrawer,
    setDeleteResourceAndConnectionDrawer,
  ] = useState<{
    show: boolean;
    data: PropertiesType | null;
  }>({
    show: false,
    data: null,
  });

  const handleTransform = useCallback(() => {
    setViewport({ x: 0, y: 0, zoom: 1 }, { duration: 800 });
  }, [setViewport]);

  const getPossibleConnectionTypes = useCallback(
    async (sourceNodeId: string, targetNodeId: string) => {
      const possibleConnectionTypes = await fetchResourceConnectionTypes?.(
        sourceNodeId,
        targetNodeId
      );

      return possibleConnectionTypes;
    },
    [resources, fetchResourceConnectionTypes]
  );

  const createConnection = useCallback(
    async (
      connection: Connection,
      connectionTypeData: SupportedConnectionType
    ) => {
      await createTopologyResourceConnection?.(
        graphData.id as string,
        {
          sourceResourceId: connection.source as string,
          targetResourceId: connection.target as string,
          connectionType: connectionTypeData.type,
          crossReferenceId: connectionTypeData?.crossReferenceId,
        },
        (resourceConnection) => {
          if (
            resourceConnection.sourceResourceId &&
            resourceConnection.targetResourceId
          ) {
            setResourceConnections((resource_connections) =>
              addEdge(
                {
                  ...connection,
                  id: resourceConnection.id as string,
                  source: resourceConnection.sourceResourceId as string,
                  target: resourceConnection.targetResourceId as string,
                  type: "customEdge",
                  style: { strokeWidth: 2, stroke: "#374252" },
                },
                resource_connections
              )
            );
            trackAmplitudeEvent("resource_connection", {
              connectionType: connectionTypeData?.type,
            });
            if (selected_node) {
              onNodeClick({
                id: selected_node.id as string,
                data: selected_node,
                position: {
                  x: selected_node?.position?.x as number,
                  y: selected_node?.position?.y as number,
                },
              });
            }
          } else {
            /** Do not show edge if it's an invalid connection
            setResourceConnections((resource_connections) =>
              addEdge(
                {
                  ...connection,
                  type: "customEdge",
                  style: { strokeWidth: 2, stroke: "#F24A4A" },
                },
                resource_connections
              )
            );
            */
          }
        }
      );
    },
    [setResourceConnections, graphData.id, selected_node]
  );

  const deleteConnection = useCallback(
    async (edges: Edge[]) => {
      edges.forEach(async (edge) => {
        edge?.data?.connectionTypes?.map(
          async (connection: TopologyResourceConnection) => {
            await deleteTopologyResourceConnection?.(
              graphData.id as string,
              connection
            );
          }
        );
        setSelectedEdge(null);
        setSelectedNode(null);
      });
    },
    [selected_node, graphData.id]
  );

  const deleteResource = useCallback(
    async (node: Node, graphData: Topology) => {
      if (graphData.id) {
        await deleteTopologyResource?.(
          graphData?.appstackId as string,
          graphData.id as string,
          node.id
        );
        trackAmplitudeEvent(`delete_resource_${node?.data?.resourceType}`);
        if (validateTopology && isFunction(validateTopology)) {
          validateTopology(graphData.id, [
            {
              id: node.id,
              resourceType: node?.data?.resourceType,
              isGroup: node?.data?.isGroup,
              label: "",
            },
          ]);
        }
        setShowResourcePropertiesForm({
          ...defaultShowResourcePropertiesForm,
          show: false,
        });
        setSelectedNode(null);
      }
    },
    [deleteTopologyResource]
  );

  const updateConnectionsStyle = useCallback(
    (
      connectionsToUpdate: TopologyResourceConnection[],
      updatedStyle?: PropertiesType
    ) => {
      setResourceConnections((resource_connections) =>
        resource_connections.map((resource_connection: any) => {
          const updated_connection = connectionsToUpdate.find(
            (updated_resource_connection) => {
              if (
                updated_resource_connection.sourceResourceId &&
                updated_resource_connection.targetResourceId
              ) {
                const edgeId = generateEdgeId(
                  updated_resource_connection.sourceResourceId,
                  updated_resource_connection.targetResourceId
                );
                return resource_connection.id === edgeId;
              }
            }
          );

          if (updated_connection) {
            resource_connection = {
              ...resource_connection,
              data: {
                ...resource_connection.data,
                updatedStyle: updatedStyle ? updatedStyle : undefined,
              },
            };
          }

          return resource_connection;
        })
      );
    },
    [setResourceConnections]
  );

  const validateAndDelete = useCallback(
    async (
      deletionType: ValidateDeleteResourceDeletionTypeEnum,
      graphData: Topology,
      itemToDelete: Node | Edge
    ) => {
      let itemsToDeleteList = [];

      // converting to an array because when deleting edges, there can be multiple connection types on a single edge
      if (deletionType === ValidateDeleteResourceDeletionTypeEnum.Resource) {
        itemsToDeleteList = [itemToDelete as Node];
      } else {
        itemsToDeleteList = itemToDelete?.data?.connectionTypes; // connectionTypes is already an array
      }

      itemsToDeleteList?.map(async (deleteItem: any) => {
        const validateDeleteResp = await validateDelete(
          graphData.id as string,
          deletionType,
          deleteItem.id
        );

        if (validateDeleteResp && validateDeleteResp?.length > 0) {
          updateConnectionsStyle(validateDeleteResp, {
            strokeWidth: 2,
            stroke: "#F24A4A",
          }); // tw-text-red-500
          setShowResourcePropertiesForm({
            ...defaultShowResourcePropertiesForm,
            show: false,
          });

          // If resource has IAM connections, show confirmation drawer before deleting
          setDeleteResourceAndConnectionDrawer({
            show: true,
            data: {
              deletionType,
              deleteLabel:
                deletionType === ValidateDeleteResourceDeletionTypeEnum.Resource
                  ? deleteItem.data?.label || deleteItem.data?.resourceTypeLabel
                  : "Connection",
              sourceResource:
                deletionType === ValidateDeleteResourceDeletionTypeEnum.Resource
                  ? deleteItem.data
                  : findResourceInTopology(
                      graphData.resources,
                      deleteItem.sourceResourceId
                    ),
              targetResource:
                deletionType === ValidateDeleteResourceDeletionTypeEnum.Resource
                  ? null
                  : findResourceInTopology(
                      graphData.resources,
                      deleteItem?.targetResourceId
                    ),
              totalHighlightedConnections: validateDeleteResp.length,
              onCancelDelete: () => {
                updateConnectionsStyle(validateDeleteResp);
                setDeleteResourceAndConnectionDrawer({
                  show: false,
                  data: null,
                });
              },
              onConfirmDelete: async () => {
                if (
                  deletionType ===
                  ValidateDeleteResourceDeletionTypeEnum.Resource
                ) {
                  await deleteResource(deleteItem as Node, graphData);
                } else {
                  // Passing `itemToDelete` in params and not `deleteItem` because `itemToDelete` is an Edge deleted from visible topology graph
                  await deleteConnection([itemToDelete as Edge]);
                }
                refetchTopologyGraphData?.();
                setDeleteResourceAndConnectionDrawer({
                  show: false,
                  data: null,
                });
              },
            },
          });
        } else {
          if (
            deletionType === ValidateDeleteResourceDeletionTypeEnum.Resource
          ) {
            await deleteResource(deleteItem as Node, graphData);
          } else {
            // Passing `itemToDelete` in params and not `deleteItem` because `itemToDelete` is an Edge deleted from visible topology graph
            await deleteConnection([itemToDelete as Edge]);
          }
        }
      });
    },
    [setResourceConnections, validateDelete, deleteResource]
  );

  const onNodesDelete = useCallback(
    async (nodes: Node[]) => {
      if (!showTopologyDiff) {
        // We are always deleting only one node at a time after selecting it
        const node = nodes[0];
        let findGroupResource = null;

        // This is to check if node is a child resource and it's parent is a group node(resourceType=group)
        if (node.data?.group) {
          findGroupResource = findResourceInTopology(
            graphData.resources,
            node.data.group
          );
        }
        if (
          !node.data?.group ||
          (node.data?.group &&
            findGroupResource &&
            findGroupResource?.isGroup &&
            findGroupResource?.resourceType !== "group")
        ) {
          await validateAndDelete(
            ValidateDeleteResourceDeletionTypeEnum.Resource,
            graphData,
            node
          );
        }
      }
    },
    [graphData.id, graphData.resources, showTopologyDiff]
  );

  const onEdgesDelete = useCallback(
    async (edges: Edge[], manual_delete: boolean) => {
      if (!showTopologyDiff && manual_delete) {
        // We only delete one selected edge at a time, so we are always deleting the first edge from the array
        // Passing in a function as array only because it is a common utility function
        await validateAndDelete(
          ValidateDeleteResourceDeletionTypeEnum.ResourceConnection,
          graphData,
          edges[0]
        );
      }
    },
    [
      showTopologyDiff,
      deleteConnection,
      graphData.id,
      graphData.resourceConnections,
      graphData.resources,
    ]
  );

  const onEdgeClick = useCallback(
    async (event: MouseEvent, edge: Edge) => {
      if (event.metaKey || event.ctrlKey) {
        setResourceConnections((resource_connections: Edge[]) =>
          resource_connections.map((resource_connection) =>
            resource_connection.id === edge.id
              ? {
                  ...resource_connection,
                  selected: !resource_connection.selected,
                }
              : resource_connection
          )
        );
        setSelectedEdge(edge);
        return;
        // If metaKey or ctrlKey is pressed, the event is for selecting resource packs, so do not process event further
      }
      if (!showTopologyDiff) {
        // Fetch possible connection types everytime the edge is selected
        const possibleConnectionTypes: Array<SupportedConnectionType> =
          await getPossibleConnectionTypes(edge.source, edge.target);
        if (possibleConnectionTypes?.length > 1) {
          setResourceConnections((resource_connections: any) =>
            resource_connections.map((resource_connection: any) =>
              resource_connection.id === edge.id
                ? {
                    ...resource_connection,
                    selected: true,
                    data: {
                      ...resource_connection.data,
                      possibleConnectionTypes,
                      setResourceConnections,
                      newConnection: false,
                      createConnection,
                      deleteConnection,
                      setSelectedEdge,
                      refetchTopologyGraphData,
                    },
                  }
                : resource_connection
            )
          );
        }
        setSelectedEdge(edge);
      }
    },
    [setSelectedEdge, showTopologyDiff, getPossibleConnectionTypes]
  );

  const onEdgeConnectionTypeClick = useCallback(
    async (selectedConnectionTypeData: any) => {
      if (!showTopologyDiff && graphData?.id && graphData?.resources) {
        // Fetch latest resource properties data
        const resource_connection_properties_data =
          await fetchResourceConnectionPropertiesValues?.(
            graphData.id,
            selectedConnectionTypeData.id
          );

        // Fetch resource template if not fetched earlier
        if (
          resourcePropertiesTemplates[
            selectedConnectionTypeData?.data?.resourceType
          ] === undefined
        ) {
          await fetchResourcePropertiesTemplate?.({
            resourceType: selectedConnectionTypeData?.data?.resourceType,
            templateId: selectedConnectionTypeData?.data?.templateId,
          });
        }

        setSelectedNode({
          ...resource_connection_properties_data?.data,
          id: resource_connection_properties_data?.id,
        } as unknown as TopologyResourceWithMisconfigurations);
        setShowResourcePropertiesForm({
          ...defaultShowResourcePropertiesForm,
          show: true,
          defaultTabId: defaultShowResourcePropertiesForm.defaultTabId,
          showValidationErrors: true,
        });
      }
    },
    [
      graphData.id,
      resourcePropertiesTemplates,
      fetchResourcePropertiesTemplate,
      setShowResourcePropertiesForm,
      showTopologyDiff,
    ]
  );

  const handleMetaKeyNodeSelection = useCallback((nodeObj: Node) => {
    const isGroup = isGroupNode(nodeObj);
    if (isGroup) {
      // Avoid node selection for group nodes
      setResources((resources_list: Node[]) =>
        resources_list.map((resource) =>
          resource.id === nodeObj.id
            ? { ...resource, selected: isGroup ? false : !resource.selected }
            : resource
        )
      );
    }
  }, []);

  const onNodeClick = useCallback(
    async (
      nodeObj: Node,
      node_type?: SelectedResourceType,
      event?: MouseEvent
    ) => {
      if (event?.metaKey || event?.ctrlKey) {
        handleMetaKeyNodeSelection(nodeObj);
        return;
        // If metaKey or ctrlKey is pressed, the event is for selecting resource packs, so do not process event further
      }
      if (!showTopologyDiff && graphData?.id && graphData?.resources) {
        // Fetch latest resource properties data
        const resource_properties_data = await fetchResourcePropertiesValues?.(
          graphData.id,
          nodeObj.id
        );

        if (nodeObj?.data?.resourceType !== "group") {
          // Fetch resource template if not fetched earlier
          if (
            resourcePropertiesTemplates[nodeObj?.data?.resourceType] ===
            undefined
          ) {
            await fetchResourcePropertiesTemplate?.({
              resourceType: nodeObj?.data?.resourceType,
              templateId: nodeObj?.data?.templateId,
            });
          }

          // Validate resource when clicked
          if (validateTopology && isFunction(validateTopology)) {
            validateTopology(graphData.id, [
              resource_properties_data as unknown as TopologyResourceWithMisconfigurations,
            ]);
          }
        }

        // Expand group node if selected node is a child of group node
        if (nodeObj?.data?.resourceType === "group") {
          updateGroupResourcesExpanded((prev) => ({
            ...prev,
            [nodeObj?.data?.group as string]: true,
          }));
        }
        setSelectedNode(
          resource_properties_data as unknown as TopologyResourceWithMisconfigurations
        );
        setShowResourcePropertiesForm({
          ...defaultShowResourcePropertiesForm,
          show: true,
          defaultTabId: defaultShowResourcePropertiesForm.defaultTabId,
          showValidationErrors:
            node_type === SelectedResourceType.VALIDATION_ERROR_NODE,
        });
        setDeleteResourceAndConnectionDrawer({
          show: false,
          data: null,
        });
      }
    },
    [
      graphData.id,
      resourcePropertiesTemplates,
      setSelectedNode,
      setShowResourcePropertiesForm,
      fetchResourcePropertiesTemplate,
      showTopologyDiff,
    ]
  );

  const onNodeDragStop = useCallback(
    async (_: MouseEvent, node: Node) => {
      if (!showTopologyDiff && node?.position) {
        await updateResourcePosition?.(
          graphData.id as string,
          node.id as string,
          {
            position: {
              x: Math.floor(node.position.x),
              y: Math.floor(node.position.y),
            },
          }
        );
      }
    },
    [graphData.id, showTopologyDiff]
  );

  const onDragStart = useCallback(
    (event: DragEvent, resourceType: string, resourceID?: string) => {
      if (!showTopologyDiff) {
        event.dataTransfer.setData("resourceType", resourceType);
        if (resourceID) {
          event.dataTransfer.setData("resourcePackId", resourceID);
        }
        event.dataTransfer.effectAllowed = "move";
      }
    },
    [showTopologyDiff]
  );

  const onDragOver = useCallback(
    (event: DragEvent) => {
      if (!showTopologyDiff) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
      }
    },
    [showTopologyDiff]
  );

  const onDrop = useCallback(
    async (event: DragEvent) => {
      if (!showTopologyDiff) {
        event.preventDefault();

        const resourceType = event.dataTransfer.getData("resourceType");

        // check if the dropped element is valid
        if (typeof resourceType === "undefined" || !resourceType) {
          return;
        }

        const position = screenToFlowPosition({
          x: event.clientX - 115,
          y: event.clientY - 25,
        });

        const dragged_resource = resourcesList?.[
          activeResourceTab as keyof ProviderWiseSupportedResourceType
        ]?.find((resource) => resource?.resourceType === resourceType);
        if (dragged_resource && position) {
          const resourceID =
            resourceType === resourcePackIdentifier
              ? event.dataTransfer.getData("resourcePackId")
              : "";
          onAddResource(dragged_resource, position, resourceID);
        }
      }
    },
    [
      reactFlowInstance,
      resourcesList,
      graphData.id,
      showTopologyDiff,
      activeResourceTab,
      resources,
    ]
  );

  const onAddResource = useCallback(
    async (
      resource: SupportedResourceType,
      position: XYPosition,
      resourceID?: string
    ) => {
      let absoluteResourcePosition: any = null;
      if (resource && position) {
        let groupNode: any = null;
        resources.forEach((resource) => {
          if (resource.type === "nestedGroupNode") {
            if (resource?.parentNode) {
              absoluteResourcePosition = findChildNodeAbsolutePosition(
                resource,
                resources
              );
            } else {
              absoluteResourcePosition = resource.position;
            }
            if (
              absoluteResourcePosition?.x <= position.x &&
              absoluteResourcePosition?.x +
                parseInt(resource.style?.width?.toString() || "0") >=
                position.x &&
              absoluteResourcePosition?.y <= position.y &&
              absoluteResourcePosition?.y +
                parseInt(resource.style?.height?.toString() || "0") >=
                position.y
            ) {
              groupNode = resource;
            }
          }
        });

        await createTopologyResource?.(
          graphData.id as string,
          {
            resourceType: resource.resourceType as string,
            iacType: resource.iacType,
            templateId: resource.templateId,
            groupType: resource?.groupType,
            isGroup: resource?.isGroup,
            ...(groupNode
              ? {
                  group: groupNode?.id,
                  position: {
                    x: position.x! - absoluteResourcePosition?.x,
                    y: position.y! - absoluteResourcePosition?.y,
                  },
                }
              : { position }),
            ...(resource.resourceType === resourcePackIdentifier
              ? {
                  resourcePackId: resourceID,
                }
              : {}),
          },
          (resource, resourceType) => {
            if (resource) {
              trackAmplitudeEvent(`drag_drop_${resource.resourceType}`);
              if (resourceType === resourcePackIdentifier) {
                refetchTopologyGraphData?.();
                return;
              }
              // This is to refetch topology graph data after adding a group type resource
              if (
                resource?.group ||
                targetComputeResourceTypes.includes(resource?.resourceType) ||
                resource?.isGroup
              ) {
                refetchTopologyGraphData?.();
              }
              // Select node automatically after adding it to the graph
              resourceType &&
                onNodeClick({
                  id: resource.id as string,
                  data: resource,
                  position: position,
                });
            } else if (resourceType === resourcePackIdentifier) {
              refetchTopologyGraphData?.();
            }
          }
        );
      }
    },
    [graphData.id, resources]
  );
  const onAddResourcePack = useCallback(
    (resourcePackID: string) => {
      trackAmplitudeEvent("resource_pack_use", {
        id: resourcePackID,
      });
      const { height, width, transform } = store.getState();
      const position = getTopologyCenter({ height, width, transform });
      const resourcePack = resourcesList?.resourcePacks?.find(
        (resource) =>
          resource?.resourceType === resourcePackIdentifier &&
          resource.resourcePackID === resourcePackID
      );
      if (resourcePack) onAddResource(resourcePack, position, resourcePackID);
    },
    [resourcesList]
  );

  const onConnect = useCallback(
    async (connection: Connection) => {
      if (!showTopologyDiff) {
        const possibleConnectionTypes: Array<SupportedConnectionType> =
          await getPossibleConnectionTypes(
            connection.source as string,
            connection.target as string
          );
        if (possibleConnectionTypes?.length > 0) {
          if (possibleConnectionTypes.length === 1) {
            // If there is only one type of connection possible
            await createConnection(connection, possibleConnectionTypes[0]);
          } else {
            // If there are multiple types of connections possible
            setResourceConnections((resource_connections) =>
              addEdge(
                {
                  ...connection,
                  id:
                    (connection.source as string) >
                    (connection.target as string)
                      ? `#${connection.source}-#${connection.target}`
                      : `#${connection.target}-#${connection.source}`,
                  source: connection.source as string,
                  target: connection.target as string,
                  type: "customEdge",
                  style: { strokeWidth: 2, stroke: "#374252" },
                  data: {
                    possibleConnectionTypes,
                    setResourceConnections,
                    newConnection: true,
                    createConnection,
                    deleteConnection,
                  },
                  selected: true,
                },
                resource_connections
              )
            );
          }
        }
      }
    },
    [
      showTopologyDiff,
      getPossibleConnectionTypes,
      createConnection,
      deleteConnection,
    ]
  );

  const onConnectStart = useCallback(
    (_: unknown, params: OnConnectStartParams) => {
      setConnectionLineStyle({
        stroke: "#3FA9F5", // tw-text-blue-500
        //TODO: use theme configs from tailwind config file https://tailwindcss.com/docs/configuration#referencing-in-java-script
        strokeWidth: 2,
      });
    },
    []
  );

  const onConnectEnd = useCallback(() => {
    setConnectionLineStyle({});
  }, []);

  const handleActiveCustomResourceAction = async (
    resource: SupportedResourceType,
    action: string
  ) => {
    switch (action) {
      case "delete":
        setActiveCustomResourceForAction(resource);
        setShowCustomResourceDeleteDialog(true);
        break;
      case "edit":
        const resource_template = await getCustomTemplateByResourceId({
          id: resource.templateId as string,
        });
        setActiveCustomResourceTemplateForAction(resource_template);
        setShowResourcePropertiesForm({
          ...showResourcePropertiesForm,
          show: false,
        });
        setShowCustomResourceDrawer(true);
        break;
    }
  };

  const getResourceOverrideMappings = useCallback(
    async (topologyId: string) => {
      const resourceOverrideMappings = await fetchResourceTypeMappings?.(
        topologyId
      );

      if (
        resourceOverrideMappings &&
        Object.keys(resourceOverrideMappings).length > 0
      ) {
        setResourceTypeMappings(resourceOverrideMappings);
      }
    },
    [fetchResourceTypeMappings]
  );

  const deleteCustomResource = async (resource: SupportedResourceType) => {
    const isSuccessResponse = await deleteCustomResourceTemplate({
      uuid: resource.templateId as string,
    });
    if (isSuccessResponse) {
      setActiveCustomResourceForAction({});
      refetchResourcesList();
    }
  };

  // Create list of all the resources which are restricted for this topology
  useEffect(() => {
    const restrictedResources: { [key: string]: SupportedResourceType } = {};

    for (const provider in ResourceProvidersMap) {
      const providerResources = resourcesList?.[
        provider as keyof ProviderWiseSupportedResourceType
      ]
        ? resourcesList[provider as keyof ProviderWiseSupportedResourceType] ??
          []
        : [];
      for (const resource of providerResources) {
        if (resource.isRestricted && resource.resourceType) {
          restrictedResources[resource.resourceType] = resource;
        }
      }
    }

    setRestrictedResources(restrictedResources);
  }, [resourcesList]);

  useEffect(() => {
    // Show missing attributes drawer if there are errors and user comes from quick appStack create flow
    if (
      topologyErrors &&
      topologyErrors?.totalErrors > 0 &&
      topologyPageMeta?.referrer === TopologyPageReferrer.QUICK_APPSTACK_CREATE
    ) {
      setShowMissingAttributesDrawer(true);
      setTopologyPageMeta(null);
    }
  }, [topologyErrors?.totalErrors, topologyPageMeta]);

  useEffect(() => {
    if (graphData.resources && graphData.resources?.length > 0) {
      const temp_graph_data = processGraphData(
        graphData,
        selected_node,
        hideUnSupportedResources,
        unsupportedResourceTypes,
        showTopologyDiff,
        topologyErrors,
        group_resources_expanded,
        updateGroupResourcesExpanded,
        onEdgeConnectionTypeClick,
        setUnsupportedResourcesExists,
        restrictedResources,
        setRestrictedResourcesInTopology
      );
      graphData = {
        ...graphData,
        resources: temp_graph_data.resources,
        resourceConnections: Object.values(temp_graph_data.resourceConnections),
      };
      setProcessedGraphData(temp_graph_data);
      getELKGraphPositions(graphData)
        .then(({ layoutedNodes, layoutedEdges }: any) => {
          setResources(layoutedNodes);
          setResourceConnections(layoutedEdges);

          if (!showTopologyDiff) {
            // Check which resource doesn't have position in data object
            const resources_without_position = layoutedNodes?.filter(
              (node: Node) =>
                node?.data?.position?.x === undefined ||
                node?.data?.position?.y === undefined
            );
            if (resources_without_position.length > 0) {
              const resourceIdWithPosition = resources_without_position.map(
                (node: Node) => {
                  return {
                    resourceId: node.id,
                    position: {
                      x: Math.floor(node.position.x),
                      y: Math.floor(node.position.y),
                    },
                  };
                }
              );
              updateBulkResourcePosition?.(
                graphData.id as string,
                resourceIdWithPosition
              );
            }
          }
        })
        .catch(console.error);
    } else {
      setResources([]);
      setResourceConnections([]);
    }
  }, [
    group_resources_expanded,
    selected_node,
    graphData,
    updateGroupResourcesExpanded,
    setResources,
    setResourceConnections,
    topologyErrors,
    showTopologyDiff,
    hideUnSupportedResources,
    restrictedResources,
  ]);

  useEffect(() => {
    if (graphData.id) {
      getResourceOverrideMappings(graphData.id);
    }
  }, [graphData.id]);

  if (graphData?.id) {
    return (
      <div className="tw-relative">
        <div className="tw-w-full tw-h-[calc(100vh-11.5rem)]">
          <ReactFlow
            panOnScroll={true}
            fitView={true}
            nodes={resources}
            edges={resourceConnections}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onInit={setReactFlowInstance}
            proOptions={{ hideAttribution: true }}
            nodeDragThreshold={1}
            connectionMode={ConnectionMode.Loose}
            onNodeDragStop={onNodeDragStop}
            onNodesChange={(changes) => {
              if (changes[0].type !== "remove") {
                onResourcesChange(changes);
              }
            }}
            onNodesDelete={onNodesDelete}
            onEdgesDelete={(...props) =>
              onEdgesDelete(...props, selected_edge ? true : false)
            }
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            connectionLineStyle={connectionLineStyle}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={(event: MouseEvent, node: Node) => {
              onNodeClick(node, undefined, event);
            }}
            onEdgeClick={onEdgeClick}
            deleteKeyCode={showTopologyDiff ? null : ["Backspace", "Delete"]}
            snapGrid={[20, 20]}
            snapToGrid={true}
            minZoom={0.1}
            maxZoom={1}
            selectionKeyCode={["Control", "Meta"]}
          >
            <Background
              variant={BackgroundVariant.Lines}
              gap={14}
              lineWidth={0.05}
              className="tw-opacity-70"
            />
            <TopologyToolbar onSave={() => setShowResourcePackDrawer(true)} />
            {restrictedResourcesInTopology &&
            Object.keys(restrictedResourcesInTopology)?.length > 0 ? (
              <Panel position="top-center" className="tw-w-full tw-m-0 tw-p-3">
                <Banner type={BannerTypes.WARNING} dismissible={true}>
                  <div className="tw-text-sm">
                    <span className="tw-text-yellow-300">
                      There are{" "}
                      {Object.keys(restrictedResourcesInTopology)?.length}{" "}
                      resources in this topology that are restricted by your
                      organization. Please replace or delete those resource to
                      export the IaC.
                    </span>
                    <span
                      className="tw-text-blue-500 tw-cursor-pointer"
                      onClick={() => setShowRestrictedResourcesDrawer(true)}
                      data-testid="show-restricted-list-drawer"
                    >
                      {" "}
                      View resources
                    </span>
                  </div>
                </Banner>
              </Panel>
            ) : restrictedResources &&
              Object.keys(restrictedResources)?.length > 0 ? (
              <Panel position="top-center" className="tw-w-full tw-m-0 tw-p-3">
                <Banner type={BannerTypes.INFO} dismissible={true}>
                  <div className="tw-text-sm">
                    <span className="tw-text-blue-300">
                      Your organization has restricted access to certain
                      resources. Please contact the administrator to remove the
                      restriction.
                    </span>
                    <span
                      className="tw-text-blue-500 tw-cursor-pointer"
                      onClick={() => setShowRestrictedResourcesDrawer(true)}
                      data-testid="show-restricted-list-drawer"
                    >
                      {" "}
                      View restricted resources.
                    </span>
                  </div>
                </Banner>
              </Panel>
            ) : null}
            {graphData?.resources?.length === 0 && (
              <>
                <Panel
                  position="top-center"
                  className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-text-gray-400 !tw-top-1/3"
                >
                  <div>
                    <Icon
                      name={IconsList.ARROW_UP_FROM_SQUARE}
                      size={IconSize["3xl"]}
                    />
                  </div>
                  <div className="tw-text-base">
                    Drag & drop resources to create your own IaC
                  </div>
                </Panel>
                {!showTopologyDiff && enabledFeatures?.llm && (
                  <Panel position="top-left">
                    <LLMPrompt
                      appStackUUID={graphData?.appstackId as string}
                      onSuccess={(successData) => {
                        if (successData?.appstack_uuid) {
                          refetchTopologyGraphData?.();
                        }
                      }}
                    />
                  </Panel>
                )}
              </>
            )}
            {showTopologyDiff && (
              <Panel position="top-center" className="tw-w-full tw-m-0 tw-p-2">
                <CompareTopology
                  appStackVersions={appStackVersions}
                  graphData={graphData}
                  getTopologyDiff={getTopologyDiff}
                  setShowTopologyDiff={setShowTopologyDiff}
                />
              </Panel>
            )}
            {!showTopologyDiff && (
              <Panel position="bottom-left">
                <div className="tw-flex tw-gap-3">
                  {resourcesList && Object.keys(resourcesList)?.length > 0 && (
                    <div
                      className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700"
                      onClick={() => setShowResourcesList(true)}
                      data-testid="supported-resources-list"
                    >
                      <Icon
                        name={IconsList.CIRCLE_PLUS}
                        className="tw-text-gray-200"
                      />
                      <span>Add New Resource</span>
                    </div>
                  )}
                  {!topologyErrors?.isValidated ? (
                    <div className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700">
                      <Spinner
                        className="tw-h-5 tw-w-5"
                        dataTestId="validating-topology-loader"
                      />
                      <span>Validating the topology...</span>
                    </div>
                  ) : (
                    <>
                      <div data-testid="topology-actions">
                        {topologyErrors?.totalValidationErrors ? (
                          <div
                            className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700"
                            onClick={() => {
                              setTopologyErrorDrawer({
                                show: true,
                                resourceId: "",
                                type: TopologyErrorDrawerTypeEnum.ACTION,
                              });
                            }}
                          >
                            <Icon
                              name={IconsList.TRIANGLE_EXCLAMATION}
                              className="tw-text-red-500"
                            />
                            <div className="tw-flex tw-gap-2">
                              <span>Actions</span>
                              <Tag
                                title={`${topologyErrors?.totalValidationErrors}`}
                                size="md"
                                className=" tw-text-white tw-font-thin"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700">
                            <Icon name={IconsList.TRIANGLE_EXCLAMATION} />
                            <span>No Actions</span>
                          </div>
                        )}
                      </div>
                      <div data-testid="topology-warnings">
                        {topologyErrors?.totalViolationErrors ? (
                          <div
                            className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700"
                            onClick={() => {
                              setTopologyErrorDrawer({
                                show: true,
                                resourceId: "",
                                type: TopologyErrorDrawerTypeEnum.WARNING,
                              });
                            }}
                            onKeyUp={() => {
                              setTopologyErrorDrawer({
                                show: true,
                                resourceId: "",
                                type: TopologyErrorDrawerTypeEnum.WARNING,
                              });
                            }}
                          >
                            <Icon
                              name={IconsList.SHIELD_EXCLAMATION}
                              className="tw-text-yellow-500"
                            />
                            <div className="tw-flex tw-gap-2">
                              <span>Warnings</span>
                              <Tag
                                title={`${topologyErrors?.totalViolationErrors}`}
                                size="md"
                                className="tw-font-thin tw-text-white"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="tw-flex tw-gap-2 tw-items-center tw-bg-gray-800 tw-text-xssm tw-text-gray-200 tw-rounded-md tw-border tw-border-gray-600 tw-p-2 tw-cursor-pointer hover:tw-bg-gray-700">
                            <Icon name={IconsList.SHIELD_EXCLAMATION} />
                            <span>No Warnings</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  {unsupportedResourcesExists && (
                    <>
                      <div className="tw-border-2 tw-border-gray-600"></div>
                      <div
                        className="tw-flex tw-gap-3 tw-items-center tw-text-sm tw-font-normal tw-text-white tw-py-2 tw-cursor-pointer"
                        onClick={() =>
                          setHideUnSupportedResources((state) => !state)
                        }
                        data-testid="unsupported-resources-toggle"
                      >
                        {hideUnSupportedResources
                          ? "View unsupported resources"
                          : "Hide unsupported resources"}
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            )}
            <Panel position="bottom-right">
              <div className="tw-flex tw-items-center tw-border tw-border-gray-600 tw-rounded-md tw-bg-gray-800 tw-cursor-pointer">
                <div
                  className="tw-border-r tw-border-gray-600 tw-p-2 hover:tw-bg-gray-700 tw-text-gray-200"
                  onClick={() => fitView({ duration: 800 })}
                >
                  <Icon name={IconsList.EXPAND} />
                </div>
                <div
                  className={`tw-border-r tw-border-gray-600 tw-p-2 hover:tw-bg-gray-700 tw-text-gray-200 ${
                    isMinZoomLevel ? "tw-opacity-50 tw-cursor-not-allowed" : ""
                  }`}
                  onClick={() => zoomOut({ duration: 800 })}
                  data-testid="zoom-out"
                >
                  <Icon name={IconsList.MINUS} />
                </div>
                <div
                  className="tw-border-r tw-border-gray-600 tw-py-2 tw-px-3 hover:tw-bg-gray-700 tw-text-xssm tw-text-gray-300"
                  onClick={handleTransform}
                  data-testid="reset-zoom"
                >
                  {Math.round(currentZoomLevel * 100)}%
                </div>
                <div
                  className={`tw-border-r tw-border-gray-600 tw-p-2 hover:tw-bg-gray-700 tw-text-gray-200 ${
                    isMaxZoomLevel ? "tw-opacity-50 tw-cursor-not-allowed" : ""
                  }`}
                  onClick={() => zoomIn({ duration: 800 })}
                  data-testid="zoom-in"
                >
                  <Icon name={IconsList.PLUS_LARGE} />
                </div>
              </div>
            </Panel>
          </ReactFlow>
        </div>

        {!showTopologyDiff &&
          resourcesList &&
          Object.keys(resourcesList)?.length > 0 && (
            <Drawer
              open={showResourcesList ? true : false}
              size="xs"
              isFullScreenDrawer={false}
              position="Left"
              dataTestId="supported-resource-list"
            >
              <SupportedResourceList
                resourcesList={resourcesList}
                onResourceTabChange={(
                  resourceType: keyof ProviderWiseSupportedResourceType
                ) => setActiveResourceTab(resourceType)}
                activeResourceTab={activeResourceTab}
                onPanelClose={() => setShowResourcesList(false)}
                onDragStart={onDragStart}
                cloudService={graphData.deploymentType as string}
                onAddCustomResource={() => {
                  setShowCustomResourceDrawer(true);
                  setShowResourcePropertiesForm({
                    ...showResourcePropertiesForm,
                    show: false,
                  });
                }}
                onEditResourcePack={() => {
                  setShowResourcePackDrawer(true);
                }}
                onAddCustomHelmResource={() => {
                  setShowCustomHelmResourceDrawer(true);
                  setShowResourcePropertiesForm({
                    ...showResourcePropertiesForm,
                    show: false,
                  });
                }}
                onActiveCustomResourceAction={(resource, action) =>
                  handleActiveCustomResourceAction(resource, action)
                }
                onAddResourcePack={onAddResourcePack}
                refetchResourcesList={refetchResourcesList}
                restrictedResources={restrictedResources}
                setShowRestrictedResourcesDrawer={
                  setShowRestrictedResourcesDrawer
                }
              />
            </Drawer>
          )}

        {!showTopologyDiff &&
          restrictedResources &&
          Object.keys(restrictedResources)?.length > 0 && (
            <Drawer
              open={showRestrictedResourcesDrawer ? true : false}
              size="sm"
              isFullScreenDrawer={false}
              position="Right"
              dataTestId="restricted-resource-list"
            >
              <RestrictedResourcesDrawer
                restrictedResources={restrictedResources}
                restrictedResourcesInTopology={restrictedResourcesInTopology}
                onPanelClose={() => setShowRestrictedResourcesDrawer(false)}
              />
            </Drawer>
          )}

        {topologyErrorDrawer.show && (
          <TopologyErrorDrawer
            resourceId={topologyErrorDrawer.resourceId}
            onClose={() => {
              setTopologyErrorDrawer({
                show: false,
                resourceId: "",
                type: TopologyErrorDrawerTypeEnum.ACTION,
              });
            }}
            topologyErrors={topologyErrors}
            graphDataResources={graphData.resources}
            onNodeClick={onNodeClick}
            showMissingDrawer={() => setShowMissingAttributesDrawer(true)}
            type={topologyErrorDrawer.type}
          />
        )}

        {!showTopologyDiff && (
          <Drawer
            open={
              showResourcePropertiesForm.show &&
              selected_node &&
              resourcePropertiesTemplates[selected_node?.resourceType]
                ? true
                : false
            }
            size="sm"
            isFullScreenDrawer={false}
            isResizable={true}
            minResizableWidth={300}
            maxResizableWidth={600}
            dataTestId="resource-properties-drawer"
          >
            {showResourcePropertiesForm.show &&
              selected_node &&
              resourcePropertiesTemplates[selected_node?.resourceType] && (
                <ResourcePropertiesForm
                  resourcePropertiesTemplate={
                    resourcePropertiesTemplates[selected_node?.resourceType]
                  }
                  resource={selected_node}
                  topologyId={graphData.id}
                  onUpdateResourcePropertiesValues={async (resource) => {
                    if (graphData.id && resource.id) {
                      let updated_properties: any = resource?.connectionType
                        ? await updateResourceConnectionPropertiesValues(
                            graphData.id,
                            resource.id,
                            {
                              _configuration: resource._configuration,
                              tfVars: resource.tfVars,
                            }
                          )
                        : await updateResourcePropertiesValues(
                            graphData.id,
                            resource.id,
                            resource
                          );

                      if (resource?.connectionType) {
                        updated_properties = {
                          ...updated_properties?.data,
                          id: updated_properties?.id,
                        };
                      }

                      if (
                        validateTopology &&
                        isFunction(validateTopology) &&
                        updated_properties
                      ) {
                        validateTopology(graphData.id, [updated_properties]);
                      }
                      setSelectedNode((prev) => {
                        if (
                          prev &&
                          updated_properties &&
                          prev.id === updated_properties.id
                        ) {
                          return updated_properties;
                        } else {
                          return prev;
                        }
                      });
                      return updated_properties;
                    }
                  }}
                  onPanelClose={() => {
                    setShowResourcePropertiesForm({
                      ...defaultShowResourcePropertiesForm,
                      show: false,
                    });
                    setSelectedNode(null);
                  }}
                  defaultSelectedTabId={showResourcePropertiesForm.defaultTabId}
                  resourceHasErrors={
                    showResourcePropertiesForm.showValidationErrors ||
                    topologyErrors?.resourcesWithErrors[
                      selected_node?.id as string
                    ]?.validationErrors
                      ? true
                      : false
                  }
                  showResourcePropertiesErrors={
                    showResourcePropertiesForm.showValidationErrors
                  }
                  onEditCustomResource={async (fetchTemplate?: boolean) => {
                    if (fetchTemplate) {
                      handleActiveCustomResourceAction(selected_node, "edit");
                    } else {
                      const resourceTemplate = {
                        provider: provider,
                        iacType: "Terraform", // TODO: Remove this hardcoded data once we start collecting it from UI
                        label: "",
                        resourceId: selected_node?.id,
                        resourceType: (selected_node?._configuration as any)
                          ?.type,
                        variables: (selected_node?._configuration as any)
                          ?.attributes
                          ? Object.keys(
                              (selected_node?._configuration as any)?.attributes
                            )?.map((attribute) => ({
                              name: attribute,
                            }))
                          : [],
                      };
                      setActiveCustomResourceTemplateForAction(
                        resourceTemplate
                      );
                      setShowCustomResourceDrawer(true);
                      setShowResourcePropertiesForm({
                        ...showResourcePropertiesForm,
                        show: false,
                      });
                    }
                  }}
                  markResourceAsExternal={async (resourceId: string) => {
                    let external_resource: any = await markResourceAsExternal(
                      resourceId
                    );

                    if (graphData.id && external_resource) {
                      if (
                        validateTopology &&
                        isFunction(validateTopology) &&
                        external_resource
                      ) {
                        validateTopology(graphData.id, [external_resource]);
                      }
                      setSelectedNode((prev) => {
                        if (
                          prev &&
                          external_resource &&
                          prev.id === external_resource.id
                        ) {
                          return external_resource;
                        } else {
                          return prev;
                        }
                      });

                      return external_resource;
                    } else {
                      return null;
                    }
                  }}
                  onShowResourceError={(type) => {
                    setTopologyErrorDrawer({
                      show: true,
                      resourceId: selected_node?.id ?? "",
                      type,
                    });
                  }}
                />
              )}
          </Drawer>
        )}

        {!showTopologyDiff && deleteResourceAndConnectionDrawer.show && (
          <Drawer
            open={deleteResourceAndConnectionDrawer.show}
            size="sm"
            isFullScreenDrawer={false}
            position="Right"
            dataTestId="delete-resource-connection-confirmation-drawer"
          >
            <DeleteResourceAndConnectionConfirmation
              onPanelClose={() =>
                setDeleteResourceAndConnectionDrawer({
                  show: false,
                  data: null,
                })
              }
              data={deleteResourceAndConnectionDrawer.data}
            />
          </Drawer>
        )}

        {showCustomResourceDrawer && (
          <CustomResourceDrawer
            open={showCustomResourceDrawer}
            customResourceToEdit={activeCustomResourceTemplateForAction}
            onClose={(shouldRefreshResourceList?: boolean) => {
              if (shouldRefreshResourceList) {
                refetchResourcesList(); // refresh resource list to show newly added resource in custom resource list
                setSelectedNode(null); // reset node selection
                // Refetch topology after custom resource edit/addition to reflect changes
                if (isFunction(refetchTopologyGraphData)) {
                  refetchTopologyGraphData?.();
                }
                const resourceType =
                  activeCustomResourceTemplateForAction?.resourceType as string;
                const resourceTemplate =
                  resourcePropertiesTemplates[resourceType];

                if (activeCustomResourceTemplateForAction?.id) {
                  // fetch properties template for newly added custom resource
                  fetchResourcePropertiesTemplate?.({
                    resourceType,
                    templateId: activeCustomResourceTemplateForAction?.id,
                  });
                }
              }
              setShowCustomResourceDrawer(false);
              setActiveCustomResourceTemplateForAction(null);
            }}
            provider={provider}
          />
        )}

        <GlobalResourceTagsDrawer
          open={showGlobalResourceTagsDrawer}
          initialTags={globalResourceTags}
          onClose={() => setShowGlobalResourceTagsDrawer(false)}
          onSubmit={onSubmitGlobalResourceTags}
        />

        {showCustomResourceDeleteDialog && (
          <Dialog
            title="Delete Token"
            bodyContent={
              <>
                <div className="tw-text-sm tw-text-gray-50">
                  Are you sure you want to delete this custom resource?
                </div>
              </>
            }
            size="sm"
            footerContent={
              <div className="tw-flex tw-justify-end tw-gap-3">
                <Button
                  variant="secondary"
                  label="Cancel"
                  onClick={() => {
                    setShowCustomResourceDeleteDialog(false);
                    setActiveCustomResourceForAction({});
                  }}
                  dataTestId="cancel-delete-custom-resource-button"
                />
                <Button
                  variant="danger"
                  label={"Remove"}
                  onClick={() => {
                    deleteCustomResource(activeCustomResourceForAction);
                    setShowCustomResourceDeleteDialog(false);
                  }}
                  dataTestId="delete-custom-resource-button"
                />
              </div>
            }
            dataTestId="delete-custom-resource-dialog"
          />
        )}

        {showConfigureTFVarsDialog && (
          <ConfigureTFVarsDialog
            resources={graphData.resources as TopologyResourceWithPosition[]}
            resourcesWithErrors={
              topologyErrors?.resourcesWithErrors as {
                [key: string]: ResourceErrors;
              }
            }
            topologyId={graphData.id}
            resourcePropertiesTemplates={resourcePropertiesTemplates}
            onClose={() => {
              setShowConfigureTFVarsDialog(false);
            }}
            onSubmit={() => {
              setShowConfigureTFVarsDialog(false);
              refetchTopologyGraphData?.();
            }}
          />
        )}

        {resourceTypeMappings && resourcesList && (
          <ResourceOverrideDialog
            resourceTypeMappings={resourceTypeMappings}
            updateResourceTypeMappings={async (mappingData) => {
              if (graphData.id) {
                await updateResourceTypeMappings(graphData.id, mappingData);
                refetchTopologyGraphData?.();
              }
            }}
            supportedResourceTypes={resourcesList}
            onClose={() => setResourceTypeMappings(null)}
          />
        )}

        <FixMissingAttributesDrawer
          open={showMissingAttributesDrawer}
          resources={
            graphData.resources as TopologyResourceWithMisconfigurations[]
          }
          resourcesWithErrors={
            topologyErrors?.resourcesWithErrors as {
              [key: string]: ResourceErrors;
            }
          }
          resourcePropertiesTemplates={resourcePropertiesTemplates}
          onClose={() => {
            setShowMissingAttributesDrawer(false);
          }}
          openTFVarsDialog={() => {
            setShowConfigureTFVarsDialog(true);
            setShowMissingAttributesDrawer(false);
          }}
          topologyId={graphData.id}
          onSubmit={() => {
            setShowMissingAttributesDrawer(false);
            refetchTopologyGraphData?.();
          }}
        />

        <CustomHelmResourceDrawer
          open={showCustomHelmResourceDrawer}
          onClose={(shouldRefreshResourceList?: boolean) => {
            if (shouldRefreshResourceList) {
              refetchResourcesList();
              setSelectedNode(null);
            }
            setShowCustomHelmResourceDrawer(false);
            setActiveCustomResourceTemplateForAction(null);
          }}
        />
        {showResourcePackDrawer && (
          <ResourcePackDrawer
            onClose={(shouldRefreshResourceList?: boolean) => {
              if (shouldRefreshResourceList) {
                refetchResourcesList();
                setSelectedNode(null);
              }
              setShowResourcePackDrawer(false);
            }}
            targetCompute={graphData?.deploymentType as TargetCompute}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="tw-text-sm" data-testid="no-topology-found-text">
        No topology data found.
      </div>
    );
  }
};

const TopologyGraphWrapper = ({ ...props }: TopologyGraphProps) => {
  return (
    <ReactFlowProvider>
      <TopologyGraph {...props} />
    </ReactFlowProvider>
  );
};

export default memo(TopologyGraphWrapper);
