/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';
import type { ResourceIdWithPosition } from './ResourceIdWithPosition';
import {
    ResourceIdWithPositionFromJSON,
    ResourceIdWithPositionFromJSONTyped,
    ResourceIdWithPositionToJSON,
} from './ResourceIdWithPosition';

/**
 * 
 * @export
 * @interface CreateGroupResourceRequest
 */
export interface CreateGroupResourceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupResourceRequest
     */
    resourceType?: string;
    /**
     * node label, e.g. "example-s3-bucket", "notifications-queue", "example-workload"
     * @type {string}
     * @memberof CreateGroupResourceRequest
     */
    label?: string;
    /**
     * resource type specific label, e.g. "S3 Bucket", "SQS", "Workload"
     * @type {string}
     * @memberof CreateGroupResourceRequest
     */
    resourceTypeLabel?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateGroupResourceRequest
     */
    isGroup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupResourceRequest
     */
    groupType?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof CreateGroupResourceRequest
     */
    position?: Coordinates;
    /**
     * 
     * @type {Array<ResourceIdWithPosition>}
     * @memberof CreateGroupResourceRequest
     */
    groupResources?: Array<ResourceIdWithPosition>;
}

/**
 * Check if a given object implements the CreateGroupResourceRequest interface.
 */
export function instanceOfCreateGroupResourceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateGroupResourceRequestFromJSON(json: any): CreateGroupResourceRequest {
    return CreateGroupResourceRequestFromJSONTyped(json, false);
}

export function CreateGroupResourceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateGroupResourceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'resourceTypeLabel': !exists(json, 'resourceTypeLabel') ? undefined : json['resourceTypeLabel'],
        'isGroup': !exists(json, 'isGroup') ? undefined : json['isGroup'],
        'groupType': !exists(json, 'groupType') ? undefined : json['groupType'],
        'position': !exists(json, 'position') ? undefined : CoordinatesFromJSON(json['position']),
        'groupResources': !exists(json, 'groupResources') ? undefined : ((json['groupResources'] as Array<any>).map(ResourceIdWithPositionFromJSON)),
    };
}

export function CreateGroupResourceRequestToJSON(value?: CreateGroupResourceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceType': value.resourceType,
        'label': value.label,
        'resourceTypeLabel': value.resourceTypeLabel,
        'isGroup': value.isGroup,
        'groupType': value.groupType,
        'position': CoordinatesToJSON(value.position),
        'groupResources': value.groupResources === undefined ? undefined : ((value.groupResources as Array<any>).map(ResourceIdWithPositionToJSON)),
    };
}

