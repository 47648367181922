/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ValidScopes = {
    OnboardRepositoriesV2: 'onboardRepositoriesV2',
    GetGovernance: 'getGovernance',
    DeleteGovernance: 'deleteGovernance',
    CreateGovernance: 'createGovernance',
    GetGovernanceVersion: 'getGovernanceVersion',
    CreateGovernanceVersion: 'createGovernanceVersion',
    DeleteGovernanceVersion: 'deleteGovernanceVersion',
    GetGovernanceAssignments: 'getGovernanceAssignments',
    UpdateGovernanceAssignments: 'updateGovernanceAssignments',
    DeleteGovernanceAssignment: 'deleteGovernanceAssignment',
    GetApplicableGovernanceVersion: 'getApplicableGovernanceVersion',
    CreateAppStacks: 'createAppStacks',
    UpdateResources: 'updateResources',
    CreateGroupResources: 'createGroupResources',
    CreateResource: 'createResource',
    UpdateResourcePosition: 'updateResourcePosition',
    UpdateGlobalResourceTags: 'updateGlobalResourceTags',
    EditAwsPermissionBundles: 'editAwsPermissionBundles',
    ConvertToExternalResource: 'convertToExternalResource',
    ImportTfstate: 'importTfstate',
    ImportTopology: 'importTopology',
    CreateTopology: 'createTopology',
    EditResourceConnection: 'editResourceConnection',
    UpdateCustomTemplate: 'updateCustomTemplate',
    SaveCustomResourceTemplateForResourceType: 'saveCustomResourceTemplateForResourceType',
    ListUser: 'listUser',
    UpdateUserRole: 'updateUserRole',
    UploadResourceRestrictions: 'uploadResourceRestrictions',
    ImportPolicies: 'importPolicies',
    ImportResourceOverrideMappingPolicies: 'importResourceOverrideMappingPolicies',
    EditOrgRoles: 'editOrgRoles',
    CreateOrg: 'createOrg',
    DeleteOrg: 'deleteOrg',
    ListOrg: 'listOrg',
    ImportAwsPermissionBundles: 'importAwsPermissionBundles',
    ImportResourceIamRestrictionPolicies: 'importResourceIAMRestrictionPolicies'
} as const;
export type ValidScopes = typeof ValidScopes[keyof typeof ValidScopes];


export function ValidScopesFromJSON(json: any): ValidScopes {
    return ValidScopesFromJSONTyped(json, false);
}

export function ValidScopesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ValidScopes {
    return json as ValidScopes;
}

export function ValidScopesToJSON(value?: ValidScopes | null): any {
    return value as any;
}

