import React, { FC } from "react";
import { FieldArray } from "formik";

import {
  IconsList,
  Icon,
  SelectBox,
  TextBox,
  IconButton,
} from "../../../../components";
import {
  CommonFieldArrayComponentProps,
  ConditionalInputComponentProps,
} from "./custom-resource-types";
import {
  CustomResourceDataType,
  booleanOptions,
} from "../../../../utils/custom-resource-handler";

const ConditionalInputComponent: FC<ConditionalInputComponentProps> = ({
  type,
  name,
  booleanOptions,
  label = "",
}) => {
  if (
    [
      CustomResourceDataType.LIST_BOOLEAN,
      CustomResourceDataType.SET_BOOLEAN,
      CustomResourceDataType.MAP_BOOLEAN,
    ].includes(type)
  ) {
    return (
      <SelectBox
        name={name}
        options={booleanOptions}
        labelVariant="gray200-medium"
        dataTestId={`default-value-input-${name}`}
        backgroundVariant="gray-800"
        label={label}
      />
    );
  } else {
    return (
      <TextBox
        name={name}
        placeholder={
          [
            CustomResourceDataType.LIST_STRING,
            CustomResourceDataType.SET_STRING,
            CustomResourceDataType.MAP_STRING,
          ].includes(type)
            ? "e.g. foo.com"
            : "e.g. 21"
        } // TODO: Add relevant placeholder in example
        type={
          [
            CustomResourceDataType.LIST_STRING,
            CustomResourceDataType.SET_STRING,
            CustomResourceDataType.MAP_STRING,
          ].includes(type)
            ? "text"
            : "number"
        }
        labelVariant="gray200-medium"
        dataTestId={`default-value-input-${name}`}
        backgroundVariant="gray-800"
        label={label}
      />
    );
  }
};

const CommonFieldArrayComponent: FC<CommonFieldArrayComponentProps> = ({
  type,
  varIndex,
  values,
  isMap,
}) => {
  return (
    <div className="tw-w-full tw-border tw-border-gray-600 tw-rounded-md tw-p-4">
      <p className="tw-text-xssm tw-font-semibold tw-text-white tw-mb-2">
        Default value <span className="tw-font-normal">(Optional)</span>
      </p>
      <FieldArray
        name={`variables.[${varIndex}].defaultVal`}
        render={(arrayHelper: any) => (
          <>
            {values?.variables?.[varIndex]?.defaultVal?.length > 0 &&
              values.variables[varIndex]?.defaultVal?.map(
                (_: any, i: number) => (
                  <div
                    className="tw-flex tw-gap-2 tw-items-start tw-mb-2"
                    key={`default_${varIndex}_${i}`}
                  >
                    {isMap && (
                      <TextBox
                        name={`variables.[${varIndex}].defaultVal.[${i}].key`}
                        placeholder={`e.g. key `}
                        label="Key"
                        backgroundVariant="gray-800"
                        labelVariant="gray200-medium"
                        dataTestId={`default-value-key-input-${varIndex}-${i}`}
                      />
                    )}
                    <div className="tw-flex-1">
                      <ConditionalInputComponent
                        type={type}
                        name={`variables.[${varIndex}].defaultVal.[${i}]${
                          isMap ? ".value" : ""
                        }`}
                        label={isMap ? "Value" : undefined}
                        booleanOptions={booleanOptions}
                      />
                    </div>
                    {values.variables[varIndex].defaultVal?.length > 1 && (
                      <div className={isMap ? "tw-mt-5.5" : ""}>
                        <IconButton
                          onClick={() => arrayHelper.remove(i)}
                          variant="secondary"
                          icon={IconsList.DELETE}
                          outlined
                          dataTestId={`delete-default-value-${varIndex}-${i}`}
                        />
                      </div>
                    )}
                  </div>
                )
              )}

            <p
              className="tw-mt-2 tw-text-blue-500 tw-flex tw-items-center tw-gap-1.5 tw-cursor-pointer tw-w-fit"
              onClick={() =>
                arrayHelper.push(isMap ? { key: "", value: "" } : "")
              }
              data-testid={`add-more-values-${varIndex}`}
            >
              <Icon name={IconsList.ADD} />
              <span className="tw-text-sm tw-font-semibold">
                Add More Values
              </span>
            </p>
          </>
        )}
      />
    </div>
  );
};

export default CommonFieldArrayComponent;
