import React, { type FC, Fragment, useState, useMemo } from "react";

import { Icon, IconSize, IconsList } from "../icons";
import { DOTS, getPaginationRange } from "./paginationRange";
import Dropdown from "../dropdown/dropdown";
import { paginationRecordsPerPageList } from "../../config/constants";
import { isFunction } from "../../utils";
import { type PaginationProps } from "./pagination-types";

/**
 * Renders a pagination component with options to navigate through pages and change the number of rows per page.
 *
 * - @param {PaginationProps} props - The props object containing the configuration for the pagination component.
 * - @param {number} props.currentPage - The current page number.
 * - @param {number} props.totalRecordCount - The total number of records.
 * - @param {number} props.recordPerPage - The number of records per page.
 * - @param {number} props.siblingCount - The number of sibling pages to show in the pagination.
 * - @param {boolean} props.showRowPerPage - Whether to show the "Rows Per Page" dropdown.
 * - @param {boolean} props.showGoToPage - Whether to show the "Go to Page" dropdown.
 * - @param {function} props.onPageChange - The callback function to be called when the page is changed.
 * - @param {function} props.onRowPerPageChange - The callback function to be called when the number of rows per page is changed.
 * - @param {function} props.onGoToPageChange - The callback function to be called when the "Go to Page" value is changed.
 * - @param {string} props.dataTestId - The data-testid of the pagination component.
 * - @returns {JSX.Element} - The pagination component.
 */
const Pagination: FC<PaginationProps> = ({
  currentPage = 1,
  totalRecordCount = 100,
  recordPerPage = 10,
  siblingCount = 1,
  showRowPerPage = true,
  showGoToPage = true,
  dataTestId,
  onPageChange,
  onRowPerPageChange,
  onGoToPageChange,
}) => {
  const [currentActivePage, setCurrentActivePage] =
    useState<number>(currentPage);
  const [activeRecordPerPageValue, setActiveRecordPerPageValue] =
    useState<number>(recordPerPage);

  // Calculate pagination range(ex. [1,2,'..',4,5]) to show pages in the pagination
  const paginationDisplayRange = useMemo(() => {
    return getPaginationRange({
      currentPage: currentActivePage,
      totalRecordCount,
      siblingCount,
      recordPerPage: activeRecordPerPageValue,
    });
  }, [
    currentActivePage,
    totalRecordCount,
    siblingCount,
    activeRecordPerPageValue,
  ]);

  if (currentActivePage < 1) {
    setCurrentActivePage(1);
  }

  // Calculate total number of pages
  const totalPages = Math.ceil(totalRecordCount / activeRecordPerPageValue);

  // Callback function for previous page button click
  const onPrevious = () => {
    const newActivePage = currentActivePage <= 1 ? 1 : currentActivePage - 1;
    setCurrentActivePage(newActivePage);
    isFunction(onPageChange) && onPageChange?.(newActivePage);
  };

  // Callback function for next page button click
  const onNext = () => {
    const newActivePage =
      currentActivePage >= totalPages
        ? currentActivePage
        : currentActivePage + 1;
    setCurrentActivePage(newActivePage);
    isFunction(onPageChange) && onPageChange?.(newActivePage);
  };

  const updateRowsPerPage = (option: any) => {
    setCurrentActivePage(1);
    setActiveRecordPerPageValue(option.value);
    isFunction(onRowPerPageChange) && onRowPerPageChange?.(option.value);
  };

  const updateGotoPage = (option: any) => {
    setCurrentActivePage(option.value);
    isFunction(onGoToPageChange) && onGoToPageChange?.(option.value);
  };

  const updateActivePage = (pageRangeNumber: number) => {
    setCurrentActivePage(pageRangeNumber);
    isFunction(onPageChange) && onPageChange?.(pageRangeNumber);
  };

  // Generate options for "Rows Per Page" dropdown
  const recordPerPageOptions = paginationRecordsPerPageList.map((item) => ({
    value: item,
    label: item,
  }));

  // Generate options for "Go to Page" dropdown
  const goToPageOptionList = Array(totalPages)
    .fill("")
    .map((_, index) => ({
      value: index + 1,
      label: index + 1,
    }));

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-py-1 tw-px-4 tw-text-xs tw-text-gray-300 tw-bg-gray-700 tw-border-t tw-border-gray-600 tw-gap-4">
      <div className="tw-flex tw-items-center tw-gap-2">
        {showRowPerPage && (
          <span className="tw-text-xs tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
            Rows Per Page:
            <Dropdown
              options={recordPerPageOptions}
              onSelectionChange={updateRowsPerPage}
              selectedValue={activeRecordPerPageValue}
              dataTestId={`${dataTestId}-pagination-rows-per-page-dropdown`}
            />
          </span>
        )}
      </div>
      <div className="tw-flex tw-items-center tw-gap-4">
        <ul className="tw-flex tw-items-center tw-gap-1">
          <li className="tw-cursor-pointer">
            <button onClick={onPrevious} type="button">
              <Icon
                name={IconsList.CARET_LEFT}
                size={IconSize.md}
                className="hover:tw-text-gray-200"
                dataTestId={`${dataTestId}-pagination-previous-button`}
              />
            </button>
          </li>
          {paginationDisplayRange?.map(
            (pageRangeNumber: string | number, index) => (
              <Fragment key={`page_${index}`}>
                {pageRangeNumber === DOTS ? (
                  <li className="tw-text-xssm tw-py-2 tw-px-3 tw-rounded-md">
                    &#8230;
                  </li>
                ) : (
                  <li
                    onClick={() => updateActivePage(pageRangeNumber as number)}
                    className={`tw-text-xssm tw-cursor-pointer tw-rounded-md tw-py-2 tw-px-3 ${
                      pageRangeNumber === currentActivePage
                        ? "tw-text-white  tw-bg-gray-600"
                        : "tw-text-gray-300 hover:tw-bg-gray-600"
                    }`}
                    data-testid={`${dataTestId}-pagination-page-${pageRangeNumber}`}
                  >
                    {pageRangeNumber}
                  </li>
                )}
              </Fragment>
            )
          )}
          <li className="tw-cursor-pointer">
            <button onClick={onNext} type="button">
              <Icon
                name={IconsList.CARET_RIGHT}
                size={IconSize.md}
                className="hover:tw-text-gray-200"
                dataTestId={`${dataTestId}-pagination-next-button`}
              />
            </button>
          </li>
        </ul>
        {showGoToPage && (
          <span className="tw-text-xs tw-text-gray-300 tw-flex tw-items-center tw-gap-2">
            Go to Page:
            <Dropdown
              options={goToPageOptionList}
              onSelectionChange={updateGotoPage}
              selectedValue={currentActivePage}
              dataTestId={`${dataTestId}-go-to-page-dropdown`}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default Pagination;
