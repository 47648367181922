/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopologyResourceConfigValidationValidation
 */
export interface TopologyResourceConfigValidationValidation {
    /**
     * Is the field is required?
     * @type {boolean}
     * @memberof TopologyResourceConfigValidationValidation
     */
    required?: boolean;
    /**
     * Is the field sensitive to show as plaintext?
     * @type {boolean}
     * @memberof TopologyResourceConfigValidationValidation
     */
    sensitive?: boolean;
    /**
     * Data type of the field
     * @type {string}
     * @memberof TopologyResourceConfigValidationValidation
     */
    dataType?: string;
    /**
     * Maximum number of objects in the list
     * @type {number}
     * @memberof TopologyResourceConfigValidationValidation
     */
    maxObjects?: number;
}

/**
 * Check if a given object implements the TopologyResourceConfigValidationValidation interface.
 */
export function instanceOfTopologyResourceConfigValidationValidation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyResourceConfigValidationValidationFromJSON(json: any): TopologyResourceConfigValidationValidation {
    return TopologyResourceConfigValidationValidationFromJSONTyped(json, false);
}

export function TopologyResourceConfigValidationValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceConfigValidationValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'required': !exists(json, 'required') ? undefined : json['required'],
        'sensitive': !exists(json, 'sensitive') ? undefined : json['sensitive'],
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'maxObjects': !exists(json, 'maxObjects') ? undefined : json['maxObjects'],
    };
}

export function TopologyResourceConfigValidationValidationToJSON(value?: TopologyResourceConfigValidationValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'required': value.required,
        'sensitive': value.sensitive,
        'dataType': value.dataType,
        'maxObjects': value.maxObjects,
    };
}

