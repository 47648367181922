/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepositoryInfo
 */
export interface RepositoryInfo {
    /**
     * 
     * @type {string}
     * @memberof RepositoryInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryInfo
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RepositoryInfo
     */
    url: string;
}

/**
 * Check if a given object implements the RepositoryInfo interface.
 */
export function instanceOfRepositoryInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function RepositoryInfoFromJSON(json: any): RepositoryInfo {
    return RepositoryInfoFromJSONTyped(json, false);
}

export function RepositoryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepositoryInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'name': json['name'],
        'url': json['url'],
    };
}

export function RepositoryInfoToJSON(value?: RepositoryInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'provider': value.provider,
        'name': value.name,
        'url': value.url,
    };
}

