/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TemplateType = {
    Atomic: 'atomic',
    Composite: 'composite'
} as const;
export type TemplateType = typeof TemplateType[keyof typeof TemplateType];


export function TemplateTypeFromJSON(json: any): TemplateType {
    return TemplateTypeFromJSONTyped(json, false);
}

export function TemplateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateType {
    return json as TemplateType;
}

export function TemplateTypeToJSON(value?: TemplateType | null): any {
    return value as any;
}

