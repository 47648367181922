import React, { type FC } from "react";

export enum SeverityLevel {
  high = "high",
  medium = "medium",
  low = "low",
}

interface SeverityProps {
  level: SeverityLevel;
}

const Severity: FC<SeverityProps> = ({ level = SeverityLevel.low }) => {
  let fillClassName = "";
  let fillCircle = 0;
  let levelCount = 3;

  switch (level) {
    case SeverityLevel.high:
      fillClassName = "tw-bg-red-500";
      fillCircle = 3;
      break;
    case SeverityLevel.medium:
      fillClassName = "tw-bg-orange-500";
      fillCircle = 2;
      break;
    case SeverityLevel.low:
      fillClassName = "tw-bg-yellow-500";
      fillCircle = 1;
      break;
  }

  const circles = [];

  for (let index = 0; index < levelCount; index++) {
    circles.push(
      <div
        key={index}
        className={`tw-w-2 tw-duration-100 tw-h-2 tw-rounded-full tw-bg-gray-500 ${
          fillCircle > index ? fillClassName : ""
        }`}
      ></div>
    );
  }

  return <div className="tw-flex tw-items-center tw-gap-1">{circles}</div>;
};

export default Severity;
