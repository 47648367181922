import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

import type { Notification } from "../components/toast/toast-types";
import { Crumb } from "../components/breadcrumb/breadcrumb-types";
import type { DialogProps } from "../components/dialog/dialog-types";

import { generateUUIdV4 } from "../utils";
import { Hook } from "../apis/vira";
import { EnvironmentType } from "../config/constants";

const storage = createJSONStorage(() =>
  typeof window !== "undefined"
    ? window.localStorage
    : (undefined as unknown as Storage)
);

// Atoms for Global components or shared components across pages
export const darkModeAtom = atomWithStorage("darkMode", true, storage as any, {
  getOnInit: true,
});
export const breadcrumbsAtom = atom<Crumb[]>([]);
export const notificationsAtom = atom<Notification[]>([]);
export const updateNotificationsAtom = atom(
  null,
  (get, set, notification: Notification) => {
    const notification_id = generateUUIdV4();

    set(notificationsAtom, (prev) => {
      return [
        ...prev,
        {
          ...notification,
          id: notification_id,
          onDismiss: (id) =>
            set(notificationsAtom, (prev) => prev.filter((n) => n.id !== id)),
        },
      ];
    });

    setTimeout(() => {
      set(notificationsAtom, (prev) =>
        prev.filter((n) => n.id !== notification_id)
      );
    }, notification.autoDismissTimer ?? 7000);
  }
);
export const sidebarCollapsedAtom = atom<boolean>(false); // maintains the collapse state of the sidebar
export const sidebarHoveredAtom = atom<boolean>(false); // maintains the hover state of the sidebar

// Show/Hide dialog with data
export const dialogAtom = atom<(DialogProps & { open: boolean }) | null>(null);

export const isNewUserAtom = atomWithStorage("isNewUser", true, storage as any);

// Feature flags
export const exportIaCWithPolicyViolationsAtom = atomWithStorage(
  "enableExportWithViolations",
  false,
  storage as any
);

export const viraActivationHookAtom = atom<{
  hook: Hook | null;
  metadata?: any; // used to pass hooks specific extra information like e.g. topology data
}>({ hook: null });
