import React, { type FC, Fragment } from "react";
import { BreadcrumbProps } from "./breadcrumb-types";
import { Link } from "gatsby";
import { isFunction } from "../../utils";

const Breadcrumb: FC<BreadcrumbProps> = ({ crumbs, separator = "/" }) => {
  if (crumbs?.length > 1) {
    return (
      <span className="tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-medium">
        {crumbs.map((item, index) => {
          const isNotLast = index !== crumbs.length - 1;
          return (
            <Fragment key={item.id}>
              {item?.link ? (
                <Link to={item.link} data-testid={`breadcrumb-link-${item.id}`}>
                  <span className="tw-text-xs hover:tw-underline tw-cursor-pointer tw-duration-500 tw-font-normal">
                    {item?.title}
                  </span>
                </Link>
              ) : item?.onClick && isFunction(item?.onClick) ? ( // for custom action on breadcrumb click
                <span
                  onClick={item?.onClick}
                  className="tw-text-xs hover:tw-underline tw-cursor-pointer tw-duration-500 tw-font-normal"
                  data-testid={`breadcrumb-link-${item.id}`}
                >
                  {item?.title}
                </span>
              ) : (
                <span
                  className="tw-text-xs tw-font-medium"
                  data-testid={`breadcrumb-text-${item.id}`}
                >
                  {item?.title}
                </span>
              )}
              {isNotLast && <span className="tw-text-xs">{separator}</span>}
            </Fragment>
          );
        })}
      </span>
    );
  } else if (crumbs?.length === 1) {
    return (
      <span
        className="tw-text-xs tw-font-medium"
        data-testid={`breadcrumb-text-${crumbs[0]?.id}`}
      >
        {crumbs[0]?.title}
      </span>
    );
  } else {
    return null;
  }
};

export default Breadcrumb;
