/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupportedTfDataType } from './SupportedTfDataType';
import {
    SupportedTfDataTypeFromJSON,
    SupportedTfDataTypeFromJSONTyped,
    SupportedTfDataTypeToJSON,
} from './SupportedTfDataType';

/**
 * 
 * @export
 * @interface SupportedTfDataTypes
 */
export interface SupportedTfDataTypes {
    /**
     * 
     * @type {Array<SupportedTfDataType>}
     * @memberof SupportedTfDataTypes
     */
    primitive?: Array<SupportedTfDataType>;
    /**
     * 
     * @type {Array<SupportedTfDataType>}
     * @memberof SupportedTfDataTypes
     */
    composite?: Array<SupportedTfDataType>;
}

/**
 * Check if a given object implements the SupportedTfDataTypes interface.
 */
export function instanceOfSupportedTfDataTypes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupportedTfDataTypesFromJSON(json: any): SupportedTfDataTypes {
    return SupportedTfDataTypesFromJSONTyped(json, false);
}

export function SupportedTfDataTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedTfDataTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'primitive': !exists(json, 'Primitive') ? undefined : ((json['Primitive'] as Array<any>).map(SupportedTfDataTypeFromJSON)),
        'composite': !exists(json, 'Composite') ? undefined : ((json['Composite'] as Array<any>).map(SupportedTfDataTypeFromJSON)),
    };
}

export function SupportedTfDataTypesToJSON(value?: SupportedTfDataTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Primitive': value.primitive === undefined ? undefined : ((value.primitive as Array<any>).map(SupportedTfDataTypeToJSON)),
        'Composite': value.composite === undefined ? undefined : ((value.composite as Array<any>).map(SupportedTfDataTypeToJSON)),
    };
}

