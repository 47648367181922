import React, { type FC, forwardRef } from "react";
import { Field, type FieldProps } from "formik";

import { FileSelectProps } from "./file-select-types";

const FileSelect = forwardRef<HTMLInputElement, FileSelectProps>(
  (
    {
      name,
      required,
      label,
      className = "",
      onChange,
      isOptional = false,
      validFileExtensions,
      showErrors = true,
    },
    ref
  ) => {
    return (
      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <>
            {label && (
              <label
                htmlFor={name}
                className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-white"
              >
                {label}
                {isOptional && (
                  <span className={`tw-font-normal tw-text-gray-200`}>
                    {" "}
                    (Optional)
                  </span>
                )}
              </label>
            )}
            <input
              {...field}
              type="file"
              required={required ?? false}
              ref={ref}
              className={className}
              onChange={onChange}
              onClick={(e) => {
                (e.target as HTMLInputElement).value =
                  null as unknown as string;
              }}
              accept={
                validFileExtensions?.map((ext) => `.${ext}`).join(", ") ??
                undefined
              }
              value={undefined}
              data-testid={`${name}-file-input`}
            />
            {meta.error && showErrors ? (
              <>
                {field.value && (
                  <div className="tw-mt-2">{field.value.name}</div>
                )}
                <div
                  className="tw-mt-0.5 tw-text-xs tw-text-red-400"
                  data-testid={`${name}-file-select-error`}
                >
                  {meta.error}
                </div>
              </>
            ) : null}
          </>
        )}
      </Field>
    );
  }
);

export default FileSelect;
