import React, { useState } from "react";
import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
} from "../../../../components";
import type { TopologyErrorItemProps } from "./topology-error-types";

const TopologyErrorItem: React.FC<TopologyErrorItemProps> = ({
  resourceType,
  label,
  resourceTypeLabel,
  children,
  id,
  initialCollapsed = false,
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const handleToggle = () => {
    setCollapsed(collapsed => !collapsed);
  };

  return (
    <div>
      <div
        className={
          "tw-flex tw-items-center tw-px-4 tw-py-3 tw-w-full tw-gap-4 tw-bg-gray-500 tw-bg-opacity-20 tw-border-b tw-border-gray-700 tw-cursor-pointer"
        }
        onClick={handleToggle}
        onKeyDown={handleToggle}
        data-testid={`topology-error-item-${id}`}
      >
        <Icon
          className="tw-text-gray-50"
          name={collapsed ? IconsList.CARET_DOWN : IconsList.CARET_UP}
        />
        <div className="tw-flex tw-gap-2 tw-items-center">
          <AWSIcon
            name={AWSIconsList[resourceType as keyof typeof AWSIconsList]}
            alt={resourceTypeLabel}
            size={IconSize.md}
            fallbackPlaceholder={resourceType}
            className="tw-rounded"
          />
          <div className="tw-flex tw-gap-2 tw-items-center">
            <span className="tw-text-white tw-text-xssm tw-font-semibold">
              {label}
            </span>
            <span className="tw-text-gray-300 tw-text-xssm tw-font-semibold tw-italic">
              {resourceTypeLabel}
            </span>
          </div>
        </div>
      </div>
      {!collapsed && (
        <div className="tw-flex tw-flex-col tw-py-2 tw-gap-4">{children}</div>
      )}
    </div>
  );
};

export default TopologyErrorItem;
