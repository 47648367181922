import React, { useCallback, useEffect } from "react";
import {
  ConnectionMode,
  type Edge,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import type { Topology } from "../../../../apis/topology";
import { processGraphData } from "../../../../utils";
import { edgeTypes, nodeTypes } from "../../topology-graph-types";
import getELKGraphPositions from "../../utils/elk-graph-positions";
import type { ResourcePackPreviewProps } from "./resource-pack-types";

const ResourcePackPreview = ({
  resourcePack,
  id,
  processResourceConnections = false,
}: ResourcePackPreviewProps) => {
  const { fitView } = useReactFlow();
  const [resources, setResources] = useNodesState<Node[]>([]);
  const [resourceConnections, setResourceConnections] = useEdgesState<Edge[]>(
    []
  );

  const fetchData = useCallback(async () => {
    /**
     * 1. Process the graph data with the given resource pack
     * 2. Get the positions of the resources and connections
     * 3. Set the resources and connections
     * 4. If processResourceConnections is false, use the passed resource connections
     */
    const temp_graph_data = processGraphData({
      resourceConnections: resourcePack.connections,
      resources: resourcePack.resources,
    } as Topology);
    const graphData = {
      resources: temp_graph_data.resources,
      resourceConnections: Object.values(temp_graph_data.resourceConnections),
    };
    await getELKGraphPositions(graphData as unknown as Topology)
      .then(({ layoutedNodes, layoutedEdges }: any) => {
        setResources(layoutedNodes);
        if (!processResourceConnections) {
          setResourceConnections(resourcePack.connections);
        } else {
          setResourceConnections(layoutedEdges);
        }
      })
      .catch(() => {
        setResources([]);
        setResourceConnections([]);
      });
  }, [resourcePack]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ReactFlow
      id={id}
      className="tw-rounded-md tw-p-1 tw-bg-gray-950"
      nodes={resources}
      edges={resourceConnections}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      connectionMode={ConnectionMode.Loose}
      onNodesChange={() => fitView()}
      nodesDraggable={false}
      nodesConnectable={false}
      connectOnClick={false}
      deleteKeyCode={null}
      edgesUpdatable={false}
      edgesFocusable={false}
      nodesFocusable={false}
      snapGrid={[20, 20]}
      snapToGrid={true}
      zoomOnScroll
      zoomOnPinch
      elementsSelectable={false}
      zoomOnDoubleClick={false}
      fitView
      proOptions={{ hideAttribution: true }}
      fitViewOptions={{ padding: 2 }}
      minZoom={0.2}
    />
  );
};

export default (props: ResourcePackPreviewProps) => (
  <ReactFlowProvider>
    <ResourcePackPreview {...props} />
  </ReactFlowProvider>
);
