/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceLayoutSectionType } from './ResourceLayoutSectionType';
import {
    ResourceLayoutSectionTypeFromJSON,
    ResourceLayoutSectionTypeFromJSONTyped,
    ResourceLayoutSectionTypeToJSON,
} from './ResourceLayoutSectionType';

/**
 * 
 * @export
 * @interface ResourceLayoutSection
 */
export interface ResourceLayoutSection {
    /**
     * 
     * @type {ResourceLayoutSectionType}
     * @memberof ResourceLayoutSection
     */
    type?: ResourceLayoutSectionType;
    /**
     * Section label
     * @type {string}
     * @memberof ResourceLayoutSection
     */
    label?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceLayoutSection
     */
    attributes?: Array<string>;
}

/**
 * Check if a given object implements the ResourceLayoutSection interface.
 */
export function instanceOfResourceLayoutSection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceLayoutSectionFromJSON(json: any): ResourceLayoutSection {
    return ResourceLayoutSectionFromJSONTyped(json, false);
}

export function ResourceLayoutSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceLayoutSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : ResourceLayoutSectionTypeFromJSON(json['type']),
        'label': !exists(json, 'label') ? undefined : json['label'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
    };
}

export function ResourceLayoutSectionToJSON(value?: ResourceLayoutSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ResourceLayoutSectionTypeToJSON(value.type),
        'label': value.label,
        'attributes': value.attributes,
    };
}

