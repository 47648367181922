/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListUsers201Response,
  UpdateUserAttributeRequest,
  UpdateUsersRoleRequest,
  UserUpdate,
} from '../models/index';
import {
    ListUsers201ResponseFromJSON,
    ListUsers201ResponseToJSON,
    UpdateUserAttributeRequestFromJSON,
    UpdateUserAttributeRequestToJSON,
    UpdateUsersRoleRequestFromJSON,
    UpdateUsersRoleRequestToJSON,
    UserUpdateFromJSON,
    UserUpdateToJSON,
} from '../models/index';

export interface ListUsersRequest {
    orgID?: string;
}

export interface UpdateUserAttributeOperationRequest {
    userId: string;
    updateUserAttributeRequest: UpdateUserAttributeRequest;
}

export interface UpdateUserRoleRequest {
    userId: string;
    userUpdate: UserUpdate;
}

export interface UpdateUsersRoleOperationRequest {
    updateUsersRoleRequest: UpdateUsersRoleRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * List All Users only performable by admin
     */
    async listUsersRaw(requestParameters: ListUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListUsers201Response>> {
        const queryParameters: any = {};

        if (requestParameters.orgID !== undefined) {
            queryParameters['orgID'] = requestParameters.orgID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUsers201ResponseFromJSON(jsonValue));
    }

    /**
     * List All Users only performable by admin
     */
    async listUsers(requestParameters: ListUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListUsers201Response> {
        const response = await this.listUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the role of a user requires super admin
     */
    async updateUserAttributeRaw(requestParameters: UpdateUserAttributeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserAttribute.');
        }

        if (requestParameters.updateUserAttributeRequest === null || requestParameters.updateUserAttributeRequest === undefined) {
            throw new runtime.RequiredError('updateUserAttributeRequest','Required parameter requestParameters.updateUserAttributeRequest was null or undefined when calling updateUserAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserAttributeRequestToJSON(requestParameters.updateUserAttributeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the role of a user requires super admin
     */
    async updateUserAttribute(requestParameters: UpdateUserAttributeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserAttributeRaw(requestParameters, initOverrides);
    }

    /**
     * Update the role of a user requires super admin
     */
    async updateUserRoleRaw(requestParameters: UpdateUserRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUserRole.');
        }

        if (requestParameters.userUpdate === null || requestParameters.userUpdate === undefined) {
            throw new runtime.RequiredError('userUpdate','Required parameter requestParameters.userUpdate was null or undefined when calling updateUserRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the role of a user requires super admin
     */
    async updateUserRole(requestParameters: UpdateUserRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Update the role of multiple users requires super admin
     */
    async updateUsersRoleRaw(requestParameters: UpdateUsersRoleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateUsersRoleRequest === null || requestParameters.updateUsersRoleRequest === undefined) {
            throw new runtime.RequiredError('updateUsersRoleRequest','Required parameter requestParameters.updateUsersRoleRequest was null or undefined when calling updateUsersRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUsersRoleRequestToJSON(requestParameters.updateUsersRoleRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the role of multiple users requires super admin
     */
    async updateUsersRole(requestParameters: UpdateUsersRoleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUsersRoleRaw(requestParameters, initOverrides);
    }

}
