import type { FormikValues } from "formik";
import type { Option } from "../../../form-elements/select-box-types";
import { CustomResourceTemplate } from "../../../../apis/topology";
import { CustomResourceCreationModes } from "../../topology-graph-types";
import { CustomResourceDataType } from "../../../../utils/custom-resource-handler";

export type DefaultValueRendererForPrimitiveTypeProps = {
  type?: string;
  varIndex: number;
};

export type DefaultValueRendererForNonPrimitiveTypeProps = {
  type?: string;
  varIndex: number;
  values: FormikValues;
  setFieldValue: (field: string, value: any) => void;
};

export type ConditionalInputComponentProps = {
  type: CustomResourceDataType;
  name: string;
  booleanOptions: Option[];
  label?: string;
};

export type CommonFieldArrayComponentProps = {
  type: CustomResourceDataType;
  varIndex: number;
  values: any;
  isMap: boolean;
};

export type RenderObjectListSchemaProps = {
  varIndex: number;
  parentIndex: number;
  childIndex: number;
  values: FormikValues;
};

export type ConfigEditorRendererProps = {
  themeDefined: boolean;
  open: boolean;
  values: CustomResourceTemplate;
  setFieldValue: (field: string, value: any) => void;
  setFieldError: (field: string, value: any) => void;
  errors: any;
};

export type DuplicateResourceTypeWarningProps = {
  showDuplicateResourceTypeWarning: boolean;
};

export type ConfigureVariablesSectionProps = {
  values: CustomResourceTemplate;
  setFieldValue: (field: string, value: any) => void;
};

export type FooterProps = {
  onCancel: () => void;
  isSubmitting: boolean;
  mode: CustomResourceCreationModes;
};

export type ConfigureVariableHeaderProps = {
  capitalizedVariableName: string;
  setFieldValue: (key: string, value: any) => void;
  index: number;
  values: CustomResourceTemplate;
};

export const CustomResourceCreationStepIds = {
  DEFINE_CONFIGURATION: "define-configuration",
  MAP_VARIABLES: "map-variables",
};

export const customResourceCreationSteps = [
  {
    label: "Define Configuration",
    id: CustomResourceCreationStepIds.DEFINE_CONFIGURATION,
    isOptional: false,
  },
  {
    label: "Map variables",
    id: CustomResourceCreationStepIds.MAP_VARIABLES,
    isOptional: false,
  },
];
