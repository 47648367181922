/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RefType } from './RefType';
import {
    RefTypeFromJSON,
    RefTypeFromJSONTyped,
    RefTypeToJSON,
} from './RefType';
import type { RepositoryInfo } from './RepositoryInfo';
import {
    RepositoryInfoFromJSON,
    RepositoryInfoFromJSONTyped,
    RepositoryInfoToJSON,
} from './RepositoryInfo';

/**
 * 
 * @export
 * @interface SourceRef
 */
export interface SourceRef {
    /**
     * 
     * @type {RepositoryInfo}
     * @memberof SourceRef
     */
    repository: RepositoryInfo;
    /**
     * A branch, tag or commit SHA. The value must be available in git.
     * @type {string}
     * @memberof SourceRef
     */
    gitReference?: string;
    /**
     * 
     * @type {RefType}
     * @memberof SourceRef
     */
    refType?: RefType;
    /**
     * Path to local directory containing source code. This is only used when refType is local
     * @type {string}
     * @memberof SourceRef
     */
    localPath?: string;
    /**
     * If the repository is private, this will be true. If false, private repos are only supported when the server has proper access to the repository
     * @type {boolean}
     * @memberof SourceRef
     */
    isPrivate?: boolean;
    /**
     * If the repository is offboarded, this will be true. If false, offboarded repos are not supported
     * @type {boolean}
     * @memberof SourceRef
     */
    offboarded?: boolean;
}

/**
 * Check if a given object implements the SourceRef interface.
 */
export function instanceOfSourceRef(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "repository" in value;

    return isInstance;
}

export function SourceRefFromJSON(json: any): SourceRef {
    return SourceRefFromJSONTyped(json, false);
}

export function SourceRefFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceRef {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repository': RepositoryInfoFromJSON(json['repository']),
        'gitReference': !exists(json, 'gitReference') ? undefined : json['gitReference'],
        'refType': !exists(json, 'refType') ? undefined : RefTypeFromJSON(json['refType']),
        'localPath': !exists(json, 'localPath') ? undefined : json['localPath'],
        'isPrivate': !exists(json, 'isPrivate') ? undefined : json['isPrivate'],
        'offboarded': !exists(json, 'offboarded') ? undefined : json['offboarded'],
    };
}

export function SourceRefToJSON(value?: SourceRef | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repository': RepositoryInfoToJSON(value.repository),
        'gitReference': value.gitReference,
        'refType': RefTypeToJSON(value.refType),
        'localPath': value.localPath,
        'isPrivate': value.isPrivate,
        'offboarded': value.offboarded,
    };
}

