import React, { type FC } from "react";
import { Drawer, Icon, IconSize, IconsList } from "../../..";
import TopologyErrorHeader from "./topology-error-header";
import TopologyErrorItem from "./topology-error-item";
import {
  TopologyErrorDrawerTypeEnum,
  type TopologyErrorDrawerProps,
} from "./topology-error-types";
import PolicyViolationsTab from "../../features/policy-violations-tab";
import useTopologyErrorDrawer from "./hooks/useTopologyErrorDrawer";
import type { DrawerSize } from "../../../drawer/drawer-types";
import { findResourceInTopology } from "../../../../utils";
import { SelectedResourceType } from "../../topology-graph-types";

const TopologyErrorDrawer: FC<TopologyErrorDrawerProps> = ({
  topologyErrors,
  onClose,
  showMissingDrawer,
  graphDataResources,
  onNodeClick,
  resourceId,
  type,
}) => {
  const {
    size,
    onToggleExpand,
    headerText,
    filteredResources,
    onResourceClick,
    isExpanded,
    errorCount,
  } = useTopologyErrorDrawer({
    topologyErrors,
    graphDataResources,
    onNodeClick,
    resourceId,
    type,
  });

  if (!topologyErrors) {
    return null;
  }

  return (
    <Drawer
      open={true}
      onClose={onClose}
      size={size as keyof typeof DrawerSize}
      isFullScreenDrawer={false}
      isResizable={false}
      dataTestId="topology-error-drawer"
      position="Bottom"
    >
      <TopologyErrorHeader
        errorCount={errorCount}
        onFixMissingAttribute={showMissingDrawer}
        onClose={onClose}
        toggleExpand={onToggleExpand}
        isExpanded={isExpanded}
        headerText={headerText}
        type={type}
      />
      {type === TopologyErrorDrawerTypeEnum.ACTION && (
        <div className="tw-overflow-y-auto tw-flex-1 tw-pb-4">
          {filteredResources.map((resourceId) => {
            const resource_obj = findResourceInTopology(
              graphDataResources,
              resourceId
            );
            const errors =
              topologyErrors.resourcesWithErrors[resourceId]?.errorFields || [];
            if (resource_obj && errors?.length > 0) {
              return (
                <TopologyErrorItem
                  key={resourceId}
                  id={resourceId}
                  resourceType={resource_obj.resourceType}
                  resourceTypeLabel={resource_obj.resourceTypeLabel}
                  label={resource_obj.label}
                >
                  {errors.map(({ key }) => (
                    <div
                      key={key}
                      className="tw-flex tw-gap-2 tw-pl-10 tw-items-center tw-cursor-pointer"
                      onClick={() =>
                        onResourceClick(
                          resource_obj,
                          SelectedResourceType.VALIDATION_ERROR_NODE
                        )
                      }
                      onKeyDown={() =>
                        onResourceClick(
                          resource_obj,
                          SelectedResourceType.VALIDATION_ERROR_NODE
                        )
                      }
                      data-testid={`topology-error-resource-${resourceId}`}
                    >
                      <div className="tw-basis-4">
                        <Icon
                          size={IconSize.sm}
                          name={IconsList.TRIANGLE_EXCLAMATION}
                          className="tw-text-red-500"
                        />
                      </div>
                      <div>
                        <span className="tw-text-xs tw-font-bold tw-text-gray-100">
                          {`Error: ${key} is missing with ${
                            resource_obj.label || resource_obj.resourceTypeLabel
                          }`}
                        </span>
                      </div>
                    </div>
                  ))}
                </TopologyErrorItem>
              );
            }
            return null;
          })}
        </div>
      )}
      {type === TopologyErrorDrawerTypeEnum.WARNING && (
        <div className="tw-overflow-y-auto tw-flex-1 tw-pb-4">
          {filteredResources.map((resourceId) => {
            const resource_obj = findResourceInTopology(
              graphDataResources,
              resourceId
            );
            const misconfigurations =
              topologyErrors.resourcesWithErrors[resourceId]?.violationFields ||
              [];
            if (resource_obj && misconfigurations?.length > 0) {
              return (
                <TopologyErrorItem
                  key={resourceId}
                  id={resourceId}
                  resourceType={resource_obj.resourceType}
                  resourceTypeLabel={resource_obj.resourceTypeLabel}
                  label={resource_obj.label}
                >
                  <PolicyViolationsTab
                    misconfigurations={misconfigurations}
                    onViolationClick={() =>
                      onResourceClick(
                        resource_obj,
                        SelectedResourceType.POLICY_VIOLATION_NODE  
                      )
                    }
                  />
                </TopologyErrorItem>
              );
            }
            return null;
          })}
        </div>
      )}
    </Drawer>
  );
};

export default TopologyErrorDrawer;
