/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentTraitsDependenciesAwsSsmParameterInner
 */
export interface ComponentTraitsDependenciesAwsSsmParameterInner {
    /**
     * 
     * @type {string}
     * @memberof ComponentTraitsDependenciesAwsSsmParameterInner
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentTraitsDependenciesAwsSsmParameterInner
     */
    type?: ComponentTraitsDependenciesAwsSsmParameterInnerTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComponentTraitsDependenciesAwsSsmParameterInner
     */
    actions?: Array<string>;
}


/**
 * @export
 */
export const ComponentTraitsDependenciesAwsSsmParameterInnerTypeEnum = {
    String: 'String',
    StringList: 'StringList',
    SecureString: 'SecureString'
} as const;
export type ComponentTraitsDependenciesAwsSsmParameterInnerTypeEnum = typeof ComponentTraitsDependenciesAwsSsmParameterInnerTypeEnum[keyof typeof ComponentTraitsDependenciesAwsSsmParameterInnerTypeEnum];


/**
 * Check if a given object implements the ComponentTraitsDependenciesAwsSsmParameterInner interface.
 */
export function instanceOfComponentTraitsDependenciesAwsSsmParameterInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesAwsSsmParameterInnerFromJSON(json: any): ComponentTraitsDependenciesAwsSsmParameterInner {
    return ComponentTraitsDependenciesAwsSsmParameterInnerFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesAwsSsmParameterInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependenciesAwsSsmParameterInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
    };
}

export function ComponentTraitsDependenciesAwsSsmParameterInnerToJSON(value?: ComponentTraitsDependenciesAwsSsmParameterInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'actions': value.actions,
    };
}

