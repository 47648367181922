import React, { useMemo } from "react";

import type { RowType } from "./table-types";
import Column from "./column";

const Row = ({
  row_data,
  dataTestId,
}: {
  row_data: RowType;
  dataTestId: string;
}) => {
  const computedRowClassName = useMemo(() => {
    const baseClassName =
      row_data?.isExpandable && row_data?.isExpanded
        ? ""
        : "tw-border-b tw-border-gray-700 tw-border-r-2";
    let selectionStatusClassName =
      "tw-border-r-transparent tw-bg-gray-800 hover:tw-bg-gray-500 hover:tw-bg-opacity-10";
    if (row_data.isActive) {
      selectionStatusClassName =
        "tw-bg-blue-500 tw-bg-opacity-20 hover:tw-bg-opacity-30 tw-border-r-blue-500";
    } else if (row_data.isSelected) {
      selectionStatusClassName =
        "tw-bg-blue-500 tw-bg-opacity-10 hover:tw-bg-opacity-20";
    } else if (row_data?.isExpandable && row_data?.isExpanded) {
      selectionStatusClassName = "tw-bg-gray-800 tw-bg-opacity-50";
    }

    return `${baseClassName} ${selectionStatusClassName} ${
      row_data?.className ? row_data.className : ""
    }`;
  }, [
    row_data.isSelected,
    row_data.isActive,
    row_data.className,
    row_data.isExpandable,
    row_data.isExpanded,
  ]);

  return (
    <tr
      className={computedRowClassName}
      onClick={row_data?.onClick}
      onMouseEnter={() =>
        row_data?.onHover && row_data?.onHover(row_data.id, true)
      }
      onMouseLeave={() =>
        row_data?.onHover && row_data?.onHover(row_data.id, false)
      }
      data-testid={`${dataTestId}-row-${row_data.id}`}
    >
      {row_data?.data?.map((column) => (
        <Column
          column_data={column}
          key={`${row_data.id}_column_${column.id}`}
          dataTestId={`${dataTestId}-row-${row_data.id}-column-${column.id}`}
        />
      ))}
    </tr>
  );
};

export default Row;
