/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Hook = {
    BeforeAnalysis: 'BEFORE_ANALYSIS',
    DuringAnalysis: 'DURING_ANALYSIS',
    AfterAnalysis: 'AFTER_ANALYSIS',
    AfterTopologyRendered: 'AFTER_TOPOLOGY_RENDERED',
    AppstackRepoSelection: 'APPSTACK_REPO_SELECTION'
} as const;
export type Hook = typeof Hook[keyof typeof Hook];


export function HookFromJSON(json: any): Hook {
    return HookFromJSONTyped(json, false);
}

export function HookFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hook {
    return json as Hook;
}

export function HookToJSON(value?: Hook | null): any {
    return value as any;
}

