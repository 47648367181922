/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppManifest } from './AppManifest';
import {
    AppManifestFromJSON,
    AppManifestFromJSONTyped,
    AppManifestToJSON,
} from './AppManifest';
import type { AppStackConfigCore } from './AppStackConfigCore';
import {
    AppStackConfigCoreFromJSON,
    AppStackConfigCoreFromJSONTyped,
    AppStackConfigCoreToJSON,
} from './AppStackConfigCore';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface AppStack
 */
export interface AppStack {
    /**
     * Name of the appStack
     * @type {string}
     * @memberof AppStack
     */
    name: string;
    /**
     * Description of the appStack
     * @type {string}
     * @memberof AppStack
     */
    description?: string;
    /**
     * List of components in the appStack
     * @type {Array<Component>}
     * @memberof AppStack
     */
    components?: Array<Component>;
    /**
     * 
     * @type {AppStackConfigCore}
     * @memberof AppStack
     */
    coreConfig?: AppStackConfigCore;
    /**
     * UUID of the AppStackConfig that this appStack is based on. Leave null if the appStack is not based on a configuration
     * @type {string}
     * @memberof AppStack
     */
    appStackConfigRef?: string;
    /**
     * Labels associated with the appStack
     * @type {Array<string>}
     * @memberof AppStack
     */
    labels?: Array<string>;
    /**
     * Creator of the policy
     * @type {string}
     * @memberof AppStack
     */
    createdBy?: string;
    /**
     * User who last updated the appStack
     * @type {string}
     * @memberof AppStack
     */
    updatedBy?: string;
    /**
     * UUID of appStack
     * @type {string}
     * @memberof AppStack
     */
    uuid: string;
    /**
     * Version of the appStack, sequential number starting at 1
     * @type {number}
     * @memberof AppStack
     */
    version: number;
    /**
     * 
     * @type {AppManifest}
     * @memberof AppStack
     */
    manifest?: AppManifest;
    /**
     * Creation date of the appStack
     * @type {Date}
     * @memberof AppStack
     */
    createdDate?: Date;
    /**
     * Date appStack was last updated
     * @type {Date}
     * @memberof AppStack
     */
    updatedDate?: Date;
    /**
     * Value of True indicates that the AppStack is archived.
     * @type {boolean}
     * @memberof AppStack
     */
    archived?: boolean;
    /**
     * 
     * @type {Status}
     * @memberof AppStack
     */
    status?: Status;
    /**
     * Reason for the status
     * @type {string}
     * @memberof AppStack
     */
    notes?: string;
}

/**
 * Check if a given object implements the AppStack interface.
 */
export function instanceOfAppStack(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function AppStackFromJSON(json: any): AppStack {
    return AppStackFromJSONTyped(json, false);
}

export function AppStackFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStack {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'components': !exists(json, 'components') ? undefined : ((json['components'] as Array<any>).map(ComponentFromJSON)),
        'coreConfig': !exists(json, 'coreConfig') ? undefined : AppStackConfigCoreFromJSON(json['coreConfig']),
        'appStackConfigRef': !exists(json, 'appStackConfigRef') ? undefined : json['appStackConfigRef'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'uuid': json['uuid'],
        'version': json['version'],
        'manifest': !exists(json, 'manifest') ? undefined : AppManifestFromJSON(json['manifest']),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'updatedDate': !exists(json, 'updatedDate') ? undefined : (new Date(json['updatedDate'])),
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function AppStackToJSON(value?: AppStack | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'components': value.components === undefined ? undefined : ((value.components as Array<any>).map(ComponentToJSON)),
        'coreConfig': AppStackConfigCoreToJSON(value.coreConfig),
        'appStackConfigRef': value.appStackConfigRef,
        'labels': value.labels,
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
        'uuid': value.uuid,
        'version': value.version,
        'manifest': AppManifestToJSON(value.manifest),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'updatedDate': value.updatedDate === undefined ? undefined : (value.updatedDate.toISOString()),
        'archived': value.archived,
        'status': StatusToJSON(value.status),
        'notes': value.notes,
    };
}

