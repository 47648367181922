import React, { type ReactElement } from "react";

const CardBody = ({
  padding,
  children,
  className,
}: {
  padding?: boolean;
  children: ReactElement | ReactElement[];
  className?: string;
}) => {
  return (
    <div className={`${padding ? "tw-p-4" : ""} ${className ? className : ""}`}>
      {children}
    </div>
  );
};

export default CardBody;
