import type { ChangeEvent, ReactElement } from "react";

export interface Option {
  label: string | ReactElement;
  value: string;
}

export interface MultiSelectBoxProps {
  name: string;
  options: Option[];
  label?: string;
  description?: string | ReactElement;
  placeholder?: string;
  createable?: boolean;
  createOption?: (new_option: string) => Promise<void> | void;
  isOptional?: boolean;
  subLabel?: string;
  disabled?: boolean;
  className?: string;
  showOptionsWithCheckbox?: boolean;
  consolidatedItemsContext?: string;
  onChange?: (event: string[]) => void;
  backgroundVariant?: keyof typeof BackgroundVariant;
  dataTestId: string; // keeping it mandatory so that it can not be missed
  required?: boolean;
  labelSuffix?: string | ReactElement;
  labelSuffixAction?: () => void;
  menuPosition?: "absolute" | "fixed";
}

export enum BackgroundVariant {
  "gray-800" = "tw-bg-gray-800",
  "gray-900" = "tw-bg-gray-900",
}
