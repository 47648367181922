import React, { type ReactElement } from "react";

const CardFooter = ({
  padding,
  children,
  className,
}: {
  padding?: boolean;
  children: ReactElement | ReactElement[];
  className?: string;
}) => {
  return (
    <div
      className={`${
        padding ? "tw-p-4" : ""
      } tw-border-t tw-border-white tw-border-opacity-10 ${
        className ? className : ""
      }`}
    >
      {children}
    </div>
  );
};

export default CardFooter;
