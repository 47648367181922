/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceOverrideMappingPolicy
 */
export interface ResourceOverrideMappingPolicy {
    /**
     * 
     * @type {string}
     * @memberof ResourceOverrideMappingPolicy
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOverrideMappingPolicy
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceOverrideMappingPolicy
     */
    policyName?: string;
    /**
     * 
     * @type {object}
     * @memberof ResourceOverrideMappingPolicy
     */
    resourceOverrideDetails?: object;
}

/**
 * Check if a given object implements the ResourceOverrideMappingPolicy interface.
 */
export function instanceOfResourceOverrideMappingPolicy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceOverrideMappingPolicyFromJSON(json: any): ResourceOverrideMappingPolicy {
    return ResourceOverrideMappingPolicyFromJSONTyped(json, false);
}

export function ResourceOverrideMappingPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceOverrideMappingPolicy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'policyName': !exists(json, 'policyName') ? undefined : json['policyName'],
        'resourceOverrideDetails': !exists(json, 'resourceOverrideDetails') ? undefined : json['resourceOverrideDetails'],
    };
}

export function ResourceOverrideMappingPolicyToJSON(value?: ResourceOverrideMappingPolicy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'policyName': value.policyName,
        'resourceOverrideDetails': value.resourceOverrideDetails,
    };
}

