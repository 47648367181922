/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * Role UUID of the user
     * @type {string}
     * @memberof UserUpdate
     */
    roleId: string;
}

/**
 * Check if a given object implements the UserUpdate interface.
 */
export function instanceOfUserUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roleId" in value;

    return isInstance;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
    return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': json['roleId'],
    };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
    };
}

