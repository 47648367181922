/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines the types of entities that can receive a governance assignment.
 * @export
 */
export const OwnerType = {
    Tenant: 'Tenant',
    Organization: 'Organization',
    User: 'User',
    AppStack: 'AppStack',
    AppStackVersion: 'AppStackVersion'
} as const;
export type OwnerType = typeof OwnerType[keyof typeof OwnerType];


export function OwnerTypeFromJSON(json: any): OwnerType {
    return OwnerTypeFromJSONTyped(json, false);
}

export function OwnerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OwnerType {
    return json as OwnerType;
}

export function OwnerTypeToJSON(value?: OwnerType | null): any {
    return value as any;
}

