import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import type {
  ProviderWiseSupportedResourceType,
  SupportedResourceType,
} from "../../../../apis/topology";
import {
  Banner,
  Icon,
  IconButton,
  IconSize,
  IconsList,
  Tabs,
  TextBox,
} from "../../../../components";
import { ResourceProvidersMap } from "../../../../config/constants";
import {
  debounce,
  getResourcesByCategory,
  isFunction,
  trackAmplitudeEvent,
} from "../../../../utils";
import { BannerTypes } from "../../../banner/banner-types";
import type { SupportedResourceListProps } from "../../topology-graph-types";
import ResourceCategoryList from "./resource-category-list";
import ResourcePackList from "./resource-pack-list";

const SupportedResourceList = ({
  resourcesList,
  onAddCustomResource = () => {},
  onAddCustomHelmResource = () => {},
  onAddResourcePack = () => {},
  onEditResourcePack = () => {},
  activeResourceTab,
  cloudService,
  onPanelClose,
  onDragStart,
  onResourceTabChange,
  refetchResourcesList,
  onActiveCustomResourceAction = () => {},
  restrictedResources,
  setShowRestrictedResourcesDrawer,
}: SupportedResourceListProps) => {
  const getResourceProviders = (
    cloudService: string
  ): { [key: string]: string } => {
    switch (cloudService) {
      case "aws-ecs":
        return { aws: "AWS", resourcePacks: "Resource Packs" };
      case "k8s":
        return {
          aws: "AWS",
          helm: "HELM",
          general: "General",
          resourcePacks: "Resource Packs",
        };
      case "aks-tf":
        return {
          azurerm: "AZURE",
          helm: "HELM",
          general: "General",
          resourcePacks: "Resource Packs",
        };
      case "aws-lambda":
        return {
          aws: "AWS",
          general: "General",
          resourcePacks: "Resource Packs",
        };
      case "gke-tf":
        return {
          gcp: "GCP",
          general: "General",
          resourcePacks: "Resource Packs",
        };
      default:
        return {
          aws: "AWS",
          azurerm: "AZURE",
          helm: "HELM",
          general: "General",
          resourcePacks: "Resource Packs",
        };
    }
  };

  const [resourceProviders, setResourceProviders] = useState(
    getResourceProviders(cloudService)
  );
  const [resource_list, setResourceList] = useState(resourcesList);

  const trackResourcePackSearchEvent = useCallback(
    debounce((searchText: string) => {
      trackAmplitudeEvent("resource_pack_search", {
        searchText: searchText,
      });
    }, 1000),
    []
  );

  useEffect(() => {
    setResourceList(resourcesList);
  }, [activeResourceTab, resourcesList]);

  useEffect(() => {
    if (onResourceTabChange && isFunction(onResourceTabChange)) {
      // select first tab by default
      onResourceTabChange(
        Object.keys(
          resourceProviders
        )?.[0] as keyof ProviderWiseSupportedResourceType
      );
    }
  }, [resourceProviders]);

  useEffect(() => {
    setResourceProviders(getResourceProviders(cloudService));
  }, [cloudService]);

  return (
    <Formik
      initialValues={{
        list_filter: "",
      }}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        const filteredResourceList = { ...resourcesList };
        filteredResourceList[
          activeResourceTab as keyof ProviderWiseSupportedResourceType
        ] = resourcesList?.[
          activeResourceTab as keyof ProviderWiseSupportedResourceType
        ]?.filter((resource: SupportedResourceType) =>
          resource?.label
            ?.toLocaleLowerCase()
            .includes(values.list_filter.toLocaleLowerCase())
        );
        setResourceList(filteredResourceList);
        setSubmitting(false);
      }}
    >
      {({ submitForm, setFieldValue }) => (
        <div className="tw-flex tw-flex-col tw-h-full !tw-scrollbar-none">
          <div className="tw-sticky tw-top-0 tw-bg-gray-800">
            <div className="tw-flex tw-gap-3 tw-justify-between tw-items-center tw-p-4">
              <span className="tw-font-semibold tw-text-white tw-text-base">
                Add New Resource
              </span>
              <div
                onClick={() => {
                  if (isFunction(onPanelClose)) {
                    onPanelClose();
                  }
                }}
                data-testid="close-resource-list"
                className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
              >
                <Icon name={IconsList.CLOSE} />
              </div>
            </div>
            <div className="tw-py-2 tw-px-4">
              <Form>
                <TextBox
                  name="list_filter"
                  placeholder="Search Resources"
                  onChange={(e) => {
                    e.preventDefault();
                    setFieldValue("list_filter", e.target.value);
                    if (activeResourceTab === "resourcePacks") {
                      trackResourcePackSearchEvent(e.target.value);
                    }
                    submitForm();
                  }}
                  prefix={
                    <Icon
                      name={IconsList.SEARCH}
                      size={IconSize.sm}
                      className="tw-text-gray-500"
                    />
                  }
                  dataTestId="search-resources"
                />
              </Form>
            </div>
          </div>
          <Tabs
            wrapperClassName="tw-h-10 tw-justify-evenly"
            contentClassName="tw-overflow-y-auto !tw-scrollbar-none !tw-min-h-0 tw-h-full"
            headerClassName="!tw-px-2"
            tabs={Object.keys(resourceProviders).map((provider) => {
              const resourceListForProvider =
                resource_list?.[
                  provider as keyof ProviderWiseSupportedResourceType
                ];

              const {
                builtInResources,
                customResources,
                dataResources,
                resourcePacks,
              } = getResourcesByCategory(resourceListForProvider);

              return {
                id: provider,
                title:
                  resourceProviders[provider as keyof typeof resourceProviders],
                content: (
                  <div className="tw-flex tw-flex-col">
                    {provider === ResourceProvidersMap.resourcePacks ? (
                      <ResourcePackList
                        resourcePacks={resourcePacks}
                        onResourceDragStart={onDragStart}
                        onAddResourcePack={onAddResourcePack}
                        onEditResourcePack={onEditResourcePack}
                        refetchResourcesList={refetchResourcesList}
                      />
                    ) : (
                      <>
                        {restrictedResources &&
                          Object.keys(restrictedResources).length > 0 && (
                            <div className="tw-px-4 tw-pt-3">
                              <Banner type={BannerTypes.INFO}>
                                <div className="tw-text-sm">
                                  <span
                                    className="tw-text-blue-500 tw-cursor-pointer"
                                    onClick={() =>
                                      setShowRestrictedResourcesDrawer(true)
                                    }
                                    data-testid="show-restricted-list-drawer"
                                  >
                                    Certain resource
                                  </span>
                                  <span className="tw-text-blue-300">
                                    {" "}
                                    are restricted by your organization.
                                  </span>
                                </div>
                              </Banner>
                            </div>
                          )}
                        {provider !== ResourceProvidersMap.general && (
                          <ResourceCategoryList
                            resourceList={customResources}
                            listTitle={
                              provider === ResourceProvidersMap.helm
                                ? "Custom Helm Chart"
                                : "Custom Resources"
                            }
                            provider={provider}
                            onResourceDragStart={onDragStart}
                            noResultText={
                              provider === ResourceProvidersMap.helm
                                ? "No custom HELM charts are added."
                                : "No results found."
                            }
                            suffixElement={
                              <IconButton
                                onClick={
                                  provider === ResourceProvidersMap.helm
                                    ? onAddCustomHelmResource
                                    : onAddCustomResource
                                }
                                icon={IconsList.ADD}
                                dataTestId={`${provider}-add-custom-resource`}
                                size={"sm"}
                                variant="secondary"
                                className="tw-rounded-full"
                              />
                            }
                          />
                        )}
                        <ResourceCategoryList
                          resourceList={builtInResources}
                          listTitle="Built In Resources"
                          provider={provider}
                          onResourceDragStart={onDragStart}
                          noResultText="No results found."
                        />
                        <ResourceCategoryList
                          resourceList={dataResources}
                          listTitle="Data Sources"
                          provider={provider}
                          onResourceDragStart={onDragStart}
                          noResultText="No results found."
                          resourceSuffixElement={
                            <div className="tw-absolute tw-bg-blue-800 tw-text-gray-50 tw-px-1.5 tw-py-1 tw-right-0 tw-top-0 tw-rounded-se-md tw-rounded-es-md">
                              <Icon
                                name={IconsList.DATABASE}
                                size={IconSize.sm}
                              />
                            </div>
                          }
                        />
                      </>
                    )}
                  </div>
                ),
                onTabChange: (tab) => {
                  setFieldValue("list_filter", "");
                  onResourceTabChange &&
                    isFunction(onResourceTabChange) &&
                    onResourceTabChange(
                      tab as keyof ProviderWiseSupportedResourceType
                    );
                },
              };
            })}
          />
        </div>
      )}
    </Formik>
  );
};

export default SupportedResourceList;
