import React, { type ReactElement, useState } from "react";

import type {
  ProviderWiseSupportedResourceType,
  SupportedResourceType,
} from "../../../../apis/topology";

import { Icon, IconsList } from "../../../../components";

import ResourceItem from "./resource-item";

const ResourceCategoryList = ({
  resourceList,
  listTitle,
  provider,
  onResourceDragStart,
  noResultText,
  suffixElement,
  resourceSuffixElement,
}: {
  resourceList: SupportedResourceType[];
  listTitle: string;
  provider: string;
  onResourceDragStart: (event: any, node_id: string) => void;
  noResultText: string;
  suffixElement?: ReactElement;
  resourceSuffixElement?: ReactElement;
}) => {
  const [showList, setShowList] = useState(true);

  return (
    <div className="tw-border-b tw-border-gray-700">
      <div className="tw-flex tw-items-center tw-justify-between tw-pl-3 tw-pr-4 tw-py-4">
        <div
          className="tw-flex tw-gap-1 tw-items-center tw-cursor-pointer"
          onClick={() => setShowList((showList) => !showList)}
          data-testid="close-resource-list"
        >
          <div className="tw-text-gray-200 hover:tw-text-gray-300">
            <Icon name={showList ? IconsList.CARET_UP : IconsList.CARET_DOWN} />
          </div>
          <span>{listTitle}</span>
        </div>
        {suffixElement && suffixElement}
      </div>
      {showList &&
        (resourceList?.length > 0 ? (
          <div
            className={"tw-pb-4 tw-px-4 tw-flex tw-flex-col tw-gap-3"}
            data-testid={`resource-list-${provider}`}
          >
            {resourceList?.map((resource) => (
              <ResourceItem
                key={resource.resourceType}
                resource={resource}
                provider={provider as keyof ProviderWiseSupportedResourceType}
                onDragStart={onResourceDragStart}
                suffixElement={resourceSuffixElement}
              />
            ))}
          </div>
        ) : (
          <div
            className="tw-flex tw-justify-center tw-text-gray-300 tw-text-xssm tw-mb-5"
            data-testid="no-result-label"
          >
            {noResultText}
          </div>
        ))}
    </div>
  );
};

export default ResourceCategoryList;
