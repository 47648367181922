/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResourceIAMRestrictionPolicy,
} from '../models/index';
import {
    ResourceIAMRestrictionPolicyFromJSON,
    ResourceIAMRestrictionPolicyToJSON,
} from '../models/index';

export interface DeleteIamRestrictionPolicyRequest {
    policyID: string;
    orgId?: string;
}

export interface GetIamRestrictionPoliciesRequest {
    orgId?: string;
    policyIds?: Array<string>;
    names?: Array<string>;
}

export interface GetIamRestrictionPolicyRequest {
    policyID: string;
    orgId?: string;
}

export interface ImportResourceIAMRestrictionPoliciesRequest {
    orgId?: string;
    iamRestrictionPolicies?: Blob;
}

export interface SaveIamRestrictionPoliciesRequest {
    resourceIAMRestrictionPolicy: ResourceIAMRestrictionPolicy;
    orgId?: string;
}

/**
 * 
 */
export class V1ResourceIamRestrictionPoliciesApi extends runtime.BaseAPI {

    /**
     * Delete IAM Restriction Policy
     */
    async deleteIamRestrictionPolicyRaw(requestParameters: DeleteIamRestrictionPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling deleteIamRestrictionPolicy.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/iam-restriction-policies/{policyID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete IAM Restriction Policy
     */
    async deleteIamRestrictionPolicy(requestParameters: DeleteIamRestrictionPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIamRestrictionPolicyRaw(requestParameters, initOverrides);
    }

    /**
     * Get IAM Restriction Policies
     */
    async getIamRestrictionPoliciesRaw(requestParameters: GetIamRestrictionPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResourceIAMRestrictionPolicy>>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.policyIds) {
            queryParameters['policyIds'] = requestParameters.policyIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.names) {
            queryParameters['names'] = requestParameters.names.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/iam-restriction-policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceIAMRestrictionPolicyFromJSON));
    }

    /**
     * Get IAM Restriction Policies
     */
    async getIamRestrictionPolicies(requestParameters: GetIamRestrictionPoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResourceIAMRestrictionPolicy>> {
        const response = await this.getIamRestrictionPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get IAM Restriction Policy
     */
    async getIamRestrictionPolicyRaw(requestParameters: GetIamRestrictionPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceIAMRestrictionPolicy>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling getIamRestrictionPolicy.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/iam-restriction-policies/{policyID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceIAMRestrictionPolicyFromJSON(jsonValue));
    }

    /**
     * Get IAM Restriction Policy
     */
    async getIamRestrictionPolicy(requestParameters: GetIamRestrictionPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceIAMRestrictionPolicy> {
        const response = await this.getIamRestrictionPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import Resource IAM Restriction Policies
     */
    async importResourceIAMRestrictionPoliciesRaw(requestParameters: ImportResourceIAMRestrictionPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.iamRestrictionPolicies !== undefined) {
            formParams.append('iam-restriction-policies', requestParameters.iamRestrictionPolicies as any);
        }

        const response = await this.request({
            path: `/v1/import-iam-restriction-policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import Resource IAM Restriction Policies
     */
    async importResourceIAMRestrictionPolicies(requestParameters: ImportResourceIAMRestrictionPoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importResourceIAMRestrictionPoliciesRaw(requestParameters, initOverrides);
    }

    /**
     * Save IAM Restriction Policies
     */
    async saveIamRestrictionPoliciesRaw(requestParameters: SaveIamRestrictionPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourceIAMRestrictionPolicy === null || requestParameters.resourceIAMRestrictionPolicy === undefined) {
            throw new runtime.RequiredError('resourceIAMRestrictionPolicy','Required parameter requestParameters.resourceIAMRestrictionPolicy was null or undefined when calling saveIamRestrictionPolicies.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/iam-restriction-policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceIAMRestrictionPolicyToJSON(requestParameters.resourceIAMRestrictionPolicy),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save IAM Restriction Policies
     */
    async saveIamRestrictionPolicies(requestParameters: SaveIamRestrictionPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveIamRestrictionPoliciesRaw(requestParameters, initOverrides);
    }

}
