import React, { type FC } from "react";

import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
  Tooltip,
} from "../../components";
import { PillProps } from "./pill-types";
import { isFunction } from "../../utils";

const Pill: FC<PillProps> = ({
  id,
  leftIcon,
  leftIconImageUrl,
  rightIcon,
  rightIconImageUrl,
  label = "",
  className = "",
  tooltipText = "",
  rightIconClassName = "",
  leftIconClassName = "",
  dataTestId,
  onClick,
}) => {
  const PillContent: FC<{}> = () => {
    return (
      <div
        className={`tw-text-gray-100 tw-border tw-border-gray-600 tw-rounded-full tw-flex tw-gap-1 tw-justify-center tw-items-center tw-py-1 tw-px-1.5 tw-w-fit ${
          onClick && isFunction(onClick) ? "tw-cursor-pointer" : ""
        } ${className}`}
        onClick={(e) => {
          e.stopPropagation();
          onClick && isFunction(onClick) && onClick(id);
        }}
        id={id}
      >
        {leftIcon || leftIconImageUrl ? (
          leftIcon ? (
            <>
              {Object.values(IconsList).includes(leftIcon as IconsList) ? (
                <Icon
                  name={leftIcon as IconsList}
                  size={IconSize.sm}
                  className={leftIconClassName}
                />
              ) : Object.values(AWSIconsList).includes(
                  leftIcon as AWSIconsList
                ) ? (
                <AWSIcon
                  name={leftIcon as AWSIconsList}
                  size={IconSize.sm}
                  className={leftIconClassName}
                /> // TODO: remove this logic once all icons are combined in all files
              ) : null}
            </>
          ) : leftIconImageUrl ? (
            <img
              src={leftIconImageUrl}
              className={`tw-h-4 tw-w-4 ${leftIconClassName}`}
            />
          ) : null
        ) : null}

        {label && (
          <span
            className="tw-text-xs tw-font-normal tw-text-gray-200"
            {...(dataTestId
              ? { "data-testid": `${dataTestId}-pill-label` }
              : {})}
          >
            {label}
          </span>
        )}

        {rightIcon || rightIconImageUrl ? (
          rightIcon ? (
            <>
              {Object.values(IconsList).includes(rightIcon as IconsList) ? (
                <Icon
                  name={rightIcon as IconsList}
                  size={IconSize.sm}
                  className={rightIconClassName}
                />
              ) : Object.values(AWSIconsList).includes(
                  rightIcon as AWSIconsList
                ) ? (
                <AWSIcon
                  name={rightIcon as AWSIconsList}
                  size={IconSize.sm}
                  className={rightIconClassName}
                /> // TODO: remove this logic once all icons are combined in all files
              ) : null}
            </>
          ) : rightIconImageUrl ? (
            <img
              src={rightIconImageUrl}
              className={`tw-h-4 tw-w-4 ${rightIconClassName}`}
            />
          ) : null
        ) : null}
      </div>
    );
  };

  return (
    <>
      {tooltipText.length > 0 ? (
        <Tooltip title={tooltipText}>
          <PillContent />
        </Tooltip>
      ) : (
        <PillContent />
      )}
    </>
  );
};
export default Pill;
