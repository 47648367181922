import React, { type FC, useState } from "react";

import {
  BannerProps,
  BannerColors,
  BannerIcons,
  BannerTypes,
} from "./banner-types";
import { Icon, IconSize, IconsList } from "../../components";

const Banner: FC<BannerProps> = ({
  title = "",
  type = BannerTypes.INFO,
  dismissible,
  children,
  dataTestId,
  onDismiss,
}) => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible && (
        <div
          className={`tw-flex tw-gap-4 tw-items-center tw-justify-between tw-px-4 tw-py-3 tw-text-sm tw-rounded-md tw-border ${BannerColors[type]}`}
        >
          <div className="tw-flex tw-items-center tw-gap-4 tw-font-normal">
            <div
              className="tw-basis-6"
              {...(dataTestId && {
                "data-testid": `${dataTestId}-icon`,
              })}
            >
              <Icon name={BannerIcons[type]} size={IconSize.lg} />
            </div>
            <div
              className="tw-flex-grow"
              {...(dataTestId && {
                "data-testid": `${dataTestId}-title`,
              })}
            >
              {children ? children : <span>{title}</span>}
            </div>
          </div>
          {dismissible && (
            <div
              className="tw-cursor-pointer tw-basis-6"
              onClick={() => {
                setVisible(false);
                onDismiss && onDismiss();
              }}
              {...(dataTestId && {
                "data-testid": `${dataTestId}-dismiss-button`,
              })}
            >
              <Icon name={IconsList.CLOSE} size={IconSize.lg} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Banner;
