/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Hook } from './Hook';
import {
    HookFromJSON,
    HookFromJSONTyped,
    HookToJSON,
} from './Hook';
import type { Topology } from './Topology';
import {
    TopologyFromJSON,
    TopologyFromJSONTyped,
    TopologyToJSON,
} from './Topology';

/**
 * 
 * @export
 * @interface QuestionnaireRequest
 */
export interface QuestionnaireRequest {
    /**
     * 
     * @type {Hook}
     * @memberof QuestionnaireRequest
     */
    hook: Hook;
    /**
     * 
     * @type {Topology}
     * @memberof QuestionnaireRequest
     */
    topology?: Topology;
}

/**
 * Check if a given object implements the QuestionnaireRequest interface.
 */
export function instanceOfQuestionnaireRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hook" in value;

    return isInstance;
}

export function QuestionnaireRequestFromJSON(json: any): QuestionnaireRequest {
    return QuestionnaireRequestFromJSONTyped(json, false);
}

export function QuestionnaireRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hook': HookFromJSON(json['hook']),
        'topology': !exists(json, 'topology') ? undefined : TopologyFromJSON(json['topology']),
    };
}

export function QuestionnaireRequestToJSON(value?: QuestionnaireRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hook': HookToJSON(value.hook),
        'topology': TopologyToJSON(value.topology),
    };
}

