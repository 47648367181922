/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrgUser } from './OrgUser';
import {
    OrgUserFromJSON,
    OrgUserFromJSONTyped,
    OrgUserToJSON,
} from './OrgUser';

/**
 * 
 * @export
 * @interface ListUsers201Response
 */
export interface ListUsers201Response {
    /**
     * 
     * @type {Array<OrgUser>}
     * @memberof ListUsers201Response
     */
    users?: Array<OrgUser>;
}

/**
 * Check if a given object implements the ListUsers201Response interface.
 */
export function instanceOfListUsers201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ListUsers201ResponseFromJSON(json: any): ListUsers201Response {
    return ListUsers201ResponseFromJSONTyped(json, false);
}

export function ListUsers201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUsers201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(OrgUserFromJSON)),
    };
}

export function ListUsers201ResponseToJSON(value?: ListUsers201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(OrgUserToJSON)),
    };
}

