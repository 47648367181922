/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ValidScopes } from './ValidScopes';
import {
    ValidScopesFromJSON,
    ValidScopesFromJSONTyped,
    ValidScopesToJSON,
} from './ValidScopes';

/**
 * 
 * @export
 * @interface ScopeInfo
 */
export interface ScopeInfo {
    /**
     * 
     * @type {ValidScopes}
     * @memberof ScopeInfo
     */
    name: ValidScopes;
    /**
     * Description of the scope
     * @type {string}
     * @memberof ScopeInfo
     */
    description: string;
}

/**
 * Check if a given object implements the ScopeInfo interface.
 */
export function instanceOfScopeInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ScopeInfoFromJSON(json: any): ScopeInfo {
    return ScopeInfoFromJSONTyped(json, false);
}

export function ScopeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScopeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': ValidScopesFromJSON(json['name']),
        'description': json['description'],
    };
}

export function ScopeInfoToJSON(value?: ScopeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': ValidScopesToJSON(value.name),
        'description': value.description,
    };
}

