import React, { type FC } from "react";
import { Button } from "../../..";

import type { CustomHelmDrawerFooterProps } from "./custom-helm-types";

const CustomHelmDrawerFooter: FC<CustomHelmDrawerFooterProps> = ({
  onClose,
  isSubmitting,
}) => (
  <div className="tw-flex tw-justify-between tw-absolute tw-bottom-0 tw-border-t tw-border-gray-700 tw-bg-gray-900 tw-w-full tw-p-4">
    <div className="tw-flex tw-gap-3 tw-w-full">
      <Button
        variant="primary"
        type="submit"
        disabled={isSubmitting}
        label={isSubmitting ? "Creating HELM Chart..." : "Add HELM Chart"}
        dataTestId="create-custom-helm-button"
        className="tw-w-full"
      />
      <Button
        className="tw-bg-gray-700 tw-w-full"
        label="Cancel"
        variant="tertiary"
        type="button"
        onClick={() => onClose()}
        dataTestId="cancel-custom-helm-button"
      />
    </div>
  </div>
);

export default CustomHelmDrawerFooter;
