/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Config
 */
export interface Config {
    /**
     * ID of the configuration
     * @type {string}
     * @memberof Config
     */
    id?: string;
    /**
     * Name of the configuration
     * @type {string}
     * @memberof Config
     */
    name: string;
    /**
     * Type of the configuration
     * @type {string}
     * @memberof Config
     */
    type: ConfigTypeEnum;
    /**
     * Parameters of the configuration
     * @type {{ [key: string]: string; }}
     * @memberof Config
     */
    parameters: { [key: string]: string; };
    /**
     * Secrets of the configuration
     * @type {{ [key: string]: string; }}
     * @memberof Config
     */
    secrets: { [key: string]: string; };
}


/**
 * @export
 */
export const ConfigTypeEnum = {
    Blobstore: 'blobstore',
    Git: 'git'
} as const;
export type ConfigTypeEnum = typeof ConfigTypeEnum[keyof typeof ConfigTypeEnum];


/**
 * Check if a given object implements the Config interface.
 */
export function instanceOfConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "secrets" in value;

    return isInstance;
}

export function ConfigFromJSON(json: any): Config {
    return ConfigFromJSONTyped(json, false);
}

export function ConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): Config {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'type': json['type'],
        'parameters': json['parameters'],
        'secrets': json['secrets'],
    };
}

export function ConfigToJSON(value?: Config | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'parameters': value.parameters,
        'secrets': value.secrets,
    };
}

