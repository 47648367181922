/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListRoles200Response,
  ListScopes200Response,
  ScopeInfo,
} from '../models/index';
import {
    ListRoles200ResponseFromJSON,
    ListRoles200ResponseToJSON,
    ListScopes200ResponseFromJSON,
    ListScopes200ResponseToJSON,
    ScopeInfoFromJSON,
    ScopeInfoToJSON,
} from '../models/index';

export interface ListScopesRequest {
    orgId?: string;
}

/**
 * 
 */
export class RoleManagementApi extends runtime.BaseAPI {

    /**
     * List All Scopes
     */
    async listAllScopesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScopeInfo>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles/scopes/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScopeInfoFromJSON));
    }

    /**
     * List All Scopes
     */
    async listAllScopes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScopeInfo>> {
        const response = await this.listAllScopesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Roles
     */
    async listRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListRoles200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListRoles200ResponseFromJSON(jsonValue));
    }

    /**
     * List Roles
     */
    async listRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListRoles200Response> {
        const response = await this.listRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Scopes
     */
    async listScopesRaw(requestParameters: ListScopesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListScopes200Response>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles/scopes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListScopes200ResponseFromJSON(jsonValue));
    }

    /**
     * List Scopes
     */
    async listScopes(requestParameters: ListScopesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListScopes200Response> {
        const response = await this.listScopesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
