import type { FormikErrors, FormikValues } from "formik";
import type { SetFieldValue } from "../../../../utils";

export enum CustomHelmChartFields {
  CHART_LOCATION = "location",
  CHART_NAME = "name",
  CHART_ZIP = "chartZip",
  CHART_REPOSITORY = "repository",
}

export enum CustomHelmLocations {
  LOCAL = "localHelmChart",
  REPO = "helmChartRepo",
}

export interface CustomHelmForm {
  [CustomHelmChartFields.CHART_NAME]: string;
  [CustomHelmChartFields.CHART_ZIP]: File[];
  [CustomHelmChartFields.CHART_LOCATION]: CustomHelmLocations;
  [CustomHelmChartFields.CHART_REPOSITORY]: string;
}

export interface CustomHelmChartDrawerProps {
  open: boolean;
  onClose: (shouldRefreshResourceList?: boolean) => void;
}

export interface CustomHelmDrawerHeaderProps {
  onClose: () => void;
}

export interface CustomHelmDrawerFooterProps {
  onClose: () => void;
  isSubmitting: boolean;
}

export interface CustomHelmDrawerFormProps {
  values: FormikValues;
  setFieldValue: SetFieldValue<CustomHelmForm>;
  errors: FormikErrors<CustomHelmForm>;
}
