import surveyQuestions from "../../../constants/quick-appstack-create-questions.json";
import { ProvidersResponse, Repository, TargetCompute } from "../../apis/appcd";

export type StepContentProps = {
  activeStepId: string;
  activeQuestionIndex: number;
  pageMeta: QuickAppStackPageMeta | null;
  configuredProvider: string | null;
  onActiveStepIdChange: (stepId: string) => void;
  onUpdateQuestionIndex: (index: number) => void;
};

export type SurveyQuestionsProps = {
  activeQuestionIndex: number;
  onUpdateQuestionIndex: (index: number) => void;
};

export type HeaderProps = {
  title: string;
  subtitle: string;
  pageMeta: QuickAppStackPageMeta | null;
  onClose: () => void;
};

export type RepoOnboardFlowSelectionProps = {
  onActiveStepIdChange: (stepId: string) => void;
  pageMeta: QuickAppStackPageMeta | null;
  configuredProviderMap: Record<string, boolean>;
};

export type ConfigurePATProps = {
  scmProviders: ProvidersResponse[];
  configuredProviderMap: Record<string, boolean>;
};

export type SummarySectionProps = {
  selectedRepositories: Repository[];
};

export type ComponentCreationProps = {
  selectedRepositories: Repository[];
  pageMeta: QuickAppStackPageMeta | null;
};

export type TargetComputeSelectionProps = {
  targetCompute: TargetCompute;
};

export type GeneralDetailsProps = {
  pageMeta: QuickAppStackPageMeta | null;
};

export type PolicySelectionDrawerProps = {
  open: boolean;
  onClose: () => void;
};

export type StepWithSummarySectionProps = {
  children: React.ReactNode;
  selectedRepositories: Repository[];
};

export type RepoSelectionProps = {
  pageMeta: QuickAppStackPageMeta | null;
  activeStepId: string;
  onActiveStepIdChange: (stepId: string) => void;
  configuredProvider: string | null;
};

export enum QuickAppStackCreationStep {
  "SURVEY_QUESTIONS" = "SURVEY_QUESTIONS",
  "REPO_ONBOARD_FLOW_SELECTION" = "REPO_ONBOARD_FLOW_SELECTION",
  "REPO_ONBOARD_PAT" = "REPO_ONBOARD_PAT",
  "REPO_ONBOARD_PUBLIC_URL" = "REPO_ONBOARD_PUBLIC_URL",
  "REPO_ONBOARD_APPCD_REPOSITORIES" = "REPO_ONBOARD_APPCD_REPOSITORIES",
  "REPO_SELECTION" = "REPO_SELECTION",
  "COMPONENT_ADDITION" = "COMPONENT_ADDITION",
  "TARGET_COMPUTE_SELECTION" = "TARGET_COMPUTE_SELECTION",
  "GENERAL_DETAILS" = "GENERAL_DETAILS",
}

export type Option = {
  id: string;
  text: string;
};

export type Question = {
  id: string;
  text: string;
  options: Option[];
  type: string;
  isMandatory: boolean;
};

export enum RepoOnboardingFlow {
  "SCM_CONFIGURATION" = "SCM_CONFIGURATION",
  "APPCD_REPOSITORIES" = "APPCD_REPOSITORIES",
  "PAT" = "PAT",
  "PUBLIC_URL" = "PUBLIC_URL",
}

export type QuickAppStackCreationForm = {
  name: string;
  description: string;
  labels: string[];
  questionResponses: {
    [key: string]: string | string[];
  };
  providerForPAT: string;
  enteredPAT: string;
  patMetadataOrgName: string;
  publicRepoUrls: string[];
  selectedRepositories: string[];
  coreConfig: {
    targetCompute: string;
    targetIac: string[];
    provider: string;
    policySettings: {
      policies: string[];
      frameworks: string[];
      categories: string[];
      providers: string[];
    };
  };
  repositories: {
    [key: string]: {
      isSelected: boolean;
      provider: string;
      components: (typeof emptyComponent)[];
    };
  };
  policyFilterProviders: string[];
  selectedDemoRepository: string;
  repoOnboardingFlow: RepoOnboardingFlow;
};

export const questions: Question[] = surveyQuestions ?? [];

export const quickAppStackCreationFormDefault: QuickAppStackCreationForm = {
  name: "",
  description: "",
  labels: [],
  questionResponses: questions.reduce(
    (acc: Record<string, string | string[]>, question: Question) => {
      acc[question.id] = question?.type === "single_select" ? "" : [];
      return acc;
    },
    {}
  ),
  providerForPAT: "",
  enteredPAT: "",
  patMetadataOrgName: "",
  publicRepoUrls: [""],
  selectedRepositories: [],
  repositories: {},
  coreConfig: {
    targetCompute: "",
    targetIac: [],
    provider: "",
    policySettings: {
      policies: [],
      providers: [],
      categories: [],
      frameworks: [],
    },
  },
  policyFilterProviders: [],
  selectedDemoRepository: "",
  repoOnboardingFlow: RepoOnboardingFlow.SCM_CONFIGURATION,
};

export type FooterProps = {
  disableNext: boolean;
  isLastStep: boolean;
  hideSkipForNow: boolean;
  hideNextButton: boolean;
  hideBackButton: boolean;
  nextButtonType: "submit" | "button";
  isLoading: boolean;
  pageMeta: QuickAppStackPageMeta | null;
  onNext: () => void;
  onBack: () => void;
  onSkipForNow: () => void;
};

export const emptyComponent = {
  name: "",
  analysisPath: "",
  language: "",
  containerImageReference: "",
  containerPath: "",
  branch: "",
  source: null,
};

export enum QuickAppStackPageReferrer {
  "CREATE_APPSTACK_FROM_SOURCE_CODE" = "CREATE_APPSTACK_FROM_SOURCE_CODE",
  "HELP_CENTER" = "HELP_CENTER",
  "LOGIN_PAGE" = "LOGIN_PAGE",
}

export type QuickAppStackPageMeta = {
  referrer?: QuickAppStackPageReferrer;
};
