import { useCallback, useState } from "react";
import type { onChangeFiles } from "../file-dropzone-types";

const useFileDropzone = (
  onChangeFiles: onChangeFiles,
  initialFiles: File[] = []
) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(initialFiles);
  const [isDragging, setIsDragging] = useState(false);

  const addFiles = (files: File[]) => {
    const newFiles: File[] = [...uploadedFiles, ...files];
    setUploadedFiles(newFiles);
    onChangeFiles?.(newFiles);
  };

  const removeFile = (fileToRemove: File) => {
    const newFiles = uploadedFiles.filter((file) => file !== fileToRemove);
    setUploadedFiles(newFiles);
    onChangeFiles?.(newFiles);
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(event.target.files || []);
      if (files.length) {
        addFiles(files);
      }
    },
    []
  );

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files || []);
    if (files.length) {
      addFiles(files);
    }
  }, []);

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    },
    []
  );

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  return {
    uploadedFiles,
    isDragging,
    addFiles,
    removeFile,
    handleFileChange,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  };
};

export default useFileDropzone;
