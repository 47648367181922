/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TargetCompute } from './TargetCompute';
import {
    TargetComputeFromJSON,
    TargetComputeFromJSONTyped,
    TargetComputeToJSON,
} from './TargetCompute';

/**
 * 
 * @export
 * @interface GovernanceRules
 */
export interface GovernanceRules {
    /**
     * List of target compute that can be used when creating an appstack. If null or empty, any target compute will be allowed to be set.
     * @type {Set<TargetCompute>}
     * @memberof GovernanceRules
     */
    allowedTargetCompute?: Set<TargetCompute>;
    /**
     * List of security policies that must be set and can only be set, if null, any security policies will be allowed to be set.
     * @type {Set<string>}
     * @memberof GovernanceRules
     */
    requiredSecurityPolicies?: Set<string>;
    /**
     * List of resources policies that will limit the avialble IaC resources for an appstack. If null or empty, no restrictions will be applied.
     * @type {Set<string>}
     * @memberof GovernanceRules
     */
    customResourceRestrictionPolicies?: Set<string>;
    /**
     * List of custom iam policies that will be required if the resource is used.  Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceRules
     */
    customIamPolicies?: Set<string>;
    /**
     * List of resource mapping policies that will apply to topology resources when creating an appstack. Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceRules
     */
    customResourceMappingPolicies?: Set<string>;
    /**
     * List of custom security policies that will be required if the resource is used. Ignored if null or empty.
     * @type {Set<string>}
     * @memberof GovernanceRules
     */
    customResourceSecurityPolicies?: Set<string>;
}

/**
 * Check if a given object implements the GovernanceRules interface.
 */
export function instanceOfGovernanceRules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GovernanceRulesFromJSON(json: any): GovernanceRules {
    return GovernanceRulesFromJSONTyped(json, false);
}

export function GovernanceRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GovernanceRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedTargetCompute': !exists(json, 'allowedTargetCompute') ? undefined : (new Set((json['allowedTargetCompute'] as Array<any>).map(TargetComputeFromJSON))),
        'requiredSecurityPolicies': !exists(json, 'requiredSecurityPolicies') ? undefined : json['requiredSecurityPolicies'],
        'customResourceRestrictionPolicies': !exists(json, 'customResourceRestrictionPolicies') ? undefined : json['customResourceRestrictionPolicies'],
        'customIamPolicies': !exists(json, 'customIamPolicies') ? undefined : json['customIamPolicies'],
        'customResourceMappingPolicies': !exists(json, 'customResourceMappingPolicies') ? undefined : json['customResourceMappingPolicies'],
        'customResourceSecurityPolicies': !exists(json, 'customResourceSecurityPolicies') ? undefined : json['customResourceSecurityPolicies'],
    };
}

export function GovernanceRulesToJSON(value?: GovernanceRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedTargetCompute': value.allowedTargetCompute === undefined ? undefined : (Array.from(value.allowedTargetCompute as Set<any>).map(TargetComputeToJSON)),
        'requiredSecurityPolicies': value.requiredSecurityPolicies === undefined ? undefined : Array.from(value.requiredSecurityPolicies as Set<any>),
        'customResourceRestrictionPolicies': value.customResourceRestrictionPolicies === undefined ? undefined : Array.from(value.customResourceRestrictionPolicies as Set<any>),
        'customIamPolicies': value.customIamPolicies === undefined ? undefined : Array.from(value.customIamPolicies as Set<any>),
        'customResourceMappingPolicies': value.customResourceMappingPolicies === undefined ? undefined : Array.from(value.customResourceMappingPolicies as Set<any>),
        'customResourceSecurityPolicies': value.customResourceSecurityPolicies === undefined ? undefined : Array.from(value.customResourceSecurityPolicies as Set<any>),
    };
}

