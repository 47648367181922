import React, { type FC } from "react";

import { SelectBox, TextBox } from "../../../../components";
import {
  ResourcePackFields,
  type ResourcePackDrawerFormProps,
} from "./resource-pack-types";

const ResourcePackForm: FC<ResourcePackDrawerFormProps> = ({
  setFieldValue,
  options,
}) => (
  <div className="tw-flex tw-gap-6 tw-flex-col tw-mb-16">
    <div className="tw-grid tw-grid-cols-1 tw-gap-4">
      <TextBox
        name={ResourcePackFields.PACK_NAME}
        label="Resource pack name"
        backgroundVariant="gray-800"
        dataTestId="resource-pack-name"
        placeholder="Enter resource pack name"
        required
      />
      <TextBox
        name={ResourcePackFields.DESCRIPTION}
        label="Resource pack description"
        backgroundVariant="gray-800"
        dataTestId="resource-pack-description"
        placeholder="Enter resource pack description"
      />
      <SelectBox
        name={ResourcePackFields.SHARE_WITH}
        label="Share with"
        backgroundVariant="gray-800"
        placeholder="Search or select from list"
        options={options.shareWith}
        onChange={(selectedValue) => {
          setFieldValue(ResourcePackFields.SHARE_WITH, selectedValue?.value);
        }}
        dataTestId="share-with"
        required
      />
    </div>
  </div>
);

export default ResourcePackForm;
