import React, { type FC, useEffect } from "react";
import { Field, type FieldProps } from "formik";
import Editor, { useMonaco, type Monaco } from "@monaco-editor/react";
import type { editor } from "monaco-editor";

// TODO: Remove direct store use inside common component
import { useSetAtom } from "jotai";
import { dialogAtom } from "../../stores/page.store";

import { TextAreaProps, BackgroundVariantValues } from "./text-area-types";
import { Icon, IconsList, Button } from "../../components";
import { BackgroundVariant, LabelVariant } from "./form-elements-types";

import { isFunction } from "../../utils";

/**
 * Functional component for rendering a text area input field with various props.
 *
 * @param {TextAreaProps} name - The name of the input field
 * @param {TextAreaProps} label - The label for the input field
 * @param {TextAreaProps} description - The description of the input field
 * @param {string} [placeholder=""] - The placeholder for the input field (optional)
 * @param {string} [className=""] - The class name for styling the input field (optional)
 * @param {string} subLabel - The sub label for the input field
 * @param {boolean} [disabled=false] - Indicates if the input field is disabled (optional)
 * @param {boolean} [isOptional=false] - Indicates if the input field is optional (optional)
 * @param {number} maxCharacterLength - The maximum character length for the input field
 * @param {string} [backgroundVariant="gray-700"] - The background variant for the input field (optional)
 * @param {number} rows -Number of rows to show in textarea
 * @param {string} editorLanguage - The language for the editor, whether JSON, YAML or anything else. (optional)
 * @param {string} dataTestId - The data test id for the input field
 * @param {boolean} required - Indicates if the input field is required(to show required * mark).
 * @return {JSX.Element} The rendered text area input field with associated props
 */
const TextArea: FC<TextAreaProps> = ({
  name,
  label,
  description,
  placeholder = "",
  className = "",
  subLabel,
  disabled = false,
  isOptional = false,
  maxCharacterLength,
  backgroundVariant = "gray-700",
  labelVariant = "white-semibold",
  rows = 7,
  dataTestId,
  required,
  editorLanguage,
  labelSuffix,
  labelSuffixAction,
}) => {
  const setDialogData = useSetAtom(dialogAtom);

  const monaco = useMonaco();

  const editorOnMount = (
    editor: editor.IStandaloneCodeEditor,
    monaco: Monaco
  ) => {
    setTimeout(function () {
      editor
        .getAction("editor.action.formatDocument")
        ?.run()
        .then(() => {
          editor.updateOptions({
            readOnly: disabled,
          });
        });
    }, 50);
  };

  useEffect(() => {
    // TODO: move this logic to common component to reuse it at other place. Create Editor component.
    if (monaco) {
      monaco.editor.defineTheme("custom-theme", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
          "editor.background":
            BackgroundVariantValues[backgroundVariant] ?? "#0A0C0F",
        },
      });
    }
  }, [monaco]);

  const LabelComponent = () => {
    if (label) {
      return (
        <label
          className={`tw-text-xssm ${
            disabled
              ? LabelVariant["gray400-semibold"]
              : LabelVariant[labelVariant] ?? ""
          }`}
          htmlFor={name}
        >
          {label}{" "}
          {isOptional ? (
            <span
              className={` tw-font-normal ${
                disabled ? "tw-text-gray-400" : "tw-text-gray-200"
              }`}
            >
              {" "}
              (Optional)
            </span>
          ) : (
            required && <span className="tw-text-red-400"> *</span>
          )}
        </label>
      );
    } else {
      return null;
    }
  };

  const SubLabelComponent = () => {
    return (
      <>
        {subLabel && (
          <label
            className={`tw-text-xs tw-italic ${
              disabled ? "tw-text-gray-400" : "tw-text-white"
            }`}
          >
            {subLabel}
          </label>
        )}
        {labelSuffix && (
          <label
            className="tw-cursor-pointer"
            data-testid={`textarea-labelsuffix-${dataTestId}`}
            onClick={() => {
              labelSuffixAction &&
                isFunction(labelSuffixAction) &&
                labelSuffixAction();
            }}
          >
            {labelSuffix}
          </label>
        )}
      </>
    );
  };

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <div className="tw-flex tw-flex-col tw-gap-1">
          <div className="tw-flex tw-items-center tw-justify-between">
            <LabelComponent />
            <div className="tw-flex tw-gap-1 tw-items-center">
              <SubLabelComponent />
              {editorLanguage && (
                <div
                  onClick={() => {
                    setDialogData({
                      open: true,
                      size: "lg",
                      dataTestId: `${dataTestId}-dialog-opened`,
                      titleContent: (
                        <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
                          <span className="tw-text-white tw-text-base tw-font-semibold">
                            {label}
                          </span>
                          <button
                            onClick={() =>
                              setDialogData({
                                open: false,
                                dataTestId: `${dataTestId}-dialog-closed`,
                              })
                            }
                            type="button"
                          >
                            <Icon
                              name={IconsList.CLOSE}
                              className="tw-text-gray-300 hover:tw-text-gray-200 tw-cursor-pointer"
                            />
                          </button>
                        </div>
                      ),
                      bodyContent: (
                        <div className="tw-w-208 tw-flex tw-flex-col tw-gap-5">
                          <Editor
                            height="460px"
                            language={editorLanguage}
                            value={field.value}
                            theme="custom-theme"
                            options={{
                              minimap: { enabled: false },
                            }}
                            onChange={(value) => {
                              form.setFieldValue(name, value);
                            }}
                            onMount={editorOnMount}
                          />
                        </div>
                      ),
                      footerContent: (
                        <div className="tw-flex tw-gap-3 tw-justify-end">
                          <Button
                            label="Done"
                            variant="primary"
                            onClick={() =>
                              setDialogData({
                                open: false,
                                dataTestId: `${dataTestId}-dialog-closed`,
                              })
                            }
                            dataTestId={`${dataTestId}-expand-done`}
                          />
                        </div>
                      ),
                    });
                  }}
                  className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
                  data-testid={`${dataTestId}-expand-expand`}
                >
                  <Icon name={IconsList.EXPAND} />
                </div>
              )}
            </div>
          </div>
          {editorLanguage ? (
            <>
              <Editor
                className={`${meta.touched && meta.error ? "error-field" : ""}`}
                height="160px"
                language={editorLanguage}
                value={field.value}
                theme="custom-theme"
                options={{
                  minimap: { enabled: false },
                  lineNumbers: "off",
                }}
                onChange={(value) => {
                  form.setFieldValue(name, value);
                }}
                data-testid={`${dataTestId}-editor`}
                onMount={editorOnMount}
              />
            </>
          ) : (
            <textarea
              {...field}
              className={`tw-text-sm tw-rounded-lg tw-block tw-w-full tw-placeholder-gray-400 ${className} ${
                disabled
                  ? `tw-bg-gray-500 tw-opacity-20 tw-border-none tw-text-gray-400 tw-cursor-not-allowed ${
                      meta.touched && meta.error ? "error-field" : ""
                    }`
                  : `tw-border tw-text-gray-100 ${
                      meta.touched && meta.error
                        ? "tw-border-red-400 error-field"
                        : "tw-border-gray-600"
                    } ${BackgroundVariant[backgroundVariant] ?? ""}`
              }`}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxCharacterLength}
              rows={rows}
              data-testid={`${dataTestId}-textarea`}
            />
          )}
          <div>
            {meta.touched && meta.error ? (
              <div className="tw-text-xs tw-text-red-400">{meta.error}</div>
            ) : null}
            {description && (
              <p className="tw-text-xs tw-text-gray-300 tw-break-all">
                {description}
              </p>
            )}
          </div>
        </div>
      )}
    </Field>
  );
};

export default TextArea;
