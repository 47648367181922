/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface ResourceIdWithPosition
 */
export interface ResourceIdWithPosition {
    /**
     * 
     * @type {string}
     * @memberof ResourceIdWithPosition
     */
    resourceId?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof ResourceIdWithPosition
     */
    position?: Coordinates;
}

/**
 * Check if a given object implements the ResourceIdWithPosition interface.
 */
export function instanceOfResourceIdWithPosition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceIdWithPositionFromJSON(json: any): ResourceIdWithPosition {
    return ResourceIdWithPositionFromJSONTyped(json, false);
}

export function ResourceIdWithPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceIdWithPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        'position': !exists(json, 'position') ? undefined : CoordinatesFromJSON(json['position']),
    };
}

export function ResourceIdWithPositionToJSON(value?: ResourceIdWithPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resourceId': value.resourceId,
        'position': CoordinatesToJSON(value.position),
    };
}

