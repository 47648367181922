/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConvertToExternalResourceRequest,
  CreateGroupResourceRequest,
  MergeResourcesRequest,
  MoveResourcesRequest,
  ProviderWiseSupportedResourceType,
  ResourceIAMDiff,
  ResourceIdWithPosition,
  ResourcePosition,
  Topology,
  TopologyResourceValidations,
  TopologyResourceWithMisconfigurations,
  TopologyResourceWithPosition,
  UpdateResourceRequest,
} from '../models/index';
import {
    ConvertToExternalResourceRequestFromJSON,
    ConvertToExternalResourceRequestToJSON,
    CreateGroupResourceRequestFromJSON,
    CreateGroupResourceRequestToJSON,
    MergeResourcesRequestFromJSON,
    MergeResourcesRequestToJSON,
    MoveResourcesRequestFromJSON,
    MoveResourcesRequestToJSON,
    ProviderWiseSupportedResourceTypeFromJSON,
    ProviderWiseSupportedResourceTypeToJSON,
    ResourceIAMDiffFromJSON,
    ResourceIAMDiffToJSON,
    ResourceIdWithPositionFromJSON,
    ResourceIdWithPositionToJSON,
    ResourcePositionFromJSON,
    ResourcePositionToJSON,
    TopologyFromJSON,
    TopologyToJSON,
    TopologyResourceValidationsFromJSON,
    TopologyResourceValidationsToJSON,
    TopologyResourceWithMisconfigurationsFromJSON,
    TopologyResourceWithMisconfigurationsToJSON,
    TopologyResourceWithPositionFromJSON,
    TopologyResourceWithPositionToJSON,
    UpdateResourceRequestFromJSON,
    UpdateResourceRequestToJSON,
} from '../models/index';

export interface ConvertToExternalResourceOperationRequest {
    convertToExternalResourceRequest: ConvertToExternalResourceRequest;
    orgId?: string;
}

export interface CreateGroupResourceOperationRequest {
    topologyId: string;
    createGroupResourceRequest: CreateGroupResourceRequest;
    orgId?: string;
}

export interface CreateResourceRequest {
    topologyId: string;
    topologyResourceWithPosition: TopologyResourceWithPosition;
    orgId?: string;
}

export interface DeleteResourceRequest {
    topologyId: string;
    resourceId: string;
    orgId?: string;
}

export interface GetResourceRequest {
    topologyId: string;
    resourceId: string;
    orgId?: string;
}

export interface GetResourceTemplateForResourceTypeRequest {
    resourceType: string;
    templateId?: string;
}

export interface GetSupportedResourceTypesRequest {
    appstackId?: string;
    deploymentType?: string;
}

export interface MergeResourcesOperationRequest {
    topologyId: string;
    mergeResourcesRequest: MergeResourcesRequest;
    orgId?: string;
}

export interface MoveTopologyResourcesRequest {
    moveResourcesRequest: MoveResourcesRequest;
    orgId?: string;
}

export interface UpdateResourceOperationRequest {
    topologyId: string;
    resourceId: string;
    updateResourceRequest: UpdateResourceRequest;
    orgId?: string;
}

export interface UpdateResourcePositionRequest {
    topologyId: string;
    resourceId: string;
    resourcePosition: ResourcePosition;
    orgId?: string;
}

export interface UpdateResourcePositionsRequest {
    topologyId: string;
    resourceIdWithPosition: Array<ResourceIdWithPosition>;
    orgId?: string;
}

export interface UpdateResourcesRequest {
    topologyId: string;
    updateResourceRequest: Array<UpdateResourceRequest>;
    orgId?: string;
}

/**
 * 
 */
export class V1ResourceApi extends runtime.BaseAPI {

    /**
     * Convert an existing cloud resource to an external resource
     */
    async convertToExternalResourceRaw(requestParameters: ConvertToExternalResourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceWithMisconfigurations>> {
        if (requestParameters.convertToExternalResourceRequest === null || requestParameters.convertToExternalResourceRequest === undefined) {
            throw new runtime.RequiredError('convertToExternalResourceRequest','Required parameter requestParameters.convertToExternalResourceRequest was null or undefined when calling convertToExternalResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/external-resource`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConvertToExternalResourceRequestToJSON(requestParameters.convertToExternalResourceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceWithMisconfigurationsFromJSON(jsonValue));
    }

    /**
     * Convert an existing cloud resource to an external resource
     */
    async convertToExternalResource(requestParameters: ConvertToExternalResourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceWithMisconfigurations> {
        const response = await this.convertToExternalResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create a group resource for a topology
     * create group resource for topology
     */
    async createGroupResourceRaw(requestParameters: CreateGroupResourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceWithMisconfigurations>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling createGroupResource.');
        }

        if (requestParameters.createGroupResourceRequest === null || requestParameters.createGroupResourceRequest === undefined) {
            throw new runtime.RequiredError('createGroupResourceRequest','Required parameter requestParameters.createGroupResourceRequest was null or undefined when calling createGroupResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/group-resources`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateGroupResourceRequestToJSON(requestParameters.createGroupResourceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceWithMisconfigurationsFromJSON(jsonValue));
    }

    /**
     * create a group resource for a topology
     * create group resource for topology
     */
    async createGroupResource(requestParameters: CreateGroupResourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceWithMisconfigurations> {
        const response = await this.createGroupResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a resource for a topology
     * create resource for topology
     */
    async createResourceRaw(requestParameters: CreateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceWithMisconfigurations>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling createResource.');
        }

        if (requestParameters.topologyResourceWithPosition === null || requestParameters.topologyResourceWithPosition === undefined) {
            throw new runtime.RequiredError('topologyResourceWithPosition','Required parameter requestParameters.topologyResourceWithPosition was null or undefined when calling createResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resources`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TopologyResourceWithPositionToJSON(requestParameters.topologyResourceWithPosition),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceWithMisconfigurationsFromJSON(jsonValue));
    }

    /**
     * Create a resource for a topology
     * create resource for topology
     */
    async createResource(requestParameters: CreateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceWithMisconfigurations> {
        const response = await this.createResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete a resource from topology
     * Delete resource from topology
     */
    async deleteResourceRaw(requestParameters: DeleteResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling deleteResource.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling deleteResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resources/{resourceId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete a resource from topology
     * Delete resource from topology
     */
    async deleteResource(requestParameters: DeleteResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResourceRaw(requestParameters, initOverrides);
    }

    /**
     * Fetch topology resource by ID
     * Get topology resource by ID
     */
    async getResourceRaw(requestParameters: GetResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceWithMisconfigurations>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling getResource.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling getResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resources/{resourceId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceWithMisconfigurationsFromJSON(jsonValue));
    }

    /**
     * Fetch topology resource by ID
     * Get topology resource by ID
     */
    async getResource(requestParameters: GetResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceWithMisconfigurations> {
        const response = await this.getResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get template for a resource type
     */
    async getResourceTemplateForResourceTypeRaw(requestParameters: GetResourceTemplateForResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceValidations>> {
        if (requestParameters.resourceType === null || requestParameters.resourceType === undefined) {
            throw new runtime.RequiredError('resourceType','Required parameter requestParameters.resourceType was null or undefined when calling getResourceTemplateForResourceType.');
        }

        const queryParameters: any = {};

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resourceType'] = requestParameters.resourceType;
        }

        if (requestParameters.templateId !== undefined) {
            queryParameters['templateId'] = requestParameters.templateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceValidationsFromJSON(jsonValue));
    }

    /**
     * Get template for a resource type
     */
    async getResourceTemplateForResourceType(requestParameters: GetResourceTemplateForResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceValidations> {
        const response = await this.getResourceTemplateForResourceTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List supported resource types
     */
    async getSupportedResourceTypesRaw(requestParameters: GetSupportedResourceTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProviderWiseSupportedResourceType>> {
        const queryParameters: any = {};

        if (requestParameters.appstackId !== undefined) {
            queryParameters['appstackId'] = requestParameters.appstackId;
        }

        if (requestParameters.deploymentType !== undefined) {
            queryParameters['deploymentType'] = requestParameters.deploymentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/supported-resource-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderWiseSupportedResourceTypeFromJSON(jsonValue));
    }

    /**
     * List supported resource types
     */
    async getSupportedResourceTypes(requestParameters: GetSupportedResourceTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProviderWiseSupportedResourceType> {
        const response = await this.getSupportedResourceTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Merge resources
     */
    async mergeResourcesRaw(requestParameters: MergeResourcesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceIAMDiff>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling mergeResources.');
        }

        if (requestParameters.mergeResourcesRequest === null || requestParameters.mergeResourcesRequest === undefined) {
            throw new runtime.RequiredError('mergeResourcesRequest','Required parameter requestParameters.mergeResourcesRequest was null or undefined when calling mergeResources.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/merge-resources`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MergeResourcesRequestToJSON(requestParameters.mergeResourcesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceIAMDiffFromJSON(jsonValue));
    }

    /**
     * Merge resources
     */
    async mergeResources(requestParameters: MergeResourcesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceIAMDiff> {
        const response = await this.mergeResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Move resources and connections to another topology
     */
    async moveTopologyResourcesRaw(requestParameters: MoveTopologyResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Topology>> {
        if (requestParameters.moveResourcesRequest === null || requestParameters.moveResourcesRequest === undefined) {
            throw new runtime.RequiredError('moveResourcesRequest','Required parameter requestParameters.moveResourcesRequest was null or undefined when calling moveTopologyResources.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/move-resources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MoveResourcesRequestToJSON(requestParameters.moveResourcesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyFromJSON(jsonValue));
    }

    /**
     * Move resources and connections to another topology
     */
    async moveTopologyResources(requestParameters: MoveTopologyResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Topology> {
        const response = await this.moveTopologyResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update topology resource by ID
     */
    async updateResourceRaw(requestParameters: UpdateResourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TopologyResourceWithMisconfigurations>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling updateResource.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling updateResource.');
        }

        if (requestParameters.updateResourceRequest === null || requestParameters.updateResourceRequest === undefined) {
            throw new runtime.RequiredError('updateResourceRequest','Required parameter requestParameters.updateResourceRequest was null or undefined when calling updateResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resources/{resourceId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateResourceRequestToJSON(requestParameters.updateResourceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopologyResourceWithMisconfigurationsFromJSON(jsonValue));
    }

    /**
     * Update topology resource by ID
     */
    async updateResource(requestParameters: UpdateResourceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TopologyResourceWithMisconfigurations> {
        const response = await this.updateResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update topology resource position by ID
     */
    async updateResourcePositionRaw(requestParameters: UpdateResourcePositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling updateResourcePosition.');
        }

        if (requestParameters.resourceId === null || requestParameters.resourceId === undefined) {
            throw new runtime.RequiredError('resourceId','Required parameter requestParameters.resourceId was null or undefined when calling updateResourcePosition.');
        }

        if (requestParameters.resourcePosition === null || requestParameters.resourcePosition === undefined) {
            throw new runtime.RequiredError('resourcePosition','Required parameter requestParameters.resourcePosition was null or undefined when calling updateResourcePosition.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resources/{resourceId}/positions`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))).replace(`{${"resourceId"}}`, encodeURIComponent(String(requestParameters.resourceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePositionToJSON(requestParameters.resourcePosition),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update topology resource position by ID
     */
    async updateResourcePosition(requestParameters: UpdateResourcePositionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateResourcePositionRaw(requestParameters, initOverrides);
    }

    /**
     * Update positions for multiple resources
     */
    async updateResourcePositionsRaw(requestParameters: UpdateResourcePositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling updateResourcePositions.');
        }

        if (requestParameters.resourceIdWithPosition === null || requestParameters.resourceIdWithPosition === undefined) {
            throw new runtime.RequiredError('resourceIdWithPosition','Required parameter requestParameters.resourceIdWithPosition was null or undefined when calling updateResourcePositions.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resource-positions`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.resourceIdWithPosition.map(ResourceIdWithPositionToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update positions for multiple resources
     */
    async updateResourcePositions(requestParameters: UpdateResourcePositionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateResourcePositionsRaw(requestParameters, initOverrides);
    }

    /**
     * update multiple resources for a topology
     * update multiple resources for a topology
     */
    async updateResourcesRaw(requestParameters: UpdateResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling updateResources.');
        }

        if (requestParameters.updateResourceRequest === null || requestParameters.updateResourceRequest === undefined) {
            throw new runtime.RequiredError('updateResourceRequest','Required parameter requestParameters.updateResourceRequest was null or undefined when calling updateResources.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/topologies/{topologyId}/resources`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateResourceRequest.map(UpdateResourceRequestToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update multiple resources for a topology
     * update multiple resources for a topology
     */
    async updateResources(requestParameters: UpdateResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateResourcesRaw(requestParameters, initOverrides);
    }

}
