/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Misconfigurations
 */
export interface Misconfigurations {
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    ruleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    severity?: string;
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    resourceType?: string;
    /**
     * 
     * @type {string}
     * @memberof Misconfigurations
     */
    provider?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Misconfigurations
     */
    attributes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Misconfigurations
     */
    remediation?: Array<string>;
}

/**
 * Check if a given object implements the Misconfigurations interface.
 */
export function instanceOfMisconfigurations(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MisconfigurationsFromJSON(json: any): Misconfigurations {
    return MisconfigurationsFromJSONTyped(json, false);
}

export function MisconfigurationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Misconfigurations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'remediation': !exists(json, 'remediation') ? undefined : json['remediation'],
    };
}

export function MisconfigurationsToJSON(value?: Misconfigurations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ruleId': value.ruleId,
        'name': value.name,
        'description': value.description,
        'severity': value.severity,
        'resourceType': value.resourceType,
        'provider': value.provider,
        'attributes': value.attributes,
        'remediation': value.remediation,
    };
}

