export enum DrawerSize {
  xs = "xs",
  sm = "sm",
  smsmmd = "sm-sm-md",
  smmd = "sm-md",
  md = "md",
  lg = "lg",
}

export enum DrawerPosition {
  Left = "left",
  Right = "right",
  Bottom = "bottom",
}

export const drawerOpenMap = (position: DrawerPosition) => {
  if (position === DrawerPosition.Left || position === DrawerPosition.Right) {
    return {
      [DrawerSize.xs]: "tw-translate-x-100",
      [DrawerSize.sm]: "tw-translate-x-100",
      [DrawerSize.smsmmd]: "tw-translate-x-105",
      [DrawerSize.smmd]: "tw-translate-x-125",
      [DrawerSize.md]: "tw-translate-x-225",
      [DrawerSize.lg]: "tw-translate-x-320",
    };
  }
  return {
    [DrawerSize.xs]: "tw-translate-y-70",
    [DrawerSize.sm]: "tw-translate-y-100",
    [DrawerSize.smsmmd]: "tw-translate-y-125",
    [DrawerSize.smmd]: "tw-translate-y-225",
    [DrawerSize.md]: "tw-translate-y-320",
    [DrawerSize.lg]: "tw-translate-y-320",
  };
};

export const drawerCloseMap = (position: DrawerPosition) => {
  if (position === DrawerPosition.Left || position === DrawerPosition.Right) {
    return {
      [DrawerSize.xs]: "tw--translate-x-100",
      [DrawerSize.sm]: "tw--translate-x-100",
      [DrawerSize.smsmmd]: "tw-translate-x-105",
      [DrawerSize.smmd]: "tw-translate-x-125",
      [DrawerSize.md]: "tw--translate-x-225",
      [DrawerSize.lg]: "tw--translate-x-320",
    };
  }
  return {
    [DrawerSize.xs]: "tw-translate-y-70",
    [DrawerSize.sm]: "tw-translate-y-100",
    [DrawerSize.smsmmd]: "tw-translate-y-125",
    [DrawerSize.smmd]: "tw-translate-y-225",
    [DrawerSize.md]: "tw-translate-y-320",
    [DrawerSize.lg]: "tw-translate-y-320",
  };
};
export const drawerDimensionsMap = (position: DrawerPosition) => {
  if (position === DrawerPosition.Left || position === DrawerPosition.Right) {
    return {
      [DrawerSize.xs]: "tw-h-full tw-w-80",
      [DrawerSize.sm]: "tw-h-full tw-w-100",
      [DrawerSize.smsmmd]: "tw-h-full tw-w-100",
      [DrawerSize.smmd]: "tw-h-full tw-w-125",
      [DrawerSize.md]: "tw-h-full tw-w-225",
      [DrawerSize.lg]: "tw-h-full tw-w-320",
    };
  }
  return {
    [DrawerSize.xs]: "tw-w-full tw-h-50",
    [DrawerSize.sm]: "tw-w-full tw-h-64",
    [DrawerSize.smsmmd]: "tw-w-full tw-h-72",
    [DrawerSize.smmd]: "tw-w-full tw-h-",
    [DrawerSize.md]: "tw-w-full tw-h-100",
    [DrawerSize.lg]: "tw-w-full tw-h-150",
  };
};
export const getDrawerPosition = (position: DrawerPosition) => {
  if (position === DrawerPosition.Left) return "tw-top-0 tw-left-0";
  if (position === DrawerPosition.Right) return "tw-top-0 tw-right-0";
  if (position === DrawerPosition.Bottom) return "tw-bottom-0 tw-left-0";
  return "tw-top-0 tw-left-0";
};

export interface DrawerProps {
  open: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  size?: keyof typeof DrawerSize;
  isResizable?: boolean;
  disableBackdropClick?: boolean;
  position?: keyof typeof DrawerPosition;
  isFullScreenDrawer?: boolean;
  minResizableWidth?: number;
  maxResizableWidth?: number;
  dataTestId: string;
}
