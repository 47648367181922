/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExportIACRequestOverridesInner } from './ExportIACRequestOverridesInner';
import {
    ExportIACRequestOverridesInnerFromJSON,
    ExportIACRequestOverridesInnerFromJSONTyped,
    ExportIACRequestOverridesInnerToJSON,
} from './ExportIACRequestOverridesInner';

/**
 * 
 * @export
 * @interface ExportIACRequest
 */
export interface ExportIACRequest {
    /**
     * topology to be exported
     * @type {string}
     * @memberof ExportIACRequest
     */
    topologyId: string;
    /**
     * appstack name
     * @type {string}
     * @memberof ExportIACRequest
     */
    appstackName: string;
    /**
     * config_id
     * @type {string}
     * @memberof ExportIACRequest
     */
    configId: string;
    /**
     * Overrides of the export
     * @type {Array<ExportIACRequestOverridesInner>}
     * @memberof ExportIACRequest
     */
    overrides?: Array<ExportIACRequestOverridesInner>;
}

/**
 * Check if a given object implements the ExportIACRequest interface.
 */
export function instanceOfExportIACRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "topologyId" in value;
    isInstance = isInstance && "appstackName" in value;
    isInstance = isInstance && "configId" in value;

    return isInstance;
}

export function ExportIACRequestFromJSON(json: any): ExportIACRequest {
    return ExportIACRequestFromJSONTyped(json, false);
}

export function ExportIACRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportIACRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topologyId': json['topology_id'],
        'appstackName': json['appstack_name'],
        'configId': json['config_id'],
        'overrides': !exists(json, 'overrides') ? undefined : ((json['overrides'] as Array<any>).map(ExportIACRequestOverridesInnerFromJSON)),
    };
}

export function ExportIACRequestToJSON(value?: ExportIACRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topology_id': value.topologyId,
        'appstack_name': value.appstackName,
        'config_id': value.configId,
        'overrides': value.overrides === undefined ? undefined : ((value.overrides as Array<any>).map(ExportIACRequestOverridesInnerToJSON)),
    };
}

