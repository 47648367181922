/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeValidation } from './AttributeValidation';
import {
    AttributeValidationFromJSON,
    AttributeValidationFromJSONTyped,
    AttributeValidationToJSON,
} from './AttributeValidation';
import type { ResourceConfigOptions } from './ResourceConfigOptions';
import {
    ResourceConfigOptionsFromJSON,
    ResourceConfigOptionsFromJSONTyped,
    ResourceConfigOptionsToJSON,
} from './ResourceConfigOptions';

/**
 * 
 * @export
 * @interface SchemaItem
 */
export interface SchemaItem {
    /**
     * 
     * @type {string}
     * @memberof SchemaItem
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaItem
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaItem
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaItem
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof SchemaItem
     */
    defaultVal?: any;
    /**
     * 
     * @type {ResourceConfigOptions}
     * @memberof SchemaItem
     */
    options?: ResourceConfigOptions;
    /**
     * 
     * @type {AttributeValidation}
     * @memberof SchemaItem
     */
    validation?: AttributeValidation;
}

/**
 * Check if a given object implements the SchemaItem interface.
 */
export function instanceOfSchemaItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SchemaItemFromJSON(json: any): SchemaItem {
    return SchemaItemFromJSONTyped(json, false);
}

export function SchemaItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'defaultVal': !exists(json, 'defaultVal') ? undefined : json['defaultVal'],
        'options': !exists(json, 'options') ? undefined : ResourceConfigOptionsFromJSON(json['options']),
        'validation': !exists(json, 'validation') ? undefined : AttributeValidationFromJSON(json['validation']),
    };
}

export function SchemaItemToJSON(value?: SchemaItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'label': value.label,
        'type': value.type,
        'description': value.description,
        'defaultVal': value.defaultVal,
        'options': ResourceConfigOptionsToJSON(value.options),
        'validation': AttributeValidationToJSON(value.validation),
    };
}

