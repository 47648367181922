import React, { type FC } from "react";
import { type StepperProps } from "./stepper.types";

const Stepper: FC<StepperProps> = ({
  steps = [],
  activeStepNumber,
  allowIncompleteStepClick = true,
  dataTestId,
  onStepClick,
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-0.5 tw-relative">
      {steps.map((step, index) => {
        const isGreaterThanActiveStep = activeStepNumber < index + 1;
        const isActiveStep = activeStepNumber === index + 1;
        const isActiveOrLessThanStep = index + 1 <= activeStepNumber;

        const borderClass = isGreaterThanActiveStep
          ? "tw-border-gray-500"
          : isActiveStep
          ? "tw-border-blue-500"
          : "tw-border-green-500";

        const backgroundClass = isGreaterThanActiveStep
          ? "tw-bg-gray-500"
          : isActiveStep
          ? "tw-bg-blue-500"
          : "tw-bg-green-500";

        const stepTitleClass = isActiveOrLessThanStep
          ? "tw-text-white tw-font-semibold"
          : "tw-text-gray-300 tw-font-normal";

        const stepNumberClass = isGreaterThanActiveStep
          ? "tw-text-white"
          : "tw-text-black";

        const cursorClass =
          allowIncompleteStepClick || isActiveOrLessThanStep
            ? "tw-cursor-pointer"
            : "";

        return (
          <div
            key={`step_${index}`}
            className={`tw-flex tw-gap-4 tw-green-500 tw-duration-300 tw-transition-all ${borderClass} ${
              index === steps.length - 1 ? "" : "tw-border-l-4"
            } ${isActiveStep ? "tw-pb-28" : "tw-pb-7"}`}
          >
            <div
              onClick={() =>
                allowIncompleteStepClick
                  ? onStepClick(index + 1)
                  : isActiveOrLessThanStep && onStepClick(index + 1)
              }
              className={`tw-h-6 tw-w-6 tw-rounded tw-ring-gray-900 tw-ring-2 tw-flex tw-justify-center tw-items-center tw-absolute tw--left-2.5 ${backgroundClass} ${cursorClass}`}
              data-testid={`${dataTestId}-step-number-${index}`}
            >
              <span
                className={`tw-font-semibold tw-text-sm ${stepNumberClass}`}
              >
                {index + 1}
              </span>
            </div>
            <div
              className={`tw-pl-6 ${cursorClass}`}
              onClick={() =>
                allowIncompleteStepClick
                  ? onStepClick(index + 1)
                  : isActiveOrLessThanStep && onStepClick(index + 1)
              }
              data-testid={`${dataTestId}-step-text-${index}`}
            >
              <span
                className={`tw-duration-200 tw-text-xssm ${stepTitleClass}`}
              >
                {step.label}
              </span>
              {step.isOptional && (
                <span className="tw-text-xssm tw-font-normal tw-text-gray-200 tw-ml-1">
                  (Optional)
                </span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
