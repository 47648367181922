/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface ResourcePosition
 */
export interface ResourcePosition {
    /**
     * 
     * @type {Coordinates}
     * @memberof ResourcePosition
     */
    position?: Coordinates;
}

/**
 * Check if a given object implements the ResourcePosition interface.
 */
export function instanceOfResourcePosition(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourcePositionFromJSON(json: any): ResourcePosition {
    return ResourcePositionFromJSONTyped(json, false);
}

export function ResourcePositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourcePosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': !exists(json, 'position') ? undefined : CoordinatesFromJSON(json['position']),
    };
}

export function ResourcePositionToJSON(value?: ResourcePosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': CoordinatesToJSON(value.position),
    };
}

