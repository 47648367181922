import type { SelectedResourceType, TopologyErrorsType } from "../../topology-graph-types";
import type { Node } from "reactflow";
import type { TopologyResourceWithPosition } from "../../../../apis/topology";
import type { AWSIconsList } from "../../../icons";

export enum TopologyErrorDrawerTypeEnum {
  ACTION = "action",
  WARNING = "warning",
}

export type TopologyErrorDrawerProps = {
  onClose: () => void;
  topologyErrors: TopologyErrorsType | undefined;
  showMissingDrawer: () => void;
  graphDataResources: Array<TopologyResourceWithPosition> | undefined;
  onNodeClick: (nodeObj: Node, node_type?: SelectedResourceType) => void;
  resourceId: string;
  type: TopologyErrorDrawerTypeEnum;
};

export interface useTopologyErrorDrawerProps {
  topologyErrors: TopologyErrorsType | undefined;
  graphDataResources: Array<TopologyResourceWithPosition> | undefined;
  onNodeClick: (nodeObj: Node, node_type?: SelectedResourceType) => void;
  resourceId: string;
  type: TopologyErrorDrawerTypeEnum;
}

export interface TopologyErrorItemProps {
  label: string;
  resourceTypeLabel: string;
  resourceType: keyof typeof AWSIconsList;
  children: React.ReactNode;
  id: string;
  initialCollapsed?: boolean;
}

export interface TopologyErrorHeaderProps {
  errorCount: number;
  onFixMissingAttribute: () => void;
  onClose: () => void;
  toggleExpand: () => void;
  isExpanded: boolean;
  headerText: string;
  type: TopologyErrorDrawerTypeEnum;
}
