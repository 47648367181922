/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Commit
 */
export interface Commit {
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Commit
     */
    author?: string;
    /**
     * 
     * @type {Date}
     * @memberof Commit
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the Commit interface.
 */
export function instanceOfCommit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CommitFromJSON(json: any): Commit {
    return CommitFromJSONTyped(json, false);
}

export function CommitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Commit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'author': !exists(json, 'author') ? undefined : json['author'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
    };
}

export function CommitToJSON(value?: Commit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'message': value.message,
        'author': value.author,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
    };
}

