/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Target computes supported for IaC generation
 * @export
 */
export const TargetCompute = {
    AwsEcs: 'aws-ecs',
    AksTf: 'aks-tf',
    K8s: 'k8s',
    AwsLambda: 'aws-lambda',
    GkeTf: 'gke-tf'
} as const;
export type TargetCompute = typeof TargetCompute[keyof typeof TargetCompute];


export function TargetComputeFromJSON(json: any): TargetCompute {
    return TargetComputeFromJSONTyped(json, false);
}

export function TargetComputeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetCompute {
    return json as TargetCompute;
}

export function TargetComputeToJSON(value?: TargetCompute | null): any {
    return value as any;
}

