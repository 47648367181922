/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Config } from './Config';
import {
    ConfigFromJSON,
    ConfigFromJSONTyped,
    ConfigToJSON,
} from './Config';
import type { ExportInfo } from './ExportInfo';
import {
    ExportInfoFromJSON,
    ExportInfoFromJSONTyped,
    ExportInfoToJSON,
} from './ExportInfo';

/**
 * 
 * @export
 * @interface GetExportStat200Response
 */
export interface GetExportStat200Response {
    /**
     * 
     * @type {ExportInfo}
     * @memberof GetExportStat200Response
     */
    latest?: ExportInfo;
    /**
     * 
     * @type {Config}
     * @memberof GetExportStat200Response
     */
    config?: Config;
}

/**
 * Check if a given object implements the GetExportStat200Response interface.
 */
export function instanceOfGetExportStat200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetExportStat200ResponseFromJSON(json: any): GetExportStat200Response {
    return GetExportStat200ResponseFromJSONTyped(json, false);
}

export function GetExportStat200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExportStat200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latest': !exists(json, 'latest') ? undefined : ExportInfoFromJSON(json['latest']),
        'config': !exists(json, 'config') ? undefined : ConfigFromJSON(json['config']),
    };
}

export function GetExportStat200ResponseToJSON(value?: GetExportStat200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latest': ExportInfoToJSON(value.latest),
        'config': ConfigToJSON(value.config),
    };
}

