import React, {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  type ReactNode,
} from "react";
import { Handle, Position, type NodeProps } from "reactflow";
import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
  Tooltip,
} from "../../../components";
import { TooltipPositions } from "../../tooltip/tooltip.types";
import CustomNodeToolbar from "./custom-node-toolbar";

const CustomResourceNode = (node: NodeProps) => {
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [isNodeHovered, setIsNodeHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    setIsNodeHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutIdRef.current = setTimeout(() => {
      setIsNodeHovered(false);
    }, 300);
  }, []);

  const nodeBaseClasses =
    "tw-w-2.5 tw-h-2.5 tw-bg-gray-700 tw-transition-opacity tw-duration-200 hover:tw-border-gray-300";

  const nodeHoverClasses = useMemo(
    () => (isNodeHovered ? "tw-opacity-100" : "tw-opacity-0"),
    [isNodeHovered]
  );

  const nodeSelectedClasses = useMemo(
    () => (node.selected ? "tw-border-blue-500" : "tw-border-gray-400"),
    [node.selected]
  );

  const getResourceWithTooltip = (resourceDiv: ReactNode) => {
    if (node.data?.isRestricted) {
      return (
        <Tooltip
          title={
            "This resource is restricted. Please contact the administrator to add support, or delete or replace the resource."
          }
          position={TooltipPositions.BOTTOM_START}
        >
          {resourceDiv}
        </Tooltip>
      );
    } else {
      return resourceDiv;
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CustomNodeToolbar node={node} />
      <Handle
        type="source"
        position={Position.Top}
        id={`${node?.data?.id}-top`}
        isConnectable={true}
        className={`${nodeBaseClasses} ${nodeHoverClasses} ${nodeSelectedClasses} -tw-top-1.5`}
        data-testid={`handle-${node?.data?.id}-top`}
      />
      <Handle
        type="source"
        position={Position.Left}
        id={`${node?.data?.id}-left`}
        isConnectable={true}
        className={`${nodeBaseClasses} ${nodeHoverClasses} ${nodeSelectedClasses} -tw-left-1.5`}
        data-testid={`handle-${node?.data?.id}-left`}
      />
      {getResourceWithTooltip(
        <div
          className={`tw-flex tw-items-center tw-justify-between tw-p-3 tw-rounded-md tw-border ${
            node.data?.isRestricted
              ? "tw-bg-gray-900"
              : node?.data?.external_resource
              ? "tw-bg-gray-800 hover:tw-bg-gray-700"
              : "tw-bg-gray-700 hover:tw-bg-gray-600"
          } ${
            node?.data?.external_resource
              ? "tw-w-70 tw-min-h-18 tw-gap-7"
              : "tw-w-62 tw-gap-2"
          } ${
            node?.data?.diffType === "created"
              ? "tw-border tw-border-green-500"
              : node?.data?.diffType === "deleted"
              ? "tw-border tw-border-red-500"
              : node.selected
              ? "tw-border-blue-500"
              : "tw-border-gray-600"
          }`}
        >
          <div className="tw-flex tw-gap-2 tw-items-center">
            <div className="tw-basis-7">
              <AWSIcon
                name={
                  AWSIconsList[node?.data?.icon as keyof typeof AWSIconsList]
                }
                alt={node?.data?.label || node?.data?.resourceTypeLabel}
                size={IconSize.xl}
                fallbackPlaceholder={node?.data?.resourceType}
                className="tw-rounded"
              />
            </div>
            <div className="tw-relative">
              <div
                className={`tw-text-sm tw-font-semibold tw-break-all ${
                  node.data?.isRestricted ? "tw-text-gray-400" : "tw-text-white"
                }`}
              >
                {node?.data?.label || node?.data?.resourceTypeLabel}
              </div>
              {node?.data?.label && (
                <div
                  className={`tw-text-xxsxs tw-font-normal tw-break-all ${
                    node.data?.isRestricted
                      ? "tw-text-gray-400"
                      : "tw-text-gray-200"
                  }`}
                >
                  {node?.data?.resourceTypeLabel}
                </div>
              )}
            </div>
            {node?.data?.external_resource && (
              <div className="tw-absolute tw-px-1.5 tw-py-1 tw-text-xxsxs tw-italic tw-text-gray-100 tw-right-px tw-top-px tw-bg-gray-600 tw-rounded-se-md tw-rounded-es-md">
                External
              </div>
            )}
            {!node?.data?.external_resource &&
              node?.data?.resourceType?.split("_")[0] === "data" && (
                <div className="tw-absolute tw-bg-blue-800 tw-text-gray-50 tw-px-1.5 tw-py-1 tw-right-0 tw-top-0 tw-rounded-se-md tw-rounded-es-md">
                  <Icon name={IconsList.DATABASE} size={IconSize.sm} />
                </div>
              )}
          </div>
          <div className="tw-basis-4">
            {node.data?.isRestricted ? (
              <Icon
                name={IconsList.CIRCLE_STOP_RESTRICTED}
                className="tw-text-yellow-700"
              />
            ) : node.data?.errors?.validationErrors ? (
              <Icon
                name={IconsList.TRIANGLE_EXCLAMATION}
                className="tw-text-red-500"
              />
            ) : node.data?.errors?.policyViolationErrors ? (
              <Icon
                name={IconsList.SHIELD_EXCLAMATION}
                className="tw-text-yellow-500"
              />
            ) : null}
          </div>
        </div>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        id={`${node?.data?.id}-bottom`}
        isConnectable={true}
        className={`${nodeBaseClasses} ${nodeHoverClasses} ${nodeSelectedClasses} -tw-bottom-1.5 hover:tw-bg-gray-500`}
        data-testid={`handle-${node?.data?.id}-bottom`}
      />
      <Handle
        type="source"
        position={Position.Right}
        id={`${node?.data?.id}-right`}
        isConnectable={true}
        className={`${nodeBaseClasses} ${nodeHoverClasses} ${nodeSelectedClasses} -tw-right-1.5 hover:tw-bg-gray-500`}
        data-testid={`handle-${node?.data?.id}-right`}
      />
    </div>
  );
};

export default memo(CustomResourceNode);
