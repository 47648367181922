import React, { useState } from "react";

import type { Misconfigurations } from "../../../apis/topology";

import { Button, IconsList, Severity } from "../../../components";

import { SeverityLevel } from "../../../components/severity/severity";

const PolicyViolationsTab = ({
  misconfigurations,
  onViolationClick,
}: {
  misconfigurations: Misconfigurations[];
  onViolationClick: () => void;
}) => {
  if (misconfigurations && misconfigurations?.length > 0) {
    const [showRemediation, setShowRemediation] = useState(
      Array(misconfigurations?.length).fill(false)
    );

    return (
      <>
        {misconfigurations.map((policy, index) => (
          <div
            className="tw-px-4 tw-py-2 tw-border-b tw-border-gray-700 tw-flex tw-gap-4 tw-cursor-pointer"
            key={`${policy.id}_${index}`}
            onClick={onViolationClick}
          >
            <div className="tw-mt-1">
              <Severity
                level={
                  SeverityLevel[
                    policy.severity?.toLowerCase() as keyof typeof SeverityLevel
                  ]
                }
              />
            </div>
            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div
                  className="tw-text-xssm tw-font-semibold tw-text-gray-50 tw-flex tw-justify-between"
                  data-testid={`${policy.id}-${index}-label`}
                >
                  <span>{policy.name}</span>
                  <span className="tw-text-xs tw-font-normal tw-text-gray-200 tw-italic">
                    Rule ID: {policy.ruleId}
                  </span>
                </div>
                <div className="tw-text-xssm tw-font-normal tw-text-gray-50">
                  {policy.description}
                </div>
              </div>
              {policy.remediation && policy.remediation?.length > 0 && (
                <>
                  <div>
                    <Button
                      label="Remediation"
                      variant="secondary"
                      size="sm"
                      rightIcon={
                        showRemediation[index]
                          ? IconsList.CARET_UP
                          : IconsList.CARET_DOWN
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowRemediation((prev) => {
                          const prevCopy = [...prev];
                          prevCopy[index] = !prevCopy[index];
                          return prevCopy;
                        })
                      }}
                      dataTestId={`remediation-${policy.id}`}
                    />
                  </div>
                  {showRemediation[index] && (
                    <div className="tw-rounded-md tw-border tw-border-gray-700 tw-p-2 tw-bg-gray-700 tw-flex tw-flex-col tw-gap-2">
                      {policy.remediation?.map((remediation, i) => (
                        <div
                          className="tw-text-xssm tw-text-gray-50"
                          key={`${policy.id}_${i}`}
                          data-testid={`remediation-${policy.id}-${i}`}
                        >
                          {remediation}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return (
      <div className="tw-p-4 tw-border-b tw-border-gray-700">
        <span className="tw-text-xssm">There are no policy violations.</span>
      </div>
    );
  }
};

export default PolicyViolationsTab;
