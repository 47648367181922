import React, { type FC } from "react";
import { Field, FieldProps } from "formik";
import { RadioProps } from "./radio.types";

/**
 * Renders a radio button component.
 *
 * - @param {RadioProps} props - The props for the radio button.
 * - @param {type} props.value - The value of the radio button.
 * - @param {type} props.name - The name attribute of the radio button.
 * - @param {type} props.id - The id attribute of the radio button.
 * - @param {type} props.disabled - The disabled attribute of the radio button.
 * - @param {type} props.dataTestId - The data-testid attribute of the radio button.
 * - @return {ReactNode} - The rendered radio button component.
 */
const Radio: FC<RadioProps> = ({ value, name, id, disabled, dataTestId }) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        return (
          <div>
            <label className="tw-block tw-relative tw-pb-4 tw-pr-4 tw-cursor-pointer">
              <input
                {...field}
                checked={field.value === value}
                type="radio"
                value={value}
                id={id}
                className="tw-absolute tw-opacity-0 tw-cursor-pointer"
                disabled={disabled}
                data-testid={dataTestId}
              />
              <span
                className={`tw-absolute tw-top-0 tw-left-0 tw-h-4 tw-w-4 tw-rounded-full ${
                  field.value === value
                    ? "tw-bg-transparent tw-border-blue-500 tw-border-4"
                    : "tw-bg-transparent tw-border tw-border-gray-500 hover:tw-border-gray-400"
                } ${disabled ? "tw-opacity-50" : ""}`}
              ></span>
            </label>
          </div>
        );
      }}
    </Field>
  );
};

export default Radio;
