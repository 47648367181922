/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PolicyProvider = {
    Aws: 'aws',
    Gcp: 'gcp',
    Azure: 'azure',
    K8s: 'k8s'
} as const;
export type PolicyProvider = typeof PolicyProvider[keyof typeof PolicyProvider];


export function PolicyProviderFromJSON(json: any): PolicyProvider {
    return PolicyProviderFromJSONTyped(json, false);
}

export function PolicyProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolicyProvider {
    return json as PolicyProvider;
}

export function PolicyProviderToJSON(value?: PolicyProvider | null): any {
    return value as any;
}

