/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportInfo
 */
export interface ExportInfo {
    /**
     * ID of the export
     * @type {string}
     * @memberof ExportInfo
     */
    id: string;
    /**
     * ID of the topology
     * @type {string}
     * @memberof ExportInfo
     */
    topologyId: string;
    /**
     * ID of the configuration
     * @type {string}
     * @memberof ExportInfo
     */
    configId: string;
    /**
     * Date of the export
     * @type {Date}
     * @memberof ExportInfo
     */
    createdAt: Date;
    /**
     * External link of the export
     * @type {string}
     * @memberof ExportInfo
     */
    externalLink?: string;
    /**
     * User who exported
     * @type {string}
     * @memberof ExportInfo
     */
    exportedBy: string;
    /**
     * Date of the topology update
     * @type {Date}
     * @memberof ExportInfo
     */
    topologyUpdatedAt: Date;
}

/**
 * Check if a given object implements the ExportInfo interface.
 */
export function instanceOfExportInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "topologyId" in value;
    isInstance = isInstance && "configId" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "exportedBy" in value;
    isInstance = isInstance && "topologyUpdatedAt" in value;

    return isInstance;
}

export function ExportInfoFromJSON(json: any): ExportInfo {
    return ExportInfoFromJSONTyped(json, false);
}

export function ExportInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'topologyId': json['topology_id'],
        'configId': json['config_id'],
        'createdAt': (new Date(json['created_at'])),
        'externalLink': !exists(json, 'external_link') ? undefined : json['external_link'],
        'exportedBy': json['exported_by'],
        'topologyUpdatedAt': (new Date(json['topology_updated_at'])),
    };
}

export function ExportInfoToJSON(value?: ExportInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'topology_id': value.topologyId,
        'config_id': value.configId,
        'created_at': (value.createdAt.toISOString()),
        'external_link': value.externalLink,
        'exported_by': value.exportedBy,
        'topology_updated_at': (value.topologyUpdatedAt.toISOString()),
    };
}

