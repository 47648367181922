/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomResourceTemplate,
  CustomSupportedResourceType,
  CustomTemplateReq,
  MatchingResourceAttributes,
  SupportedTfDataTypes,
  UpdateCustomResourceTemplateRequest,
} from '../models/index';
import {
    CustomResourceTemplateFromJSON,
    CustomResourceTemplateToJSON,
    CustomSupportedResourceTypeFromJSON,
    CustomSupportedResourceTypeToJSON,
    CustomTemplateReqFromJSON,
    CustomTemplateReqToJSON,
    MatchingResourceAttributesFromJSON,
    MatchingResourceAttributesToJSON,
    SupportedTfDataTypesFromJSON,
    SupportedTfDataTypesToJSON,
    UpdateCustomResourceTemplateRequestFromJSON,
    UpdateCustomResourceTemplateRequestToJSON,
} from '../models/index';

export interface DeleteCustomResourceTemplateRequest {
    uuid: string;
    orgId?: string;
}

export interface GetCustomTemplateByResourceIdRequest {
    id: string;
    orgId?: string;
}

export interface GetMatchingResourceAttributesRequest {
    resourceType: string;
    cloudProvider: string;
    orgId?: string;
}

export interface GetSupportedCustomResourceTypesRequest {
    orgId?: string;
}

export interface SaveCustomHelmChartRequest {
    orgId?: string;
    chartZip?: Blob;
    repository?: string;
    name?: string;
}

export interface SaveCustomResourceTemplateForResourceTypeRequest {
    customTemplateReq: CustomTemplateReq;
    orgId?: string;
}

export interface UpdateCustomTemplateRequest {
    uuid: string;
    updateCustomResourceTemplateRequest: UpdateCustomResourceTemplateRequest;
    orgId?: string;
}

/**
 * 
 */
export class V1CustomResourceTemplateApi extends runtime.BaseAPI {

    /**
     * Delete custom template by ID
     */
    async deleteCustomResourceTemplateRaw(requestParameters: DeleteCustomResourceTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteCustomResourceTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/custom-resource-template/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete custom template by ID
     */
    async deleteCustomResourceTemplate(requestParameters: DeleteCustomResourceTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomResourceTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Get custom template for a resource ID
     */
    async getCustomTemplateByResourceIdRaw(requestParameters: GetCustomTemplateByResourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCustomTemplateByResourceId.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/custom-resource-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Get custom template for a resource ID
     */
    async getCustomTemplateByResourceId(requestParameters: GetCustomTemplateByResourceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.getCustomTemplateByResourceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get matching resource attributes
     */
    async getMatchingResourceAttributesRaw(requestParameters: GetMatchingResourceAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MatchingResourceAttributes>> {
        if (requestParameters.resourceType === null || requestParameters.resourceType === undefined) {
            throw new runtime.RequiredError('resourceType','Required parameter requestParameters.resourceType was null or undefined when calling getMatchingResourceAttributes.');
        }

        if (requestParameters.cloudProvider === null || requestParameters.cloudProvider === undefined) {
            throw new runtime.RequiredError('cloudProvider','Required parameter requestParameters.cloudProvider was null or undefined when calling getMatchingResourceAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resourceType'] = requestParameters.resourceType;
        }

        if (requestParameters.cloudProvider !== undefined) {
            queryParameters['cloudProvider'] = requestParameters.cloudProvider;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/matching-resource-attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchingResourceAttributesFromJSON(jsonValue));
    }

    /**
     * Get matching resource attributes
     */
    async getMatchingResourceAttributes(requestParameters: GetMatchingResourceAttributesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MatchingResourceAttributes> {
        const response = await this.getMatchingResourceAttributesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List supported custom resource types
     */
    async getSupportedCustomResourceTypesRaw(requestParameters: GetSupportedCustomResourceTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomSupportedResourceType>>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/custom-supported-resource-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomSupportedResourceTypeFromJSON));
    }

    /**
     * List supported custom resource types
     */
    async getSupportedCustomResourceTypes(requestParameters: GetSupportedCustomResourceTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomSupportedResourceType>> {
        const response = await this.getSupportedCustomResourceTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List supported Terraform data types
     */
    async getSupportedTfDataTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SupportedTfDataTypes>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/supported-tf-datatypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SupportedTfDataTypesFromJSON(jsonValue));
    }

    /**
     * List supported Terraform data types
     */
    async getSupportedTfDataTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SupportedTfDataTypes> {
        const response = await this.getSupportedTfDataTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Receive custom helm chart from user
     */
    async saveCustomHelmChartRaw(requestParameters: SaveCustomHelmChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.chartZip !== undefined) {
            formParams.append('chartZip', requestParameters.chartZip as any);
        }

        if (requestParameters.repository !== undefined) {
            formParams.append('repository', requestParameters.repository as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        const response = await this.request({
            path: `/v1/bring-your-own-helm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Receive custom helm chart from user
     */
    async saveCustomHelmChart(requestParameters: SaveCustomHelmChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.saveCustomHelmChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save custom template for a resource type
     */
    async saveCustomResourceTemplateForResourceTypeRaw(requestParameters: SaveCustomResourceTemplateForResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        if (requestParameters.customTemplateReq === null || requestParameters.customTemplateReq === undefined) {
            throw new runtime.RequiredError('customTemplateReq','Required parameter requestParameters.customTemplateReq was null or undefined when calling saveCustomResourceTemplateForResourceType.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/custom-resource-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomTemplateReqToJSON(requestParameters.customTemplateReq),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Save custom template for a resource type
     */
    async saveCustomResourceTemplateForResourceType(requestParameters: SaveCustomResourceTemplateForResourceTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.saveCustomResourceTemplateForResourceTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update custom template by ID
     */
    async updateCustomTemplateRaw(requestParameters: UpdateCustomTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomResourceTemplate>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling updateCustomTemplate.');
        }

        if (requestParameters.updateCustomResourceTemplateRequest === null || requestParameters.updateCustomResourceTemplateRequest === undefined) {
            throw new runtime.RequiredError('updateCustomResourceTemplateRequest','Required parameter requestParameters.updateCustomResourceTemplateRequest was null or undefined when calling updateCustomTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/custom-resource-template/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCustomResourceTemplateRequestToJSON(requestParameters.updateCustomResourceTemplateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomResourceTemplateFromJSON(jsonValue));
    }

    /**
     * Update custom template by ID
     */
    async updateCustomTemplate(requestParameters: UpdateCustomTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomResourceTemplate> {
        const response = await this.updateCustomTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
