import React, { useCallback } from "react";
import { Formik, Form } from "formik";

import type {
  ProviderWiseSupportedResourceType,
  SupportedResourceType,
} from "../../../apis/topology";

import { PropertiesType } from "../topology-graph-types";

import {
  Dialog,
  Icon,
  IconsList,
  Tag,
  Checkbox,
  AWSIcon,
  AWSIconsList,
  SelectBox,
  Button,
} from "../../../components";
import { TagVariant } from "../../../components/tag/tag.types";

const ResourceOverrideDialog = ({
  resourceTypeMappings,
  updateResourceTypeMappings,
  supportedResourceTypes,
  onClose,
}: {
  resourceTypeMappings: PropertiesType | null;
  updateResourceTypeMappings: (mappingData: PropertiesType) => Promise<void>;
  supportedResourceTypes: ProviderWiseSupportedResourceType;
  onClose?: () => void;
}) => {
  const submitResourceTypeMappings = useCallback(
    async (mappingData: PropertiesType) => {
      await updateResourceTypeMappings(mappingData);
      onClose?.();
    },
    [updateResourceTypeMappings]
  );

  const findInSupportedResourceTypes = useCallback(
    (resourceTypeToFind: string): PropertiesType | null => {
      let resourceTypeObject = null;

      Object.keys(supportedResourceTypes).some((provider) => {
        const findResourceTypeObject = (supportedResourceTypes as any)[
          provider
        ].find(
          (resource: SupportedResourceType) =>
            resource.resourceType === resourceTypeToFind
        );
        if (findResourceTypeObject) {
          resourceTypeObject = findResourceTypeObject;
          return true;
        }
      });

      return resourceTypeObject;
    },
    [supportedResourceTypes]
  );

  return (
    <Dialog
      size="md"
      titleContent={
        <div className="tw-flex tw-gap-1.5">
          <Icon name={IconsList.LOOP} />
          <span className="tw-text-sm tw-text-gray-100 tw-font-semibold">
            Map Resources
          </span>
          <Tag
            size="sm"
            title={`${
              resourceTypeMappings
                ? Object.keys(resourceTypeMappings)?.length
                : 0
            }`}
            variant={TagVariant.SOLID}
            className="tw-bg-gray-700"
          />
        </div>
      }
      bodyContent={
        <Formik
          initialValues={{
            updatedMappings: {},
          }}
          onSubmit={async (values) => {
            await submitResourceTypeMappings(values.updatedMappings);
          }}
        >
          <Form>
            <div className="tw-text-xs tw-text-gray-200 tw-pt-2 tw-pb-1">
              Resources available to override
            </div>
            {resourceTypeMappings &&
              Object.keys(resourceTypeMappings).map((resourceType, index) => (
                <div
                  className="tw-flex tw-items-center tw-py-3"
                  key={resourceType}
                >
                  <div className="tw-flex tw-gap-2 tw-w-33">
                    <div className="tw-basis-6">
                      <AWSIcon
                        name={
                          AWSIconsList[
                            resourceType as keyof typeof AWSIconsList
                          ]
                        }
                      />
                    </div>
                    <span className="tw-text-xssm tw-text-white">
                      {findInSupportedResourceTypes(resourceType)?.label}
                    </span>
                  </div>
                  <div className="tw-w-22 tw-flex tw-justify-center tw-items-center">
                    <Icon
                      name={IconsList.ARROW_RIGHT}
                      className="tw-text-gray-400"
                    />
                  </div>
                  <div className="tw-w-full">
                    <SelectBox
                      name={`updatedMappings[${resourceType}]`}
                      options={
                        resourceTypeMappings[resourceType].map(
                          (overrideOptionObject: any) => {
                            return {
                              label: (
                                <div className="tw-flex tw-gap-2 tw-items-center">
                                  <AWSIcon
                                    name={
                                      AWSIconsList[
                                        overrideOptionObject.overrideType as keyof typeof AWSIconsList
                                      ]
                                    }
                                    fallbackPlaceholder={
                                      overrideOptionObject.overrideType
                                    }
                                  />
                                  <span className="tw-text-xssm tw-text-white">
                                    {
                                      findInSupportedResourceTypes(
                                        overrideOptionObject.overrideType
                                      )?.label
                                    }
                                  </span>
                                </div>
                              ),
                              value: overrideOptionObject,
                            };
                          }
                        ) ?? []
                      }
                      backgroundVariant="gray-700"
                      dataTestId={`override-${resourceType}-select`}
                      className="tw-w-full"
                    />
                  </div>
                </div>
              ))}
            <div className="tw-flex tw-justify-end tw-gap-3">
              <Button
                variant="secondary"
                label="Cancel"
                dataTestId="cancel-btn"
                leftIcon={IconsList.CLOSE}
                onClick={() => {
                  submitResourceTypeMappings({});
                }}
              />
              <Button
                type="submit"
                variant="primary"
                label="Done"
                dataTestId="done-btn"
                leftIcon={IconsList.CHECK}
              />
            </div>
          </Form>
        </Formik>
      }
      bodyWrapperClassName="tw-w-180"
      dataTestId="resource-mapping-override-dialog"
    />
  );
};

export default ResourceOverrideDialog;
