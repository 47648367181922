import React from "react";
import { Formik, Form } from "formik";

import { Button, TextArea, Link, IconsList } from "../../components";

const ReportBug = ({
  onSubmit,
  onSubmitCallback,
}: {
  onSubmit: (description: string) => void;
  onSubmitCallback: () => void;
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-5">
      <span className="tw-text-sm tw-font-normal tw-text-white">
        Are you facing any problems using StackGen? Help us by describing the
        steps to reproduce this issue.
      </span>
      <Formik
        initialValues={{
          description: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await onSubmit(values.description);
          setSubmitting(false);
          onSubmitCallback && onSubmitCallback();
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form className="tw-flex tw-flex-col tw-gap-5">
              <div>
                <TextArea
                  name="description"
                  placeholder="Looks like something is not working correctly..."
                  label="Description"
                  className="tw-text-white"
                  dataTestId="report-bug-description"
                />
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-mt-5">
                <span className="tw-text-sm tw-font-normal tw-text-gray-200">
                  You can also reach out to us at{" "}
                  <Link
                    href="mailto:support@stackgen.com"
                    text="support@stackgen.com"
                    dataTestId="report-bug-email"
                  />
                </span>
                <Button
                  type="submit"
                  label={isSubmitting ? "Submitting Bug..." : "Submit Bug"}
                  leftIcon={isSubmitting ? IconsList.SPINNER : undefined}
                  disabled={values.description === "" || isSubmitting}
                  dataTestId="report-bug-submit-button"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ReportBug;
