/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Variable } from './Variable';
import {
    VariableFromJSON,
    VariableFromJSONTyped,
    VariableToJSON,
} from './Variable';

/**
 * 
 * @export
 * @interface UpdateCustomResourceTemplateRequest
 */
export interface UpdateCustomResourceTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomResourceTemplateRequest
     */
    description?: string;
    /**
     * 
     * @type {Array<Variable>}
     * @memberof UpdateCustomResourceTemplateRequest
     */
    variables?: Array<Variable>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomResourceTemplateRequest
     */
    template?: string;
}

/**
 * Check if a given object implements the UpdateCustomResourceTemplateRequest interface.
 */
export function instanceOfUpdateCustomResourceTemplateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCustomResourceTemplateRequestFromJSON(json: any): UpdateCustomResourceTemplateRequest {
    return UpdateCustomResourceTemplateRequestFromJSONTyped(json, false);
}

export function UpdateCustomResourceTemplateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCustomResourceTemplateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'variables': !exists(json, 'variables') ? undefined : ((json['variables'] as Array<any>).map(VariableFromJSON)),
        'template': !exists(json, 'template') ? undefined : json['template'],
    };
}

export function UpdateCustomResourceTemplateRequestToJSON(value?: UpdateCustomResourceTemplateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'variables': value.variables === undefined ? undefined : ((value.variables as Array<any>).map(VariableToJSON)),
        'template': value.template,
    };
}

