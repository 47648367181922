import React, { useMemo, useState, type FC } from "react";

import { useAtomValue } from "jotai";
import { sidebar_menu_items } from "../../../config/constants";
import { userLoginStatusAtom } from "../../../stores/auth.store";
import { SidebarBodyProps, SidebarMenuItem } from "../sidebar-types";
import SidebarBodyItem from "./sidebarBodyItem";

const SidebarBody: FC<SidebarBodyProps> = ({
  activeUrlPath,
  navigationType,
}) => {
  const userLoginStatus = useAtomValue(userLoginStatusAtom);

  // list of ids for which child items are open
  const [expandedChildMenuItemIds, setExpandedChildMenuItemIds] = useState<
    string[]
  >(
    sidebar_menu_items
      ?.filter((item) => item?.submenuItem && item.submenuItem?.length > 0)
      ?.map((item) => item.id)
  ); // keep all submenu items expanded by default

  // filtering navigation items based on navigation type
  const filteredSidebarMenuItems = useMemo(() => {
    return sidebar_menu_items.filter((item) => {
      if (item.scopes && item.scopes.length > 0) {
        return item.scopes.every((scope) =>
          userLoginStatus?.scopes?.includes(scope)
        );
      }

      return navigationType === "DEFAULT"
        ? !item.type || item.type === "DEFAULT" // if type is not defined(for backward compatibility) or is DEFAULT
        : item.type === navigationType;
    });
  }, [navigationType, userLoginStatus?.scopes]);

  // make menu item active based on active route
  const sidebarMenuItems = filteredSidebarMenuItems.map((item) => {
    const menuItem = {
      ...item,
      isActive:
        item.link === activeUrlPath ||
        item.otherActivationPath?.includes(activeUrlPath),
    };
    // update active status of child item
    if (item.submenuItem && item.submenuItem.length > 0) {
      return {
        ...menuItem,
        submenuItem: item.submenuItem.map((child) => ({
          ...child,
          isActive:
            child.link === activeUrlPath ||
            child.otherActivationPath?.includes(activeUrlPath),
        })),
      };
    } else {
      // update active status of parent item
      return menuItem;
    }
  });

  const onMenuItemClick = (itemId: string) => {
    // if menu item has submenuItem, toggle them (to set one/many child items open at a time)
    if (
      sidebarMenuItems.some(
        (item) => item.id === itemId && item?.submenuItem?.length
      )
    ) {
      setExpandedChildMenuItemIds((expandedChildMenuItemIds) =>
        expandedChildMenuItemIds.includes(itemId)
          ? expandedChildMenuItemIds.filter((id) => id !== itemId)
          : [...expandedChildMenuItemIds, itemId]
      );
    }
  };

  return (
    <ul>
      {sidebarMenuItems.map((item) => (
        <li key={item.id}>
          <SidebarBodyItem
            menuItem={item as SidebarMenuItem}
            onMenuItemClick={onMenuItemClick}
            expandedChildMenuItemIds={expandedChildMenuItemIds}
          />
        </li>
      ))}
    </ul>
  );
};

export default SidebarBody;
