import React, { useState, useCallback, useMemo, type FC, useRef } from "react";

import {
  drawerCloseMap,
  drawerOpenMap,
  DrawerPosition,
  DrawerSize,
  type DrawerProps,
  drawerDimensionsMap,
  getDrawerPosition,
} from "./drawer-types";
import { isFunction } from "../../utils";

/**
 * Renders a Drawer component that can be opened and closed.
 *
 * @param {DrawerProps} props - The props object containing the following properties:
 *   - open: a boolean indicating whether the Drawer is open or closed (defaults to false)
 *   - onClose: a function to be called when the Drawer is closed
 *   - children: the content to be rendered inside the Drawer
 *   - size: the size of the Drawer (defaults to DrawerSize.sm)
 *   - isResizable: a boolean indicating whether the Drawer can be resized (defaults to false)
 *   - disableBackdropClick: a boolean indicating whether clicking on the background should close the Drawer (defaults to false)
 *   - position: the position of the Drawer (defaults to DrawerPosition.Right)
 *   - isFullScreenDrawer: a boolean indicating whether the Drawer should be rendered as a full-screen overlay (defaults to false)
 *   - minResizableWidth: the minimum width of the resizable Drawer (defaults to 280)
 *   - maxResizableWidth: the maximum width of the resizable Drawer (defaults to 1280)
 *   - dataTestId: the data-test-id of the Drawer
 *
 * @return {ReactElement} The rendered Drawer component
 */

const Drawer: FC<DrawerProps> = ({
  open = false,
  onClose,
  children,
  size = "sm",
  isResizable = false,
  disableBackdropClick = false,
  position = "Right",
  isFullScreenDrawer = true, // NOTE: when fullscreen is set to false, make sure, it's container has tw-relative class to make it work
  minResizableWidth = 280,
  maxResizableWidth = 1280,
  dataTestId,
}) => {
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const [isDraggerActive, setIsDraggerActive] = useState(false);
  // ---- Logic for resizable drawer - Start ----
  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      let newWidth =
        DrawerPosition[position] === DrawerPosition.Left
          ? e.clientX - document.body.offsetLeft
          : document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

      // deduct relative position left of the parent div in the DOM
      if (
        !isFullScreenDrawer &&
        DrawerPosition[position] === DrawerPosition.Left &&
        drawerContainerRef.current?.getBoundingClientRect()?.left
      ) {
        newWidth =
          newWidth - drawerContainerRef.current.getBoundingClientRect().left;
      }

      if (
        isResizable &&
        newWidth > minResizableWidth &&
        newWidth < maxResizableWidth &&
        drawerContainerRef.current
      ) {
        drawerContainerRef.current.style.width = `${newWidth}px`;
      }
    },
    [position]
  );

  const handleMouseDown = () => {
    setIsDraggerActive(true);
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    setIsDraggerActive(false);
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };
  // ---- Logic for resizable drawer - End ----

  // prepare class list for drawer container in fullscreen mode
  const fullScreenDrawerContainerClassName = useMemo(() => {
    const baseClassName =
      "tw-w-screen tw-h-screen tw-overflow-hidden tw-bg-gray-200 tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-flex tw-duration-200";

    const positionClassName =
      DrawerPosition[position] === DrawerPosition.Right
        ? "tw-justify-end"
        : "tw-justify-start";

    const visibilityClassName = open
      ? "tw-visible tw-bg-opacity-20"
      : "tw-invisible tw-bg-opacity-0";

    return `${baseClassName} ${positionClassName} ${visibilityClassName}`;
  }, [open, size, position, isResizable]);

  // prepare class list for drawer container
  const drawerClassName = useMemo(() => {
    const baseClassName =
      "tw-flex tw-bg-gray-800 tw-border-gray-700 tw-shadow-md tw-shadow-black";

    const drawerTypeClassName = isFullScreenDrawer
      ? "tw-relative"
      : "tw-absolute";

    const dimensionClassName = drawerDimensionsMap(DrawerPosition[position])[
      DrawerSize[size]
    ];
    const positionClassName = getDrawerPosition(DrawerPosition[position]);

    const visibilityClassName = open
      ? "ml-0" // NOTE: It should be tw-translate-x-0 here. however with this class, if any dialog is rendered from a child component, it's position is getting relative to parent. So, for now, keeping it ml-10. (ref: https://stackoverflow.com/questions/21091958)
      : DrawerPosition[position] === DrawerPosition.Left
      ? drawerCloseMap(DrawerPosition[position])[DrawerSize[size]]
      : drawerOpenMap(DrawerPosition[position])[DrawerSize[size]];

    const resizableClassName = isResizable
      ? "tw-duration-0"
      : "tw-duration-200";

    return `${baseClassName} ${drawerTypeClassName} ${dimensionClassName} ${positionClassName} ${visibilityClassName} ${resizableClassName}`;
  }, [open, size, position, isResizable]);

  // prepare class list for dragger
  const draggerClassName = useMemo(() => {
    const baseClassName =
      "tw-cursor-col-resize tw-h-full tw-w-0.5 hover:tw-bg-blue-500 tw-absolute tw-duration-300 tw-z-10";
    const positionClassName =
      DrawerPosition[position] === DrawerPosition.Right
        ? "tw-left-0"
        : "tw-right-0";
    return `${baseClassName} ${positionClassName} ${
      isDraggerActive ? "tw-bg-blue-500" : ""
    }`;
  }, [position, isDraggerActive]);

  const handleOnBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      !disableBackdropClick && isFunction(onClose) && onClose?.();
    }
  };

  const DrawerAreaComponent = () => {
    return (
      <div
        className={`${drawerClassName} ${
          !isFullScreenDrawer ? (open ? "tw-opacity-100" : "tw-opacity-0") : ""
        }`}
        ref={drawerContainerRef}
        data-testid={dataTestId}
      >
        {isResizable && (
          <button className={draggerClassName} onMouseDown={handleMouseDown} />
        )}
        <div className="tw-w-full tw-h-full tw-overflow-y-auto !tw-scrollbar-none">
          {children}
        </div>
      </div>
    );
  };

  return (
    <>
      {isFullScreenDrawer ? (
        <div
          onClick={(e) => handleOnBackdropClick(e)}
          className={fullScreenDrawerContainerClassName}
        >
          {DrawerAreaComponent()}
        </div>
      ) : (
        <>{DrawerAreaComponent()}</>
      )}
    </>
  );
};

export default Drawer;
