import React, { type MouseEvent } from "react";

const Link = ({
  text,
  href,
  target = "_self",
  rel = "noopener noreferrer",
  onClick,
  className = "",
  dataTestId,
}: {
  text: string;
  href?: string;
  target?: "_self" | "_blank" | "_parent" | "_top";
  rel?: string;
  onClick?: (e: MouseEvent) => void;
  className?: string;
  dataTestId: string;
}) => {
  return (
    <>
      <a
        href={href}
        target={target}
        className={`tw-text-blue-500 hover:tw-underline ${className}`}
        rel={rel}
        onClick={onClick}
        data-testid={dataTestId}
      >
        {text}
      </a>
      {target === "_blank" && (
        <span className="tw-sr-only">Opens in new tab</span>
      )}
    </>
  );
};

export default Link;
