import React, { useEffect } from "react";

import { useFormikContext } from "formik";

const ScrollToError = () => {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const formHasErrors = Object.keys(formik?.errors).length > 0;

  useEffect(() => {
    const el = document.querySelector(".error-field");
    el?.scrollIntoView({ behavior: "smooth", block: "center" });
    (el as HTMLElement)?.focus({ preventScroll: true });
  }, [submitting, formHasErrors]);

  return null;
};

export default ScrollToError;
