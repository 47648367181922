import { navigate } from "gatsby";

export const goToLogin = () => navigate("/login");
export const goToAppStacks = () => navigate("/appstacks");
export const goToAppStacksById = (appStackId: string) =>
  navigate(`/appstacks/${appStackId}`);
export const goToAppStacksWithParentAppStackId = (parentAppStackId: string) =>
  navigate(`/appstacks/create?parent_appstack=${parentAppStackId}`);
export const goToAppStacksWithReferenceAppStackId = (
  referenceAppStackId: string
) => navigate(`/appstacks/create?reference_appstack=${referenceAppStackId}`);
export const goToCreateAppStack = () => navigate("/appstacks/create");
export const goToRepositories = () => navigate("/repositories");
export const goToRepoOnboard = () => navigate("/repositories/onboard/");
export const goToAccountSettings = () => navigate("/account-settings");
export const goToProfile = () => navigate("/account-settings/profile");
export const goToQuickAppStackCreate = () => navigate("/quick-appstack-create");

export const goToRoute = (path: string) => navigate(path); // wildcard route to any path. Note: use only when it is backend driven

export const goToMembers = () => navigate("/account-settings/members");
export const goToTeams = () => navigate("/account-settings/teams");
export const goToCreateTeam = () => navigate("/account-settings/teams/create");

export const goToCreateGovernanceConfiguration = () =>
  navigate("/governance-configurations/create");
export const goToGovernanceConfigurations = () =>
  navigate("/governance-configurations");
