/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppManifest } from './AppManifest';
import {
    AppManifestFromJSON,
    AppManifestFromJSONTyped,
    AppManifestToJSON,
} from './AppManifest';
import type { CloudProvider } from './CloudProvider';
import {
    CloudProviderFromJSON,
    CloudProviderFromJSONTyped,
    CloudProviderToJSON,
} from './CloudProvider';
import type { IacType } from './IacType';
import {
    IacTypeFromJSON,
    IacTypeFromJSONTyped,
    IacTypeToJSON,
} from './IacType';
import type { TargetCompute } from './TargetCompute';
import {
    TargetComputeFromJSON,
    TargetComputeFromJSONTyped,
    TargetComputeToJSON,
} from './TargetCompute';

/**
 * 
 * @export
 * @interface NewTopologyRequest
 */
export interface NewTopologyRequest {
    /**
     * 
     * @type {string}
     * @memberof NewTopologyRequest
     */
    appstackId?: string;
    /**
     * 
     * @type {AppManifest}
     * @memberof NewTopologyRequest
     */
    appManifest?: AppManifest;
    /**
     * 
     * @type {CloudProvider}
     * @memberof NewTopologyRequest
     */
    cloudProvider?: CloudProvider;
    /**
     * 
     * @type {TargetCompute}
     * @memberof NewTopologyRequest
     */
    deploymentType?: TargetCompute;
    /**
     * 
     * @type {IacType}
     * @memberof NewTopologyRequest
     */
    iacType?: IacType;
}

/**
 * Check if a given object implements the NewTopologyRequest interface.
 */
export function instanceOfNewTopologyRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewTopologyRequestFromJSON(json: any): NewTopologyRequest {
    return NewTopologyRequestFromJSONTyped(json, false);
}

export function NewTopologyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewTopologyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appstackId': !exists(json, 'appstackId') ? undefined : json['appstackId'],
        'appManifest': !exists(json, 'appManifest') ? undefined : AppManifestFromJSON(json['appManifest']),
        'cloudProvider': !exists(json, 'cloudProvider') ? undefined : CloudProviderFromJSON(json['cloudProvider']),
        'deploymentType': !exists(json, 'deploymentType') ? undefined : TargetComputeFromJSON(json['deploymentType']),
        'iacType': !exists(json, 'iacType') ? undefined : IacTypeFromJSON(json['iacType']),
    };
}

export function NewTopologyRequestToJSON(value?: NewTopologyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appstackId': value.appstackId,
        'appManifest': AppManifestToJSON(value.appManifest),
        'cloudProvider': CloudProviderToJSON(value.cloudProvider),
        'deploymentType': TargetComputeToJSON(value.deploymentType),
        'iacType': IacTypeToJSON(value.iacType),
    };
}

