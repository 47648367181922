import React, { type FC } from "react";

import { Slider } from "../../../components";
import { ViraQuestionLayout, type ViraRangeQuestionProps } from "../";

const ViraRangeQuestion: FC<ViraRangeQuestionProps> = ({
  question,
  onSkip,
  onAnswer,
}) => {
  return (
    <ViraQuestionLayout
      question={question}
      onClose={onSkip}
      onAnswer={onAnswer}
      questionBodyRenderer={(formValues) => (
        <div className="tw-px-1 tw-text-sm">
          <Slider
            step={1}
            min={0}
            max={100}
            name="answer"
            dataTestId="slider-vira-range-question"
          />
          <p className="tw-text-3xl tw-font-semibold tw-w-100 tw-rounded-md tw-bg-gray-800 tw-px-6 tw-py-3 tw-text-center tw-my-6">
            {(formValues as { answer: [number] }).answer[0]}%
          </p>
        </div>
      )}
    />
  );
};

export default ViraRangeQuestion;
