import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

const storage = createJSONStorage(() =>
  typeof window !== "undefined"
    ? window.localStorage
    : (undefined as unknown as Storage)
);

export const errorRequestIdsAtom = atomWithStorage<string[]>(
  "errorRequestIds",
  [],
  storage as any,
  {
    getOnInit: true,
  }
);

export const setErrorRequestIdsAtom = atom(
  null,
  async (get, set, requestId: string) => {
    const errorRequestIds = await get(errorRequestIdsAtom);
    set(errorRequestIdsAtom, [requestId, ...errorRequestIds.slice(0, 9)]);
  }
);

export const setRequestIdFromResponseAtom = atom(
  null,
  async (get, set, response: any) => {
    if (Headers && response?.headers) {
      const xRequestId = await new Headers(response.headers).get(
        "X-Request-Id"
      );
      if (xRequestId) {
        set(setErrorRequestIdsAtom, xRequestId);
      }
    }
  }
);
