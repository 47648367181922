import React, { type FC } from "react";

import { type ViraBubbleProps } from "./";
import {
  Icon,
  IconsList,
  IconSize,
  AWSIconsList,
  AWSIcon,
} from "../../components";

const ViraBubble: FC<ViraBubbleProps> = ({ answerStatus, showBubble }) => {
  return (
    <div className="tw-z-50">
      {answerStatus === "DEFAULT" ? (
        <div className="tw-animate-scale-up-rotate">
          <div className="tw-bg-bubble-ring-gradient tw-rounded-full tw-absolute tw--top-0.5 tw--left-0.5 tw-h-11 tw-w-11 tw-animate-rotate-center"></div>
          <div className="tw-bg-slate-800 tw-bg-bubble-gradient tw-rounded-full tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-relative">
            <AWSIcon name={AWSIconsList.appcd_vira_bubble} size={IconSize.lg} />
          </div>
        </div>
      ) : answerStatus === "ANSWERED" ? (
        <div className="tw-bg-green-500 tw-h-10 tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center">
          <Icon
            name={IconsList.CHECK}
            className="tw-text-gray-500"
            size={IconSize.lg}
          />
        </div>
      ) : null}
      {showBubble && (
        <audio autoPlay>
          <source src="/audio/vira-bubble-open.mp3" type="audio/mpeg" />
        </audio>
      )}
    </div>
  );
};

export default ViraBubble;
