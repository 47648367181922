import { atom } from "jotai";
import { ErrorHandler, recommendationsApiObj } from "../utils";
import type {
  RecommendationResponseRequest,
  GetRecommendationsRequest,
} from "../apis/vira";
import { setRequestIdFromResponseAtom } from "./logger.store";

export const fetchRecommendationsAtom = atom(
  null,
  async (get, set, requestParams: GetRecommendationsRequest) => {
    try {
      const respObj = await recommendationsApiObj.getRecommendations(
        requestParams
      );
      return respObj;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      ErrorHandler(error);
    }
  }
);
export const saveQuestionResponseAtom = atom(
  null,
  async (get, set, requestParams: RecommendationResponseRequest) => {
    try {
      const respObj = await recommendationsApiObj.recommendationResponse(
        requestParams
      );
      return respObj;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      ErrorHandler(error);
    }
  }
);
