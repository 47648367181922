export interface SpinnerProps {
  className?: string;
  dataTestId?: string;
  type?: keyof typeof SpinnerType;
  variant?: keyof typeof SpinnerVariant;
}

export enum SpinnerType {
  BARS = "BARS",
  RING = "RING",
}

export enum SpinnerVariant {
  light = "tw-text-gray-100",
  dark = "tw-text-black-100",
}
