import { useEffect } from "react";

interface KeyboardZoomHandlers {
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const useKeyboardZoom = ({ onZoomIn, onZoomOut }: KeyboardZoomHandlers) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey || e.ctrlKey) {
        switch (e.key) {
          case "=":
          case "+":
            e.preventDefault();
            onZoomIn?.();
            break;
          case "-":
            e.preventDefault();
            onZoomOut?.();
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onZoomIn, onZoomOut]);
};

export default useKeyboardZoom;
