/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TokenType } from './TokenType';
import {
    TokenTypeFromJSON,
    TokenTypeFromJSONTyped,
    TokenTypeToJSON,
} from './TokenType';

/**
 * Contains all the information needed to manage API Key sessions
 * @export
 * @interface SessionInfo
 */
export interface SessionInfo {
    /**
     * Name of the API Key. You cannot have two API Keys with the same name
     * @type {string}
     * @memberof SessionInfo
     */
    apiKeyName: string;
    /**
     * Description of the API Key. The maximum length is 100 characters, should be something descriptive for future use
     * @type {string}
     * @memberof SessionInfo
     */
    description: string;
    /**
     * API Key expiration date, cannot be longer than 1 year
     * @type {Date}
     * @memberof SessionInfo
     */
    ttl: Date;
    /**
     * 
     * @type {TokenType}
     * @memberof SessionInfo
     */
    sessionType: TokenType;
    /**
     * Last time the API Key was used
     * @type {Date}
     * @memberof SessionInfo
     */
    lastUsed?: Date | null;
    /**
     * The date and time the API Key was created
     * @type {Date}
     * @memberof SessionInfo
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the SessionInfo interface.
 */
export function instanceOfSessionInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiKeyName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "ttl" in value;
    isInstance = isInstance && "sessionType" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function SessionInfoFromJSON(json: any): SessionInfo {
    return SessionInfoFromJSONTyped(json, false);
}

export function SessionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiKeyName': json['apiKeyName'],
        'description': json['description'],
        'ttl': (new Date(json['ttl'])),
        'sessionType': TokenTypeFromJSON(json['sessionType']),
        'lastUsed': !exists(json, 'lastUsed') ? undefined : (json['lastUsed'] === null ? null : new Date(json['lastUsed'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function SessionInfoToJSON(value?: SessionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiKeyName': value.apiKeyName,
        'description': value.description,
        'ttl': (value.ttl.toISOString()),
        'sessionType': TokenTypeToJSON(value.sessionType),
        'lastUsed': value.lastUsed === undefined ? undefined : (value.lastUsed === null ? null : value.lastUsed.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

