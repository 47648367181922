/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetHealth200Response
 */
export interface GetHealth200Response {
    /**
     * Status of the service
     * @type {string}
     * @memberof GetHealth200Response
     */
    status?: GetHealth200ResponseStatusEnum;
    /**
     * Version of the service
     * @type {string}
     * @memberof GetHealth200Response
     */
    version?: string;
    /**
     * Uptime of the service in milliseconds
     * @type {number}
     * @memberof GetHealth200Response
     */
    uptime?: number;
}


/**
 * @export
 */
export const GetHealth200ResponseStatusEnum = {
    Up: 'UP',
    Down: 'DOWN'
} as const;
export type GetHealth200ResponseStatusEnum = typeof GetHealth200ResponseStatusEnum[keyof typeof GetHealth200ResponseStatusEnum];


/**
 * Check if a given object implements the GetHealth200Response interface.
 */
export function instanceOfGetHealth200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetHealth200ResponseFromJSON(json: any): GetHealth200Response {
    return GetHealth200ResponseFromJSONTyped(json, false);
}

export function GetHealth200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetHealth200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'uptime': !exists(json, 'uptime') ? undefined : json['uptime'],
    };
}

export function GetHealth200ResponseToJSON(value?: GetHealth200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'version': value.version,
        'uptime': value.uptime,
    };
}

