/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceIAMDiff
 */
export interface ResourceIAMDiff {
    /**
     * 
     * @type {string}
     * @memberof ResourceIAMDiff
     */
    resourceId1?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceIAMDiff
     */
    resourceId2?: string;
    /**
     * 
     * @type {object}
     * @memberof ResourceIAMDiff
     */
    resource1IAMPolicy?: object;
    /**
     * 
     * @type {object}
     * @memberof ResourceIAMDiff
     */
    resource2IAMPolicy?: object;
}

/**
 * Check if a given object implements the ResourceIAMDiff interface.
 */
export function instanceOfResourceIAMDiff(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceIAMDiffFromJSON(json: any): ResourceIAMDiff {
    return ResourceIAMDiffFromJSONTyped(json, false);
}

export function ResourceIAMDiffFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceIAMDiff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resourceId1': !exists(json, 'ResourceId1') ? undefined : json['ResourceId1'],
        'resourceId2': !exists(json, 'ResourceId2') ? undefined : json['ResourceId2'],
        'resource1IAMPolicy': !exists(json, 'Resource1IAMPolicy') ? undefined : json['Resource1IAMPolicy'],
        'resource2IAMPolicy': !exists(json, 'Resource2IAMPolicy') ? undefined : json['Resource2IAMPolicy'],
    };
}

export function ResourceIAMDiffToJSON(value?: ResourceIAMDiff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ResourceId1': value.resourceId1,
        'ResourceId2': value.resourceId2,
        'Resource1IAMPolicy': value.resource1IAMPolicy,
        'Resource2IAMPolicy': value.resource2IAMPolicy,
    };
}

