/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMeRequest
 */
export interface UpdateMeRequest {
    /**
     * Name of the user
     * @type {string}
     * @memberof UpdateMeRequest
     */
    name?: string;
    /**
     * Company of the user
     * @type {string}
     * @memberof UpdateMeRequest
     */
    company?: string;
}

/**
 * Check if a given object implements the UpdateMeRequest interface.
 */
export function instanceOfUpdateMeRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateMeRequestFromJSON(json: any): UpdateMeRequest {
    return UpdateMeRequestFromJSONTyped(json, false);
}

export function UpdateMeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'company': !exists(json, 'company') ? undefined : json['company'],
    };
}

export function UpdateMeRequestToJSON(value?: UpdateMeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'company': value.company,
    };
}

