import React, { useState, useCallback } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";

import {
  Button,
  Icon,
  IconsList,
  TextArea,
  Dialog,
  IconSize,
  Spinner,
} from "../../../components";

import { trackAmplitudeEvent, isFunction } from "../../../utils";

const LlmPrompt = ({
  appStackUUID,
  onSuccess,
}: {
  appStackUUID: string;
  onSuccess?: (successData: any) => void;
}) => {
  const [showLLMPrompt, setShowLLMPrompt] = useState(false);
  const [llmPromptSubmitError, setLlmPromptSubmitError] = useState<
    string | null
  >(null);

  const submitPrompt = useCallback(
    async (message: string) => {
      setLlmPromptSubmitError(null);
      trackAmplitudeEvent("llm_topology_prompt_submit");
      try {
        const promptResult = await fetch(`/ai/public/generate_topology`, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            app_stack_uuid: appStackUUID,
            message: message,
          }),
        });
        if (promptResult.ok) {
          trackAmplitudeEvent("llm_topology_prompt_success");
          const appStackData = await promptResult.json();
          setShowLLMPrompt(false);
          if (onSuccess && isFunction(onSuccess)) {
            onSuccess(appStackData);
          }
        } else {
          trackAmplitudeEvent("llm_topology_prompt_failed");
          const errorMsg = await promptResult.json();
          if (typeof errorMsg?.detail === "string") {
            setLlmPromptSubmitError(errorMsg.detail);
          } else {
            if (errorMsg?.detail?.length > 0) {
              setLlmPromptSubmitError(errorMsg.detail[0]?.msg);
            } else {
              setLlmPromptSubmitError(
                "Failed to generate deployment architecture. Please try again with other message."
              );
            }
          }
        }
      } catch (err) {
        setLlmPromptSubmitError("Something went wrong! Please try again.");
      }
    },
    [
      appStackUUID,
      llmPromptSubmitError,
      setLlmPromptSubmitError,
      setShowLLMPrompt,
    ]
  );

  if (showLLMPrompt) {
    return (
      <Formik
        initialValues={{
          message: "",
        }}
        validationSchema={yup.object().shape({
          message: yup.string().required("This field is required."),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          await submitPrompt(values.message);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form className="tw-w-125">
              <Dialog
                size="sm"
                titleContent={
                  <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
                    <span className="tw-text-white tw-text-xssm tw-font-semibold">
                      Generate deployment architecture
                    </span>
                    <button
                      onClick={() => {
                        setLlmPromptSubmitError(null);
                        setShowLLMPrompt(false);
                      }}
                      type="button"
                      disabled={isSubmitting}
                      className={`tw-text-gray-300 ${
                        isSubmitting
                          ? "tw-opacity-50 tw-cursor-not-allowed"
                          : "hover:tw-text-gray-200 tw-cursor-pointer"
                      }`}
                      data-testid="llm-prompt-close-dialog"
                    >
                      <Icon name={IconsList.CLOSE} />
                    </button>
                  </div>
                }
                bodyContent={
                  <>
                    {isSubmitting ? (
                      <div className="tw-flex tw-flex-col tw-items-center tw-gap-4 tw-text-center tw-text-sm tw-text-white tw-font-normal tw-mb-2">
                        <Spinner />
                        <span>
                          Generating deployment architecture for your prompt-
                        </span>
                        <span className="tw-italic">"{values.message}"</span>
                      </div>
                    ) : (
                      <>
                        <TextArea
                          name="message"
                          placeholder="Deployment architecture for an external facing e-commerce application with two micro-services of cart service and payment service on EKS and order picked up from a message queue on AWS Lambda."
                          backgroundVariant="gray-800"
                          dataTestId="llm-prompt-textarea"
                          rows={6}
                        />
                        {llmPromptSubmitError && (
                          <div className="tw-mt-1 tw-text-xs tw-text-red-400">
                            {llmPromptSubmitError}
                          </div>
                        )}
                      </>
                    )}
                    <div className="tw-pt-3">
                      <Button
                        className="tw-w-full"
                        type="submit"
                        label={isSubmitting ? "Generating..." : "Generate"}
                        leftIcon={IconsList.SPARKLES}
                        dataTestId="llm-prompt-submit-button"
                        disabled={isSubmitting}
                      />
                      <div className="tw-mt-2 tw-flex tw-gap-2 tw-items-center tw-text-gray-300">
                        <Icon name={IconsList.INFO_CIRCLE} size={IconSize.sm} />
                        <span className="tw-text-xs">
                          The deployment architecture will be generated as a new
                          version of this AppStack.
                        </span>
                      </div>
                    </div>
                  </>
                }
                dataTestId="llm-prompt-dialog"
              />
            </Form>
          );
        }}
      </Formik>
    );
  } else {
    return (
      <div
        className="tw-p-2 tw-bg-gray-800 tw-border tw-border-gray-600 tw-rounded-md tw-cursor-pointer hover:tw-bg-gray-700 tw-text-purple-400"
        onClick={() => setShowLLMPrompt(true)}
      >
        <Icon name={IconsList.SPARKLES} />
      </div>
    );
  }
};

export default LlmPrompt;
