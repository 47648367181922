/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Policy
 */
export interface Policy {
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    ruleId?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    severity?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Policy
     */
    benchmarks?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    resourceType?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof Policy
     */
    version?: string;
}

/**
 * Check if a given object implements the Policy interface.
 */
export function instanceOfPolicy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolicyFromJSON(json: any): Policy {
    return PolicyFromJSONTyped(json, false);
}

export function PolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Policy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ruleId': !exists(json, 'ruleId') ? undefined : json['ruleId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'severity': !exists(json, 'severity') ? undefined : json['severity'],
        'benchmarks': !exists(json, 'benchmarks') ? undefined : json['benchmarks'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function PolicyToJSON(value?: Policy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ruleId': value.ruleId,
        'name': value.name,
        'description': value.description,
        'severity': value.severity,
        'benchmarks': value.benchmarks,
        'category': value.category,
        'resourceType': value.resourceType,
        'type': value.type,
        'provider': value.provider,
        'version': value.version,
    };
}

