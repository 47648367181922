import { Formik } from "formik";
import React, { MouseEvent } from "react";
import type { ColumnType } from "./table-types";

import { Checkbox } from "../../components";

const TableHeader = ({
  data,
  dataTestId,
}: {
  data: ColumnType[];
  dataTestId: string;
}) => {
  return (
    <thead className="tw-text-xssm tw-text-gray-400 tw-bg-gray-800 tw-border-b tw-border-b-gray-700">
      <tr>
        {data?.map((item) => (
          <th
            scope="col"
            className={`tw-px-4 tw-py-3 tw-text-xssm tw-text-gray-300 tw-font-normal ${
              item.className ? item.className : ""
            }`}
            key={`table_header_${item.id}`}
            data-testid={`${dataTestId}-table-header-${item.id}`}
          >
            {item.hasSelectAllCheckbox ? (
              <Formik
                initialValues={{ [`${item.id}_select_all_checkbox`]: false }}
                onSubmit={() => {}}
              >
                {() => (
                  <Checkbox
                    name={`${item.id}_select_all_checkbox`}
                    id={`${item.id}_select_all_checkbox`}
                    dataTestId={`${dataTestId}-select-all-checkbox`}
                    onClick={(e: MouseEvent<HTMLInputElement>) => {
                      item.onSelectAllCheckboxClick?.(
                        (e.target as HTMLInputElement)?.checked
                      );
                    }}
                    className="!tw-border-gray-400"
                  />
                )}
              </Formik>
            ) : (
              <>{item.data}</>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
