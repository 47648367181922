/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceConfigOptionDependentAttributesInnerLinkAttributes } from './ResourceConfigOptionDependentAttributesInnerLinkAttributes';
import {
    ResourceConfigOptionDependentAttributesInnerLinkAttributesFromJSON,
    ResourceConfigOptionDependentAttributesInnerLinkAttributesFromJSONTyped,
    ResourceConfigOptionDependentAttributesInnerLinkAttributesToJSON,
} from './ResourceConfigOptionDependentAttributesInnerLinkAttributes';

/**
 * 
 * @export
 * @interface ResourceConfigOptionDependentAttributesInner
 */
export interface ResourceConfigOptionDependentAttributesInner {
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOptionDependentAttributesInner
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOptionDependentAttributesInner
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOptionDependentAttributesInner
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOptionDependentAttributesInner
     */
    label: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceConfigOptionDependentAttributesInner
     */
    disabled?: boolean;
    /**
     * 
     * @type {ResourceConfigOptionDependentAttributesInnerLinkAttributes}
     * @memberof ResourceConfigOptionDependentAttributesInner
     */
    linkAttributes?: ResourceConfigOptionDependentAttributesInnerLinkAttributes;
}

/**
 * Check if a given object implements the ResourceConfigOptionDependentAttributesInner interface.
 */
export function instanceOfResourceConfigOptionDependentAttributesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "label" in value;

    return isInstance;
}

export function ResourceConfigOptionDependentAttributesInnerFromJSON(json: any): ResourceConfigOptionDependentAttributesInner {
    return ResourceConfigOptionDependentAttributesInnerFromJSONTyped(json, false);
}

export function ResourceConfigOptionDependentAttributesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceConfigOptionDependentAttributesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'type': json['type'],
        'label': json['label'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'linkAttributes': !exists(json, 'linkAttributes') ? undefined : ResourceConfigOptionDependentAttributesInnerLinkAttributesFromJSON(json['linkAttributes']),
    };
}

export function ResourceConfigOptionDependentAttributesInnerToJSON(value?: ResourceConfigOptionDependentAttributesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'value': value.value,
        'type': value.type,
        'label': value.label,
        'disabled': value.disabled,
        'linkAttributes': ResourceConfigOptionDependentAttributesInnerLinkAttributesToJSON(value.linkAttributes),
    };
}

