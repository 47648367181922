/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Options } from './Options';
import {
    OptionsFromJSON,
    OptionsFromJSONTyped,
    OptionsToJSON,
} from './Options';
import type { QuestionType } from './QuestionType';
import {
    QuestionTypeFromJSON,
    QuestionTypeFromJSONTyped,
    QuestionTypeToJSON,
} from './QuestionType';
import type { RangeOption } from './RangeOption';
import {
    RangeOptionFromJSON,
    RangeOptionFromJSONTyped,
    RangeOptionToJSON,
} from './RangeOption';

/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    title?: string;
    /**
     * 
     * @type {QuestionType}
     * @memberof Question
     */
    type?: QuestionType;
    /**
     * 
     * @type {Array<Options>}
     * @memberof Question
     */
    options?: Array<Options>;
    /**
     * 
     * @type {RangeOption}
     * @memberof Question
     */
    rangeOption?: RangeOption;
}

/**
 * Check if a given object implements the Question interface.
 */
export function instanceOfQuestion(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function QuestionFromJSON(json: any): Question {
    return QuestionFromJSONTyped(json, false);
}

export function QuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Question {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'type': !exists(json, 'type') ? undefined : QuestionTypeFromJSON(json['type']),
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(OptionsFromJSON)),
        'rangeOption': !exists(json, 'range_option') ? undefined : RangeOptionFromJSON(json['range_option']),
    };
}

export function QuestionToJSON(value?: Question | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'type': QuestionTypeToJSON(value.type),
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(OptionsToJSON)),
        'range_option': RangeOptionToJSON(value.rangeOption),
    };
}

