import { createJSONStorage, atomWithStorage } from "jotai/utils";
import {
  QuickAppStackCreationForm,
  type QuickAppStackPageMeta,
} from "../components/quick-appstack-creation/quick-appstack-creation-types";
import { atom } from "jotai";
import { ftuxApiObject } from "../utils/api-objects";
import { ErrorHandler } from "../utils";
import { setRequestIdFromResponseAtom } from "./logger.store";

const storage = createJSONStorage(() =>
  typeof window !== "undefined"
    ? window.localStorage
    : (undefined as unknown as Storage)
);

// it will hold a backup of the form data in quick appstack creation flow. Since when scm based configuration is triggered, it will rerender whole ui and by using this, we'll take slow to last filled state
export const quickAppStackCreateFormDataBackupAtom =
  atomWithStorage<null | QuickAppStackCreationForm>(
    "quickAppStackCreateFormDataBackup",
    null,
    storage as any,
    {
      getOnInit: true,
    }
  );

export const submitQuestionnaireAtom = atom(
  null,
  async (get, set, reqBody: object) => {
    try {
      await ftuxApiObject.fTUXQuestionnaire({
        body: reqBody,
      });
      return true;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      await ErrorHandler(error);
      return false;
    }
  }
);

export const quickAppStackPageMetaAtom =
  atomWithStorage<QuickAppStackPageMeta | null>(
    "quickAppStackPageMeta",
    null,
    storage as any,
    {
      getOnInit: true,
    }
  );
