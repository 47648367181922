import React, { type FC } from "react";
import { Field, type FieldProps } from "formik";
import Select from "react-select";
import { type SelectBoxProps } from "./select-box-types";
import { BackgroundVariant, LabelVariant } from "./form-elements-types";
import { isFunction } from "../../utils";

const SelectBox = ({
  name,
  options,
  label,
  description,
  className = "",
  descriptionClassName = "",
  menuListStyleOverride = {},
  onChange,
  placeholder,
  disabled,
  backgroundVariant = "gray-900",
  labelVariant = "white-semibold",
  subLabel = "",
  isOptional,
  dataTestId,
  required,
  labelSuffix,
  labelSuffixAction,
  menuPosition = "absolute",
  filterOption,
}: SelectBoxProps) => {
  const LabelComponent: FC<void> = () => (
    <>
      {(label || subLabel) && (
        <div className="tw-flex tw-items-center tw-justify-between">
          {
            <>
              {label && (
                <label
                  className={`tw-text-xssm ${
                    disabled
                      ? LabelVariant["gray400-semibold"]
                      : LabelVariant[labelVariant] ?? ""
                  }`}
                  htmlFor={name}
                >
                  {label}{" "}
                  {isOptional ? (
                    <span
                      className={` tw-font-normal ${
                        disabled ? "tw-text-gray-400" : "tw-text-gray-200"
                      }`}
                    >
                      {" "}
                      (Optional)
                    </span>
                  ) : (
                    required && <span className="tw-text-red-400"> *</span>
                  )}
                </label>
              )}
              {labelSuffix && (
                <label
                  className="tw-cursor-pointer"
                  data-testid={`selectbox-labelsuffix-${dataTestId}`}
                  onClick={() => {
                    labelSuffixAction &&
                      isFunction(labelSuffixAction) &&
                      labelSuffixAction();
                  }}
                >
                  {labelSuffix}
                </label>
              )}
              {subLabel && (
                <label
                  className={`tw-text-xs tw-italic ${
                    disabled ? "tw-text-gray-400" : "tw-text-white"
                  }`}
                >
                  {subLabel}
                </label>
              )}
            </>
          }
        </div>
      )}
    </>
  );

  return (
    <Field name={name}>
      {({ form, meta, field }: FieldProps) => {
        const getValue = () => {
          if (options) {
            return (
              options.find((option) => option.value === field.value) ?? null
            );
          } else {
            return null;
          }
        };

        return (
          <div
            className="tw-flex tw-flex-col tw-gap-1"
            data-testid={`${dataTestId}-select-box-wrapper`}
          >
            {LabelComponent()}
            <Select
              name={name}
              options={options}
              value={getValue()}
              menuPosition={menuPosition}
              onBlur={() => field.onBlur({ target: { name } })}
              isDisabled={disabled}
              filterOption={filterOption ?? undefined}
              onChange={
                onChange ??
                ((selected_option) => {
                  form.setFieldValue(
                    name,
                    selected_option ? selected_option?.value : null
                  );
                })
              }
              unstyled
              styles={{
                input: (base) => ({
                  ...base,
                  "input:focus": {
                    boxShadow: "none",
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  ...menuListStyleOverride, // need to add this to override the default style
                }),
              }}
              classNames={{
                control: ({ isFocused }) =>
                  `tw-py-1.25 tw-px-2 tw-border tw-text-sm tw-rounded-lg tw-placeholder-gray-400 tw-text-white !tw-min-h-fit ${
                    isFocused
                      ? "!tw-border-blue-600 tw-ring-0.5 tw-ring-inset"
                      : ""
                  } ${
                    meta.touched && meta.error
                      ? "tw-border-red-400 error-field"
                      : "tw-border-gray-600"
                  } ${
                    disabled
                      ? "!tw-bg-gray-500 tw-border-none !tw-bg-opacity-20"
                      : `tw-bg-gray-700 ${className} ${
                          BackgroundVariant[backgroundVariant] ?? ""
                        }`
                  }`,
                menu: () =>
                  "tw-rounded-lg tw-bg-gray-700 tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-text-sm tw-text-gray-200 tw-border tw-border-gray-600",
                option: ({ isSelected }) =>
                  `tw-px-4 tw-py-2 hover:tw-cursor-pointer tw-text-xs tw-flex tw-items-center hover:tw-bg-gray-500 hover:tw-bg-opacity-20 ${
                    isSelected
                      ? "after:tw-content-['✓'] after:tw-absolute after:tw-right-4 after:tw-text-gray-100 after:tw-text-xssm"
                      : ""
                  }`,
                noOptionsMessage: () =>
                  "tw-px-4 tw-py-2 hover:tw-cursor-not-allowed hover:tw-bg-gray-700",
                indicatorsContainer: () => "tw-text-sm tw-text-gray-400",
                indicatorSeparator: () => "tw-w-4",
                placeholder: () => "tw-text-sm tw-text-gray-400",
                singleValue: () =>
                  `${
                    disabled ? "tw-text-gray-400" : "tw-text-gray-50"
                  } tw-text-sm`,
              }}
              {...(placeholder && { placeholder })}
              menuPlacement="auto"
              data-testid={`${dataTestId}-select-box`}
            />
            <div>
              {meta.touched && meta.error ? (
                <div
                  className="tw-text-xs tw-text-red-400"
                  data-testid={`${dataTestId}-select-box-error`}
                >
                  {meta.error}
                </div>
              ) : null}
              {description && (
                <p
                  className={`tw-break-all tw-text-xs ${
                    disabled ? "tw-text-gray-400" : "tw-text-gray-300"
                  } ${descriptionClassName}`}
                  data-testid={`${dataTestId}-select-box-description`}
                >
                  {description}
                </p>
              )}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default SelectBox;
