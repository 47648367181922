import React, { useState, useEffect, type FC } from "react";
import Select from "react-select";
import { type DropdownProps } from "./dropdown-types";

/**
 * Creates a dropdown component with the given options and handlers.
 *
 * - @param {DropdownProps} options - The options for the dropdown.
 * - @param {Function} onSelectionChange - The event handler for when the selection changes.
 * - @param {string} selectedValue - The currently selected value.
 * - @param {string} placeholder - The placeholder text for the dropdown.
 * - @param {string} className - The CSS class name for the dropdown.
 * - @param {string} containerClassName - The CSS class name for the container.
 * - @param {string} menuListClassName - The CSS class name for the menu list.
 * - @param {string} menuClassName - The CSS class name for the menu.
 * - @param {Function} onMenuOpen - The event handler for when the menu is opened.
 * - @param {Function} onMenuClose - The event handler for when the menu is closed.
 * - @param {boolean} isMenuOpen - Whether the menu is open can be used to programmatically open/close the menu.
 * - @param {string} dataTestId - The data-testid for the dropdown.
 * - @param {boolean} closeMenuOnSelect - Whether menu should remain open after selection.
 * - @return {JSX.Element} The rendered dropdown component.
 */
const Dropdown: FC<DropdownProps> = ({
  options = [],
  selectedValue,
  children,
  placeholder = "",
  isMenuOpen = false,
  className,
  containerClassName = "tw-w-fit",
  menuClassName = "!tw-w-fit",
  menuListClassName = "tw-w-max",
  dataTestId,
  onSelectionChange = (option: { [key: string]: any }) => {},
  onMenuClose = () => {},
  onMenuOpen = () => {},
  closeMenuOnSelect = true,
}) => {
  const [value, setValue] = useState(selectedValue);
  const [open, setOpen] = useState(isMenuOpen); // open state for the menu

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setOpen(isMenuOpen);
  }, [isMenuOpen]);

  // Find the selected option based on the selected value
  const selectedOption = options.find((option) => option.value === value);
  return (
    <Select
      className={containerClassName}
      value={selectedOption || null}
      options={options}
      isSearchable={false}
      menuPlacement="auto"
      placeholder={placeholder}
      onChange={(option) => {
        setValue(option.value);
        onSelectionChange?.(option);
      }}
      onMenuOpen={() => {
        onMenuOpen();
        setOpen(true);
      }}
      onMenuClose={() => {
        onMenuClose();
        setOpen(false);
      }}
      menuIsOpen={open}
      unstyled
      closeMenuOnSelect={closeMenuOnSelect}
      classNames={{
        control: () => (className ? className : ""),
        menu: () =>
          `tw-rounded-md tw-text-center tw-bg-gray-700 tw-border tw-border-gray-600 tw-shadow-sm tw-right-0 ${menuClassName}`,
        menuList: () =>
          `tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md ${menuListClassName}`,
        option: ({ isSelected, isDisabled, data }) => {
          return `tw-p-2 hover:tw-bg-gray-500 tw-bg-gray-700 tw-text-xs ${
            isSelected ? "tw-text-white" : "tw-text-gray-200"
          } ${
            isDisabled
              ? "tw-cursor-not-allowed tw-opacity-50"
              : "tw-cursor-pointer"
          } ${data?.className ? data.className : ""}`;
        },
        indicatorSeparator: () => "tw-w-1",
        indicatorsContainer: () => "tw-cursor-pointer",
        singleValue: () => "tw-text-gray-200",
        noOptionsMessage: () =>
          "tw-px-2 tw-py-2 tw-text-left hover:tw-cursor-not-allowed",
      }}
      components={
        children
          ? {
              IndicatorsContainer: () => (
                <div className="tw-cursor-pointer tw-w-full">{children}</div>
              ),
              SingleValue: () => null,
            }
          : {}
      }
      data-testid={dataTestId}
    />
  );
};

export default Dropdown;
