/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Config,
  CreateConfig201Response,
} from '../models/index';
import {
    ConfigFromJSON,
    ConfigToJSON,
    CreateConfig201ResponseFromJSON,
    CreateConfig201ResponseToJSON,
} from '../models/index';

export interface CreateConfigRequest {
    config: Config;
    orgId?: string;
}

export interface DeleteConfigRequest {
    id: string;
    orgId?: string;
}

export interface DeleteConfigsRequest {
    ids: Array<string>;
    orgId?: string;
}

export interface GetConfigRequest {
    id: string;
    orgId?: string;
}

export interface GetConfigsRequest {
    orgId?: string;
    type?: GetConfigsTypeEnum;
}

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     * Create a new configuration
     */
    async createConfigRaw(requestParameters: CreateConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateConfig201Response>> {
        if (requestParameters.config === null || requestParameters.config === undefined) {
            throw new runtime.RequiredError('config','Required parameter requestParameters.config was null or undefined when calling createConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigToJSON(requestParameters.config),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateConfig201ResponseFromJSON(jsonValue));
    }

    /**
     * Create a new configuration
     */
    async createConfig(requestParameters: CreateConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateConfig201Response> {
        const response = await this.createConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a configuration
     */
    async deleteConfigRaw(requestParameters: DeleteConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a configuration
     */
    async deleteConfig(requestParameters: DeleteConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConfigRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a configuration
     */
    async deleteConfigsRaw(requestParameters: DeleteConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling deleteConfigs.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/config`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a configuration
     */
    async deleteConfigs(requestParameters: DeleteConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConfigsRaw(requestParameters, initOverrides);
    }

    /**
     * Get the configuration of the service
     */
    async getConfigRaw(requestParameters: GetConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Config>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConfig.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/config/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigFromJSON(jsonValue));
    }

    /**
     * Get the configuration of the service
     */
    async getConfig(requestParameters: GetConfigRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Config> {
        const response = await this.getConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the configuration of the service
     */
    async getConfigsRaw(requestParameters: GetConfigsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Config>>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConfigFromJSON));
    }

    /**
     * Get the configuration of the service
     */
    async getConfigs(requestParameters: GetConfigsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Config>> {
        const response = await this.getConfigsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetConfigsTypeEnum = {
    Blobstore: 'blobstore',
    Git: 'git'
} as const;
export type GetConfigsTypeEnum = typeof GetConfigsTypeEnum[keyof typeof GetConfigsTypeEnum];
