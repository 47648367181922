/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceConfigOptions } from './ResourceConfigOptions';
import {
    ResourceConfigOptionsFromJSON,
    ResourceConfigOptionsFromJSONTyped,
    ResourceConfigOptionsToJSON,
} from './ResourceConfigOptions';
import type { SchemaItem } from './SchemaItem';
import {
    SchemaItemFromJSON,
    SchemaItemFromJSONTyped,
    SchemaItemToJSON,
} from './SchemaItem';
import type { TopologyResourceConfigValidationValidation } from './TopologyResourceConfigValidationValidation';
import {
    TopologyResourceConfigValidationValidationFromJSON,
    TopologyResourceConfigValidationValidationFromJSONTyped,
    TopologyResourceConfigValidationValidationToJSON,
} from './TopologyResourceConfigValidationValidation';

/**
 * 
 * @export
 * @interface TopologyResourceConfigValidation
 */
export interface TopologyResourceConfigValidation {
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigValidation
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigValidation
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigValidation
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigValidation
     */
    type?: string;
    /**
     * 
     * @type {TopologyResourceConfigValidationValidation}
     * @memberof TopologyResourceConfigValidation
     */
    validation?: TopologyResourceConfigValidationValidation;
    /**
     * 
     * @type {ResourceConfigOptions}
     * @memberof TopologyResourceConfigValidation
     */
    options?: ResourceConfigOptions;
    /**
     * 
     * @type {Array<SchemaItem>}
     * @memberof TopologyResourceConfigValidation
     */
    schema?: Array<SchemaItem>;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConfigValidation
     */
    fallbackValue?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopologyResourceConfigValidation
     */
    dependentAttributes?: boolean;
}

/**
 * Check if a given object implements the TopologyResourceConfigValidation interface.
 */
export function instanceOfTopologyResourceConfigValidation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyResourceConfigValidationFromJSON(json: any): TopologyResourceConfigValidation {
    return TopologyResourceConfigValidationFromJSONTyped(json, false);
}

export function TopologyResourceConfigValidationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceConfigValidation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'validation': !exists(json, 'validation') ? undefined : TopologyResourceConfigValidationValidationFromJSON(json['validation']),
        'options': !exists(json, 'options') ? undefined : ResourceConfigOptionsFromJSON(json['options']),
        'schema': !exists(json, 'schema') ? undefined : ((json['schema'] as Array<any>).map(SchemaItemFromJSON)),
        'fallbackValue': !exists(json, 'fallbackValue') ? undefined : json['fallbackValue'],
        'dependentAttributes': !exists(json, 'dependentAttributes') ? undefined : json['dependentAttributes'],
    };
}

export function TopologyResourceConfigValidationToJSON(value?: TopologyResourceConfigValidation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'label': value.label,
        'description': value.description,
        'type': value.type,
        'validation': TopologyResourceConfigValidationValidationToJSON(value.validation),
        'options': ResourceConfigOptionsToJSON(value.options),
        'schema': value.schema === undefined ? undefined : ((value.schema as Array<any>).map(SchemaItemToJSON)),
        'fallbackValue': value.fallbackValue,
        'dependentAttributes': value.dependentAttributes,
    };
}

