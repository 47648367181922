/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResourceOverrideMappingPolicy,
} from '../models/index';
import {
    ResourceOverrideMappingPolicyFromJSON,
    ResourceOverrideMappingPolicyToJSON,
} from '../models/index';

export interface DeleteResourceOverrideMappingPolicyRequest {
    policyID: string;
    orgId?: string;
}

export interface GetResourceOverrideMappingPoliciesRequest {
    orgId?: string;
    policyIds?: Array<string>;
    names?: Array<string>;
}

export interface GetResourceOverrideMappingPolicyRequest {
    policyID: string;
    orgId?: string;
}

export interface ImportResourceOverrideMappingPoliciesRequest {
    orgId?: string;
    resourceOverrideMappingPolicies?: Blob;
}

export interface SaveResourceOverrideMappingPoliciesRequest {
    resourceOverrideMappingPolicy: ResourceOverrideMappingPolicy;
    orgId?: string;
}

/**
 * 
 */
export class V1ResourceMappingOverridePoliciesApi extends runtime.BaseAPI {

    /**
     * Delete resource override mapping policy
     */
    async deleteResourceOverrideMappingPolicyRaw(requestParameters: DeleteResourceOverrideMappingPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling deleteResourceOverrideMappingPolicy.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-override-mapping-policies/{policyID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete resource override mapping policy
     */
    async deleteResourceOverrideMappingPolicy(requestParameters: DeleteResourceOverrideMappingPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResourceOverrideMappingPolicyRaw(requestParameters, initOverrides);
    }

    /**
     * Get resource override mapping policies
     */
    async getResourceOverrideMappingPoliciesRaw(requestParameters: GetResourceOverrideMappingPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResourceOverrideMappingPolicy>>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.policyIds) {
            queryParameters['policyIds'] = requestParameters.policyIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters.names) {
            queryParameters['names'] = requestParameters.names.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-override-mapping-policies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceOverrideMappingPolicyFromJSON));
    }

    /**
     * Get resource override mapping policies
     */
    async getResourceOverrideMappingPolicies(requestParameters: GetResourceOverrideMappingPoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResourceOverrideMappingPolicy>> {
        const response = await this.getResourceOverrideMappingPoliciesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get resource override mapping policy
     */
    async getResourceOverrideMappingPolicyRaw(requestParameters: GetResourceOverrideMappingPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceOverrideMappingPolicy>> {
        if (requestParameters.policyID === null || requestParameters.policyID === undefined) {
            throw new runtime.RequiredError('policyID','Required parameter requestParameters.policyID was null or undefined when calling getResourceOverrideMappingPolicy.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-override-mapping-policies/{policyID}`.replace(`{${"policyID"}}`, encodeURIComponent(String(requestParameters.policyID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceOverrideMappingPolicyFromJSON(jsonValue));
    }

    /**
     * Get resource override mapping policy
     */
    async getResourceOverrideMappingPolicy(requestParameters: GetResourceOverrideMappingPolicyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceOverrideMappingPolicy> {
        const response = await this.getResourceOverrideMappingPolicyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import resource override mapping policies
     */
    async importResourceOverrideMappingPoliciesRaw(requestParameters: ImportResourceOverrideMappingPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.resourceOverrideMappingPolicies !== undefined) {
            formParams.append('resource-override-mapping-policies', requestParameters.resourceOverrideMappingPolicies as any);
        }

        const response = await this.request({
            path: `/v1/import-resource-override-mapping-policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Import resource override mapping policies
     */
    async importResourceOverrideMappingPolicies(requestParameters: ImportResourceOverrideMappingPoliciesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importResourceOverrideMappingPoliciesRaw(requestParameters, initOverrides);
    }

    /**
     * Save resource override mapping policies
     */
    async saveResourceOverrideMappingPoliciesRaw(requestParameters: SaveResourceOverrideMappingPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourceOverrideMappingPolicy === null || requestParameters.resourceOverrideMappingPolicy === undefined) {
            throw new runtime.RequiredError('resourceOverrideMappingPolicy','Required parameter requestParameters.resourceOverrideMappingPolicy was null or undefined when calling saveResourceOverrideMappingPolicies.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resource-override-mapping-policies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceOverrideMappingPolicyToJSON(requestParameters.resourceOverrideMappingPolicy),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save resource override mapping policies
     */
    async saveResourceOverrideMappingPolicies(requestParameters: SaveResourceOverrideMappingPoliciesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveResourceOverrideMappingPoliciesRaw(requestParameters, initOverrides);
    }

}
