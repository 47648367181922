/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignUsersToOrgRequest
 */
export interface AssignUsersToOrgRequest {
    /**
     * uuid of the users to be added to organization
     * @type {Array<string>}
     * @memberof AssignUsersToOrgRequest
     */
    userId: Array<string>;
    /**
     * Role ID to assign user in the organization, Default to developer role
     * @type {string}
     * @memberof AssignUsersToOrgRequest
     */
    roleId?: string;
}

/**
 * Check if a given object implements the AssignUsersToOrgRequest interface.
 */
export function instanceOfAssignUsersToOrgRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function AssignUsersToOrgRequestFromJSON(json: any): AssignUsersToOrgRequest {
    return AssignUsersToOrgRequestFromJSONTyped(json, false);
}

export function AssignUsersToOrgRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignUsersToOrgRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'roleId': !exists(json, 'role_id') ? undefined : json['role_id'],
    };
}

export function AssignUsersToOrgRequestToJSON(value?: AssignUsersToOrgRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'role_id': value.roleId,
    };
}

