import React from "react";
import { IconButton, Icon, IconsList } from "../../../components";

const FileList: React.FC<{
  files: File[];
  onDelete: (file: File) => void;
}> = ({ files, onDelete }) => (
  <div className="tw-w-full tw-text-gray-400">
    {files.map((file) => {
      return (
        <div
          key={file.name}
          className="tw-flex tw-items-center tw-justify-between tw-mb-2"
        >
          <div className="tw-flex tw-items-center tw-gap-1">
            <Icon name={IconsList.FILE_CODE} className="tw-text-yellow-400" />
            <span className="tw-text-white">{file.name}</span>
          </div>
          <IconButton
            icon={IconsList.DELETE}
            dataTestId={`delete-file-${file.name}`}
            variant="secondary"
            outlined
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(file);
            }}
          />
        </div>
      );
    })}
  </div>
);

export default FileList;
