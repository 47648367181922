import React, { useCallback, useEffect, useState } from "react";
import { useAtomValue, useSetAtom, useAtom } from "jotai";
import { useLocation } from "@reach/router";

import { GetMe200ResponseOrgsInner } from "../../../apis/appcd";

import {
  goToAccountSettings,
  goToLogin,
  trackAmplitudeEvent,
} from "../../../utils";

import {
  Icon,
  IconsList,
  Avatar,
  Dropdown,
  Popover,
  IconSize,
} from "../../../components";
import InitialsLogo from "../../initials-logo/initials-logo";

import { sidebarHoveredAtom } from "../../../stores/page.store";
import {
  userLoginStatusAtom,
  userLogoutAtom,
  loggedInUserSelectedTeamAtom,
} from "../../../stores/auth.store";

const SidebarFooter = () => {
  const loggedInUserInfo = useAtomValue(userLoginStatusAtom);
  const isSidebarHovered = useAtomValue(sidebarHoveredAtom);
  const logoutUser = useSetAtom(userLogoutAtom);
  const [selectedTeam, setSelectedTeam] = useAtom(loggedInUserSelectedTeamAtom);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSwitchTeamPopoverOpen, setIsSwitchTeamPopoverOpen] = useState(false);
  const placeholderUserInfo = { name: "John Doe", email: "hello@example.com" }; // TODO: remove this once we start using full fledged auth
  const [footerDropdownOptions, setFooterDropdownOptions] = useState<any[]>([]);

  const location = useLocation();

  const onFooterMenuItemClick = async (value: string) => {
    switch (value) {
      case "switch_team":
        trackAmplitudeEvent("leftnav_user_switch_team");
        setIsSwitchTeamPopoverOpen(true);
        break;
      case "account_settings":
        trackAmplitudeEvent("leftnav_user_account_settings");
        goToAccountSettings();
        break;
      case "logout":
        await logoutUser();
        goToLogin();
        break;
      default:
        break;
    }
  };

  const updateTeamSelection = useCallback(
    (teamId: string | null) => {
      setSelectedTeam(teamId);

      const urlParams = new URLSearchParams(location.search);
      if (teamId) {
        urlParams.set("teamId", teamId);
      } else {
        urlParams.delete("teamId");
      }

      if (typeof window !== "undefined") {
        // Update URL
        const { protocol, pathname, host } = window.location;
        const newUrl = `${protocol}//${host}${pathname}?${urlParams.toString()}`;
        window.history.pushState({}, "", newUrl);

        window.location.reload();
      }
    },
    [location.search]
  );

  useEffect(() => {
    // close menu if sidebar is not hovered and in collapsed state
    if (!isSidebarHovered) {
      setIsMenuOpen(false);
      setIsSwitchTeamPopoverOpen(false);
    }
  }, [isSidebarHovered]);

  useEffect(() => {
    if (loggedInUserInfo?.orgs && loggedInUserInfo.orgs?.length > 0) {
      setFooterDropdownOptions([
        {
          label: (
            <Popover
              open={isSwitchTeamPopoverOpen}
              position="right"
              trigger={
                <div className="tw-flex tw-justify-between tw-items-center">
                  <div
                    className="tw-flex tw-gap-3 tw-items-center tw-px-1 tw-z-10"
                    data-testid="switch-team-menu-item"
                  >
                    <Icon name={IconsList.USERS} className="tw-text-gray-300" />
                    <span className="tw-text-xssm tw-text-gray-100">
                      Switch Team
                    </span>
                  </div>
                  <div>
                    <Icon
                      name={IconsList.CARET_RIGHT}
                      className="tw-text-gray-300"
                    />
                  </div>
                </div>
              }
              customContentRenderer={
                <div
                  className="tw-bg-gray-700 tw-rounded-md tw-border tw-border-gray-600 tw-py-1 tw-w-56 tw-flex tw-flex-col tw-items-start"
                  onClick={(e) => e.stopPropagation()}
                >
                  {loggedInUserInfo?.orgs.map((team) => {
                    return (
                      <div
                        key={team.id}
                        className="tw-py-2 tw-pl-3 tw-pr-2 tw-flex tw-gap-4 tw-items-center tw-justify-between tw-w-full"
                        onClick={() => {
                          updateTeamSelection(team.id as string);
                          setIsSwitchTeamPopoverOpen(false);
                        }}
                      >
                        <div className="tw-flex tw-gap-3 tw-items-center">
                          <span className="tw-basis-6">
                            <InitialsLogo
                              label={team?.name as string}
                              size={IconSize.lg}
                              className="tw-rounded-md"
                            />
                          </span>
                          <span className="tw-text-xssm tw-text-gray-100">
                            {team.name}
                          </span>
                        </div>
                        {selectedTeam === team.id && (
                          <div className="tw-text-gray-400">
                            <Icon name={IconsList.CHECK} size={IconSize.sm} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className="tw-py-2 tw-pl-3 tw-pr-2 tw-flex tw-gap-4 tw-items-center tw-justify-between tw-w-full"
                    onClick={() => {
                      updateTeamSelection(null);
                      setIsSwitchTeamPopoverOpen(false);
                    }}
                  >
                    <div className="tw-flex tw-gap-3 tw-items-center">
                      <span className="tw-basis-6">
                        <InitialsLogo
                          label={loggedInUserInfo?.name as string}
                          size={IconSize.lg}
                          className="tw-rounded-md"
                        />
                      </span>
                      <span className="tw-text-xssm tw-text-gray-100">
                        Personal Workspace
                      </span>
                    </div>
                    {!selectedTeam && (
                      <div className="tw-text-gray-400">
                        <Icon name={IconsList.CHECK} size={IconSize.sm} />
                      </div>
                    )}
                  </div>
                </div>
              }
              dataTestId="switch-team-popover"
            />
          ),
          value: "switch_team",
        },
      ]);
    }
  }, [loggedInUserInfo?.orgs, selectedTeam, isSwitchTeamPopoverOpen]);

  return (
    <Dropdown
      onMenuClose={() => setIsMenuOpen(false)}
      onMenuOpen={() => {
        setIsMenuOpen(true);
        trackAmplitudeEvent("leftnav_user_menu");
      }}
      closeMenuOnSelect={false}
      isMenuOpen={isMenuOpen}
      containerClassName="tw-w-full"
      menuClassName="tw-mb-2 tw-left-2 !tw-w-[calc(100%-1rem)]"
      menuListClassName=""
      options={[
        ...footerDropdownOptions,
        {
          label: (
            <div
              className="tw-flex tw-gap-3 tw-items-center tw-px-1 tw-z-10"
              data-testid="account-settings-menu-item"
            >
              <Icon name={IconsList.GEAR} className="tw-text-gray-300" />
              <span className="tw-text-xssm tw-text-gray-100">
                Account Settings
              </span>
            </div>
          ),
          value: "account_settings",
        },
        {
          label: (
            <div
              className="tw-flex tw-gap-3 tw-items-center tw-px-1 tw-z-10"
              data-testid="logout-menu-item"
            >
              <Icon name={IconsList.DELETE} className="tw-text-red-400" />
              <span className="tw-text-xssm tw-text-red-400">Logout</span>
            </div>
          ),
          value: "logout",
        },
      ]}
      onSelectionChange={(e) => onFooterMenuItemClick(e.value)}
      dataTestId={"sidebar-footer"}
    >
      <div className="tw-p-3 tw-border-t tw-border-gray-700 tw-overflow-hidden tw-flex tw-items-center tw-w-full">
        <div className="tw-mr-3 tw-float-left">
          <Avatar
            imageUrl={loggedInUserInfo.avatar}
            title={loggedInUserInfo.name || placeholderUserInfo.name}
            size="2xl"
          />
        </div>
        <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
          <div className="tw-flex tw-flex-col tw-flex-1">
            <span className="tw-text-gray-50 tw-text-sm tw-whitespace-nowrap">
              {loggedInUserInfo.name || placeholderUserInfo.name}
            </span>
            <span className="tw-text-gray-300 tw-text-xs tw-whitespace-nowrap">
              {loggedInUserInfo.email || placeholderUserInfo.email}
            </span>
          </div>
          <div className="tw-text-gray-300 tw-cursor-pointer tw-transition-colors hover:tw-text-gray-200">
            <Icon
              name={isMenuOpen ? IconsList.CARET_UP : IconsList.CARET_DOWN}
              dataTestId="sidebar-footer-menu-caret-icon"
            />
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default SidebarFooter;
