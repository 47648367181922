import { useSetAtom } from "jotai";
import React, { useCallback, useRef, useState } from "react";
import type { SupportedResourceType } from "../../../../apis/topology";
import {
  Button,
  Dialog,
  Dropdown,
  Icon,
  IconButton,
  IconSize,
  IconsList,
  Popover,
  Tooltip,
} from "../../../../components";
import {
  activeResourcePackAtom,
  deleteMultipleResourcePacksAtom,
} from "../../../../stores/topology.store";
import ResourcePackPreview from "../../features/resource-pack/resource-pack-preview";
import { resourcePackIdentifier } from "../../../../config/constants";
import { ResourcePackShareWithOptions } from "../../features/resource-pack/resource-pack-types";
import { TooltipPositions } from "../../../tooltip/tooltip.types";

const ResourcePackList: React.FC<{
  onResourceDragStart: (
    event: React.DragEvent<HTMLDivElement>,
    resourceType: string,
    resourcePackID: string
  ) => void;
  onAddResourcePack: (resourcePackID: string) => void;
  onEditResourcePack: () => void;
  resourcePacks: SupportedResourceType[];
  refetchResourcesList: () => void;
}> = ({
  onResourceDragStart,
  onAddResourcePack,
  onEditResourcePack,
  resourcePacks,
  refetchResourcesList,
}) => {
  const iconRef = useRef<HTMLDivElement>(null);
  const setActiveResourcePack = useSetAtom(activeResourcePackAtom);
  const deleteResourcePacks = useSetAtom(deleteMultipleResourcePacksAtom);
  const [showResourcePackDetails, setShowResourcePackDetails] = useState(false);
  const [
    showResourcePackDeleteConfirmDialog,
    setShowResourcePackDeleteConfirmDialog,
  ] = useState(false);
  const [activePack, setActivePack] = useState<SupportedResourceType | null>(
    null
  );
  const [selectedPacks, setSelectedPacks] = useState<string[]>([]);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const handlePackClick = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      pack: SupportedResourceType
    ) => {
      if (event.metaKey || event.ctrlKey) {
        setSelectedPacks((prevSelected) => {
          let newSelectedPacks = [];
          if (pack.resourcePackID) {
            if (prevSelected.includes(pack.resourcePackID)) {
              newSelectedPacks = prevSelected.filter(
                (id) => id !== pack.resourcePackID
              );
            } else {
              newSelectedPacks = [...prevSelected, pack.resourcePackID];
            }
          } else {
            newSelectedPacks = prevSelected;
          }

          return newSelectedPacks;
        });
        setShowResourcePackDetails(false);
      } else {
        if (pack.resourcePackID) {
          setSelectedPacks([pack.resourcePackID]);
        }
        setShowResourcePackDetails(true);
        setActivePack(pack);
      }
    },
    []
  );

  const handleRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (selectedPacks.length > 0) {
      setContextMenuPosition({ x: event.screenX, y: event.clientY });
      setShowContextMenu(true);
    }
  };

  const handleDeleteSelectedPacks = useCallback(() => {
    setShowResourcePackDeleteConfirmDialog(true);
    setShowContextMenu(false);
  }, []);

  const onConfirmDeleteResourcePacks = useCallback(async () => {
    setIsDeleting(true);
    await deleteResourcePacks(selectedPacks);
    setIsDeleting(false);
    setShowResourcePackDeleteConfirmDialog(false);
    refetchResourcesList();
  }, [selectedPacks]);

  if (resourcePacks.length === 0) {
    return (
      <div className="tw-flex tw-align-center tw-items-center tw-flex-col tw-p-4 tw-gap-5 tw-mt-4 tw-text-base">
        <Icon name={IconsList.RESOURCE_PACK} size={IconSize.lg} />
        <span className="tw-text-gray-200 tw-text-md tw-text-center tw-text-base">
          No resource packs are created
        </span>
        <span className="tw-text-gray-200 tw-text-xssm tw-text-center">
          You can select a group of resources on the canvas and save them as a
          resource pack here.
        </span>
      </div>
    );
  }

  return (
    <div
      className="tw-p-4 tw-flex tw-flex-col tw-gap-3"
      // TODO: Fix the right click implementation.
      // onContextMenu={handleRightClick}
    >
      <div
        ref={iconRef}
        className="tw-hidden tw-absolute tw-pointer-events-none"
      >
        <Icon name={IconsList.RESOURCE_PACK} size={IconSize.lg} className="tw-text-slate-300" />
      </div>
      <Popover
        onClose={() => setShowResourcePackDetails(false)}
        closeOnBlur
        open={showResourcePackDetails}
        trigger={
          <div className="tw-flex tw-flex-col tw-gap-4">
            {resourcePacks.map((pack) => (
              <div
                key={pack.resourcePackID}
                onClick={(event) => handlePackClick(event, pack)}
                onDragStart={(event) => {
                  event.stopPropagation();
                  const iconElement = iconRef.current;
                  if (pack.resourcePackID && iconElement) {
                    iconElement.style.display = "block";
                    document.body.appendChild(iconElement);
                    event.dataTransfer.setDragImage(iconElement, 12, 12);
                    onResourceDragStart(
                      event,
                      resourcePackIdentifier,
                      pack.resourcePackID
                    );
                  }
                }}
                onDragEnd={() => {
                  const iconElement = iconRef.current;
                  if (iconElement) {
                    iconElement.style.display = "none";
                    if (iconElement.parentNode) {
                      iconElement.parentNode.removeChild(iconElement);
                    }
                  }
                }}
                draggable={true}
                className={`tw-flex tw-flex-col tw-gap-0 tw-rounded-lg tw-border  ${
                  pack.resourcePackID &&
                  selectedPacks.includes(pack.resourcePackID)
                    ? "tw-border-blue-500"
                    : "tw-border-gray-500"
                } active:tw-cursor-grabbing tw-cursor-grab `}
              >
                <>
                  <div className="tw-pointer-events-none tw-w-full tw-h-48">
                    <ResourcePackPreview
                      resourcePack={{
                        resources: pack.packResources || [],
                        connections: pack.packConnections || [],
                      }}
                      id={pack.resourcePackID || ""}
                      processResourceConnections
                    />
                  </div>
                  <div className="tw-divide-x" />
                  <div className="tw-flex tw-justify-between tw-items-center tw-px-4 tw-py-2 tw-border-t tw-border-gray-500 tw-rounded-lg tw-bg-gray-700">
                    <span className="tw-col-span-2 tw-text-base">
                      {pack.label}
                    </span>
                    <div className="tw-justify-center tw-items-center tw-flex tw-gap-1">
                      {pack.shareWith ===
                        ResourcePackShareWithOptions.Everyone && (
                        <Tooltip title="Shared with others" position={TooltipPositions.BOTTOM}>
                          <IconButton
                            icon={IconsList.USERS}
                            variant="secondary"
                            onClick={(e) => e.stopPropagation()}
                            dataTestId="resource-pack-share"
                            className="tw-bg-transparent"
                          />
                        </Tooltip>
                      )}
                      <Dropdown
                        options={[
                          {
                            value: "edit",
                            label: (
                              <span
                                className="tw-text-xssm tw-text-gray-50"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setActiveResourcePack({
                                    ...pack,
                                    name: pack.label,
                                    resources: pack.packResources || [],
                                    connections: pack.packConnections || [],
                                    description: pack.packDescription,
                                  });
                                  onEditResourcePack();
                                }}
                              >
                                Edit
                              </span>
                            ),
                          },
                          {
                            value: "delete",
                            label: (
                              <span
                                className="tw-text-xssm tw-text-gray-50"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedPacks([pack.resourcePackID || ""]);
                                  setShowResourcePackDeleteConfirmDialog(true);
                                }}
                              >
                                Delete
                              </span>
                            ),
                          },
                        ]}
                        dataTestId="resource-pack-options-dropdown"
                      >
                        <IconButton
                          variant="secondary"
                          icon={IconsList.ELLIPSIS_VERTICAL}
                          dataTestId="resource-pack-details-button"
                          className="tw-bg-transparent"
                        />
                      </Dropdown>
                    </div>
                  </div>
                </>
              </div>
            ))}
          </div>
        }
        dataTestId={"resource-pack-popover"}
        position="right"
        offset={25}
        customContentRenderer={
          activePack &&
          showResourcePackDetails && (
            <div
              className="tw-bg-gray-700 tw-rounded-md tw-border tw-border-gray-600 tw-w-100 tw-flex tw-flex-col tw-items-start"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between tw-border-b tw-border-gray-700 tw-w-full">
                <span className="tw-text-white tw-text-base tw-font-semibold">
                  {activePack.label}
                </span>
                <div
                  className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
                  onClick={() => setShowResourcePackDetails(false)}
                  data-testid="resource-pack-drawer-close"
                >
                  <Icon name={IconsList.CLOSE} />
                </div>
              </div>
              <div className="tw-p-4 tw-w-full tw-gap-4 tw-flex tw-flex-col">
                <div className="tw-w-full tw-border tw-border-gray-500 tw-rounded-lg tw-h-64">
                  <ResourcePackPreview
                    resourcePack={{
                      resources: activePack.packResources || [],
                      connections: activePack.packConnections || [],
                    }}
                    id={activePack.resourcePackID || ""}
                    processResourceConnections
                  />
                </div>
                <div className="tw-flex tw-flex-col">
                  <span className="tw-col-span-2 tw-text-sm">Created by</span>
                  <span className="tw-col-span-2 tw-text-xssm tw-text-gray-200">
                    {activePack.creatorName}
                  </span>
                </div>
                {activePack?.packDescription && (
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-col-span-2 tw-text-sm">
                      Description
                    </span>
                    <span className="tw-col-span-2 tw-text-xssm tw-text-gray-200">
                      {activePack.packDescription}
                    </span>
                  </div>
                )}
                <Button
                  className="tw-flex tw-w-full"
                  variant="primary"
                  size="md"
                  label="Use this resource pack"
                  dataTestId={"resource-pack-use-btn"}
                  onClick={() => {
                    if (activePack?.resourcePackID) {
                      onAddResourcePack(activePack.resourcePackID);
                    }
                  }}
                />
              </div>
            </div>
          )
        }
      />

      {showContextMenu && contextMenuPosition && (
        <div
          className="tw-absolute tw-z-50 tw-shadow-lg tw-rounded-md"
          style={{
            top: "50%",
            left: contextMenuPosition.x,
          }}
          onClick={() => setShowContextMenu(false)}
        >
          <Button
            label="Delete"
            variant="secondary"
            dataTestId={"resource-pack-delete"}
            className="tw-text-red-500"
            onClick={handleDeleteSelectedPacks}
          />
        </div>
      )}
      {showResourcePackDeleteConfirmDialog && (
        <Dialog
          title="Delete Selected Resource Packs"
          bodyContent={
            <div className="tw-text-white tw-text-sm tw-font-normal">
              <p className="tw-mb-5">
                {`Are you sure to delete the ${
                  selectedPacks.length > 1 ? selectedPacks : ""
                } selected resource pack?`}
              </p>
              <p>
                Note: Deleting the resource pack will revoke access for anyone
                with whom those are shared.
              </p>
            </div>
          }
          footerContent={
            <div className="tw-flex tw-justify-end tw-gap-3">
              <Button
                variant="secondary"
                label="Cancel"
                onClick={() => setShowResourcePackDeleteConfirmDialog(false)}
                dataTestId="resource-pack-delete-confirm-dialog-cancel"
              />
              <Button
                variant="danger"
                label={isDeleting ? "Deleting..." : "Delete Resource Packs"}
                showLoader={isDeleting}
                disabled={isDeleting}
                onClick={onConfirmDeleteResourcePacks}
                dataTestId={
                  isDeleting
                    ? "resource-pack-delete-confirm-dialog-disabled-delete"
                    : "resource-pack-delete-confirm-dialog-delete"
                }
              />
            </div>
          }
          dataTestId="resource-pack-delete-confirm-dialog"
        />
      )}
    </div>
  );
};

export default ResourcePackList;
