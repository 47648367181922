exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-settings-index-tsx": () => import("./../../../src/pages/account-settings/index.tsx" /* webpackChunkName: "component---src-pages-account-settings-index-tsx" */),
  "component---src-pages-account-settings-license-tsx": () => import("./../../../src/pages/account-settings/license.tsx" /* webpackChunkName: "component---src-pages-account-settings-license-tsx" */),
  "component---src-pages-account-settings-members-tsx": () => import("./../../../src/pages/account-settings/members.tsx" /* webpackChunkName: "component---src-pages-account-settings-members-tsx" */),
  "component---src-pages-account-settings-pat-tsx": () => import("./../../../src/pages/account-settings/pat.tsx" /* webpackChunkName: "component---src-pages-account-settings-pat-tsx" */),
  "component---src-pages-account-settings-profile-tsx": () => import("./../../../src/pages/account-settings/profile.tsx" /* webpackChunkName: "component---src-pages-account-settings-profile-tsx" */),
  "component---src-pages-account-settings-teams-create-tsx": () => import("./../../../src/pages/account-settings/teams/create.tsx" /* webpackChunkName: "component---src-pages-account-settings-teams-create-tsx" */),
  "component---src-pages-account-settings-teams-index-tsx": () => import("./../../../src/pages/account-settings/teams/index.tsx" /* webpackChunkName: "component---src-pages-account-settings-teams-index-tsx" */),
  "component---src-pages-appstacks-app-stack-id-tsx": () => import("./../../../src/pages/appstacks/[appStackId].tsx" /* webpackChunkName: "component---src-pages-appstacks-app-stack-id-tsx" */),
  "component---src-pages-appstacks-configurations-index-tsx": () => import("./../../../src/pages/appstacks/configurations/index.tsx" /* webpackChunkName: "component---src-pages-appstacks-configurations-index-tsx" */),
  "component---src-pages-appstacks-create-tsx": () => import("./../../../src/pages/appstacks/create.tsx" /* webpackChunkName: "component---src-pages-appstacks-create-tsx" */),
  "component---src-pages-appstacks-index-tsx": () => import("./../../../src/pages/appstacks/index.tsx" /* webpackChunkName: "component---src-pages-appstacks-index-tsx" */),
  "component---src-pages-appstacks-policies-index-tsx": () => import("./../../../src/pages/appstacks/policies/index.tsx" /* webpackChunkName: "component---src-pages-appstacks-policies-index-tsx" */),
  "component---src-pages-governance-configurations-create-tsx": () => import("./../../../src/pages/governance-configurations/create.tsx" /* webpackChunkName: "component---src-pages-governance-configurations-create-tsx" */),
  "component---src-pages-governance-configurations-index-tsx": () => import("./../../../src/pages/governance-configurations/index.tsx" /* webpackChunkName: "component---src-pages-governance-configurations-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-quick-appstack-create-index-tsx": () => import("./../../../src/pages/quick-appstack-create/index.tsx" /* webpackChunkName: "component---src-pages-quick-appstack-create-index-tsx" */),
  "component---src-pages-repositories-index-tsx": () => import("./../../../src/pages/repositories/index.tsx" /* webpackChunkName: "component---src-pages-repositories-index-tsx" */),
  "component---src-pages-repositories-onboard-tsx": () => import("./../../../src/pages/repositories/onboard.tsx" /* webpackChunkName: "component---src-pages-repositories-onboard-tsx" */)
}

