import React from "react";

import type {
  ProviderWiseSupportedResourceType,
  SupportedResourceType,
} from "../../../../apis/topology";

import { isLongSingleWord } from "../../../../utils";

import {
  AWSIcon,
  AWSIconsList,
  Icon,
  IconSize,
  IconsList,
  Tooltip,
} from "../../../../components";

const ResourceItem = ({
  resource,
  provider,
  onDragStart,
  suffixElement,
}: {
  resource: SupportedResourceType;
  provider: keyof ProviderWiseSupportedResourceType;
  onDragStart: (event: any, node_id: string) => void;
  suffixElement?: React.ReactElement;
}) => {
  return (
    <div
      className={`tw-relative tw-flex tw-items-center tw-justify-between tw-gap-3 tw-rounded-md tw-p-3 tw-border ${
        resource.isRestricted
          ? "tw-bg-gray-800 tw-border-gray-700"
          : "tw-group tw-bg-gray-700 hover:tw-bg-gray-600 tw-border-gray-600 active:tw-cursor-grabbing tw-cursor-grab"
      }`}
      key={resource.resourceType}
      {...(!resource.isRestricted && {
        draggable: true,
        onDragStart: (event) =>
          onDragStart(event, resource.resourceType as string),
      })}
    >
      <div className="tw-flex tw-items-center tw-gap-2.5">
        <div className="tw-basis-7">
          <AWSIcon
            name={
              AWSIconsList[resource?.resourceType as keyof typeof AWSIconsList]
            }
            size={IconSize.xl}
            fallbackPlaceholder={resource.resourceType}
          />
        </div>
        {/* Show Ellipses text when label is long single word */}
        {isLongSingleWord(resource.label as string, 20) ? (
          <span className="tw-text-sm tw-text-white tw-font-medium tw-capitalize tw-w-40 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">
            <Tooltip title={resource.label}>{resource.label}</Tooltip>
          </span>
        ) : (
          <span
            className="tw-text-sm tw-text-white tw-font-medium tw-capitalize"
            data-testid={`label_${resource.resourceType}`}
          >
            {resource.label}
          </span>
        )}
      </div>
      <div className="tw-basis-6 tw-flex tw-gap-2 tw-items-center">
        {resource.isRestricted ? (
          <Icon
            name={IconsList.CIRCLE_STOP_RESTRICTED}
            className="tw-text-yellow-700"
          />
        ) : (
          <Icon
            name={IconsList.GRIP_DOTS_VERTICAL}
            className="tw-text-gray-400 tw-hidden group-hover:tw-block"
            dataTestId={`resource-drag-${resource.resourceType}`}
          />
        )}
      </div>
      {suffixElement && suffixElement}
    </div>
  );
};

export default ResourceItem;
