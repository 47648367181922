import { type TopologyTfVars } from "../../../apis/topology";
import {
  IAC_UNZIP_FILES_MAX_COMPRESSION_RATIO,
  IAC_UNZIP_FILES_MAX_ENTRIES,
  IAC_UNZIP_FILES_MAX_UNCOMPRESSED_SIZE,
} from "../../../config/constants";

export const generateEdgeId = (
  sourceResourceId: string,
  targetResourceId: string
) => {
  return sourceResourceId > targetResourceId
    ? `${sourceResourceId}-${targetResourceId}`
    : `${targetResourceId}-${sourceResourceId}`;
};

// Returns a map of Terraform variable names to their corresponding data types.
export const getTfVarsNameVsDataTypeMap = (tfVars: TopologyTfVars[]) => {
  if (tfVars && tfVars?.length > 0) {
    return tfVars.reduce((acc: any, curr: TopologyTfVars) => {
      if (curr.tfVarName) {
        acc[curr.tfVarName] = curr.type;
      }
      return acc;
    }, {});
  } else {
    return {};
  }
};

/**
 * Validates the IaC files and folders in the unzipped archive by checking:
 * 1. The number of entries in the archive should not exceed the maximum allowed limit.
 * 2. The total size of the uncompressed data should not exceed the maximum allowed limit.
 * 3. The compression ratio between compressed and uncompressed data should not exceed the maximum allowed limit.
 * @param {Object} unzippedFilesFolders - The unzipped content of the zip file.
 * @param {Blob} iacFilesFoldersBlob - The blob of the zip file.
 * @throws {Error} If any of the validations fail.
 */
export const validateIacUnzipFiles = (
  unzippedFilesFolders: { files: { [x: string]: any } },
  iacFilesFoldersBlob: Blob
) => {
  // Validate the number of entries in the archive
  const numEntries = Object.keys(unzippedFilesFolders?.files)?.length;
  if (numEntries > IAC_UNZIP_FILES_MAX_ENTRIES) {
    throw new Error("Archive contains too many entries");
  }

  // Validate the total size of the uncompressed data
  let totalUncompressedSize = 0;
  for (const fileName in unzippedFilesFolders?.files) {
    const file = unzippedFilesFolders.files?.[fileName];
    totalUncompressedSize += file._data.uncompressedSize;
  }
  if (totalUncompressedSize > IAC_UNZIP_FILES_MAX_UNCOMPRESSED_SIZE) {
    throw new Error("Uncompressed data size exceeds the maximum allowed limit");
  }

  // Validate the ratio between compressed and uncompressed data
  const compressedSize = iacFilesFoldersBlob.size;
  const compressionRatio = totalUncompressedSize / compressedSize;
  if (compressionRatio > IAC_UNZIP_FILES_MAX_COMPRESSION_RATIO) {
    throw new Error("Compression ratio exceeds the maximum allowed limit");
  }

  // Validate the contents of the archive to prevent directory traversal attacks
  const isValid = Object.keys(unzippedFilesFolders.files).every((fileName) => {
    // Check for directory traversal attempts
    return !fileName.includes("..") && !fileName.startsWith("/");
  });

  if (!isValid) {
    throw new Error("Invalid archive contents");
  }
};
