import React, { type FC, useMemo } from "react";
import { Button } from "../../../../components";

import type { ResourcePackDrawerFooterProps } from "./resource-pack-types";

const ResourcePackDrawerFooter: FC<ResourcePackDrawerFooterProps> = ({
  onClose,
  isSubmitting,
  isCreate,
}) => {
  const buttonText = useMemo(() => {
    if (isSubmitting) {
      return "Saving Resource Pack...";
    }
    if (isCreate) {
      return "Save Resource Pack";
    }
    return "Done";
  }, [isSubmitting, isCreate]);

  return (
    <div className="tw-flex tw-justify-between tw-border-t tw-border-gray-700 tw-bg-gray-900 tw-w-full tw-p-4">
      <div className="tw-flex tw-gap-3 tw-w-full">
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting}
          label={buttonText}
          dataTestId="save-resource-pack-button"
          className="tw-w-full"
        />
        <Button
          className="tw-bg-gray-700 tw-w-full"
          label="Cancel"
          variant="tertiary"
          type="button"
          onClick={() => onClose()}
          dataTestId="cancel-resource-pack-button"
        />
      </div>
    </div>
  );
};

export default ResourcePackDrawerFooter;
