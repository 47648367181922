export enum AvatarSize {
  "xxs" = "tw-w-2 tw-h-2",
  "xs" = "tw-w-3 tw-h-3",
  "sm" = "tw-w-4 tw-h-4",
  "md" = "tw-w-5 tw-h-5",
  "lg" = "tw-w-6 tw-h-6",
  "xl" = "tw-w-7 tw-h-7",
  "2xl" = "tw-w-8 tw-h-8",
  "3xl" = "tw-w-9 tw-h-9",
  "4xl" = "tw-w-10 tw-h-10",
  "5xl" = "tw-w-12 tw-h-12",
  "6xl" = "tw-w-16 tw-h-16",
  "7xl" = "tw-w-[5.25rem] tw-h-[5.25rem]",
}
