import ELK from "elkjs/lib/elk.bundled.js";
import type { ElkNode } from "elkjs/lib/elk-api.js";

import type { Topology } from "../../../apis/topology";
import type { Resource } from "../topology-graph-types";

import { type Node, type XYPosition } from "reactflow";

const elk = new ELK();

const getChildrenNodes = (childNodes: any[], nestedLevel: number): any => {
  return childNodes?.map((child_resource) => {
    const hasNestedChildren =
      child_resource?.isGroup &&
      child_resource?.groupType &&
      child_resource?.resourceType !== "group" &&
      child_resource?.children?.length > 0;

    return {
      id: child_resource.id as string,
      selected: child_resource?.selected,
      data: {
        ...child_resource,
        icon: child_resource?.resourceType,
        nestedLevel,
      },
      width: 300,
      height: 90,
      position: (child_resource?.position as XYPosition) ?? {
        x: 0,
        y: 0,
      },
      children: hasNestedChildren
        ? getChildrenNodes(child_resource?.children, nestedLevel + 1)
        : undefined,
    };
  });
};

const parseChildNodes = (
  children: any[],
  parentResource: any,
  resources: any[]
) => {
  children?.forEach?.((child_resource: any) => {
    const isNestedGroup =
      child_resource?.data?.isGroup &&
      child_resource?.data?.groupType &&
      child_resource?.data?.resourceType !== "group";
    resources.push({
      id: child_resource.id,
      type: isNestedGroup ? "nestedGroupNode" : "customResourceNode",
      selected: child_resource?.selected,
      parentNode: parentResource.id,
      extent: "parent",
      data: {
        ...child_resource?.data,
        width: child_resource.width,
        height: child_resource.height,
      },
      position: {
        x: child_resource?.data?.position?.x ?? child_resource.x,
        y: child_resource?.data?.position?.y ?? child_resource.y,
      },
      style: isNestedGroup
        ? {
            width:
              child_resource?.width +
              200 -
              child_resource?.data?.nestedLevel * 100,
            height:
              child_resource?.height +
              250 -
              child_resource?.data?.nestedLevel * 100,
          }
        : undefined,
      className: isNestedGroup
        ? `tw-bg-purple-400 tw-bg-opacity-5 tw-border ${
            child_resource?.selected
              ? "tw-border-blue-500"
              : "tw-border-purple-400"
          } tw-rounded-md`
        : "",
    });
    if (isNestedGroup && child_resource?.children?.length > 0) {
      parseChildNodes(child_resource?.children, child_resource, resources);
    }
  });
};

const getELKGraphPositions = (graphData: Topology) => {
  const graph_children: (Node & ElkNode)[] = [];

  graphData?.resources?.forEach((resource: any) => {
    if (
      resource?.isGroup &&
      resource?.groupType &&
      resource?.resourceType !== "group"
    ) {
      graph_children.push({
        id: resource.id as string,
        selected: resource?.selected,
        data: {
          ...resource,
          icon: resource?.resourceType,
          nestedLevel: 0,
        },
        children: getChildrenNodes(resource?.children, 1),
        width: 200,
        height: 46,
        position: (resource?.position as XYPosition) ?? { x: 0, y: 0 },
      });
    } else if (resource?.resourceType === "group") {
      graph_children.push({
        id: resource.id as string,
        selected: resource?.selected,
        data: {
          ...resource,
          icon: "microservice",
        },
        layoutOptions: {
          "elk.algorithm": "layered",
          "elk.direction": "DOWN",
          "elk.layered.spacing.nodeNodeBetweenLayers": "20",
          "elk.edgeRouting": "POLYLINE",
        },
        children: resource?.children
          ?.filter((child_resource: Resource) => !child_resource?.hidden)
          .map((child_resource: Resource) => ({
            id: child_resource.id as string,
            selected: child_resource?.selected,
            data: {
              ...child_resource,
              icon: child_resource?.resourceType,
            },
            width: 300,
            height: 90,
            position: (child_resource?.position as XYPosition) ?? {
              x: 0,
              y: 0,
            },
          })),
        width: 200,
        height: 46,
        position: (resource?.position as XYPosition) ?? { x: 0, y: 0 },
      });
    } else if (!resource.group && !resource?.hidden) {
      graph_children.push({
        id: resource.id as string,
        selected: resource?.selected,
        data: {
          ...resource,
          icon: resource.external_resource
            ? (resource?._configuration as { [key: string]: any })
                ?.resource_type
            : resource?.resourceType,
        },
        width: 300,
        height: 90,
        children: [],
        position: (resource?.position as XYPosition) ?? { x: 0, y: 0 },
      });
    }
  });
  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.direction": "DOWN",
      "elk.layered.spacing.nodeNodeBetweenLayers": "50",
      "elk.spacing.nodeNode": "150",
      "elk.edgeRouting": "POLYLINE",
      "elk.layered.nodePlacement.strategy": "LINEAR_SEGMENTS",
    },
    children: graph_children,
    edges: graphData?.resourceConnections
      ?.filter((resource_connection: any) => !resource_connection?.data?.hidden)
      ?.map((resource_connection: any) => ({
        id: resource_connection.id,
        source: resource_connection.sourceResourceId,
        target: resource_connection.targetResourceId,
        type: "customEdge",
        data: {
          ...resource_connection?.data,
          diffType: resource_connection?.diffType,
        },
      })),
  };

  return elk
    .layout(graph as ElkNode)
    .then((layoutedGraph) => {
      const resources = [] as Node[];

      layoutedGraph?.children?.forEach((resource: any) => {
        if (
          resource?.data?.isGroup &&
          resource?.data?.groupType &&
          resource?.data?.resourceType !== "group"
        ) {
          resources.push({
            id: resource.id,
            type: "nestedGroupNode",
            selected: resource?.selected,
            data: {
              ...resource?.data,
              width: resource.width,
              height: resource.height,
            },
            position: {
              x: resource?.data?.position?.x ?? resource.x,
              y: resource?.data?.position?.y ?? resource.y,
            },
            style: {
              width: resource.width + 200,
              height: resource.height + 250,
            },
            className: `tw-bg-purple-400 tw-bg-opacity-5 tw-border ${
              resource.data?.selected
                ? "tw-border-blue-500"
                : "tw-border-purple-400"
            } tw-rounded-md`,
          });

          parseChildNodes(resource?.children, resource, resources);
        } else if (resource?.data?.resourceType === "group") {
          resources.push({
            id: resource.id,
            type: "customGroupNode",
            selected: resource?.selected,
            data: resource.data,
            position: {
              x: resource?.data?.position?.x ?? resource.x,
              y: resource?.data?.position?.y ?? resource.y,
            },
            style: {
              width: resource.width,
              height: resource.height + (resource?.data?.expanded ? 40 : 0),
            },
            className: `${
              resource?.data?.expanded
                ? `tw-border ${
                    resource.data?.diffType === "created"
                      ? "tw-border-green-500"
                      : resource.data?.diffType === "deleted"
                      ? "tw-border-red-500"
                      : resource.data?.selected
                      ? "tw-border-blue-500"
                      : "tw-border-gray-600"
                  } tw-rounded-md`
                : "tw-border-gray-600"
            }`,
          });

          resource?.children?.forEach?.((child_resource: any) =>
            resources.push({
              id: child_resource.id,
              type: "customResourceNode",
              selected: child_resource?.selected,
              parentNode: resource.id,
              extent: "parent",
              data: child_resource.data,
              position: {
                x: child_resource?.data?.position?.x ?? child_resource.x,
                y:
                  child_resource?.data?.position?.y ??
                  child_resource.y + (resource?.data?.expanded ? 60 : 40),
              },
            })
          );
        } else {
          resources.push({
            id: resource.id,
            type: "customResourceNode",
            selected: resource?.selected,
            data: resource.data,
            position: {
              x: resource?.data?.position?.x ?? resource.x,
              y: resource?.data?.position?.y ?? resource.y,
            },
          });
        }
      }, []);
      return {
        layoutedNodes: resources,
        layoutedEdges: layoutedGraph?.edges,
      };
    })
    .catch(console.error);
};

export default getELKGraphPositions;
