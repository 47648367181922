/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourceRestrictions
 */
export interface ResourceRestrictions {
    /**
     * 
     * @type {string}
     * @memberof ResourceRestrictions
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRestrictions
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRestrictions
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResourceRestrictions
     */
    resources?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResourceRestrictions
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceRestrictions
     */
    createdBy?: string;
}

/**
 * Check if a given object implements the ResourceRestrictions interface.
 */
export function instanceOfResourceRestrictions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceRestrictionsFromJSON(json: any): ResourceRestrictions {
    return ResourceRestrictionsFromJSONTyped(json, false);
}

export function ResourceRestrictionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceRestrictions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
    };
}

export function ResourceRestrictionsToJSON(value?: ResourceRestrictions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'resources': value.resources,
        'owner': value.owner,
        'createdBy': value.createdBy,
    };
}

