import React from "react";

import type { Crumb } from "../breadcrumb/breadcrumb-types";

import {
  IconButton,
  IconsList,
  IconSize,
  Icon,
  Breadcrumb,
} from "../../components";
import VersionIndicator from "./version-indicator";
import { quickAppStackPageMetaAtom } from "../../stores/ftux.store";
import { useAtomValue } from "jotai";
import { QuickAppStackPageReferrer } from "../quick-appstack-creation/quick-appstack-creation-types";
const Navbar = ({
  breadcrumbs_data,
  onHelpClick,
  isQuickAppStackCreationRouteActive = false,
}: {
  breadcrumbs_data: Crumb[];
  onHelpClick: () => void;
  isQuickAppStackCreationRouteActive?: boolean;
}) => {
  const quickAppStackPageMeta = useAtomValue(quickAppStackPageMetaAtom);
  // const searchInputRef = useRef<HTMLInputElement | null>(null);

  // useEffect(() => {
  //   const onKeyDown = (e: KeyboardEvent) => {
  //     // taking action on command + p action
  //     if (e.key === "/" && (e.ctrlKey || e.metaKey)) {
  //       e.preventDefault();
  //       searchInputRef?.current?.focus();
  //     }
  //   };
  //   window.addEventListener("keydown", onKeyDown, false);
  //   return () => {
  //     // unsubscribing on page unmount
  //     window.removeEventListener("keydown", onKeyDown, false);
  //   };
  // }, []);

  return (
    <header className="tw-bg-gray-900 tw-w-full tw-h-14" id="header">
      <nav className="tw-p-3">
        <div className="tw-flex tw-justify-between tw-items-center">
          {isQuickAppStackCreationRouteActive &&
          [
            QuickAppStackPageReferrer.LOGIN_PAGE,
            QuickAppStackPageReferrer.HELP_CENTER,
          ].includes(
            quickAppStackPageMeta?.referrer as QuickAppStackPageReferrer
          ) ? (
            <span className="tw-text-base text-gray-100 tw-font-medium">
              Get Started with StackGen
            </span>
          ) : (
            <div>
              <Breadcrumb
                crumbs={breadcrumbs_data}
                separator={
                  <Icon
                    name={IconsList.CARET_RIGHT}
                    size={IconSize.xs}
                    className="tw-text-gray-400"
                  />
                }
              />
            </div>
          )}
          <div className="tw-flex tw-gap-3">
            {/* <Formik initialValues={{ header_search: "" }} onSubmit={() => {}}>
              <TextBox
                name="header_search"
                placeholder="Search"
                ref={searchInputRef}
                className="tw-bg-gray-900"
                prefix={
                  <Icon
                    name={IconsList.SEARCH}
                    size={IconSize.sm}
                    className="tw-self-center tw-text-gray-500"
                  ></Icon>
                }
                suffix={
                  <span
                    className="text-gray-500 sm:text-sm tw-text-gray-500"
                    id="price-currency"
                  >
                    ⌘ /
                  </span>
                }
              />
            </Formik> */}

            <VersionIndicator />
            <div onClick={onHelpClick} data-testid="help-button">
              <IconButton
                icon={IconsList.HELP_CIRCLE}
                variant="secondary"
                outlined
                dataTestId="help-button-icon"
              />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
