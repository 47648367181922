import React, { type FC } from "react";

import { AWSIcon, AWSIconsList, Icon, IconSize, IconsList } from "../../../../components";
import type { CustomHelmDrawerHeaderProps } from "./custom-helm-types";

const CustomHelmDrawerHeader: FC<CustomHelmDrawerHeaderProps> = ({
  onClose,
}) => (
  <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between tw-border-b tw-border-gray-700">
    <div className="tw-flex tw-items-center tw-gap-2">
      <AWSIcon name={AWSIconsList.aws_unsupported} size={IconSize.lg} />
      <span className="tw-text-white tw-text-base tw-font-semibold">
        Add Custom Helm Chart
      </span>
    </div>
    <div
      className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
      onClick={() => onClose()}
      data-testid="custom-helm-drawer-close"
    >
      <Icon name={IconsList.CLOSE} />
    </div>
  </div>
);

export default CustomHelmDrawerHeader;
