import React, { type FC, useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";
import { initSentry } from "../utils";

import FullScreenLayout from "./full-screen-layout";
import InAppLayout from "./in-app-layout";
import { Toast, Dialog } from "../components";
import { notificationsAtom, dialogAtom } from "../stores/page.store";
import {
  fetchUserProductModeAtom,
  fetchProductVersionAtom,
  fetchEnabledFeaturesAtom,
} from "../stores/auth.store";
import { LayoutProps } from "./layout-types";
import Vira from "../components/vira/vira";

const Page: FC<LayoutProps> = ({ children, pageContext }) => {
  const [notifications, updateNotifications] = useAtom(notificationsAtom);
  const [dialogData] = useAtom(dialogAtom);
  const fetchUserProductMode = useSetAtom(fetchUserProductModeAtom);
  const fetchEnabledFeatures = useSetAtom(fetchEnabledFeaturesAtom);
  const fetchProductVersion = useSetAtom(fetchProductVersionAtom);

  useEffect(() => {
    const initializeApp = async () => {
      fetchUserProductMode();
      fetchEnabledFeatures();
      const productVersion = await fetchProductVersion();
      initSentry(productVersion);
    };
    initializeApp();
  }, []);

  return (
    <>
      {pageContext.layout === "inAppRoutes" ? (
        <InAppLayout>{children}</InAppLayout>
      ) : (
        <FullScreenLayout>{children}</FullScreenLayout>
      )}
      <Toast
        notifications={notifications}
        onDismissAll={() => updateNotifications([])}
      />
      {/* Vira-Virtual Interaction and Recommendation Assistant component for all in-app QnA */}
      <Vira />
      {dialogData?.open && <Dialog {...dialogData} />}
    </>
  );
};

export default Page;
