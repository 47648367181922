export enum DialogSize {
  xs = "tw-max-w-md",
  sm = "tw-max-w-xl",
  md = "tw-max-w-4xl",
  lg = "tw-max-w-6xl",
}

export interface DialogProps {
  title?: string;
  bodyText?: string;
  titleContent?: React.ReactNode;
  bodyContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  size?: keyof typeof DialogSize;
  showFooterDivider?: boolean;
  dataTestId: string;
  backgroundBlur?: boolean;
  showHeaderCloseButton?: boolean;
  onClose?: () => void;
  bodyWrapperClassName?: string;
}
