import { FormikValues } from "formik";
import { Hook, type Question } from "../../apis/vira";

export enum ViraAnswerStatus {
  "ANSWERED" = "ANSWERED",
  "DEFAULT" = "DEFAULT",
  "UNANSWERED" = "UNANSWERED",
}

export type ViraBubbleProps = {
  answerStatus: ViraAnswerStatus;
  showBubble: boolean;
};

export type ViraQuestionProps = {
  question: Question;
  onSkip: () => void;
  onAnswer: (response: AnswerCallback) => void;
};

export type ViraQuestionLayoutProps = {
  questionBodyRenderer?: (values?: FormikValues) => JSX.Element;
  onClose: () => void;
  question: Question;
  onAnswer?: (response: AnswerCallback) => void;
};

export type ViraBooleanQuestionProps = {
  question: Question;
  onSkip: () => void;
  onAnswer: (response: AnswerCallback) => void;
};

export type ViraRangeQuestionProps = {
  question: Question;
  onSkip: () => void;
  onAnswer: (response: AnswerCallback) => void;
};

export type ViraTextQuestionProps = {
  question: Question;
  onSkip: () => void;
  onAnswer: (response: AnswerCallback) => void;
};

export type ViraSelectQuestionProps = {
  question: Question;
  onSkip: () => void;
  onAnswer: (response: AnswerCallback) => void;
};

export type ViraMultiSelectQuestionProps = {
  question: Question;
  onSkip: () => void;
  onAnswer: (response: AnswerCallback) => void;
};

export type AnswerCallback = {
  question: Question;
  answer: any;
};

type ViraBubbleConfigMeta = {
  positionClass?: string;
};

export const ViraBubbleConfig: Record<Hook, ViraBubbleConfigMeta> = {
  APPSTACK_REPO_SELECTION: {
    positionClass: "tw-bottom-24 tw-right-8",
  },
  BEFORE_ANALYSIS: {},
  DURING_ANALYSIS: {},
  AFTER_ANALYSIS: {},
  AFTER_TOPOLOGY_RENDERED: {},
};
