/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Database
 */
export interface Database {
    /**
     * The database name. For Redis, this might be a number.
     * @type {string}
     * @memberof Database
     */
    database?: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    port?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Database
     */
    tls?: boolean;
    /**
     * The full connection string, for example `jdbc:mysql://db.server:3306/mydb?useSsl=true`.
     * Environment variables are allowed in, for example `jdbc:${MYSQL_HOST}/mydb`.
     * @type {string}
     * @memberof Database
     */
    connectionString?: string;
    /**
     * 
     * @type {string}
     * @memberof Database
     */
    engine?: DatabaseEngineEnum;
}


/**
 * @export
 */
export const DatabaseEngineEnum = {
    Mssql: 'MSSQL',
    Mysql: 'MYSQL',
    Oracle: 'ORACLE',
    Postgres: 'POSTGRES',
    Sqlite: 'SQLITE',
    Mongodb: 'MONGODB',
    Redshift: 'REDSHIFT'
} as const;
export type DatabaseEngineEnum = typeof DatabaseEngineEnum[keyof typeof DatabaseEngineEnum];


/**
 * Check if a given object implements the Database interface.
 */
export function instanceOfDatabase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DatabaseFromJSON(json: any): Database {
    return DatabaseFromJSONTyped(json, false);
}

export function DatabaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Database {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'database': !exists(json, 'database') ? undefined : json['database'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'tls': !exists(json, 'tls') ? undefined : json['tls'],
        'connectionString': !exists(json, 'connectionString') ? undefined : json['connectionString'],
        'engine': !exists(json, 'engine') ? undefined : json['engine'],
    };
}

export function DatabaseToJSON(value?: Database | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'database': value.database,
        'username': value.username,
        'password': value.password,
        'host': value.host,
        'port': value.port,
        'tls': value.tls,
        'connectionString': value.connectionString,
        'engine': value.engine,
    };
}

