import { atom } from "jotai";
import {
  ErrorHandler,
  authenticationApiObject,
  healthApiObject,
  resetAmplitudeUser,
  setAmplitudeUserId,
} from "../utils";

import { UpdateMeOperationRequest, type GetMe200Response } from "../apis/appcd";

import { createJSONStorage } from "jotai/utils";
import { setRequestIdFromResponseAtom } from "./logger.store";
import { updateNotificationsAtom } from "./page.store";

export type UserProductModeData = { mode: string; main: string } | null;

const storage = createJSONStorage(() =>
  typeof window !== "undefined"
    ? window.localStorage
    : (undefined as unknown as Storage)
);

export const userLoginStatusAtom = atom<GetMe200Response>(
  {} as GetMe200Response
);
export const fetchUserLoginStatusAtom = atom(null, async (get, set) => {
  try {
    const user = await authenticationApiObject.getMe();
    set(userLoginStatusAtom, user);

    if (user?.userId && user?.email) {
      setAmplitudeUserId(user?.email);
    }

    return user;
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    const errorResponse = await ErrorHandler(error);
    if (
      errorResponse.code === 404 ||
      errorResponse.errCode === "INVALID_SESSION"
    ) {
      resetAmplitudeUser();
      throw errorResponse; // throw error for further handling in component
    } else {
      set(updateNotificationsAtom, {
        type: "error",
        title: errorResponse.msg,
        description: errorResponse?.extras?.join("\n") ?? "",
        dismissable: true,
      });
    }
  }
});

export const loggedInUserSelectedTeamAtom = atom<string | null>(null);

export const fetchAuthSCMProvidersAtom = atom(null, async (get, set) => {
  try {
    const providers = await authenticationApiObject.getProviders();
    return providers;
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    const errorResponse = await ErrorHandler(error);
    if (errorResponse.code === 404) {
      throw errorResponse; // throw error for further handling in component
    } else {
      set(updateNotificationsAtom, {
        type: "error",
        title: errorResponse.msg,
        description: errorResponse?.extras?.join("\n") ?? "",
        dismissable: true,
      });
    }
  }
});

export const userLogoutAtom = atom(null, async (get, set) => {
  try {
    const resp = await authenticationApiObject.logout();
    set(updateNotificationsAtom, {
      type: "success",
      title: "You have been logged out.",
      dismissable: true,
    });
    resetAmplitudeUser();
    return resp;
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    const errorResponse = await ErrorHandler(error);
    set(updateNotificationsAtom, {
      type: "error",
      title: errorResponse.msg,
      description: errorResponse?.extras?.join("\n") ?? "",
      dismissable: true,
    });
  }
});

export const updateMeAtom = atom(
  null,
  async (get, set, updatedProfileReq: UpdateMeOperationRequest) => {
    try {
      const updatedUserInfo = await authenticationApiObject.updateMe(
        updatedProfileReq
      );
      set(userLoginStatusAtom, updatedUserInfo);
      set(updateNotificationsAtom, {
        type: "success",
        title: "Profile updated",
        dismissable: true,
      });
      return updatedUserInfo;
    } catch (error) {
      await set(setRequestIdFromResponseAtom, (error as any).response);
      const errorResponse = await ErrorHandler(error);
      set(updateNotificationsAtom, {
        type: "error",
        title: `${errorResponse.msg}`,
        description: `${errorResponse.extras?.join("\n") ?? ""}`,
        dismissable: true,
      });
    }
  }
);

export const userProductModeAtom = atom<UserProductModeData>(null);

export const fetchUserProductModeAtom = atom(null, async (get, set) => {
  try {
    let userProductMode = (await fetch("/mode.json")) as any;

    if (userProductMode && userProductMode.ok) {
      userProductMode = await userProductMode.json();
      if (userProductMode?.mode) {
        if (userProductMode.mode === "ci") {
          userProductMode = {
            mode: "dev",
            main: userProductMode.main,
          };
        }
      } else {
        userProductMode = null;
      }

      set(userProductModeAtom, userProductMode);
    }
  } catch (error) {
    ErrorHandler(error);
  }
});

export const enabledFeaturesAtom = atom<Record<string, boolean>>({});

export const fetchEnabledFeaturesAtom = atom(null, async (get, set) => {
  try {
    let enabledFeatures = (await fetch("/features.json")) as any;
    if (enabledFeatures && enabledFeatures.ok) {
      enabledFeatures = await enabledFeatures.json();
      set(enabledFeaturesAtom, enabledFeatures);
      // set(enabledFeaturesAtom, EnabledFeatures); // uncomment this line for local testing
    }
  } catch (error) {
    ErrorHandler(error);
  }
});

export const productVersionAtom = atom<{ [key: string]: any } | null>(null);

export const fetchProductVersionAtom = atom(null, async (get, set) => {
  try {
    let productVersion = (await fetch("/version.json")) as any;

    if (productVersion && productVersion.ok) {
      productVersion = await productVersion.json();
      set(productVersionAtom, productVersion ?? null);
    }
    return productVersion;
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    return {};
  }
});

export const appcdVersionAtom = atom<string | null>(null);

export const fetchAppCDVersionAtom = atom(null, async (get, set) => {
  try {
    const appcdVersion = await healthApiObject.getHealth();
    if (appcdVersion?.version) {
      set(appcdVersionAtom, appcdVersion.version);
    }
  } catch (error) {
    await set(setRequestIdFromResponseAtom, (error as any).response);
    ErrorHandler(error);
  }
});
