/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportIAC201Response,
  ExportIACRequest,
  GetExportHistory200Response,
  GetExportStat200Response,
} from '../models/index';
import {
    ExportIAC201ResponseFromJSON,
    ExportIAC201ResponseToJSON,
    ExportIACRequestFromJSON,
    ExportIACRequestToJSON,
    GetExportHistory200ResponseFromJSON,
    GetExportHistory200ResponseToJSON,
    GetExportStat200ResponseFromJSON,
    GetExportStat200ResponseToJSON,
} from '../models/index';

export interface CheckExportStatRequest {
    topologyId: string;
    orgId?: string;
}

export interface ExportIACOperationRequest {
    exportIACRequest: ExportIACRequest;
    orgId?: string;
}

export interface GetExportHistoryRequest {
    orgId?: string;
    appstackName?: string;
    topologyId?: string;
    limit?: number;
}

export interface GetExportStatRequest {
    topologyId: string;
    orgId?: string;
}

/**
 * 
 */
export class ExportApi extends runtime.BaseAPI {

    /**
     * Check if the export is to be done
     */
    async checkExportStatRaw(requestParameters: CheckExportStatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling checkExportStat.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/status/{topologyId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Check if the export is to be done
     */
    async checkExportStat(requestParameters: CheckExportStatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.checkExportStatRaw(requestParameters, initOverrides);
    }

    /**
     * Export the IAC
     */
    async exportIACRaw(requestParameters: ExportIACOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExportIAC201Response>> {
        if (requestParameters.exportIACRequest === null || requestParameters.exportIACRequest === undefined) {
            throw new runtime.RequiredError('exportIACRequest','Required parameter requestParameters.exportIACRequest was null or undefined when calling exportIAC.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportIACRequestToJSON(requestParameters.exportIACRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportIAC201ResponseFromJSON(jsonValue));
    }

    /**
     * Export the IAC
     */
    async exportIAC(requestParameters: ExportIACOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExportIAC201Response> {
        const response = await this.exportIACRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the history of the exports
     */
    async getExportHistoryRaw(requestParameters: GetExportHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExportHistory200Response>> {
        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        if (requestParameters.appstackName !== undefined) {
            queryParameters['appstack_name'] = requestParameters.appstackName;
        }

        if (requestParameters.topologyId !== undefined) {
            queryParameters['topology_id'] = requestParameters.topologyId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExportHistory200ResponseFromJSON(jsonValue));
    }

    /**
     * Get the history of the exports
     */
    async getExportHistory(requestParameters: GetExportHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExportHistory200Response> {
        const response = await this.getExportHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the status of the export
     */
    async getExportStatRaw(requestParameters: GetExportStatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetExportStat200Response>> {
        if (requestParameters.topologyId === null || requestParameters.topologyId === undefined) {
            throw new runtime.RequiredError('topologyId','Required parameter requestParameters.topologyId was null or undefined when calling getExportStat.');
        }

        const queryParameters: any = {};

        if (requestParameters.orgId !== undefined) {
            queryParameters['orgId'] = requestParameters.orgId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/status/{topologyId}`.replace(`{${"topologyId"}}`, encodeURIComponent(String(requestParameters.topologyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExportStat200ResponseFromJSON(jsonValue));
    }

    /**
     * Get the status of the export
     */
    async getExportStat(requestParameters: GetExportStatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetExportStat200Response> {
        const response = await this.getExportStatRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
