import type { FormikErrors, FormikValues } from "formik";
import type { TargetCompute } from "../../../../apis/appcd";
import type { TopologyResourceConnectionData } from "../../../../apis/topology";
import type { SetFieldValue } from "../../../../utils";
import type { ResourcePack } from "../../topology-graph-types";

export enum ResourcePackFields {
  PACK_NAME = "name",
  SHARE_WITH = "shareWith",
  TEAMS = "teams",
  DESCRIPTION = "description",
}

export enum ResourcePackShareWithOptions {
  Everyone = "tenant",
  OnlyMe = "user",
}

export interface ResourcePackForm {
  [ResourcePackFields.PACK_NAME]: string;
  [ResourcePackFields.SHARE_WITH]: string;
  [ResourcePackFields.DESCRIPTION]: string;
}

export interface ResourcePackDrawerProps {
  onClose: (shouldRefreshResourceList?: boolean) => void;
  targetCompute: TargetCompute;
}

export interface ResourcePackDrawerHeaderProps {
  onClose: () => void;
  isCreate: boolean;
}

export interface ResourcePackDrawerFooterProps {
  onClose: () => void;
  isSubmitting: boolean;
  isCreate: boolean;
}

export interface ResourcePackDrawerFormProps {
  values: FormikValues;
  setFieldValue: SetFieldValue<ResourcePackForm>;
  errors: FormikErrors<ResourcePackForm>;
  options: {
    shareWith: {
      label: string;
      value: string;
    }[];
  };
}

//TODO: Infer from existing types
export interface ResourceConnectionDetails {
  source: string;
  target: string;
  data: {
    connectionTypes: Array<{
      data: TopologyResourceConnectionData;
    }>;
  };
}

export const shareWithOptions = [
  {
    label: "Everyone",
    value: ResourcePackShareWithOptions.Everyone,
  },
  {
    label: "Only me",
    value: ResourcePackShareWithOptions.OnlyMe,
  },
];

export interface ResourcePackPreviewProps {
  resourcePack: ResourcePack;
  id: string;
  processResourceConnections?: boolean;
}
