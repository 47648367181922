import React, { type FC } from "react";

import { FileDropzone, SelectableTile, TextBox } from "../../../../components";
import { SelectionType } from "../../../selectable-tile/selectable-tile-types";
import { validCustomHelmFileExtensions } from "../../../../config/constants";
import {
  CustomHelmChartFields,
  type CustomHelmDrawerFormProps,
  CustomHelmLocations,
} from "./custom-helm-types";

const CustomHelmDrawerForm: FC<CustomHelmDrawerFormProps> = ({
  values,
  setFieldValue,
  errors,
}) => (
  <div className="tw-flex tw-gap-6 tw-flex-col tw-p-6 tw-mb-16">
    <div className="tw-grid tw-grid-cols-1 tw-gap-4">
      <label
        className={"tw-text-xssm"}
        htmlFor={CustomHelmChartFields.CHART_LOCATION}
        data-testid={"helmChartLocation-text-box-label"}
      >
        Where is your HELM chart located?
      </label>
      <div className="tw-grid tw-grid-cols-2 tw-gap-6">
        <SelectableTile
          type={SelectionType.RADIO}
          title={"Local HELM chart ZIP"}
          isSelected={
            values[CustomHelmChartFields.CHART_LOCATION] ===
            CustomHelmLocations.LOCAL
          }
          id={CustomHelmLocations.LOCAL}
          name={CustomHelmChartFields.CHART_LOCATION}
          value={CustomHelmLocations.LOCAL}
          dataTestId={"custom-helm-option-localHelmChart"}
          className="tw-h-11"
        />
        <SelectableTile
          type={SelectionType.RADIO}
          title={"HELM chart repository"}
          isSelected={
            values[CustomHelmChartFields.CHART_LOCATION] ===
            CustomHelmLocations.REPO
          }
          id={CustomHelmLocations.REPO}
          name={CustomHelmChartFields.CHART_LOCATION}
          value={CustomHelmLocations.REPO}
          dataTestId={"custom-helm-option-helmChartRepo"}
          className="tw-h-11"
        />
      </div>
      {values[CustomHelmChartFields.CHART_LOCATION] ===
        CustomHelmLocations.LOCAL && (
        <FileDropzone
          name={CustomHelmChartFields.CHART_ZIP}
          label="Select or drop local file containing HELM chart"
          required
          onChangeFiles={(files) => {
            setFieldValue(CustomHelmChartFields.CHART_ZIP, files);
          }}
          initialFiles={[]}
          validFileExtensions={validCustomHelmFileExtensions}
          error={errors[CustomHelmChartFields.CHART_ZIP]}
        />
      )}
      {values[CustomHelmChartFields.CHART_LOCATION] ===
        CustomHelmLocations.REPO && (
        <div className="tw-flex tw-flex-col tw-gap-4">
          <TextBox
            name={CustomHelmChartFields.CHART_REPOSITORY}
            label={"Provide repository URL containing HELM charts"}
            className="!tw-bg-gray-800"
            placeholder="https://example.com/charts"
            dataTestId="custom-helm-repo-url-input"
            description={"Note: Make sure the repository is public."}
            required
          />
        </div>
      )}
      <TextBox
        name={CustomHelmChartFields.CHART_NAME}
        label="Helm chart name"
        description="Enter the name you want to use for this chart in future topologies."
        placeholder="Kafka"
        backgroundVariant="gray-800"
        dataTestId="custom-helm-chart-name-input"
        required
      />
    </div>
  </div>
);

export default CustomHelmDrawerForm;
