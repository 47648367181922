/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TokenType = {
    ApiKey: 'apiKey',
    BrowserSession: 'browserSession'
} as const;
export type TokenType = typeof TokenType[keyof typeof TokenType];


export function TokenTypeFromJSON(json: any): TokenType {
    return TokenTypeFromJSONTyped(json, false);
}

export function TokenTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenType {
    return json as TokenType;
}

export function TokenTypeToJSON(value?: TokenType | null): any {
    return value as any;
}

