/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectionType } from './ConnectionType';
import {
    ConnectionTypeFromJSON,
    ConnectionTypeFromJSONTyped,
    ConnectionTypeToJSON,
} from './ConnectionType';
import type { TopologyResourceConfigValidation } from './TopologyResourceConfigValidation';
import {
    TopologyResourceConfigValidationFromJSON,
    TopologyResourceConfigValidationFromJSONTyped,
    TopologyResourceConfigValidationToJSON,
} from './TopologyResourceConfigValidation';

/**
 * 
 * @export
 * @interface TopologyResourceConnectionData
 */
export interface TopologyResourceConnectionData {
    /**
     * 
     * @type {object}
     * @memberof TopologyResourceConnectionData
     */
    _configuration?: object;
    /**
     * 
     * @type {ConnectionType}
     * @memberof TopologyResourceConnectionData
     */
    connectionType?: ConnectionType;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnectionData
     */
    iacType?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnectionData
     */
    resourceType?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnectionData
     */
    resourceTypeLabel?: string;
    /**
     * 
     * @type {Array<TopologyResourceConfigValidation>}
     * @memberof TopologyResourceConnectionData
     */
    references?: Array<TopologyResourceConfigValidation>;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceConnectionData
     */
    templateID?: string;
    /**
     * 
     * @type {object}
     * @memberof TopologyResourceConnectionData
     */
    tfVars?: object;
    /**
     * 
     * @type {object}
     * @memberof TopologyResourceConnectionData
     */
    overrideAttributes?: object;
}

/**
 * Check if a given object implements the TopologyResourceConnectionData interface.
 */
export function instanceOfTopologyResourceConnectionData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyResourceConnectionDataFromJSON(json: any): TopologyResourceConnectionData {
    return TopologyResourceConnectionDataFromJSONTyped(json, false);
}

export function TopologyResourceConnectionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceConnectionData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'connectionType': !exists(json, 'connectionType') ? undefined : ConnectionTypeFromJSON(json['connectionType']),
        'iacType': !exists(json, 'iacType') ? undefined : json['iacType'],
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'resourceTypeLabel': !exists(json, 'resourceTypeLabel') ? undefined : json['resourceTypeLabel'],
        'references': !exists(json, 'references') ? undefined : ((json['references'] as Array<any>).map(TopologyResourceConfigValidationFromJSON)),
        'templateID': !exists(json, 'templateID') ? undefined : json['templateID'],
        'tfVars': !exists(json, 'tfVars') ? undefined : json['tfVars'],
        'overrideAttributes': !exists(json, 'overrideAttributes') ? undefined : json['overrideAttributes'],
    };
}

export function TopologyResourceConnectionDataToJSON(value?: TopologyResourceConnectionData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configuration': value._configuration,
        'connectionType': ConnectionTypeToJSON(value.connectionType),
        'iacType': value.iacType,
        'resourceType': value.resourceType,
        'resourceTypeLabel': value.resourceTypeLabel,
        'references': value.references === undefined ? undefined : ((value.references as Array<any>).map(TopologyResourceConfigValidationToJSON)),
        'templateID': value.templateID,
        'tfVars': value.tfVars,
        'overrideAttributes': value.overrideAttributes,
    };
}

