import type { EdgeTypes, NodeTypes } from "reactflow";
import {
  CustomEdge,
  CustomGroupNode,
  CustomResourceNode,
  NestedGroupNode,
} from ".";
import type { AppStack, CloudProvider } from "../../apis/appcd";
import type {
  CustomResourceTemplate,
  GetResourceTemplateForResourceTypeRequest,
  Misconfigurations,
  NewTopologyResourceConnectionRequest,
  ProviderWiseSupportedResourceType,
  ResourceIdWithPosition,
  ResourcePackRequest,
  ResourcePosition,
  SupportedResourceType,
  Topology,
  TopologyDiff,
  TopologyResourceConnection,
  TopologyResourceConnectionData,
  TopologyResourceValidations,
  TopologyResourceWithMisconfigurations,
  TopologyResourceWithPosition,
  UpdateResourceConnectionRequest,
} from "../../apis/topology";
import type { TopologyErrorDrawerTypeEnum } from "./drawers/topology-error/topology-error-types";

export type PropertiesType = { [key: string]: any };

export type ResourceListType = SupportedResourceType[];

export type ResourceErrors = {
  policyViolationErrors?: number;
  validationErrors?: number;
  errorFields?: any[];
  violationFields?: Misconfigurations[];
};

export type TopologyErrorsType = {
  topologyId: string;
  totalErrors: number;
  totalValidationErrors: number;
  totalViolationErrors: number;
  resourcesWithErrors: {
    [key: string]: ResourceErrors;
  };
  isValidated: boolean;
};

export type Resource = TopologyResourceWithPosition & {
  hidden?: boolean;
  expanded?: boolean;
  updateExpanded?: (expanded: boolean) => void;
  selected?: boolean;
  errors?: ResourceErrors;
  diffType?: "created" | "deleted";
  external_resource?: boolean;
};

export type ResourceConnection = TopologyResourceConnection & {
  data: {
    hidden?: boolean;
    connectionTypes: Array<
      TopologyResourceConnectionData & {
        diffType?: "created" | "deleted";
        onConnectionTypeClick?: (connection_data: any) => void;
        selected_resource_id?: string;
      }
    >;
  };
};

export interface SupportedResourceListProps {
  resourcesList: ProviderWiseSupportedResourceType;
  onPanelClose: () => void;
  onDragStart: (event: any, node_id: string) => void;
  onResourceTabChange?: (
    resourceType: keyof ProviderWiseSupportedResourceType
  ) => void;
  cloudService: string;
  activeResourceTab?: keyof ProviderWiseSupportedResourceType;
  onAddCustomResource?: () => void;
  onAddCustomHelmResource?: () => void;
  onAddResourcePack?: (resourcePackID: string) => void;
  onEditResourcePack?: () => void;
  refetchResourcesList: () => void;
  onActiveCustomResourceAction?: (
    resource: SupportedResourceType,
    action: string
  ) => void;
  restrictedResources?: { [key: string]: SupportedResourceType };
  setShowRestrictedResourcesDrawer: (
    showRestrictedResourcesDrawer: boolean
  ) => void;
}

export interface ResourcePropertiesFormProps {
  resourcePropertiesTemplate: TopologyResourceValidations & {
    attributes: {
      disabled?: boolean;
    };
  };
  resource: TopologyResourceWithMisconfigurations;
  onPanelClose: () => void;
  onUpdateResourcePropertiesValues: (
    topologyResourceWithPosition: TopologyResourceWithPosition &
      TopologyResourceConnectionData
  ) => void;
  defaultSelectedTabId?: string;
  resourceHasErrors?: boolean;
  showResourcePropertiesErrors?: boolean;
  topologyId: string;
  onEditCustomResource?: (fetchTemplate?: boolean) => void;
  markResourceAsExternal?: (resourceId: string) => Promise<void>;
  onShowResourceError?: (type: TopologyErrorDrawerTypeEnum) => void;
}

export interface CompareTopologyProps {
  appStackVersions?: AppStack[];
  graphData: Topology;
  getTopologyDiff: (newAppStackId: string) => Promise<TopologyDiff | undefined>;
  setShowTopologyDiff: (showTopologyDiff: boolean) => void;
}

export interface TopologyGraphProps {
  showTopologyDiff: boolean;
  setShowTopologyDiff: (showTopologyDiff: boolean) => void;
  getTopologyDiff: (newAppStackId: string) => Promise<TopologyDiff | undefined>;
  appStackVersions?: AppStack[];
  graphData: Topology;
  resourcePropertiesTemplates: {
    [resourceType: string]: TopologyResourceValidations;
  };
  fetchResourcePropertiesTemplate: (
    reqBody: GetResourceTemplateForResourceTypeRequest
  ) => void;
  fetchResourcePropertiesValues: (
    topologyId: string,
    resourceId: string
  ) => void;
  fetchResourceConnectionPropertiesValues: (
    topologyId: string,
    resourceConnectionId: string
  ) => any;
  fetchResourceConnectionTypes: (
    sourceResourceID: string,
    targetResourceID: string
  ) => any;
  updateResourcePropertiesValues: (
    topologyId: string,
    resourceId: string,
    topologyResourceWithPosition: TopologyResourceWithPosition
  ) => void;
  updateResourceConnectionPropertiesValues: (
    topologyId: string,
    resourceConnectionId: string,
    updateResourceConnectionRequest: UpdateResourceConnectionRequest
  ) => void;
  updateResourcePosition: (
    topologyId: string,
    resourceId: string,
    resourcePosition: ResourcePosition
  ) => void;
  updateBulkResourcePosition: (
    topologyId: string,
    resourceIdWithPosition: ResourceIdWithPosition[]
  ) => void;
  resourcesList?: ProviderWiseSupportedResourceType;
  createTopologyResource?: (
    topologyId: string,
    topologyResourceWithPosition: TopologyResourceWithPosition,
    resourceCreatedCallback?: (
      resource: TopologyResourceWithMisconfigurations,
      resourceType: string
    ) => void
  ) => void;
  createTopologyResourceConnection?: (
    topologyId: string,
    newTopologyResourceConnectionRequest: NewTopologyResourceConnectionRequest,
    resourceConnectionCreatedCallback?: (
      resourceConnection: TopologyResourceConnection
    ) => void
  ) => void;
  deleteTopologyResource?: (
    topologyId: string,
    resourceId: string,
    resourceType: string
  ) => void;
  deleteTopologyResourceConnection?: (
    topologyId: string,
    resourceConnection: TopologyResourceConnection
  ) => Promise<void>;
  topologyErrors?: TopologyErrorsType;
  validateTopology?: (
    topologyId: string,
    resourcesToVerify: TopologyResourceWithPosition[]
  ) => void;
  refetchTopologyGraphData?: () => void;
  provider: CloudProvider;
  refetchResourcesList?: () => void;
  showGlobalResourceTagsDrawer: boolean;
  setShowGlobalResourceTagsDrawer: (
    showGlobalResourceTagsDrawer: boolean
  ) => void;
  globalResourceTags: Record<string, any>;
  onSubmitGlobalResourceTags: (tags: object) => void;
  markResourceAsExternal: (
    resourceId: string
  ) => Promise<TopologyResourceWithMisconfigurations | undefined>;
}

export interface CustomResourceDrawerProps {
  open: boolean;
  provider: CloudProvider;
  customResourceToEdit:
    | (CustomResourceTemplate & { resourceId?: string })
    | null;
  onClose: (shouldRefreshResourceList?: boolean) => void;
}

export enum CustomResourceCreationModes {
  "FROM_SCRATCH" = "FROM_SCRATCH", // when custom resource template creation is stated from scratch
  "FROM_UNSUPPORTED_RESOURCE" = "FROM_UNSUPPORTED_RESOURCE", // when custom resource template creation is initiated from an unsupported resource
  "EDIT_MODE" = "EDIT_MODE", // when custom resource template is being edited
}

export enum TopologyPageReferrer {
  "QUICK_APPSTACK_CREATE" = "QUICK_APPSTACK_CREATE",
}

export type TopologyPageMeta = {
  referrer?: TopologyPageReferrer;
};

export type ResourceProviders = keyof ProviderWiseSupportedResourceType;

export enum SelectedResourceType {
  VALIDATION_ERROR_NODE = "validation_error_node",
  POLICY_VIOLATION_NODE = "policy_violation_node",
}

export const nodeTypes: NodeTypes = {
  customResourceNode: CustomResourceNode,
  customGroupNode: CustomGroupNode,
  nestedGroupNode: NestedGroupNode,
};

export const edgeTypes: EdgeTypes = {
  customEdge: CustomEdge,
};

// TODO: Use ResourcePackRequest once types are fixed in backend
export interface ResourcePack
  extends Omit<ResourcePackRequest, "resources" | "connections"> {
  resourcePackID?: string;
  resources: Array<TopologyResourceWithPosition>;
  connections: Array<TopologyResourceConnection>;
}
