import { useCallback, useMemo, useState } from "react";
import type { TopologyResourceWithPosition } from "../../../../../apis/topology";
import { findResourceInTopology } from "../../../../../utils";
import {
  TopologyErrorDrawerTypeEnum,
  type useTopologyErrorDrawerProps,
} from "../topology-error-types";
import { DrawerSize } from "../../../../../components";
import type { SelectedResourceType } from "../../../topology-graph-types";

const useTopologyErrorDrawer = ({
  topologyErrors,
  graphDataResources,
  onNodeClick,
  resourceId,
  type,
}: useTopologyErrorDrawerProps) => {
  const [size, setDrawerSize] = useState<keyof typeof DrawerSize>("sm");
  const [isExpanded, setIsExpanded] = useState(false);

  const onResourceClick = useCallback(
    (
      resource_obj: TopologyResourceWithPosition,
      nodeType: SelectedResourceType
    ) => {
      onNodeClick(
        {
          id: resource_obj.id as string,
          data: resource_obj,
          position: {
            x: resource_obj?.position?.x as number,
            y: resource_obj?.position?.y as number,
          },
        },
        nodeType
      );
    },
    [onNodeClick]
  );

  const onToggleExpand = () => {
    setDrawerSize((prevSize) =>
      prevSize === DrawerSize.sm ? DrawerSize.lg : DrawerSize.sm
    );
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const headerText = useMemo(() => {
    const startText =
      type === TopologyErrorDrawerTypeEnum.WARNING
        ? "Policy violations"
        : "Actions required";
    if (resourceId) {
      const resource_obj = findResourceInTopology(
        graphDataResources,
        resourceId
      );

      return `${startText} in ${
        resource_obj?.resourceTypeLabel || resource_obj?.label
      }`;
    }
    return `${startText}`;
  }, [resourceId, graphDataResources, type]);

  const filteredResources = useMemo(() => {
    if (topologyErrors?.resourcesWithErrors) {
      return Object.keys(topologyErrors.resourcesWithErrors).filter((id) =>
        resourceId ? id === resourceId : true
      );
    }
    return [];
  }, [topologyErrors?.resourcesWithErrors, resourceId]);

  const errorCount = useMemo(() => {
    if (topologyErrors) {
      return type === TopologyErrorDrawerTypeEnum.ACTION
        ? topologyErrors.totalValidationErrors
        : topologyErrors.totalViolationErrors;
    }
    return 0;
  }, [topologyErrors, type]);

  return {
    size,
    onToggleExpand,
    headerText,
    filteredResources,
    onResourceClick,
    isExpanded,
    errorCount,
  };
};

export default useTopologyErrorDrawer;
