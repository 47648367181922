/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface PostTroubleshootingRequest {
    type: string;
    version: string;
    comment?: string;
    reaction?: string;
    requestIds?: Array<string>;
    userEmail?: string;
    attachmentsName?: Array<string>;
    attachments?: Array<Blob>;
}

/**
 * 
 */
export class TroubleshootingApi extends runtime.BaseAPI {

    /**
     * Post troubleshooting request
     */
    async postTroubleshootingRaw(requestParameters: PostTroubleshootingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling postTroubleshooting.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling postTroubleshooting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.type !== undefined) {
            formParams.append('type', requestParameters.type as any);
        }

        if (requestParameters.comment !== undefined) {
            formParams.append('comment', requestParameters.comment as any);
        }

        if (requestParameters.reaction !== undefined) {
            formParams.append('reaction', requestParameters.reaction as any);
        }

        if (requestParameters.requestIds) {
            formParams.append('requestIds', requestParameters.requestIds.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.version !== undefined) {
            formParams.append('version', requestParameters.version as any);
        }

        if (requestParameters.userEmail !== undefined) {
            formParams.append('userEmail', requestParameters.userEmail as any);
        }

        if (requestParameters.attachmentsName) {
            formParams.append('attachmentsName', requestParameters.attachmentsName.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.attachments) {
            requestParameters.attachments.forEach((element) => {
                formParams.append('attachments', element as any);
            })
        }

        const response = await this.request({
            path: `/troubleshooting`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Post troubleshooting request
     */
    async postTroubleshooting(requestParameters: PostTroubleshootingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postTroubleshootingRaw(requestParameters, initOverrides);
    }

}
