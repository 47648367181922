import React, { type FC, useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { PopoverProps } from "./popover-types";

import { generateUUIdV4, isFunction } from "../../utils";
import { Icon, IconsList } from "../icons";

const Popover: FC<PopoverProps> = ({
  trigger,
  heading,
  body,
  open = false,
  onClose,
  onOpen,
  position = "top",
  dataTestId,
  closeOnBlur = false,
  customContentRenderer,
  offset = 8,
  triggerOnHover = false, // New prop to trigger on hover
}) => {
  const popoverId = generateUUIdV4();
  const [isOpen, setIsOpen] = useState(open);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (closeOnBlur) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          tooltipRef.current &&
          !(tooltipRef.current as HTMLElement)?.contains?.(event.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside, true);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside, true);
      };
    }
  }, [closeOnBlur]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOpen = () => {
    setIsOpen(true);
    if (onOpen && isFunction(onOpen)) {
      onOpen();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    if (onClose && isFunction(onClose)) {
      onClose();
    }
  };

  return (
    <>
      <a
        data-tooltip-id={popoverId}
        data-tooltip-place={position}
        data-tooltip-offset={offset}
        data-tooltip-position-strategy="fixed"
        data-tooltip-float={false}
        onClick={(e) => {
          if (!triggerOnHover) {
            handleOpen();
            e.stopPropagation();
          }
        }}
        onMouseEnter={() => {
          if (triggerOnHover) {
            handleOpen();
          }
        }}
        onMouseLeave={() => {
          if (triggerOnHover) {
            handleClose();
          }
        }}
        onBlur={handleClose}
        data-testid={`${dataTestId}-trigger`}
      >
        {trigger}
      </a>
      <ReactTooltip
        ref={tooltipRef}
        id={popoverId}
        noArrow
        disableStyleInjection
        className="tw-z-50"
        clickable={true}
        opacity={1}
        imperativeModeOnly={true}
        isOpen={isOpen}
        afterHide={handleClose}
      >
        <>
          {customContentRenderer ? (
            customContentRenderer
          ) : (
            <div className="tw-rounded-md tw-border tw-border-gray-600 tw-bg-gray-700 tw-w-100">
              <div className="tw-flex tw-items-center tw-justify-between tw-py-2.5 tw-px-3 tw-gap-2 tw-border-b tw-border-gray-600">
                {heading && heading}
                <div
                  className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
                  onClick={handleClose}
                  data-testid={`${dataTestId}-close-icon`}
                >
                  <Icon name={IconsList.CLOSE} />
                </div>
              </div>
              {body && (
                <div className="tw-max-h-44 tw-overflow-y-scroll">{body}</div>
              )}
            </div>
          )}
        </>
      </ReactTooltip>
    </>
  );
};

export default Popover;
