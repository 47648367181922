/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceOutputConfig } from './ResourceOutputConfig';
import {
    ResourceOutputConfigFromJSON,
    ResourceOutputConfigFromJSONTyped,
    ResourceOutputConfigToJSON,
} from './ResourceOutputConfig';
import type { TopologyResourceConfigLayout } from './TopologyResourceConfigLayout';
import {
    TopologyResourceConfigLayoutFromJSON,
    TopologyResourceConfigLayoutFromJSONTyped,
    TopologyResourceConfigLayoutToJSON,
} from './TopologyResourceConfigLayout';
import type { TopologyResourceConfigValidation } from './TopologyResourceConfigValidation';
import {
    TopologyResourceConfigValidationFromJSON,
    TopologyResourceConfigValidationFromJSONTyped,
    TopologyResourceConfigValidationToJSON,
} from './TopologyResourceConfigValidation';
import type { TopologyResourceValidationConfig } from './TopologyResourceValidationConfig';
import {
    TopologyResourceValidationConfigFromJSON,
    TopologyResourceValidationConfigFromJSONTyped,
    TopologyResourceValidationConfigToJSON,
} from './TopologyResourceValidationConfig';

/**
 * 
 * @export
 * @interface TopologyResourceValidations
 */
export interface TopologyResourceValidations {
    /**
     * attributes for the resource
     * @type {Array<TopologyResourceConfigValidation>}
     * @memberof TopologyResourceValidations
     */
    attributes: Array<TopologyResourceConfigValidation>;
    /**
     * Layout configuration to render attributes in user interface
     * @type {Array<TopologyResourceConfigLayout>}
     * @memberof TopologyResourceValidations
     */
    layouts: Array<TopologyResourceConfigLayout>;
    /**
     * Output attributes for the resource
     * @type {Array<ResourceOutputConfig>}
     * @memberof TopologyResourceValidations
     */
    outputs?: Array<ResourceOutputConfig>;
    /**
     * Custom validations for the resource
     * @type {Array<TopologyResourceValidationConfig>}
     * @memberof TopologyResourceValidations
     */
    validations?: Array<TopologyResourceValidationConfig>;
}

/**
 * Check if a given object implements the TopologyResourceValidations interface.
 */
export function instanceOfTopologyResourceValidations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attributes" in value;
    isInstance = isInstance && "layouts" in value;

    return isInstance;
}

export function TopologyResourceValidationsFromJSON(json: any): TopologyResourceValidations {
    return TopologyResourceValidationsFromJSONTyped(json, false);
}

export function TopologyResourceValidationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceValidations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(TopologyResourceConfigValidationFromJSON)),
        'layouts': ((json['layouts'] as Array<any>).map(TopologyResourceConfigLayoutFromJSON)),
        'outputs': !exists(json, 'outputs') ? undefined : ((json['outputs'] as Array<any>).map(ResourceOutputConfigFromJSON)),
        'validations': !exists(json, 'validations') ? undefined : ((json['validations'] as Array<any>).map(TopologyResourceValidationConfigFromJSON)),
    };
}

export function TopologyResourceValidationsToJSON(value?: TopologyResourceValidations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(TopologyResourceConfigValidationToJSON)),
        'layouts': ((value.layouts as Array<any>).map(TopologyResourceConfigLayoutToJSON)),
        'outputs': value.outputs === undefined ? undefined : ((value.outputs as Array<any>).map(ResourceOutputConfigToJSON)),
        'validations': value.validations === undefined ? undefined : ((value.validations as Array<any>).map(TopologyResourceValidationConfigToJSON)),
    };
}

