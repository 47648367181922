/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyTfVarsResourcesDataInner } from './TopologyTfVarsResourcesDataInner';
import {
    TopologyTfVarsResourcesDataInnerFromJSON,
    TopologyTfVarsResourcesDataInnerFromJSONTyped,
    TopologyTfVarsResourcesDataInnerToJSON,
} from './TopologyTfVarsResourcesDataInner';

/**
 * 
 * @export
 * @interface TopologyTfVars
 */
export interface TopologyTfVars {
    /**
     * 
     * @type {string}
     * @memberof TopologyTfVars
     */
    tfVarName?: string;
    /**
     * 
     * @type {any}
     * @memberof TopologyTfVars
     */
    _default?: any;
    /**
     * 
     * @type {string}
     * @memberof TopologyTfVars
     */
    type?: string;
    /**
     * 
     * @type {Array<TopologyTfVarsResourcesDataInner>}
     * @memberof TopologyTfVars
     */
    resourcesData?: Array<TopologyTfVarsResourcesDataInner>;
}

/**
 * Check if a given object implements the TopologyTfVars interface.
 */
export function instanceOfTopologyTfVars(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyTfVarsFromJSON(json: any): TopologyTfVars {
    return TopologyTfVarsFromJSONTyped(json, false);
}

export function TopologyTfVarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyTfVars {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tfVarName': !exists(json, 'tfVarName') ? undefined : json['tfVarName'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'resourcesData': !exists(json, 'ResourcesData') ? undefined : ((json['ResourcesData'] as Array<any>).map(TopologyTfVarsResourcesDataInnerFromJSON)),
    };
}

export function TopologyTfVarsToJSON(value?: TopologyTfVars | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tfVarName': value.tfVarName,
        'default': value._default,
        'type': value.type,
        'ResourcesData': value.resourcesData === undefined ? undefined : ((value.resourcesData as Array<any>).map(TopologyTfVarsResourcesDataInnerToJSON)),
    };
}

