/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteAppStackRequest
 */
export interface DeleteAppStackRequest {
    /**
     * List of appStack names to delete along with its versions
     * @type {Array<string>}
     * @memberof DeleteAppStackRequest
     */
    appstackNames?: Array<string>;
    /**
     * List of appStack UUIDs to delete
     * @type {Array<string>}
     * @memberof DeleteAppStackRequest
     */
    appstackIds?: Array<string>;
}

/**
 * Check if a given object implements the DeleteAppStackRequest interface.
 */
export function instanceOfDeleteAppStackRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteAppStackRequestFromJSON(json: any): DeleteAppStackRequest {
    return DeleteAppStackRequestFromJSONTyped(json, false);
}

export function DeleteAppStackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteAppStackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appstackNames': !exists(json, 'appstackNames') ? undefined : json['appstackNames'],
        'appstackIds': !exists(json, 'appstackIds') ? undefined : json['appstackIds'],
    };
}

export function DeleteAppStackRequestToJSON(value?: DeleteAppStackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appstackNames': value.appstackNames,
        'appstackIds': value.appstackIds,
    };
}

