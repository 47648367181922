/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyResourceConnection } from './TopologyResourceConnection';
import {
    TopologyResourceConnectionFromJSON,
    TopologyResourceConnectionFromJSONTyped,
    TopologyResourceConnectionToJSON,
} from './TopologyResourceConnection';

/**
 * 
 * @export
 * @interface ResourcePackRequest
 */
export interface ResourcePackRequest {
    /**
     * 
     * @type {string}
     * @memberof ResourcePackRequest
     */
    shareWith?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcePackRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcePackRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourcePackRequest
     */
    creatorName?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ResourcePackRequest
     */
    resources?: Array<any>;
    /**
     * 
     * @type {Array<TopologyResourceConnection>}
     * @memberof ResourcePackRequest
     */
    connections?: Array<TopologyResourceConnection>;
    /**
     * 
     * @type {string}
     * @memberof ResourcePackRequest
     */
    deploymentType?: string;
}

/**
 * Check if a given object implements the ResourcePackRequest interface.
 */
export function instanceOfResourcePackRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourcePackRequestFromJSON(json: any): ResourcePackRequest {
    return ResourcePackRequestFromJSONTyped(json, false);
}

export function ResourcePackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourcePackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shareWith': !exists(json, 'shareWith') ? undefined : json['shareWith'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'creatorName': !exists(json, 'creatorName') ? undefined : json['creatorName'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'connections': !exists(json, 'connections') ? undefined : ((json['connections'] as Array<any>).map(TopologyResourceConnectionFromJSON)),
        'deploymentType': !exists(json, 'deploymentType') ? undefined : json['deploymentType'],
    };
}

export function ResourcePackRequestToJSON(value?: ResourcePackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shareWith': value.shareWith,
        'name': value.name,
        'description': value.description,
        'creatorName': value.creatorName,
        'resources': value.resources,
        'connections': value.connections === undefined ? undefined : ((value.connections as Array<any>).map(TopologyResourceConnectionToJSON)),
        'deploymentType': value.deploymentType,
    };
}

