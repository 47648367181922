/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopologyResourceValidationConfig
 */
export interface TopologyResourceValidationConfig {
    /**
     * key to uniquely identify a advanced validation
     * @type {string}
     * @memberof TopologyResourceValidationConfig
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceValidationConfig
     */
    description?: string;
    /**
     * Apply the validation on these resource attributes
     * @type {Array<string>}
     * @memberof TopologyResourceValidationConfig
     */
    attributes?: Array<string>;
    /**
     * advanced validation type
     * @type {string}
     * @memberof TopologyResourceValidationConfig
     */
    type?: string;
    /**
     * validation string
     * @type {string}
     * @memberof TopologyResourceValidationConfig
     */
    validation?: string;
    /**
     * assert this value
     * @type {string}
     * @memberof TopologyResourceValidationConfig
     */
    assert?: string;
    /**
     * Show user this error message is the validation is not satisfied
     * @type {string}
     * @memberof TopologyResourceValidationConfig
     */
    errorMessage?: string;
}

/**
 * Check if a given object implements the TopologyResourceValidationConfig interface.
 */
export function instanceOfTopologyResourceValidationConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyResourceValidationConfigFromJSON(json: any): TopologyResourceValidationConfig {
    return TopologyResourceValidationConfigFromJSONTyped(json, false);
}

export function TopologyResourceValidationConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceValidationConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'validation': !exists(json, 'validation') ? undefined : json['validation'],
        'assert': !exists(json, 'assert') ? undefined : json['assert'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
    };
}

export function TopologyResourceValidationConfigToJSON(value?: TopologyResourceValidationConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'description': value.description,
        'attributes': value.attributes,
        'type': value.type,
        'validation': value.validation,
        'assert': value.assert,
        'errorMessage': value.errorMessage,
    };
}

