/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AzureIam } from './AzureIam';
import {
    AzureIamFromJSON,
    AzureIamFromJSONTyped,
    AzureIamToJSON,
} from './AzureIam';

/**
 * 
 * @export
 * @interface ComponentTraitsDependenciesAzureKeyVaultKeyInner
 */
export interface ComponentTraitsDependenciesAzureKeyVaultKeyInner {
    /**
     * Name / ID of the Key
     * @type {string}
     * @memberof ComponentTraitsDependenciesAzureKeyVaultKeyInner
     */
    keyName?: string;
    /**
     * URL of the Key Vault
     * @type {string}
     * @memberof ComponentTraitsDependenciesAzureKeyVaultKeyInner
     */
    vaultUrl?: string;
    /**
     * 
     * @type {AzureIam}
     * @memberof ComponentTraitsDependenciesAzureKeyVaultKeyInner
     */
    iam?: AzureIam;
}

/**
 * Check if a given object implements the ComponentTraitsDependenciesAzureKeyVaultKeyInner interface.
 */
export function instanceOfComponentTraitsDependenciesAzureKeyVaultKeyInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ComponentTraitsDependenciesAzureKeyVaultKeyInnerFromJSON(json: any): ComponentTraitsDependenciesAzureKeyVaultKeyInner {
    return ComponentTraitsDependenciesAzureKeyVaultKeyInnerFromJSONTyped(json, false);
}

export function ComponentTraitsDependenciesAzureKeyVaultKeyInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentTraitsDependenciesAzureKeyVaultKeyInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keyName': !exists(json, 'keyName') ? undefined : json['keyName'],
        'vaultUrl': !exists(json, 'vaultUrl') ? undefined : json['vaultUrl'],
        'iam': !exists(json, 'iam') ? undefined : AzureIamFromJSON(json['iam']),
    };
}

export function ComponentTraitsDependenciesAzureKeyVaultKeyInnerToJSON(value?: ComponentTraitsDependenciesAzureKeyVaultKeyInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyName': value.keyName,
        'vaultUrl': value.vaultUrl,
        'iam': AzureIamToJSON(value.iam),
    };
}

