import { useLocation } from "@reach/router";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { type FC, useEffect, useState } from "react";

import {
  checkIsProdEnv,
  getAmplitudeSessionId,
  goToLogin,
  initAmplitude,
  trackAmplitudeEvent,
} from "../utils";

import {
  Icon,
  IconSize,
  IconsList,
  Navbar,
  PageBanner,
  Sidebar,
} from "../components";
import { HelpCentreDrawer } from "../components/help-centre";

import { StackGenReBrandDocUrl } from "../config/constants";
import {
  fetchUserLoginStatusAtom,
  loggedInUserSelectedTeamAtom,
} from "../stores/auth.store";
import {
  reportBugAtom,
  showHelpDrawerAtom,
  submitFeedbackAtom,
  submitTroubleshootingPackageAtom,
  toggleHelpDrawerAtom,
} from "../stores/help-centre.store";
import { breadcrumbsAtom, sidebarCollapsedAtom } from "../stores/page.store";
import { InAppLayoutProps } from "./layout-types";

// Layout with header, sidebar, body and to use when user is logged in
const InAppLayout: FC<InAppLayoutProps> = ({ children }) => {
  const location = useLocation();
  const activeUrlPath = location.pathname.slice(0, -1);
  const [showPageBanner, setShowPageBanner] = useState(true);

  const fetchUserLoginStatus = useSetAtom(fetchUserLoginStatusAtom);
  const sidebarCollapsed = useAtomValue(sidebarCollapsedAtom);
  const breadcrumbs_data = useAtomValue(breadcrumbsAtom);
  const showHelpDrawer = useAtomValue(showHelpDrawerAtom);
  const toggleHelpDrawer = useSetAtom(toggleHelpDrawerAtom);
  const onSubmitBug = useSetAtom(reportBugAtom);
  const onSubmitFeedback = useSetAtom(submitFeedbackAtom);
  const onSubmitTroubleshootingPackage = useSetAtom(
    submitTroubleshootingPackageAtom
  );
  const [selectedTeam, setSelectedTeam] = useAtom(loggedInUserSelectedTeamAtom);

  const isQuickAppStackCreationRouteActive = location.pathname.includes(
    "/quick-appstack-create"
  );
  const isAppStackListingRouteActive = location.pathname === "/appstacks/";
  const isAccountSettingsRouteActive =
    location.pathname.includes("/account-settings");

  // set selected team if teamId is present in query params
  const urlParams = new URLSearchParams(location.search);
  const teamId = urlParams.get("teamId");
  if (teamId) {
    setSelectedTeam(teamId);
  }

  useEffect(() => {
    const initiateAmplitude = async () => {
      if (checkIsProdEnv() && !getAmplitudeSessionId()) {
        await initAmplitude(true);
      }
    };
    const fetchLoginStatus = async () => {
      try {
        const user = await fetchUserLoginStatus();
        if (user?.userId) {
          initiateAmplitude();
        }
      } catch (error: any) {
        if (error?.errCode === "INVALID_SESSION") {
          goToLogin(); // navigate to login screen if session is invalid
        }
      }
    };
    fetchLoginStatus();
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      const urlParams = new URLSearchParams(location.search);
      urlParams.set("teamId", selectedTeam);

      if (typeof window !== "undefined") {
        // Update URL
        const { protocol, pathname, host } = window.location;
        const newUrl = `${protocol}//${host}${pathname}?${urlParams.toString()}`;
        window.history.pushState({}, "", newUrl);
      }
    }
  }, [location.pathname]);

  return (
    <div>
      {showPageBanner &&
        (isAppStackListingRouteActive ||
          isQuickAppStackCreationRouteActive) && (
          <PageBanner
            type="theme"
            hideIcon={true}
            dismissible={true}
            onDismiss={() => setShowPageBanner(false)}
            content={
              <div className="tw-flex tw-gap-2">
                <span className="tw-flex tw-gap-2 tw-items-center">
                  <Icon
                    name={IconsList.SPARKLES_SOLID}
                    size={IconSize.md}
                    className="tw-text-green-800"
                  />{" "}
                  appCD is now StackGen!
                </span>
                <span
                  className="tw-flex tw-items-center tw-font-semibold tw-cursor-pointer tw-text-blue-800 hover:tw-underline"
                  onClick={() => {
                    if (window !== undefined) {
                      trackAmplitudeEvent(
                        "appcd_to_stackgen_banner_learn_more"
                      );
                      window.open(StackGenReBrandDocUrl, "_blank");
                    }
                  }}
                >
                  Learn more{" "}
                  <Icon name={IconsList.ARROW_UP_RIGHT} size={IconSize.sm} />
                </span>
              </div>
            }
            dataTestId="playground-banner"
          />
        )}
      <div
        className={`tw-w-screen ${
          showPageBanner &&
          (isAppStackListingRouteActive || isQuickAppStackCreationRouteActive)
            ? "tw-h-[calc(100vh-2.5rem)]"
            : "tw-h-screen"
        } tw-flex tw-text-gray-100`}
      >
        <Sidebar
          activeUrlPath={activeUrlPath}
          isQuickAppStackCreationRouteActive={
            isQuickAppStackCreationRouteActive
          }
          isAccountSettingsRouteActive={isAccountSettingsRouteActive}
        />
        <div
          className={`${
            showPageBanner &&
            (isAppStackListingRouteActive || isQuickAppStackCreationRouteActive)
              ? "tw-h-[calc(100vh-6rem)]"
              : "tw-h-full"
          } ${
            sidebarCollapsed
              ? "tw-absolute tw-left-14 tw-w-[calc(100vw-3.5rem)]"
              : "tw-w-4/5"
          } 
          `}
        >
          <Navbar
            breadcrumbs_data={breadcrumbs_data}
            onHelpClick={() => {
              if (!showHelpDrawer) {
                trackAmplitudeEvent("topnav_help");
              }
              toggleHelpDrawer(!showHelpDrawer);
            }}
            isQuickAppStackCreationRouteActive={
              isQuickAppStackCreationRouteActive
            }
          />
          <main
            className={`${
              showPageBanner &&
              (isAppStackListingRouteActive ||
                isQuickAppStackCreationRouteActive)
                ? "tw-h-full"
                : "tw-h-[calc(100vh-3.5rem)]"
            } tw-relative tw-overflow-y-auto tw-overflow-x-hidden tw-bg-gray-900 tw-text-white tw-border-t tw-border-gray-700`}
          >
            {children}
            <HelpCentreDrawer
              show={showHelpDrawer}
              onClose={() => toggleHelpDrawer(false)}
              onSubmitBug={(description) => onSubmitBug(description)}
              onSubmitFeedback={(description, rating) =>
                onSubmitFeedback(description, rating)
              }
              onSubmitTroubleshootingPackage={(comment) =>
                onSubmitTroubleshootingPackage(comment)
              }
            />
          </main>
        </div>
      </div>
    </div>
  );
};

export default InAppLayout;
