/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const QuestionType = {
    Boolean: 'BOOLEAN',
    SingleSelect: 'SINGLE_SELECT',
    MultiSelect: 'MULTI_SELECT',
    Text: 'TEXT',
    Range: 'RANGE',
    ResourceAction: 'RESOURCE_ACTION',
    ResourceConnectionAction: 'RESOURCE_CONNECTION_ACTION'
} as const;
export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


export function QuestionTypeFromJSON(json: any): QuestionType {
    return QuestionTypeFromJSONTyped(json, false);
}

export function QuestionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionType {
    return json as QuestionType;
}

export function QuestionTypeToJSON(value?: QuestionType | null): any {
    return value as any;
}

