/* tslint:disable */
/* eslint-disable */
/**
 * StackGen Exporter API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExportInfo } from './ExportInfo';
import {
    ExportInfoFromJSON,
    ExportInfoFromJSONTyped,
    ExportInfoToJSON,
} from './ExportInfo';

/**
 * 
 * @export
 * @interface GetExportHistory200Response
 */
export interface GetExportHistory200Response {
    /**
     * Number of exports
     * @type {number}
     * @memberof GetExportHistory200Response
     */
    count: number;
    /**
     * 
     * @type {Array<ExportInfo>}
     * @memberof GetExportHistory200Response
     */
    records: Array<ExportInfo>;
}

/**
 * Check if a given object implements the GetExportHistory200Response interface.
 */
export function instanceOfGetExportHistory200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "records" in value;

    return isInstance;
}

export function GetExportHistory200ResponseFromJSON(json: any): GetExportHistory200Response {
    return GetExportHistory200ResponseFromJSONTyped(json, false);
}

export function GetExportHistory200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExportHistory200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'records': ((json['records'] as Array<any>).map(ExportInfoFromJSON)),
    };
}

export function GetExportHistory200ResponseToJSON(value?: GetExportHistory200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'records': ((value.records as Array<any>).map(ExportInfoToJSON)),
    };
}

