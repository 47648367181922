/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignUsersToOrgRequest,
  CreateOrganizationRequest,
  GetOrganization200ResponseInner,
  ListOrganizations200Response,
  Org,
  OrgUser,
  RemoveUserFromOrgRequest,
  UpdateUserOrgRoleRequest,
} from '../models/index';
import {
    AssignUsersToOrgRequestFromJSON,
    AssignUsersToOrgRequestToJSON,
    CreateOrganizationRequestFromJSON,
    CreateOrganizationRequestToJSON,
    GetOrganization200ResponseInnerFromJSON,
    GetOrganization200ResponseInnerToJSON,
    ListOrganizations200ResponseFromJSON,
    ListOrganizations200ResponseToJSON,
    OrgFromJSON,
    OrgToJSON,
    OrgUserFromJSON,
    OrgUserToJSON,
    RemoveUserFromOrgRequestFromJSON,
    RemoveUserFromOrgRequestToJSON,
    UpdateUserOrgRoleRequestFromJSON,
    UpdateUserOrgRoleRequestToJSON,
} from '../models/index';

export interface AssignUsersToOrgOperationRequest {
    orgId: string;
    assignUsersToOrgRequest: AssignUsersToOrgRequest;
}

export interface CreateOrganizationOperationRequest {
    createOrganizationRequest: CreateOrganizationRequest;
}

export interface DeleteOrganizationRequest {
    orgId: string;
}

export interface GetOrganizationRequest {
    orgId: string;
}

export interface GetOrganizationUsersRequest {
    orgId: string;
}

export interface ListOrganizationsRequest {
    name?: string;
    includeMembers?: boolean;
}

export interface RemoveUserFromOrgOperationRequest {
    orgId: string;
    removeUserFromOrgRequest: RemoveUserFromOrgRequest;
}

export interface UpdateUserOrgRoleOperationRequest {
    orgId: string;
    updateUserOrgRoleRequest: UpdateUserOrgRoleRequest;
}

/**
 * 
 */
export class OrgManagementApi extends runtime.BaseAPI {

    /**
     * Assign User(s) to Organization with corresponding roles
     */
    async assignUsersToOrgRaw(requestParameters: AssignUsersToOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling assignUsersToOrg.');
        }

        if (requestParameters.assignUsersToOrgRequest === null || requestParameters.assignUsersToOrgRequest === undefined) {
            throw new runtime.RequiredError('assignUsersToOrgRequest','Required parameter requestParameters.assignUsersToOrgRequest was null or undefined when calling assignUsersToOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs/{org_id}/users`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignUsersToOrgRequestToJSON(requestParameters.assignUsersToOrgRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign User(s) to Organization with corresponding roles
     */
    async assignUsersToOrg(requestParameters: AssignUsersToOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignUsersToOrgRaw(requestParameters, initOverrides);
    }

    /**
     * Create Organization requires super admin
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Org>> {
        if (requestParameters.createOrganizationRequest === null || requestParameters.createOrganizationRequest === undefined) {
            throw new runtime.RequiredError('createOrganizationRequest','Required parameter requestParameters.createOrganizationRequest was null or undefined when calling createOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrganizationRequestToJSON(requestParameters.createOrganizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgFromJSON(jsonValue));
    }

    /**
     * Create Organization requires super admin
     */
    async createOrganization(requestParameters: CreateOrganizationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Org> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Organization requires super admin
     */
    async deleteOrganizationRaw(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling deleteOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Organization requires super admin
     */
    async deleteOrganization(requestParameters: DeleteOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganizationRaw(requestParameters, initOverrides);
    }

    /**
     * Get organization details
     */
    async getOrganizationRaw(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrganization200ResponseInner>>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganization200ResponseInnerFromJSON));
    }

    /**
     * Get organization details
     */
    async getOrganization(requestParameters: GetOrganizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrganization200ResponseInner>> {
        const response = await this.getOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get get all users assigned to an organization
     */
    async getOrganizationUsersRaw(requestParameters: GetOrganizationUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrgUser>>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrganizationUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs/{org_id}/users`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgUserFromJSON));
    }

    /**
     * Get get all users assigned to an organization
     */
    async getOrganizationUsers(requestParameters: GetOrganizationUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrgUser>> {
        const response = await this.getOrganizationUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List All Organizations requires super admin
     */
    async listOrganizationsRaw(requestParameters: ListOrganizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOrganizations200Response>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.includeMembers !== undefined) {
            queryParameters['includeMembers'] = requestParameters.includeMembers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orgs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOrganizations200ResponseFromJSON(jsonValue));
    }

    /**
     * List All Organizations requires super admin
     */
    async listOrganizations(requestParameters: ListOrganizationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOrganizations200Response> {
        const response = await this.listOrganizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove user from organization requires super admin
     */
    async removeUserFromOrgRaw(requestParameters: RemoveUserFromOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling removeUserFromOrg.');
        }

        if (requestParameters.removeUserFromOrgRequest === null || requestParameters.removeUserFromOrgRequest === undefined) {
            throw new runtime.RequiredError('removeUserFromOrgRequest','Required parameter requestParameters.removeUserFromOrgRequest was null or undefined when calling removeUserFromOrg.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs/{org_id}/users`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveUserFromOrgRequestToJSON(requestParameters.removeUserFromOrgRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove user from organization requires super admin
     */
    async removeUserFromOrg(requestParameters: RemoveUserFromOrgOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeUserFromOrgRaw(requestParameters, initOverrides);
    }

    /**
     * Update user to role in Organization
     */
    async updateUserOrgRoleRaw(requestParameters: UpdateUserOrgRoleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling updateUserOrgRole.');
        }

        if (requestParameters.updateUserOrgRoleRequest === null || requestParameters.updateUserOrgRoleRequest === undefined) {
            throw new runtime.RequiredError('updateUserOrgRoleRequest','Required parameter requestParameters.updateUserOrgRoleRequest was null or undefined when calling updateUserOrgRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orgs/{org_id}/users`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserOrgRoleRequestToJSON(requestParameters.updateUserOrgRoleRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user to role in Organization
     */
    async updateUserOrgRole(requestParameters: UpdateUserOrgRoleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUserOrgRoleRaw(requestParameters, initOverrides);
    }

}
