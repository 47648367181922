/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppManifest } from './AppManifest';
import {
    AppManifestFromJSON,
    AppManifestFromJSONTyped,
    AppManifestToJSON,
} from './AppManifest';
import type { AppStackConfigCore } from './AppStackConfigCore';
import {
    AppStackConfigCoreFromJSON,
    AppStackConfigCoreFromJSONTyped,
    AppStackConfigCoreToJSON,
} from './AppStackConfigCore';
import type { Component } from './Component';
import {
    ComponentFromJSON,
    ComponentFromJSONTyped,
    ComponentToJSON,
} from './Component';

/**
 * 
 * @export
 * @interface AppStackUpload
 */
export interface AppStackUpload {
    /**
     * Name of the appStack
     * @type {string}
     * @memberof AppStackUpload
     */
    name: string;
    /**
     * Description of the appStack
     * @type {string}
     * @memberof AppStackUpload
     */
    description?: string;
    /**
     * List of components in the appStack
     * @type {Array<Component>}
     * @memberof AppStackUpload
     */
    components?: Array<Component>;
    /**
     * 
     * @type {AppStackConfigCore}
     * @memberof AppStackUpload
     */
    coreConfig?: AppStackConfigCore;
    /**
     * UUID of the AppStackConfig that this appStack is based on. Leave null if the appStack is not based on a configuration
     * @type {string}
     * @memberof AppStackUpload
     */
    appStackConfigRef?: string;
    /**
     * Labels associated with the appStack
     * @type {Array<string>}
     * @memberof AppStackUpload
     */
    labels?: Array<string>;
    /**
     * Creator of the policy
     * @type {string}
     * @memberof AppStackUpload
     */
    createdBy?: string;
    /**
     * User who last updated the appStack
     * @type {string}
     * @memberof AppStackUpload
     */
    updatedBy?: string;
    /**
     * 
     * @type {AppManifest}
     * @memberof AppStackUpload
     */
    manifest: AppManifest;
}

/**
 * Check if a given object implements the AppStackUpload interface.
 */
export function instanceOfAppStackUpload(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "manifest" in value;

    return isInstance;
}

export function AppStackUploadFromJSON(json: any): AppStackUpload {
    return AppStackUploadFromJSONTyped(json, false);
}

export function AppStackUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStackUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'components': !exists(json, 'components') ? undefined : ((json['components'] as Array<any>).map(ComponentFromJSON)),
        'coreConfig': !exists(json, 'coreConfig') ? undefined : AppStackConfigCoreFromJSON(json['coreConfig']),
        'appStackConfigRef': !exists(json, 'appStackConfigRef') ? undefined : json['appStackConfigRef'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'manifest': AppManifestFromJSON(json['manifest']),
    };
}

export function AppStackUploadToJSON(value?: AppStackUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'components': value.components === undefined ? undefined : ((value.components as Array<any>).map(ComponentToJSON)),
        'coreConfig': AppStackConfigCoreToJSON(value.coreConfig),
        'appStackConfigRef': value.appStackConfigRef,
        'labels': value.labels,
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
        'manifest': AppManifestToJSON(value.manifest),
    };
}

