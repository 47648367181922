import React, { type ReactElement } from "react";

const Card = ({
  padding,
  children,
  className = "",
}: {
  padding?: boolean;
  children: ReactElement | ReactElement[];
  className?: string;
}) => {
  return (
    <div
      className={`tw-bg-gray-800 tw-rounded-lg tw-border tw-border-gray-700 ${className} ${
        padding ? "tw-p-4" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Card;
