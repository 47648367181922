import React, { type FC } from "react";

import {
  ViraSelectQuestion,
  ViraMultiSelectQuestion,
  ViraTextQuestion,
  ViraRangeQuestion,
  ViraBooleanQuestion,
  type ViraQuestionProps,
} from "./";
import { QuestionType } from "../../apis/vira";

const ViraQuestion: FC<ViraQuestionProps> = ({
  question,
  onSkip,
  onAnswer,
}) => {
  // Render question based on question type (kept it separate & modular for more styling flexibility in future)
  switch (question?.type) {
    case QuestionType.Boolean:
      return (
        <ViraBooleanQuestion
          question={question}
          onSkip={onSkip}
          onAnswer={onAnswer}
        />
      );
    case QuestionType.Range:
      return (
        <ViraRangeQuestion
          question={question}
          onSkip={onSkip}
          onAnswer={onAnswer}
        />
      );
    case QuestionType.Text:
      return (
        <ViraTextQuestion
          question={question}
          onSkip={onSkip}
          onAnswer={onAnswer}
        />
      );
    case QuestionType.SingleSelect:
      return (
        <ViraSelectQuestion
          question={question}
          onSkip={onSkip}
          onAnswer={onAnswer}
        />
      );
    case QuestionType.MultiSelect:
      return (
        <ViraMultiSelectQuestion
          question={question}
          onSkip={onSkip}
          onAnswer={onAnswer}
        />
      );
    default:
      onSkip();
      return null;
  }
};

export default ViraQuestion;
