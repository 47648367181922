import React, { type FC, useEffect, useState, useMemo } from "react";
import { FieldArray, Form, Formik } from "formik";
import { useAtomValue, useSetAtom } from "jotai";
import * as yup from "yup";

import {
  CustomResourceCreationModes,
  CustomResourceDrawerProps,
} from "../../topology-graph-types";
import { Editor, useMonaco } from "@monaco-editor/react";
import type {
  CustomResourceTemplate,
  ProviderWiseSupportedResourceType,
  Variable,
  MatchingResourceAttributes,
  CustomTemplateReq,
} from "../../../../apis/topology";
import { Option } from "../../../form-elements/select-box-types";

import {
  fetchSupportedTfDataTypesAtom,
  saveCustomResourceTemplateForResourceTypeAtom,
  supportedTfDataTypesAtom,
  topologyResourcesListAtom,
  updateCustomTemplateAtom,
  getMatchingResourceAttributesAtom,
} from "../../../../stores/topology.store";
import {
  Icon,
  IconsList,
  IconSize,
  Drawer,
  TextBox,
  AWSIconsList,
  AWSIcon,
  Button,
  SelectBox,
  TextArea,
  ToggleSwitch,
  Banner,
  ScrollToError,
  Stepper,
} from "../../../../components";
import InitialsLogo from "../../../initials-logo/initials-logo";
import { BannerTypes } from "../../../banner/banner-types";
import {
  IaCFileTypes,
  ResourceProvidersMap,
  unsupportedResourceTypes,
} from "../../../../config/constants";
import {
  getResourcesByCategory,
  trackAmplitudeEvent,
  debounce,
} from "../../../../utils";
import DefaultValueRendererForPrimitiveType from "./default-value-renderer-for-primitive-type";
import DefaultValueRendererForNonPrimitiveType from "./default-value-renderer-for-non-primitive-type";
import {
  CustomResourceDataType,
  attributeToFormikProperties,
  formikValuesToAttributeProperties,
  getCapitalizedVariableName,
  getCustomResourceEditorPlaceholderText,
  getCustomResourceTypePlaceholder,
} from "../../../../utils/custom-resource-handler";
import type {
  ConfigEditorRendererProps,
  ConfigureVariableHeaderProps,
  ConfigureVariablesSectionProps,
  DuplicateResourceTypeWarningProps,
  FooterProps,
} from "./custom-resource-types";
import {
  customResourceCreationSteps,
  CustomResourceCreationStepIds,
} from "./custom-resource-types";
import CustomResourceMapVariables from "./custom-resource-map-variables";

const CustomResourceDrawer: FC<CustomResourceDrawerProps> = ({
  open,
  provider,
  customResourceToEdit,
  onClose,
}) => {
  const defaultInitialFormValues: CustomResourceTemplate & {
    resourceMappings?: { [key: string]: string };
  } = {
    label: "",
    resourceType: "",
    provider: provider,
    template: getCustomResourceEditorPlaceholderText(provider),
    iacType: "Terraform", // TODO: Remove this hardcoded data once we start collecting it from UI
    variables: [],
    resourceMappings: {},
  };

  const newTemplateVariable = {
    name: "",
    label: "",
    type: "",
    description: "",
    defaultVal: "",
    required: false,
  };

  const monaco = useMonaco();
  const [themeDefined, setThemeDefined] = useState(false);
  const [mode, setMode] = useState<CustomResourceCreationModes>(
    CustomResourceCreationModes.FROM_SCRATCH
  );
  const [tfDataTypes, setTfDataTypes] = useState([] as Option[]);
  const [formInitialValues, setFormInitialValues] = useState<
    CustomResourceTemplate & {
      resourceMappings?: { [key: string]: string };
    } & {
      resourceId?: string;
    }
  >(customResourceToEdit ? customResourceToEdit : defaultInitialFormValues);
  const [activeStepNumber, setActiveStepNumber] = useState(1);
  const [
    filteredCustomResourceCreationSteps,
    setFilteredCustomResourceCreationSteps,
  ] = useState([customResourceCreationSteps[0]]);
  const [resourceTypeMappings, setResourceTypeMappings] =
    useState<MatchingResourceAttributes>({});

  const supportedTfDataTypes = useAtomValue(supportedTfDataTypesAtom);
  const topologyResourcesList = useAtomValue(topologyResourcesListAtom);
  const fetchSupportedTfDataTypes = useSetAtom(fetchSupportedTfDataTypesAtom);
  const saveCustomResourceTemplateForResourceType = useSetAtom(
    saveCustomResourceTemplateForResourceTypeAtom
  );
  const updateCustomTemplate = useSetAtom(updateCustomTemplateAtom);
  const getMatchingResourceAttributes = useSetAtom(
    getMatchingResourceAttributesAtom
  );

  const onDataTypeChange = (
    selectedOption: Option,
    index: number,
    values: CustomResourceTemplate,
    setFieldValue: (field: string, value: any) => void
  ) => {
    let defaultVal;
    let schema;

    switch (selectedOption?.value) {
      case CustomResourceDataType.STRING:
      case CustomResourceDataType.NUMBER:
      case CustomResourceDataType.BOOLEAN:
        defaultVal = undefined;
        break;
      case CustomResourceDataType.LIST_STRING:
      case CustomResourceDataType.LIST_NUMBER:
      case CustomResourceDataType.LIST_BOOLEAN:
      case CustomResourceDataType.SET_STRING:
      case CustomResourceDataType.SET_NUMBER:
      case CustomResourceDataType.SET_BOOLEAN:
        defaultVal = [""];
        break;
      case CustomResourceDataType.MAP_STRING:
      case CustomResourceDataType.MAP_NUMBER:
      case CustomResourceDataType.MAP_BOOLEAN:
        defaultVal = [{ key: "", value: "" }];
        break;
      case CustomResourceDataType.OBJECT:
      case CustomResourceDataType.LIST_OBJECT:
        defaultVal =
          selectedOption?.value === CustomResourceDataType.LIST_OBJECT
            ? [[""]]
            : [""];
        schema = [{ key: "", dataType: undefined }];
        break;
      default:
        return;
    }

    setFieldValue(`variables.[${index}]`, {
      ...values?.variables?.[index],
      defaultVal,
      schema,
      type: selectedOption?.value,
    });
  };

  const handleEditModeSubmission = async (
    values: CustomResourceTemplate,
    transformedVariables: Variable[],
    resetForm: () => void
  ) => {
    const isSuccess = await updateCustomTemplate({
      uuid: customResourceToEdit?.id as string,
      updateCustomResourceTemplateRequest: {
        description: values.description,
        variables: transformedVariables,
        template: values.template,
      },
    });
    if (isSuccess) {
      trackAmplitudeEvent("custom_resource_edit_success", {
        uuid: customResourceToEdit?.id as string,
      });
      resetForm();
      onClose(true);
    } else {
      trackAmplitudeEvent("custom_resource_edit_fail", {
        uuid: customResourceToEdit?.id as string,
      });
    }
  };

  const handleCreationModeSubmission = async (
    customTemplateReq: any,
    resetForm: () => void
  ) => {
    const responseObj = await saveCustomResourceTemplateForResourceType({
      customTemplateReq,
    });
    if (responseObj) {
      if (mode === CustomResourceCreationModes.FROM_UNSUPPORTED_RESOURCE) {
        trackAmplitudeEvent(
          "custom_resource_from_unsupported_creation_success",
          {
            resourceId: customResourceToEdit?.resourceId,
          }
        );
      } else {
        trackAmplitudeEvent("custom_resource_creation_success", {
          uuid: responseObj.id,
        });
      }
      resetForm();
      onClose(true);
    } else {
      if (mode === CustomResourceCreationModes.FROM_UNSUPPORTED_RESOURCE) {
        trackAmplitudeEvent("custom_resource_from_unsupported_creation_fail", {
          resourceId: customResourceToEdit?.resourceId,
        });
      } else {
        trackAmplitudeEvent("custom_resource_creation_fail", {});
      }
    }
  };

  const onCustomResourceSubmit = async (
    values: CustomResourceTemplate & {
      resourceMappings?: { [key: string]: string };
    },
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: () => void
  ) => {
    const transformedVariables = formikValuesToAttributeProperties(
      values.variables as Variable[]
    );
    // make required conversions here if needed
    const customTemplateReq: any = {
      ...values,
      variables: transformedVariables,
      ...(values.resourceMappings && {
        overrideConfig: {
          overriddenResourceType: resourceTypeMappings?.resourceType,
          configMapping: values.resourceMappings,
        },
        resourceMappings: undefined,
      }),
    };
    setSubmitting(true);
    if (
      mode === CustomResourceCreationModes.EDIT_MODE &&
      customResourceToEdit
    ) {
      await handleEditModeSubmission(values, transformedVariables, resetForm);
    } else {
      // add resourceId in case of from unsupported resource
      if (mode === CustomResourceCreationModes.FROM_UNSUPPORTED_RESOURCE) {
        customTemplateReq["resourceId"] = customResourceToEdit?.resourceId;
      }
      await handleCreationModeSubmission(customTemplateReq, resetForm);
    }
    setSubmitting(false);
  };

  const DuplicateResourceTypeWarning: FC<DuplicateResourceTypeWarningProps> = ({
    showDuplicateResourceTypeWarning,
  }) =>
    showDuplicateResourceTypeWarning ? (
      <Banner
        title="This resource_type is already configured. If you still wish to continue, it may override all your existing configurations."
        type={BannerTypes.WARNING}
        dataTestId="duplicate-resource-type-warning"
      />
    ) : null;

  const ConfigEditorRenderer: FC<ConfigEditorRendererProps> = ({
    themeDefined,
    open,
    values,
    setFieldValue,
    setFieldError,
    errors,
  }) => (
    <div className="tw-flex tw-flex-col">
      <span className="tw-text-white tw-text-xssm tw-font-semibold tw-mb-1">
        Resource Configuration IaC
      </span>
      <div
        className={`tw-h-72 tw-border tw-rounded-md tw-p-2 tw-bg-gray-800 ${
          values.template ? "tw-border-gray-500" : "tw-border-red-400"
        }`}
      >
        {themeDefined && open && (
          <>
            <Editor
              height="100%"
              language={IaCFileTypes.hcl}
              theme="custom-theme"
              value={values.template}
              onChange={(value) => {
                setFieldValue("template", value);
                if (values?.template?.trim() === "") {
                  setFieldError(
                    "template",
                    "Resource Configuration IaC can not be empty"
                  );
                }
              }}
              options={{
                lineNumbers: "off",
                minimap: { enabled: false },
                glyphMargin: false,
                folding: false,
                lineDecorationsWidth: 0,
                hideCursorInOverviewRuler: false,
              }}
            />
            {errors.template && (
              <div
                className="tw-text-xs tw-text-red-400 tw-mt-3"
                data-testid="editor-error"
              >
                {errors.template}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  const ConfigureVariableHeader: FC<ConfigureVariableHeaderProps> = ({
    capitalizedVariableName,
    setFieldValue,
    index,
    values,
  }) => (
    <div className="tw-flex tw-justify-between tw-items-center">
      <span
        className="tw-text-white tw-text-xssm tw-font-semibold"
        data-testid={`variable-name-${index}`}
      >
        {capitalizedVariableName}
      </span>
      <div
        className="tw-cursor-pointer tw-text-gray-400 hover:tw-text-gray-300"
        onClick={() => {
          setFieldValue(
            "variables",
            values?.variables?.filter((_, i: number) => i !== index)
          );
        }}
        data-testid={`delete-variable-${index}`}
      >
        <Icon name={IconsList.DELETE} size={IconSize.sm} />
      </div>
    </div>
  );

  const ConfigureVariablesSection: FC<ConfigureVariablesSectionProps> = ({
    values,
    setFieldValue,
  }) => (
    <div className="tw-flex tw-flex-col">
      <span className="tw-text-white tw-text-xssm tw-font-semibold tw-mb-1">
        Configuration Variables
      </span>
      <span className="tw-text-gray-200 tw-text-xssm tw-font-normal">
        Please ensure that the Terraform variables you define have names derived
        from the attributes. For example, if the attribute name is
        "domain_name," the Terraform variable should have the same name & you
        can refer it in your code as "var.domain_name."
      </span>
      <FieldArray
        name="variables"
        render={() => (
          <>
            {values.variables && values.variables.length > 0 ? (
              <div className="tw-flex tw-flex-col tw-gap-4 tw-mt-3">
                {values.variables.map((_, index: number) => {
                  const capitalizedVariableName = getCapitalizedVariableName(
                    (values?.variables?.[index] as Variable).name as string,
                    index
                  );

                  return (
                    <div
                      className="tw-border tw-border-gray-500 tw-rounded-md tw-p-6 tw-flex tw-flex-col tw-gap-4"
                      key={`variable_${index}`}
                    >
                      <ConfigureVariableHeader
                        capitalizedVariableName={capitalizedVariableName}
                        setFieldValue={setFieldValue}
                        index={index}
                        values={values}
                      />
                      <div className="tw-flex tw-flex-col tw-gap-4">
                        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                          <TextBox
                            labelVariant="gray200-medium"
                            name={`variables.[${index}].name`}
                            label="Variable Name"
                            placeholder="e.g. domain_name"
                            onChange={(e) => {
                              setFieldValue(
                                `variables.[${index}].name`,
                                e.target.value
                              );
                              setTimeout(() => {
                                setFieldValue(
                                  `variables.[${index}].label`,
                                  e.target.value
                                    ? getCapitalizedVariableName(
                                        e.target.value,
                                        index
                                      )
                                    : ""
                                );
                              }, 0);
                            }}
                            backgroundVariant="gray-800"
                            dataTestId={`variable-name-input-${index}`}
                            required
                          />
                          <TextBox
                            labelVariant="gray200-medium"
                            name={`variables.[${index}].label`}
                            label="Label for this variable"
                            placeholder="e.g. Domain Name"
                            isOptional
                            backgroundVariant="gray-800"
                            dataTestId={`variable-label-input-${index}`}
                          />
                        </div>
                        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                          <SelectBox
                            name={`variables.[${index}].type`}
                            label="Data type"
                            labelVariant="gray200-medium"
                            placeholder="Select variable data type"
                            options={tfDataTypes}
                            onChange={(newType) =>
                              onDataTypeChange(
                                newType as Option,
                                index,
                                values,
                                setFieldValue
                              )
                            }
                            backgroundVariant="gray-800"
                            dataTestId={`variable-type-select-${index}`}
                            required
                          />
                          {(values?.variables?.[index] as Variable) &&
                            [
                              CustomResourceDataType.STRING,
                              CustomResourceDataType.NUMBER,
                              CustomResourceDataType.BOOLEAN,
                            ].includes(
                              values?.variables?.[index]
                                ?.type as CustomResourceDataType
                            ) && (
                              <DefaultValueRendererForPrimitiveType
                                type={values?.variables?.[index]?.type}
                                varIndex={index}
                              />
                            )}
                        </div>
                        {(values?.variables?.[index] as Variable) && (
                          <DefaultValueRendererForNonPrimitiveType
                            type={values?.variables?.[index]?.type}
                            varIndex={index}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        <div>
                          <TextArea
                            label="Description"
                            labelVariant="gray200-medium"
                            name={`variables.[${index}].description`}
                            isOptional
                            placeholder="Add description here..."
                            rows={2}
                            backgroundVariant="gray-800"
                            dataTestId={`variable-description-input-${index}`}
                          />
                        </div>
                        <div className="tw-flex tw-gap-2 tw-items-center">
                          <ToggleSwitch
                            name={`variables.[${index}].required`}
                            value={
                              (
                                values?.variables?.[index] as {
                                  [key: string]: string;
                                }
                              ).required
                            }
                            id={`variables.[${index}].required`}
                            dataTestId={`variable-required-switch-${index}`}
                          />
                          <label
                            htmlFor={`variables.[${index}].required`}
                            className="tw-text-gray-200 tw-text-xssm tw-font-medium"
                          >
                            {capitalizedVariableName} is required variable.
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </>
        )}
      />
      <Button
        className="tw-mt-4 tw-w-fit"
        variant="secondary"
        label="New Variable"
        onClick={() => {
          setFieldValue(
            "variables",
            Array.isArray(values.variables)
              ? [...values.variables, newTemplateVariable]
              : [newTemplateVariable]
          );
        }}
        leftIcon={IconsList.ADD}
        dataTestId="add-new-variable-button"
      />
    </div>
  );

  const Footer: FC<FooterProps> = ({ onCancel, isSubmitting, mode }) => (
    <div className="tw-flex tw-justify-between tw-absolute tw-bottom-0 tw-border-t tw-border-gray-700 tw-bg-gray-900 tw-w-full tw-p-4">
      <span className="tw-text-xs tw-cursor-pointer tw-text-blue-500 hover:tw-underline tw-flex tw-ml-2 tw-font-light tw-items-center">
        Learn more about creating custom resources.
        <Icon name={IconsList.ARROW_UP_RIGHT}></Icon>
      </span>
      <div className="tw-flex tw-gap-3">
        <Button
          className="tw-bg-gray-900"
          label="Cancel"
          variant="tertiary"
          type="button"
          onClick={() => onCancel()}
          dataTestId="cancel-custom-resource-button"
        />
        {filteredCustomResourceCreationSteps.length > 1 &&
        activeStepNumber < filteredCustomResourceCreationSteps.length ? (
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            label="Proceed"
            rightIcon={IconsList.ARROW_RIGHT}
            dataTestId="create-custom-resource-next-button"
          />
        ) : (
          <Button
            variant="primary"
            type="submit"
            disabled={isSubmitting}
            label={
              mode === CustomResourceCreationModes.EDIT_MODE
                ? isSubmitting
                  ? "Updating Resource..."
                  : "Update Resource"
                : isSubmitting
                ? "Creating Resource..."
                : "Create Resource"
            }
            dataTestId="create-custom-resource-button"
          />
        )}
      </div>
    </div>
  );

  const alphanumericRule = yup
    .string()
    .matches(
      /^[A-Za-z_0-9]*$/,
      "Only alpha-numeric and underscores are allowed"
    );

  const uniqueKeyTest = yup
    .object()
    .test("unique", "unique-key", function (value: any) {
      const { parent, path } = this;
      if (!value) return true;
      const duplicate =
        parent?.filter((v: any) => v?.key && v.key === value.key)?.length > 1;
      return (
        !duplicate ||
        this.createError({
          path: `${path}.key`,
          message: "key must be unique",
        })
      );
    });

  const uniqueDefaultValueTest = yup
    .string()
    .test("unique", "unique-default-value", function (value) {
      const { parent } = this;
      const duplicate =
        parent?.filter((v: any) => v && v === value)?.length > 1;
      return (
        !duplicate ||
        this?.createError({
          message: "default values must be unique for Set data type",
        })
      );
    });

  const existingCustomResources = useMemo(() => {
    const { customResources } = getResourcesByCategory(
      topologyResourcesList[
        ResourceProvidersMap[
          provider as keyof ProviderWiseSupportedResourceType
        ]
      ]
    );
    return customResources ?? [];
  }, [topologyResourcesList, provider]);

  const getValidationSchema = () => {
    return yup.object().shape({
      label: yup
        .string()
        .notOneOf(
          existingCustomResources.map((resource) => resource.label),
          "Resource Name is already in use"
        )
        .required("Resource Name is required"),
      resourceType: alphanumericRule
        .notOneOf(
          unsupportedResourceTypes,
          `${unsupportedResourceTypes.join(", ")} are not allowed`
        )
        .notOneOf(
          [
            ...unsupportedResourceTypes,
            ...existingCustomResources.map((resource) => resource.label),
          ],
          "Resource Type is not allowed or already in use"
        )
        .required("Resource Type is required"),
      template: yup.string().required("Template is required"),
      variables: yup.array().of(
        yup.object().shape({
          name: alphanumericRule.required("Variable Name is required"),
          type: yup.string().required("Data Type is required"),
          defaultVal: yup.array().when("type", (type: any, schema: any) => {
            // unique key validations based on data type
            switch (type[0]) {
              // validates: each item of set must be unique
              case CustomResourceDataType.SET_STRING:
              case CustomResourceDataType.SET_BOOLEAN:
              case CustomResourceDataType.SET_NUMBER:
                return schema.of(uniqueDefaultValueTest);
              // validates: keys of map must be unique
              case CustomResourceDataType.MAP_STRING:
              case CustomResourceDataType.MAP_NUMBER:
              case CustomResourceDataType.MAP_BOOLEAN:
                return schema.of(uniqueKeyTest);
              default:
                return yup.mixed().nullable();
            }
          }),
          schema: yup.array().when("type", (type: any, schema: any) => {
            // validates key of objects & list of objects must be unique
            if (
              type[0] === CustomResourceDataType.OBJECT ||
              type[0] === CustomResourceDataType.LIST_OBJECT
            ) {
              return schema.of(uniqueKeyTest);
            } else {
              return yup.mixed().nullable();
            }
          }),
        })
      ),
    });
  };

  const activeStepId = useMemo(() => {
    // if active step number is greater than total steps, return last step
    if (activeStepNumber > filteredCustomResourceCreationSteps.length) {
      return filteredCustomResourceCreationSteps[
        filteredCustomResourceCreationSteps.length - 1
      ]?.id;
    }
    return filteredCustomResourceCreationSteps[activeStepNumber - 1]?.id;
  }, [activeStepNumber, filteredCustomResourceCreationSteps]);

  const checkResourceTypeMappings = debounce(async (resourceType) => {
    if (resourceType && resourceType.length > 2) {
      const matchingAttributes = await getMatchingResourceAttributes({
        resourceType: resourceType,
        cloudProvider: provider,
      });

      if (
        matchingAttributes &&
        matchingAttributes.attributes?.length &&
        matchingAttributes.attributes.length > 0
      ) {
        setResourceTypeMappings(matchingAttributes);
      }
    } else {
      setResourceTypeMappings({});
    }
  }, 1000);

  useEffect(() => {
    if (supportedTfDataTypes && Object.keys(supportedTfDataTypes)?.length > 0) {
      setTfDataTypes(
        Object.values(supportedTfDataTypes)
          .flat(1)
          ?.map((item) => ({ label: item.type, value: item.type }))
      );
    } else {
      fetchSupportedTfDataTypes();
    }
  }, [supportedTfDataTypes]);

  useEffect(() => {
    // TODO: move this logic to common component to reuse it at other place. Create Editor component.
    if (monaco) {
      monaco.editor.defineTheme("custom-theme", {
        base: "vs-dark",
        inherit: true,
        rules: [],
        colors: {
          "editor.background": "#101317",
        },
      });
      setThemeDefined(true);
    }
  }, [monaco]);

  useEffect(() => {
    if (customResourceToEdit) {
      setMode(
        customResourceToEdit?.resourceId
          ? CustomResourceCreationModes.FROM_UNSUPPORTED_RESOURCE
          : CustomResourceCreationModes.EDIT_MODE
      );
      customResourceToEdit?.variables?.forEach((variable, index) => {
        if (customResourceToEdit?.variables?.[index]) {
          customResourceToEdit.variables[index] =
            attributeToFormikProperties(variable);
        }
      });
    } else {
      setMode(CustomResourceCreationModes.FROM_SCRATCH);
    }
    setFormInitialValues({
      ...defaultInitialFormValues,
      ...customResourceToEdit,
    });
  }, [customResourceToEdit]);

  return (
    <Drawer open={open} size="lg" dataTestId="custom-resource-drawer">
      <div className="tw-bg-gray-900 tw-h-full">
        <div className="tw-flex tw-gap-3 tw-p-4 tw-items-center tw-justify-between tw-border-b tw-border-gray-700">
          {mode === CustomResourceCreationModes.EDIT_MODE ? (
            <div className="tw-flex tw-items-center tw-gap-2">
              <InitialsLogo
                trimProviderPrefix={true}
                label={customResourceToEdit?.resourceType ?? ""}
                size={IconSize.lg}
              />
              <span className="tw-text-white tw-text-base tw-font-semibold">
                Edit {customResourceToEdit?.label}
              </span>
            </div>
          ) : (
            <div className="tw-flex tw-items-center tw-gap-2">
              <AWSIcon name={AWSIconsList.aws_unsupported} size={IconSize.lg} />
              <span className="tw-text-white tw-text-base tw-font-semibold">
                Add Unsupported Resource as Custom Resource
              </span>
            </div>
          )}
          <div
            className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
            onClick={() => onClose()}
            data-testid="custom-resource-drawer-close"
          >
            <Icon name={IconsList.CLOSE} />
          </div>
        </div>
        {open && (
          <Formik
            initialValues={formInitialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              if (
                filteredCustomResourceCreationSteps.length > activeStepNumber
              ) {
                setActiveStepNumber(activeStepNumber + 1);
                setSubmitting(false);
              } else {
                onCustomResourceSubmit(values, setSubmitting, resetForm);
              }
            }}
            validationSchema={getValidationSchema()}
            enableReinitialize
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              setFieldError,
              errors,
            }) => {
              useEffect(() => {
                if (
                  values.resourceType &&
                  resourceTypeMappings?.attributes?.length &&
                  values.variables?.length
                ) {
                  setFilteredCustomResourceCreationSteps(
                    customResourceCreationSteps
                  );
                } else if (filteredCustomResourceCreationSteps.length > 1) {
                  // Added check for length > 1 to avoid rerendering when only one step is there
                  setFilteredCustomResourceCreationSteps([
                    customResourceCreationSteps[0],
                  ]);
                }
              }, [values.resourceType, values.variables]);

              return (
                <Form>
                  <ScrollToError />
                  <div className="tw-flex tw-flex-col tw-justify-between tw-bg-gray-900">
                    <div className="tw-flex tw-items-start tw-justify-start">
                      <div className="tw-py-6 tw-pr-6 tw-pl-8 tw-mb-16 tw-basis-3/12">
                        <Stepper
                          activeStepNumber={activeStepNumber}
                          steps={filteredCustomResourceCreationSteps}
                          onStepClick={(step) => {
                            setActiveStepNumber(step);
                          }}
                          allowIncompleteStepClick={false}
                          dataTestId="custom-resource-creation-stepper"
                        />
                      </div>
                      <div className="tw-p-6 tw-mb-16 tw-basis-9/12">
                        {activeStepId ===
                          CustomResourceCreationStepIds.DEFINE_CONFIGURATION && (
                          <div className="tw-flex tw-gap-4 tw-flex-col">
                            <div className="tw-flex tw-flex-col tw-gap-1">
                              <div className="tw-text-white tw-text-base tw-font-semibold">
                                Define Configuration
                              </div>
                              <div className="tw-text-gray-300 tw-text-xssm">
                                Provide custom resource template and define
                                variables.
                              </div>
                            </div>
                            <div className="tw-grid tw-grid-cols-3 tw-gap-6">
                              <TextBox
                                name="label"
                                label="Resource Name"
                                description="Enter a unique name for this resource."
                                placeholder="Resource Name"
                                disabled={
                                  mode === CustomResourceCreationModes.EDIT_MODE
                                }
                                backgroundVariant="gray-800"
                                dataTestId="resource-name-input"
                                required
                              />
                              <TextBox
                                name="resourceType"
                                label="Resource Type"
                                description="Enter a terraform resource type."
                                placeholder={getCustomResourceTypePlaceholder(
                                  provider
                                )}
                                disabled={[
                                  CustomResourceCreationModes.EDIT_MODE,
                                  CustomResourceCreationModes.FROM_UNSUPPORTED_RESOURCE,
                                ].includes(mode)}
                                onChange={(e) => {
                                  checkResourceTypeMappings(e.target.value);
                                }}
                                backgroundVariant="gray-800"
                                dataTestId="resource-type-input"
                                required
                              />
                            </div>
                            <ConfigEditorRenderer
                              themeDefined={themeDefined}
                              open={open}
                              values={values}
                              setFieldValue={setFieldValue}
                              setFieldError={setFieldError}
                              errors={errors}
                            />
                            <ConfigureVariablesSection
                              values={values}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        )}
                        {activeStepId ===
                          CustomResourceCreationStepIds.MAP_VARIABLES && (
                          <CustomResourceMapVariables
                            resourceTemplate={values}
                            resourceTypeMappings={resourceTypeMappings}
                          />
                        )}
                        {/* Uncomment when we allow user to override resource during edit mode */}
                        {/* <DuplicateResourceTypeWarning
                          showDuplicateResourceTypeWarning={
                            showDuplicateResourceTypeWarning
                          }
                        /> */}
                      </div>
                    </div>
                    <Footer
                      onCancel={onClose}
                      isSubmitting={isSubmitting}
                      mode={mode}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </Drawer>
  );
};

export default CustomResourceDrawer;
