/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CloudProviderMetadata } from './CloudProviderMetadata';
import {
    CloudProviderMetadataFromJSON,
    CloudProviderMetadataFromJSONTyped,
    CloudProviderMetadataToJSON,
} from './CloudProviderMetadata';
import type { IacTypeMetadata } from './IacTypeMetadata';
import {
    IacTypeMetadataFromJSON,
    IacTypeMetadataFromJSONTyped,
    IacTypeMetadataToJSON,
} from './IacTypeMetadata';
import type { TargetComputeMetadata } from './TargetComputeMetadata';
import {
    TargetComputeMetadataFromJSON,
    TargetComputeMetadataFromJSONTyped,
    TargetComputeMetadataToJSON,
} from './TargetComputeMetadata';

/**
 * Metadata for the appStack. Used for user interface input scenarios
 * @export
 * @interface AppStackMetadata
 */
export interface AppStackMetadata {
    /**
     * 
     * @type {Array<TargetComputeMetadata>}
     * @memberof AppStackMetadata
     */
    targetCompute?: Array<TargetComputeMetadata>;
    /**
     * 
     * @type {Array<IacTypeMetadata>}
     * @memberof AppStackMetadata
     */
    iacType?: Array<IacTypeMetadata>;
    /**
     * 
     * @type {Array<CloudProviderMetadata>}
     * @memberof AppStackMetadata
     */
    cloudProvider?: Array<CloudProviderMetadata>;
}

/**
 * Check if a given object implements the AppStackMetadata interface.
 */
export function instanceOfAppStackMetadata(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppStackMetadataFromJSON(json: any): AppStackMetadata {
    return AppStackMetadataFromJSONTyped(json, false);
}

export function AppStackMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStackMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetCompute': !exists(json, 'targetCompute') ? undefined : ((json['targetCompute'] as Array<any>).map(TargetComputeMetadataFromJSON)),
        'iacType': !exists(json, 'iacType') ? undefined : ((json['iacType'] as Array<any>).map(IacTypeMetadataFromJSON)),
        'cloudProvider': !exists(json, 'cloudProvider') ? undefined : ((json['cloudProvider'] as Array<any>).map(CloudProviderMetadataFromJSON)),
    };
}

export function AppStackMetadataToJSON(value?: AppStackMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetCompute': value.targetCompute === undefined ? undefined : ((value.targetCompute as Array<any>).map(TargetComputeMetadataToJSON)),
        'iacType': value.iacType === undefined ? undefined : ((value.iacType as Array<any>).map(IacTypeMetadataToJSON)),
        'cloudProvider': value.cloudProvider === undefined ? undefined : ((value.cloudProvider as Array<any>).map(CloudProviderMetadataToJSON)),
    };
}

