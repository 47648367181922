/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HttpIngress
 */
export interface HttpIngress {
    /**
     * 
     * @type {string}
     * @memberof HttpIngress
     */
    endpoint?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HttpIngress
     */
    operations?: Array<HttpIngressOperationsEnum>;
}


/**
 * @export
 */
export const HttpIngressOperationsEnum = {
    Connect: 'CONNECT',
    Delete: 'DELETE',
    Get: 'GET',
    Head: 'HEAD',
    Options: 'OPTIONS',
    Patch: 'PATCH',
    Post: 'POST',
    Put: 'PUT',
    Trace: 'TRACE'
} as const;
export type HttpIngressOperationsEnum = typeof HttpIngressOperationsEnum[keyof typeof HttpIngressOperationsEnum];


/**
 * Check if a given object implements the HttpIngress interface.
 */
export function instanceOfHttpIngress(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HttpIngressFromJSON(json: any): HttpIngress {
    return HttpIngressFromJSONTyped(json, false);
}

export function HttpIngressFromJSONTyped(json: any, ignoreDiscriminator: boolean): HttpIngress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'operations': !exists(json, 'operations') ? undefined : json['operations'],
    };
}

export function HttpIngressToJSON(value?: HttpIngress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endpoint': value.endpoint,
        'operations': value.operations,
    };
}

