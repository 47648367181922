/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GovernanceVersion } from './GovernanceVersion';
import {
    GovernanceVersionFromJSON,
    GovernanceVersionFromJSONTyped,
    GovernanceVersionToJSON,
} from './GovernanceVersion';
import type { OwnerType } from './OwnerType';
import {
    OwnerTypeFromJSON,
    OwnerTypeFromJSONTyped,
    OwnerTypeToJSON,
} from './OwnerType';

/**
 * 
 * @export
 * @interface Governance
 */
export interface Governance {
    /**
     * The UUID of the governance entry.
     * @type {string}
     * @memberof Governance
     */
    uuid: string;
    /**
     * The name of the governance entry.
     * @type {string}
     * @memberof Governance
     */
    name: string;
    /**
     * The description of the governance entry.
     * @type {string}
     * @memberof Governance
     */
    description?: string;
    /**
     * The UUID of the associated owner, either organization or user_id.
     * @type {string}
     * @memberof Governance
     */
    owner: string;
    /**
     * 
     * @type {OwnerType}
     * @memberof Governance
     */
    ownerType: OwnerType;
    /**
     * The UUID of the user creating the governance entry.
     * @type {string}
     * @memberof Governance
     */
    createdBy?: string;
    /**
     * The UUID of the user updating the governance entry.
     * @type {string}
     * @memberof Governance
     */
    updatedBy?: string;
    /**
     * The date and time the governance entry was created.
     * @type {Date}
     * @memberof Governance
     */
    createdAt?: Date;
    /**
     * The date and time the governance entry was last updated.
     * @type {Date}
     * @memberof Governance
     */
    updatedAt?: Date;
    /**
     * The versions of the governance entry.
     * @type {Array<GovernanceVersion>}
     * @memberof Governance
     */
    versions?: Array<GovernanceVersion>;
}

/**
 * Check if a given object implements the Governance interface.
 */
export function instanceOfGovernance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "ownerType" in value;

    return isInstance;
}

export function GovernanceFromJSON(json: any): Governance {
    return GovernanceFromJSONTyped(json, false);
}

export function GovernanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Governance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'owner': json['owner'],
        'ownerType': OwnerTypeFromJSON(json['ownerType']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'versions': !exists(json, 'versions') ? undefined : ((json['versions'] as Array<any>).map(GovernanceVersionFromJSON)),
    };
}

export function GovernanceToJSON(value?: Governance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'name': value.name,
        'description': value.description,
        'owner': value.owner,
        'ownerType': OwnerTypeToJSON(value.ownerType),
        'createdBy': value.createdBy,
        'updatedBy': value.updatedBy,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'versions': value.versions === undefined ? undefined : ((value.versions as Array<any>).map(GovernanceVersionToJSON)),
    };
}

