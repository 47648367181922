import React from "react";
import { Tag, Button, Icon, IconSize, IconsList } from "../../../../components";
import {
  TopologyErrorDrawerTypeEnum,
  type TopologyErrorHeaderProps,
} from "./topology-error-types";

const TopologyErrorHeader: React.FC<TopologyErrorHeaderProps> = ({
  errorCount,
  onFixMissingAttribute,
  onClose,
  toggleExpand,
  isExpanded = false,
  headerText,
  type,
}) => {
  return (
    <div className="tw-sticky tw-top-0 tw-py-2 tw-px-4 tw-flex tw-justify-between tw-items-center tw-bg-gray-700 tw-border tw-border-gray-600">
      <div className=" tw-text-sm tw-flex tw-gap-2 tw-items-center tw-font-semibold tw-text-white">
        <Icon
          name={
            type === TopologyErrorDrawerTypeEnum.ACTION
              ? IconsList.TRIANGLE_EXCLAMATION
              : IconsList.SHIELD_EXCLAMATION
          }
          className={
            type === TopologyErrorDrawerTypeEnum.ACTION
              ? "tw-text-red-500"
              : "tw-text-yellow-500"
          }
          size={IconSize.sm}
        />
        <div className="tw-flex tw-gap-2">
          <span className="tw-text-sm">{headerText}</span>
          <Tag
            title={errorCount}
            size="md"
            className=" tw-text-white tw-font-thin"
          />
        </div>
      </div>
      <div className="tw-flex tw-gap-3 tw-items-center">
        {type === TopologyErrorDrawerTypeEnum.ACTION && (
          <Button
            label="Fix Missing Attributes"
            dataTestId="fix-missing-attributes-action"
            className="tw-text-xssm tw-cursor-pointer hover:tw-no-underline tw-p-2 !tw-text-gray-200"
            onClick={onFixMissingAttribute}
            leftIcon={IconsList.CHECK}
            variant="secondary2"
          />
        )}
        <div
          className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
          onClick={toggleExpand}
          onKeyDown={toggleExpand}
          data-testid="topology-error-drawer-collapse"
        >
          <Icon name={isExpanded ? IconsList.CARET_DOWN : IconsList.CARET_UP} />
        </div>
        <div
          className="tw-cursor-pointer tw-text-gray-300 hover:tw-text-gray-200"
          onClick={() => onClose()}
          onKeyDown={() => onClose()}
          data-testid="topology-error-drawer-close"
        >
          <Icon name={IconsList.CLOSE} />
        </div>
      </div>
    </div>
  );
};

export default TopologyErrorHeader;
