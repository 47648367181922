import React, { type FC } from "react";

import {
  PageBannerProps,
  PageBannerColors,
  PageBannerIcons,
} from "./page-banner-types";
import { Icon, IconsList } from "../../components";

const PageBanner: FC<PageBannerProps> = ({
  type = "info",
  content,
  dataTestId,
  hideIcon = false,
  dismissible = false,
  onDismiss = () => {},
}) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium ${PageBannerColors[type]}`}
      data-testid={dataTestId}
    >
      <div>
        {!hideIcon && <Icon name={PageBannerIcons[type]} />}
        <div>{content}</div>
      </div>

      {dismissible && (
        <span onClick={onDismiss} className="tw-cursor-pointer">
          <Icon name={IconsList.CLOSE} />
        </span>
      )}
    </div>
  );
};

export default PageBanner;
