import React from "react";

import {
  CustomResourceTemplate,
  MatchingResourceAttributes,
} from "../../../../apis/topology";

import { Icon, IconsList, Banner, SelectBox } from "../../../../components";
import { BannerTypes } from "../../../banner/banner-types";

const CustomResourceMapVariables = ({
  resourceTemplate,
  resourceTypeMappings,
}: {
  resourceTemplate: CustomResourceTemplate;
  resourceTypeMappings: MatchingResourceAttributes;
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-text-white tw-text-base tw-font-semibold">
        Map variables for your custom resource: {resourceTemplate.label}
      </div>
      <div>
        <Banner
          type={BannerTypes.INFO}
          title="We've detected that StackGen already supports a resource of this type. To enable us to identify your resource attributes during code analysis, you can map it's variables to those of the corresponding StackGen supported resource."
        />
      </div>
      <div className="tw-text-gray-200 tw-text-sm tw-flex tw-items-center">
        <div className="tw-w-100">Custom template variables</div>
        <div className="tw-w-20"></div>
        <div className="tw-w-100">Equivalent StackGen variables</div>
      </div>
      {resourceTemplate.variables?.map((variable) => {
        const mappingOptions = resourceTypeMappings.attributes
          ?.filter((attribute) => attribute.type === variable.type)
          .map((attribute) => ({
            label: (
              <div>
                <div className="tw-text-white tw-text-sm tw-font-semibold">
                  {attribute.name}
                </div>
                <div className="tw-text-gray-100 tw-text-xs">
                  {attribute.description}
                </div>
              </div>
            ),
            value: attribute.name,
          }));
        return (
          <div className="tw-flex tw-flex-col tw-gap-1" key={variable.name}>
            <div className="tw-flex">
              <div className="tw-w-100 tw-text-xssm tw-font-medium tw-text-gray-100">
                {variable.label}
              </div>
              <div className="tw-w-20"></div>
              <div className="tw-w-100 tw-text-xssm tw-font-medium tw-text-gray-100">
                {mappingOptions &&
                  mappingOptions?.length > 0 &&
                  "StackGen defined template variables"}
              </div>
            </div>
            <div className="tw-flex tw-items-center">
              <div className="tw-w-100 tw-text-sm tw-text-white">
                {variable.name}
              </div>
              <div className="tw-w-20">
                <Icon name={IconsList.ARROW_RIGHT} />
              </div>
              <div className="tw-w-100">
                {mappingOptions && mappingOptions?.length > 0 ? (
                  <SelectBox
                    options={mappingOptions}
                    name={`resourceMappings.${variable.name}`}
                    placeholder="Select variable"
                    backgroundVariant="gray-800"
                    dataTestId={`select-variable-${variable.name}`}
                  />
                ) : (
                  <div className="tw-text-sm tw-text-gray-100">
                    No matching variables found.
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomResourceMapVariables;
