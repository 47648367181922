import * as Sentry from "@sentry/gatsby";

export const initSentry = (productVersion: { [key: string]: any } | null) => {
  const hostname = window.location.hostname;
  let env = "";

  if (hostname === "cloud.stackgen.com") {
    env = "production";
  } else if (hostname === "stage.dev.stackgen.com") {
    env = "staging";
  } else if (hostname === "main.dev.stackgen.com") {
    env = "development";
  }
  if (env) {
    Sentry.init({
      dsn: "https://d1dfbd971d90007c232b4d74dcadccdb@o4508017977720832.ingest.us.sentry.io/4508017980538880",
      release: "appcd-ui@" + productVersion?.["ui"] || "default",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.extraErrorDataIntegration(), // This integration will add extra data to the error event (can be seen under Additional data section in dashboard)
        // Sentry.debugIntegration(), // This integration will log all the events to the console
        // Sentry.captureConsoleIntegration({
        //   levels: ["warn", "error"], // Capture console warn, and error
        // }), // captures all level of consoles(log, warn, error..) & helps us debug an issue better. Commenting as need some more info on this
      ],
      environment: env,
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "https://main.dev.stackgen.com/",
        "https://stage.dev.stackgen.com",
        "https://cloud.stackgen.com",
      ],
      initialScope: {
        // add versions of each service as a tag. This will help in searching for issues related to a specific version of the service
        ...(productVersion &&
          Object.keys(productVersion).length > 0 && {
            tags: Object.keys(productVersion).reduce(
              (acc: { [key: string]: any }, key: string) => {
                acc["version." + key] = productVersion[key];
                return acc;
              },
              {}
            ),
          }),
      },

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

export const captureSentryException = (error: any, metaData: any) => {
  Sentry.captureException(error, metaData);
};

const HTTP_ERROR_STATUS_CODES_IN_BE = [
  400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414,
  415, 416, 417, 421, 422, 423, 424, 425, 426, 428, 429, 431, 451, 500, 501,
  502, 503, 504, 505, 506, 507, 508, 510, 511,
];

// List of HTTP status codes to capture in Sentry - update status codes that needs to be filtered here
export const HTTP_STATUS_CODES_TO_CAPTURE_IN_SENTRY =
  HTTP_ERROR_STATUS_CODES_IN_BE.filter(
    (statusCode) => ![401, 409].includes(statusCode) // excluding 409 as it's a conflict error and we don't want to capture it in sentry
  );
