import React, { type FC } from "react";
import { Link } from "gatsby";
import { useAtomValue } from "jotai";

import { trackAmplitudeEvent } from "../../../utils";

import { SidebarBodyItemProps } from "../sidebar-types";
import { Icon, IconSize, IconsList } from "../../icons";

import {
  sidebarCollapsedAtom,
  sidebarHoveredAtom,
} from "../../../stores/page.store";

const SidebarBodyItem: FC<SidebarBodyItemProps> = ({
  menuItem,
  onMenuItemClick,
  expandedChildMenuItemIds,
}) => {
  const sidebarCollapsed = useAtomValue(sidebarCollapsedAtom);
  const isSidebarHovered = useAtomValue(sidebarHoveredAtom);
  return (
    <>
      <Link
        to={menuItem.link ? menuItem.link : "#"}
        data-testid={`menu-item-${menuItem.id}`}
      >
        <div
          className={`tw-duration-150 tw-py-3 tw-pr-3 tw-pl-4 tw-cursor-pointer ${
            menuItem.isActive ? "tw-bg-gray-600" : "hover:tw-bg-gray-700"
          } ${
            !sidebarCollapsed && isSidebarHovered
              ? "tw-flex tw-items-center"
              : ""
          } ${
            sidebarCollapsed &&
            !isSidebarHovered &&
            menuItem?.submenuItem &&
            menuItem.submenuItem?.length > 0 &&
            menuItem.submenuItem.some((subMenu) => subMenu.isActive)
              ? "tw-bg-gray-600"
              : ""
          }`}
          onClick={() => {
            onMenuItemClick(menuItem.id);
            if (menuItem.analyticsEventId) {
              trackAmplitudeEvent(menuItem.analyticsEventId);
            }
          }}
          id={`menuItem-${menuItem.id}`}
        >
          {menuItem.icon && (
            <Icon
              name={menuItem.icon}
              size={IconSize.lg}
              className="tw-mr-4 tw-float-left tw-items-center tw-text-gray-300 tw-w-5 tw-h-5 tw-cursor-pointer tw-transition-colors tw-ease-in tw-duration-150 hover:tw-text-gray-200"
            />
          )}
          <div className="tw-flex tw-justify-between tw-items-center tw-flex-1">
            <span
              className={`tw-text-gray-50 tw-text-xssm tw-font-normal tw-whitespace-nowrap tw-overflow-hidden tw-truncate tw-max-w-52 ${
                !menuItem.icon ? "tw-pl-10" : ""
              }`}
              title={menuItem.title}
            >
              {menuItem.title}
            </span>
            {menuItem?.submenuItem && menuItem.submenuItem?.length > 0 && (
              <Icon
                name={
                  expandedChildMenuItemIds.includes(menuItem.id)
                    ? IconsList.CARET_UP
                    : IconsList.CARET_DOWN
                }
                className="tw-text-gray-300 tw-cursor-pointer tw-transition-color tw-transition-transform tw-duration-100 hover:tw-text-gray-200 "
                dataTestId={`caret-icon-${menuItem.id}`}
              />
            )}
          </div>
        </div>
      </Link>
      <div
        className={`tw-ease-in tw-transition-max-height ${
          menuItem?.submenuItem &&
          expandedChildMenuItemIds.includes(menuItem.id) &&
          (isSidebarHovered || !sidebarCollapsed)
            ? "tw-overflow-auto tw-max-h-screen tw-duration-500"
            : "tw-overflow-hidden tw-max-h-0 tw-duration-150"
        }`}
      >
        {
          <>
            {menuItem?.submenuItem?.map((child) => (
              <SidebarBodyItem
                key={child.id}
                menuItem={child}
                onMenuItemClick={onMenuItemClick}
                expandedChildMenuItemIds={expandedChildMenuItemIds}
              />
            ))}
          </>
        }
      </div>
    </>
  );
};

export default SidebarBodyItem;
