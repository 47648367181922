import React from "react";
import { getIn } from "formik";

import { SelectBox, TextBox, InputArray, TextArea } from "../../../components";

import { IaCFileTypes } from "../../../config/constants";

import LinkElement from "./link-element";

const GetFormElement = ({ field_props, values, errors, touched }: any) => {
  return (
    <>
      {field_props.type === "text" && <TextBox {...field_props} />}
      {field_props.type === "password" && (
        <TextBox
          {...field_props}
          type="password"
          allowPasswordVisibilityToggle={true}
        />
      )}
      {field_props.type === "select" && <SelectBox {...field_props} />}
      {field_props.type === "textarea" && <TextArea {...field_props} />}
      {field_props.type in IaCFileTypes && (
        <TextArea
          {...field_props}
          backgroundVariant="gray-900"
          editorLanguage={field_props.type}
        />
      )}
      {field_props.type === "dictionary" && (
        <InputArray
          {...field_props}
          fields={[
            {
              name: "key",
              type: "text",
            },
            {
              name: "value",
              type: "text",
            },
          ]}
          value={getIn(values, field_props.name)}
          error={
            errors[field_props.name] &&
            typeof errors[field_props.name] === "string"
              ? `${errors[field_props.name]}`
              : undefined
          }
          touched={touched[field_props.name] ? true : false}
        />
      )}
      {field_props.type === "textarea_list" && (
        <InputArray
          {...field_props}
          fields={[
            {
              name: "key",
              type: "textarea",
            },
          ]}
          value={getIn(values, field_props.name)}
          error={
            errors[field_props.name] &&
            typeof errors[field_props.name] === "string"
              ? `${errors[field_props.name]}`
              : undefined
          }
          touched={touched[field_props.name] ? true : false}
        />
      )}
      {field_props.type === "text_list" && (
        <InputArray
          {...field_props}
          fields={[
            {
              name: "key",
              type: "text",
            },
          ]}
          value={getIn(values, field_props.name)}
          error={
            errors[field_props.name] &&
            typeof errors[field_props.name] === "string"
              ? `${errors[field_props.name]}`
              : undefined
          }
          touched={touched[field_props.name] ? true : false}
        />
      )}
      {field_props.type === "object_list" && (
        <InputArray
          {...field_props}
          fields={field_props?.schema?.map((field_obj: any) => {
            return {
              name: field_obj.key,
              type: field_obj.type,
              attributes: {
                label: field_obj.label,
                items: field_obj?.options?.items,
              },
            };
          })}
          value={getIn(values, field_props.name)}
          error={
            errors[field_props.name] &&
            typeof errors[field_props.name] === "string"
              ? `${errors[field_props.name]}`
              : undefined
          }
          touched={touched[field_props.name] ? true : false}
        />
      )}
      {field_props.type === "link" && field_props.linkAttributes && (
        <LinkElement {...(field_props?.linkAttributes ?? {})} />
      )}
    </>
  );
};

export default GetFormElement;
