import React from "react";
import { productVersionAtom } from "../../stores/auth.store";
import { useAtomValue } from "jotai";
import { checkIsProdEnv } from "../../utils";

const VersionIndicator: React.FC = () => {
  const versions = useAtomValue(productVersionAtom);
  const isProd = checkIsProdEnv();
  return (
    <div className="tw-text-xs tw-flex tw-items-center tw-gap-2">
      {!isProd ? (
        <div className="tw-flex tw-items-center tw-gap-1 tw-rounded-md tw-bg-red-500 tw-py-1 tw-px-2">
          <span className="tw-text-white">dev</span>
        </div>
      ) : null}
      {isProd && versions ? (
        <div className="tw-flex tw-items-center tw-gap-1">
          <span className="tw-text-gray-200">{versions["appcd"]}</span>
        </div>
      ) : (
        versions &&
        Object.keys(versions).map((key) => (
          <div key={key} className="tw-flex tw-items-center tw-gap-1">
            <span className="tw-text-gray-400">{key}</span>
            <span className="tw-text-gray-200">{versions[key]}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default VersionIndicator;
