/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const IacType = {
    Helm: 'Helm',
    Terraform: 'Terraform'
} as const;
export type IacType = typeof IacType[keyof typeof IacType];


export function IacTypeFromJSON(json: any): IacType {
    return IacTypeFromJSONTyped(json, false);
}

export function IacTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IacType {
    return json as IacType;
}

export function IacTypeToJSON(value?: IacType | null): any {
    return value as any;
}

