/* tslint:disable */
/* eslint-disable */
/**
 * Appcd API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PolicyProvider } from './PolicyProvider';
import {
    PolicyProviderFromJSON,
    PolicyProviderFromJSONTyped,
    PolicyProviderToJSON,
} from './PolicyProvider';

/**
 * Settings that are applicable to all policies in the appStack configuration
 * @export
 * @interface AppStackConfigCorePolicySettings
 */
export interface AppStackConfigCorePolicySettings {
    /**
     * Policy IDs that are associated with the appStack configuration
     * @type {Set<string>}
     * @memberof AppStackConfigCorePolicySettings
     */
    policies?: Set<string>;
    /**
     * Frameworks that the policy applies to
     * @type {Array<string>}
     * @memberof AppStackConfigCorePolicySettings
     */
    frameworks?: Array<string>;
    /**
     * Domain that the policies apply to
     * @type {Array<string>}
     * @memberof AppStackConfigCorePolicySettings
     */
    categories?: Array<string>;
    /**
     * Cloud Providers to apply default policies to
     * @type {Array<PolicyProvider>}
     * @memberof AppStackConfigCorePolicySettings
     */
    providers?: Array<PolicyProvider>;
}

/**
 * Check if a given object implements the AppStackConfigCorePolicySettings interface.
 */
export function instanceOfAppStackConfigCorePolicySettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppStackConfigCorePolicySettingsFromJSON(json: any): AppStackConfigCorePolicySettings {
    return AppStackConfigCorePolicySettingsFromJSONTyped(json, false);
}

export function AppStackConfigCorePolicySettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppStackConfigCorePolicySettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'policies': !exists(json, 'policies') ? undefined : json['policies'],
        'frameworks': !exists(json, 'frameworks') ? undefined : json['frameworks'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(PolicyProviderFromJSON)),
    };
}

export function AppStackConfigCorePolicySettingsToJSON(value?: AppStackConfigCorePolicySettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'policies': value.policies === undefined ? undefined : Array.from(value.policies as Set<any>),
        'frameworks': value.frameworks,
        'categories': value.categories,
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(PolicyProviderToJSON)),
    };
}

