import React, { useState, FC } from "react";
import { Field, FieldMetaProps, type FieldProps } from "formik";

import CreatableSelect from "react-select/creatable";
import { isFunction } from "../../utils";
import { Checkbox, Tag } from "../../components";
import { TagVariant } from "../tag/tag.types";

import Select, {
  ActionMeta,
  CSSObjectWithLabel,
  GroupBase,
  MultiValue,
  StylesConfig,
} from "react-select";
import {
  Option,
  MultiSelectBoxProps,
  BackgroundVariant,
} from "./multi-select-box-types";

/**
 * Renders a multi-select box component with various options and functionalities.
 *
 * @param {MultiSelectBoxProps} props - The props object containing the following properties:
 *    - name: The name of the multi-select box.
 *    - options: An array of options for the multi-select box.
 *    - label: The label for the multi-select box.
 *    - description: The description for the multi-select box.
 *    - placeholder: The placeholder text for the multi-select box.
 *    - createable: A boolean indicating if new options can be created.
 *    - createOption: A function to create a new option.
 *    - isOptional: A boolean indicating if the multi-select box is optional.
 *    - subLabel: The secondary label for the multi-select box.
 *    - disabled: A boolean indicating if the multi-select box is disabled.
 *    - className: The CSS class name for the multi-select box.
 *    - showOptionsWithCheckbox: A boolean indicating if options should be shown with checkboxes.
 *    - consolidatedItemsContext: The context for the consolidated items ex. for tag - "5 Languages", consolidatedItemsContext will be "Languages".
 *    - onChange: A function to handle the change event.
 *    - backgroundVariant: The background variant of the multi-select box.
 *    - dataTestId: The data test id for the multi-select box.
 *    - required: A boolean indicating if the multi-select box is required(to show required * mark).
 * @return {JSX.Element} The rendered multi-select box component.
 */
const MultiSelectBox: FC<MultiSelectBoxProps> = ({
  name,
  options,
  label,
  description,
  placeholder,
  createable,
  createOption = () => {},
  isOptional,
  subLabel,
  disabled,
  className,
  showOptionsWithCheckbox = false,
  consolidatedItemsContext = "Items",
  onChange,
  backgroundVariant = "gray-900",
  dataTestId,
  required,
  labelSuffix,
  labelSuffixAction,
  menuPosition = "absolute",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const visibleSelectedItemsLimit = 1; // can be taken from props if needed in future

  const baseStyles: StylesConfig<Option, true, GroupBase<Option>> = {
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      "input:focus": {
        boxShadow: "none",
      },
    }),
    multiValueLabel: (base: CSSObjectWithLabel) => ({
      ...base,
      whiteSpace: "normal",
      overflow: "visible",
    }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      minHeight: 32, // override the input height that comes default with react-select
    }),
  };

  const LabelComponent: FC<void> = () => (
    <>
      {(label || subLabel) && (
        <div className="tw-flex tw-items-center tw-justify-between">
          {
            <>
              {label && (
                <label
                  className={`tw-text-xssm tw-font-semibold ${
                    disabled ? "tw-text-gray-400" : "tw-text-white"
                  }`}
                  htmlFor={name}
                >
                  {label}{" "}
                  {isOptional && (
                    <span
                      className={` tw-font-normal ${
                        disabled ? "tw-text-gray-400" : "tw-text-gray-200"
                      }`}
                    >
                      {" "}
                      (Optional)
                    </span>
                  )}
                </label>
              )}
              {labelSuffix && (
                <label
                  className="tw-cursor-pointer"
                  data-testid={`multiselectbox-labelsuffix-${dataTestId}`}
                  onClick={() => {
                    labelSuffixAction &&
                      isFunction(labelSuffixAction) &&
                      labelSuffixAction();
                  }}
                >
                  {labelSuffix}
                </label>
              )}
              {subLabel && (
                <label
                  className={`tw-text-xs tw-italic ${
                    disabled ? "tw-text-gray-400" : "tw-text-white"
                  }`}
                >
                  {subLabel}
                </label>
              )}
            </>
          }
        </div>
      )}
    </>
  );

  const FooterComponent: FC<FieldMetaProps<any>> = (meta) => (
    <div>
      {meta.touched && meta.error ? (
        <div className="tw-text-xs tw-text-red-400 error-field">
          {meta.error}
        </div>
      ) : null}
      {description && (
        <p
          className={`tw-text-xs tw-break-all ${
            disabled ? "tw-text-gray-400" : "tw-text-gray-300"
          }`}
        >
          {description}
        </p>
      )}
    </div>
  );

  const commonClasses = {
    control: ({ isFocused }: { isFocused: boolean }) =>
      `tw-px-2 tw-py-1.25 tw-rounded-lg tw-text-sm tw-min-w-36 tw-flex tw-gap-1.5 ${
        isFocused ? "!tw-border-blue-600 tw-ring-0.5 tw-ring-blue-600" : ""
      } ${
        disabled
          ? "tw-bg-gray-500 tw-bg-opacity-20"
          : `tw-border tw-border-gray-600 hover:tw-border-gray-500 ${
              BackgroundVariant[backgroundVariant] ?? ""
            }`
      } ${className ? className : ""}`,
    placeholder: () =>
      `tw-text-sm ${disabled ? "tw-text-gray-500" : "tw-text-gray-400"}`,
    menu: () =>
      "tw-rounded-lg tw-bg-gray-700 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-text-sm tw-text-gray-100 tw-border tw-border-gray-500 tw-mt-2",
    option: ({ isSelected }: { isSelected: boolean }) =>
      `tw-px-4 tw-py-2 hover:tw-cursor-pointer tw-text-xs tw-flex tw-items-center hover:tw-bg-gray-500 hover:tw-bg-opacity-20 ${
        isSelected
          ? "after:tw-content-['✓'] after:tw-absolute after:tw-right-4 after:tw-text-gray-100 after:tw-text-xssm"
          : ""
      }`,
    input: () => "tw-text-gray-100",
    noOptionsMessage: () =>
      "tw-px-4 tw-py-2 hover:tw-cursor-not-allowed hover:tw-bg-gray-600",
    indicatorSeparator: () => "tw-w-4",
    indicatorsContainer: () =>
      `${disabled ? "tw-text-gray-600" : "tw-text-gray-400"}`,
  };

  return (
    <Field name={name}>
      {({ form, meta, field }: FieldProps) => {
        const getValue = () => {
          if (options) {
            return options.filter(
              (option) => field?.value?.indexOf(option.value) >= 0
            );
          } else {
            return [];
          }
        };

        const handleOnChange = (
          newValue: MultiValue<Option>,
          _: ActionMeta<Option>
        ) => {
          const selectedValues = newValue.map(
            (item: Option) => item?.value ?? item
          );
          form.setFieldValue(name, selectedValues);
          isFunction(onChange) && onChange?.(selectedValues);
        };

        const handleCreate = async (new_option: string) => {
          setIsLoading(true);
          await createOption?.(new_option);
          form.setFieldValue(
            name,
            field.value ? [...field.value, new_option] : [new_option]
          );
          setIsLoading(false);
        };

        return (
          <div className="tw-flex tw-flex-col tw-gap-1">
            {LabelComponent()}
            {createable && isFunction(createOption) ? (
              <CreatableSelect
                isMulti
                menuPosition={menuPosition}
                name={name}
                options={options}
                value={getValue()}
                onBlur={field.onBlur}
                onChange={handleOnChange}
                isLoading={isLoading}
                onCreateOption={handleCreate}
                closeMenuOnSelect={false}
                unstyled
                styles={baseStyles}
                isDisabled={isLoading || disabled}
                isClearable={false}
                placeholder={placeholder}
                components={{
                  MultiValue: ({ index, children, ...props }) => (
                    <Tag
                      variant={TagVariant.SOLID}
                      className="tw-mr-2"
                      disabled={disabled}
                      dismissible
                      size="sm"
                      title={props.data?.label as string}
                      onDismiss={() => {
                        handleOnChange(
                          field.value?.includes(props.data?.value)
                            ? field.value.filter(
                                (item: string) => item !== props.data?.value
                              )
                            : field.value
                            ? [...field.value, props.data?.value]
                            : [props.data?.value],
                          {
                            action: "deselect-option",
                            option: props.data,
                          }
                        );
                      }}
                      dataTestId={`${props.data?.value}-multi-select-value-tag`}
                    />
                  ),
                }}
                classNames={commonClasses}
                data-testid={dataTestId}
              />
            ) : !showOptionsWithCheckbox ? (
              <Select
                isMulti
                menuPosition={menuPosition}
                name={name}
                options={options}
                value={getValue()}
                onBlur={field.onBlur}
                inputId={name}
                onChange={handleOnChange}
                unstyled
                isClearable={false}
                closeMenuOnSelect={false}
                hideSelectedOptions
                styles={baseStyles}
                isDisabled={disabled}
                placeholder={placeholder}
                components={{
                  MultiValue: ({ index, children, ...props }) => (
                    <Tag
                      className="tw-mr-2"
                      variant={TagVariant.SOLID}
                      disabled={disabled}
                      dismissible
                      size="sm"
                      title={props.data?.label as string}
                      onDismiss={() => {
                        handleOnChange(
                          field.value?.includes(props.data?.value)
                            ? field.value.filter(
                                (item: string) => item !== props.data?.value
                              )
                            : field.value
                            ? [...field.value, props.data?.value]
                            : [props.data?.value],
                          {
                            action: "deselect-option",
                            option: props.data,
                          }
                        );
                      }}
                      dataTestId={`${props.data?.value}-multi-select-value-tag`}
                    />
                  ),
                }}
                menuPlacement="auto"
                classNames={commonClasses}
                data-testid={dataTestId}
              />
            ) : (
              <Select
                isMulti
                menuPosition={menuPosition}
                name={name}
                options={options}
                value={getValue()}
                onBlur={field.onBlur}
                inputId={name}
                onChange={handleOnChange}
                unstyled
                isClearable={false}
                hideSelectedOptions={false}
                styles={baseStyles}
                isDisabled={disabled}
                placeholder={placeholder}
                closeMenuOnSelect={false}
                components={{
                  MultiValue: ({ index, children, ...props }) => {
                    if (
                      field.value &&
                      field.value.length > visibleSelectedItemsLimit
                    ) {
                      return !index ? (
                        <Tag
                          className="tw-mr-2"
                          variant={TagVariant.SOLID}
                          disabled={disabled}
                          size="sm"
                          title={`${field.value.length} ${consolidatedItemsContext}`}
                          dataTestId={`${
                            field.value.length
                          }-${consolidatedItemsContext?.replaceAll(
                            " ",
                            "-"
                          )}-multi-select-value-tag`}
                        />
                      ) : null;
                    }
                    return (
                      <Tag
                        className="tw-mr-2"
                        variant={TagVariant.SOLID}
                        disabled={disabled}
                        size="sm"
                        title={(props.data?.label as string) ?? ""}
                        dataTestId={`${props.data?.value}-multi-select-value-tag`}
                      />
                    );
                  },
                  Option: ({ children, ...props }) => (
                    <div
                      className="tw-px-3 tw-py-2.5 hover:tw-cursor-pointer tw-flex tw-gap-3 tw-items-center"
                      onClick={() => {
                        handleOnChange(
                          field.value?.includes(props.data?.value)
                            ? field.value.filter(
                                (item: string) => item !== props.data?.value
                              )
                            : field.value
                            ? [...field.value, props.data?.value]
                            : [props.data?.value],
                          {
                            action: "select-option",
                            option: props.data,
                          }
                        );
                      }}
                    >
                      <span className="tw-w-fit tw-h-fit">
                        <Checkbox
                          name={name}
                          id={`option_${props.data?.label}`}
                          value={props.data?.value}
                          dataTestId={`${dataTestId}-option-${props.data?.label}-checkbox`}
                        />
                      </span>
                      {children}
                    </div>
                  ),
                  MenuList: ({ children }) => (
                    <>
                      <div className="tw-border-b tw-border-gray-600">
                        {children}
                      </div>
                      <div className="tw-py-2 tw-px-3">
                        <span
                          className="tw-text-xssm tw-font-semibold tw-cursor-pointer tw-text-blue-500 hover:tw-text-blue-400"
                          onClick={() => {
                            handleOnChange([], {
                              action: "clear",
                              removedValues: [],
                            });
                          }}
                          data-testid={`${dataTestId}-clear-multi-select`}
                        >
                          Clear
                        </span>
                      </div>
                    </>
                  ),
                }}
                menuPlacement="auto"
                classNames={commonClasses}
                data-testid={dataTestId}
              />
            )}
            {FooterComponent(meta)}
          </div>
        );
      }}
    </Field>
  );
};

export default MultiSelectBox;
