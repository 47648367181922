export enum LabelVariant { // format: (color)-(font-weight)
  "white-semibold" = "tw-text-white tw-font-semibold",
  "gray200-medium" = "tw-text-gray-200 tw-font-medium",
  "gray400-semibold" = "tw-text-gray-400 tw-font-semibold",
}

export enum BackgroundVariant {
  "gray-700" = "tw-bg-gray-700",
  "gray-800" = "tw-bg-gray-800",
  "gray-900" = "tw-bg-gray-900",
}
