/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyResourceConnection } from './TopologyResourceConnection';
import {
    TopologyResourceConnectionFromJSON,
    TopologyResourceConnectionFromJSONTyped,
    TopologyResourceConnectionToJSON,
} from './TopologyResourceConnection';
import type { TopologyResourceWithPosition } from './TopologyResourceWithPosition';
import {
    TopologyResourceWithPositionFromJSON,
    TopologyResourceWithPositionFromJSONTyped,
    TopologyResourceWithPositionToJSON,
} from './TopologyResourceWithPosition';

/**
 * 
 * @export
 * @interface TopologyDiff
 */
export interface TopologyDiff {
    /**
     * 
     * @type {Array<TopologyResourceWithPosition>}
     * @memberof TopologyDiff
     */
    createdResources?: Array<TopologyResourceWithPosition>;
    /**
     * 
     * @type {Array<TopologyResourceWithPosition>}
     * @memberof TopologyDiff
     */
    deletedResources?: Array<TopologyResourceWithPosition>;
    /**
     * 
     * @type {Array<TopologyResourceConnection>}
     * @memberof TopologyDiff
     */
    createdResourceConnections?: Array<TopologyResourceConnection>;
    /**
     * 
     * @type {Array<TopologyResourceConnection>}
     * @memberof TopologyDiff
     */
    deletedResourceConnections?: Array<TopologyResourceConnection>;
}

/**
 * Check if a given object implements the TopologyDiff interface.
 */
export function instanceOfTopologyDiff(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopologyDiffFromJSON(json: any): TopologyDiff {
    return TopologyDiffFromJSONTyped(json, false);
}

export function TopologyDiffFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyDiff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdResources': !exists(json, 'createdResources') ? undefined : ((json['createdResources'] as Array<any>).map(TopologyResourceWithPositionFromJSON)),
        'deletedResources': !exists(json, 'deletedResources') ? undefined : ((json['deletedResources'] as Array<any>).map(TopologyResourceWithPositionFromJSON)),
        'createdResourceConnections': !exists(json, 'createdResourceConnections') ? undefined : ((json['createdResourceConnections'] as Array<any>).map(TopologyResourceConnectionFromJSON)),
        'deletedResourceConnections': !exists(json, 'deletedResourceConnections') ? undefined : ((json['deletedResourceConnections'] as Array<any>).map(TopologyResourceConnectionFromJSON)),
    };
}

export function TopologyDiffToJSON(value?: TopologyDiff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdResources': value.createdResources === undefined ? undefined : ((value.createdResources as Array<any>).map(TopologyResourceWithPositionToJSON)),
        'deletedResources': value.deletedResources === undefined ? undefined : ((value.deletedResources as Array<any>).map(TopologyResourceWithPositionToJSON)),
        'createdResourceConnections': value.createdResourceConnections === undefined ? undefined : ((value.createdResourceConnections as Array<any>).map(TopologyResourceConnectionToJSON)),
        'deletedResourceConnections': value.deletedResourceConnections === undefined ? undefined : ((value.deletedResourceConnections as Array<any>).map(TopologyResourceConnectionToJSON)),
    };
}

