/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResourceRestrictions,
} from '../models/index';
import {
    ResourceRestrictionsFromJSON,
    ResourceRestrictionsToJSON,
} from '../models/index';

export interface DeleteResourceRestrictionRequest {
    resourceRestrictionId: string;
}

export interface GetResourceRestrictionsRequest {
    owner: string;
    policyIds?: Array<string>;
}

export interface UpdateResourceRestrictionRequest {
    resourceRestrictionId: string;
    resourceRestrictions: ResourceRestrictions;
}

export interface UploadResourceRestrictionsRequest {
    resourceRestrictions: ResourceRestrictions;
}

/**
 * 
 */
export class V1ResourceRestrictionsApi extends runtime.BaseAPI {

    /**
     * Delete a certain resource restriction
     */
    async deleteResourceRestrictionRaw(requestParameters: DeleteResourceRestrictionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourceRestrictionId === null || requestParameters.resourceRestrictionId === undefined) {
            throw new runtime.RequiredError('resourceRestrictionId','Required parameter requestParameters.resourceRestrictionId was null or undefined when calling deleteResourceRestriction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-restrictions/{resourceRestrictionId}`.replace(`{${"resourceRestrictionId"}}`, encodeURIComponent(String(requestParameters.resourceRestrictionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a certain resource restriction
     */
    async deleteResourceRestriction(requestParameters: DeleteResourceRestrictionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResourceRestrictionRaw(requestParameters, initOverrides);
    }

    /**
     * Get all resource restrictions from a certain owner
     */
    async getResourceRestrictionsRaw(requestParameters: GetResourceRestrictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResourceRestrictions>>> {
        if (requestParameters.owner === null || requestParameters.owner === undefined) {
            throw new runtime.RequiredError('owner','Required parameter requestParameters.owner was null or undefined when calling getResourceRestrictions.');
        }

        const queryParameters: any = {};

        if (requestParameters.owner !== undefined) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.policyIds) {
            queryParameters['policyIds'] = requestParameters.policyIds.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/resource-restrictions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceRestrictionsFromJSON));
    }

    /**
     * Get all resource restrictions from a certain owner
     */
    async getResourceRestrictions(requestParameters: GetResourceRestrictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResourceRestrictions>> {
        const response = await this.getResourceRestrictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a certain resource restriction
     */
    async updateResourceRestrictionRaw(requestParameters: UpdateResourceRestrictionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourceRestrictionId === null || requestParameters.resourceRestrictionId === undefined) {
            throw new runtime.RequiredError('resourceRestrictionId','Required parameter requestParameters.resourceRestrictionId was null or undefined when calling updateResourceRestriction.');
        }

        if (requestParameters.resourceRestrictions === null || requestParameters.resourceRestrictions === undefined) {
            throw new runtime.RequiredError('resourceRestrictions','Required parameter requestParameters.resourceRestrictions was null or undefined when calling updateResourceRestriction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resource-restrictions/{resourceRestrictionId}`.replace(`{${"resourceRestrictionId"}}`, encodeURIComponent(String(requestParameters.resourceRestrictionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceRestrictionsToJSON(requestParameters.resourceRestrictions),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a certain resource restriction
     */
    async updateResourceRestriction(requestParameters: UpdateResourceRestrictionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateResourceRestrictionRaw(requestParameters, initOverrides);
    }

    /**
     * Upload resource restrictions
     */
    async uploadResourceRestrictionsRaw(requestParameters: UploadResourceRestrictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceRestrictions>> {
        if (requestParameters.resourceRestrictions === null || requestParameters.resourceRestrictions === undefined) {
            throw new runtime.RequiredError('resourceRestrictions','Required parameter requestParameters.resourceRestrictions was null or undefined when calling uploadResourceRestrictions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/resource-restrictions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceRestrictionsToJSON(requestParameters.resourceRestrictions),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceRestrictionsFromJSON(jsonValue));
    }

    /**
     * Upload resource restrictions
     */
    async uploadResourceRestrictions(requestParameters: UploadResourceRestrictionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceRestrictions> {
        const response = await this.uploadResourceRestrictionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
