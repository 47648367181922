/* tslint:disable */
/* eslint-disable */
/**
 * Virtual Interaction and Recommendation Assistant - VIRA
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceConfigOptionDependentAttributesInner } from './ResourceConfigOptionDependentAttributesInner';
import {
    ResourceConfigOptionDependentAttributesInnerFromJSON,
    ResourceConfigOptionDependentAttributesInnerFromJSONTyped,
    ResourceConfigOptionDependentAttributesInnerToJSON,
} from './ResourceConfigOptionDependentAttributesInner';

/**
 * One of the options for the resource configuration field
 * @export
 * @interface ResourceConfigOption
 */
export interface ResourceConfigOption {
    /**
     * 
     * @type {any}
     * @memberof ResourceConfigOption
     */
    value?: any;
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOption
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ResourceConfigOption
     */
    description?: string;
    /**
     * 
     * @type {Array<ResourceConfigOptionDependentAttributesInner>}
     * @memberof ResourceConfigOption
     */
    dependentAttributes?: Array<ResourceConfigOptionDependentAttributesInner>;
}

/**
 * Check if a given object implements the ResourceConfigOption interface.
 */
export function instanceOfResourceConfigOption(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceConfigOptionFromJSON(json: any): ResourceConfigOption {
    return ResourceConfigOptionFromJSONTyped(json, false);
}

export function ResourceConfigOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceConfigOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dependentAttributes': !exists(json, 'dependentAttributes') ? undefined : ((json['dependentAttributes'] as Array<any>).map(ResourceConfigOptionDependentAttributesInnerFromJSON)),
    };
}

export function ResourceConfigOptionToJSON(value?: ResourceConfigOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'label': value.label,
        'description': value.description,
        'dependentAttributes': value.dependentAttributes === undefined ? undefined : ((value.dependentAttributes as Array<any>).map(ResourceConfigOptionDependentAttributesInnerToJSON)),
    };
}

