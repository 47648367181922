import React, { type FC } from "react";
import { Field, FieldProps } from "formik";

import { Checkbox } from "../../components";
import { SwitchProp } from "./toggle-switch.types";

/**
 * Renders a toggle switch component.
 *
 * - @param {SwitchProp} props - The props for the toggle switch.
 * - @param {string} props.name - The name of the switch.
 * - @param {boolean} props.value - The current value of the switch.
 * - @param {string} props.id - The id of the switch.
 * - @param {string} props.dataTestId - The data-testid of the switch.
 * - @returns {JSX.Element} - The rendered toggle switch component.
 */
const ToggleSwitch: FC<SwitchProp> = ({
  name,
  value,
  id,
  dataTestId,
  onToggle,
}) => {
  return (
    <Field name={name}>
      {({ field }: FieldProps) => {
        return (
          <label
            role="button"
            className="tw-relative tw-inline-flex tw-items-center tw-cursor-pointer"
          >
            <Checkbox
              {...field}
              className="tw-sr-only tw-peer"
              name={name}
              value={value}
              id={id}
              dataTestId={dataTestId}
              onInput={onToggle}
            />
            <div className="tw-w-7 tw-h-4 tw-rounded-full tw-bg-gray-300 peer-checked:after:tw-translate-x-3 peer-checked:after:tw-border-green-500 after:tw-absolute after:tw--left-0 after:tw-bg-white after:tw-border-gray-300 after:tw-border after:tw-rounded-full after:tw-h-4 after:tw-w-4 after:tw-transition-all tw-border-gray-600 peer-checked:tw-bg-green-500"></div>
          </label>
        );
      }}
    </Field>
  );
};
export default ToggleSwitch;
