import React, { type ReactElement } from "react";
import { useAtomValue } from "jotai";
import logoWithTagline from "../../images/logo-with-tagline.png";

import { darkModeAtom } from "../../stores/page.store";

const CommonHead = ({
  title,
  children,
}: {
  title: string;
  children?: ReactElement | ReactElement[];
}) => {
  const darkMode = useAtomValue(darkModeAtom);

  return (
    <>
      <title>{title}</title>
      <meta name="description" content="Generative infrastructure from Code." />
      <meta name="image" content={logoWithTagline} />
      <html className={`${darkMode ? "tw-dark" : ""}`} />
      {children}
    </>
  );
};

export default CommonHead;
