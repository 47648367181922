/* tslint:disable */
/* eslint-disable */
/**
 * IAC Gen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';
import type { Misconfigurations } from './Misconfigurations';
import {
    MisconfigurationsFromJSON,
    MisconfigurationsFromJSONTyped,
    MisconfigurationsToJSON,
} from './Misconfigurations';
import type { TopologyResourceConfigValidation } from './TopologyResourceConfigValidation';
import {
    TopologyResourceConfigValidationFromJSON,
    TopologyResourceConfigValidationFromJSONTyped,
    TopologyResourceConfigValidationToJSON,
} from './TopologyResourceConfigValidation';

/**
 * 
 * @export
 * @interface TopologyResourceWithMisconfigurations
 */
export interface TopologyResourceWithMisconfigurations {
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    resourceType: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    iacType?: string;
    /**
     * Node label, for example, "example-s3-bucket", "notifications-queue", "example-workload"
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    label?: string;
    /**
     * Resource type-specific label, for example "S3 Bucket", "SQS", "Workload"
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    resourceTypeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    group?: string;
    /**
     * 
     * @type {object}
     * @memberof TopologyResourceWithMisconfigurations
     */
    _configuration?: object;
    /**
     * 
     * @type {object}
     * @memberof TopologyResourceWithMisconfigurations
     */
    disabledConfiguration?: object;
    /**
     * 
     * @type {Array<TopologyResourceConfigValidation>}
     * @memberof TopologyResourceWithMisconfigurations
     */
    references?: Array<TopologyResourceConfigValidation>;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    templateId?: string;
    /**
     * 
     * @type {object}
     * @memberof TopologyResourceWithMisconfigurations
     */
    tfVars?: object;
    /**
     * 
     * @type {boolean}
     * @memberof TopologyResourceWithMisconfigurations
     */
    isGroup?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    groupType?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyResourceWithMisconfigurations
     */
    resourcePackId?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof TopologyResourceWithMisconfigurations
     */
    position?: Coordinates;
    /**
     * 
     * @type {Array<Misconfigurations>}
     * @memberof TopologyResourceWithMisconfigurations
     */
    misconfigurations?: Array<Misconfigurations>;
}

/**
 * Check if a given object implements the TopologyResourceWithMisconfigurations interface.
 */
export function instanceOfTopologyResourceWithMisconfigurations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "resourceType" in value;

    return isInstance;
}

export function TopologyResourceWithMisconfigurationsFromJSON(json: any): TopologyResourceWithMisconfigurations {
    return TopologyResourceWithMisconfigurationsFromJSONTyped(json, false);
}

export function TopologyResourceWithMisconfigurationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyResourceWithMisconfigurations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resourceType': json['resourceType'],
        'iacType': !exists(json, 'iacType') ? undefined : json['iacType'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'resourceTypeLabel': !exists(json, 'resourceTypeLabel') ? undefined : json['resourceTypeLabel'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        '_configuration': !exists(json, 'configuration') ? undefined : json['configuration'],
        'disabledConfiguration': !exists(json, 'disabledConfiguration') ? undefined : json['disabledConfiguration'],
        'references': !exists(json, 'references') ? undefined : ((json['references'] as Array<any>).map(TopologyResourceConfigValidationFromJSON)),
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'tfVars': !exists(json, 'tfVars') ? undefined : json['tfVars'],
        'isGroup': !exists(json, 'isGroup') ? undefined : json['isGroup'],
        'groupType': !exists(json, 'groupType') ? undefined : json['groupType'],
        'resourcePackId': !exists(json, 'resourcePackId') ? undefined : json['resourcePackId'],
        'position': !exists(json, 'position') ? undefined : CoordinatesFromJSON(json['position']),
        'misconfigurations': !exists(json, 'misconfigurations') ? undefined : ((json['misconfigurations'] as Array<any>).map(MisconfigurationsFromJSON)),
    };
}

export function TopologyResourceWithMisconfigurationsToJSON(value?: TopologyResourceWithMisconfigurations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resourceType': value.resourceType,
        'iacType': value.iacType,
        'label': value.label,
        'resourceTypeLabel': value.resourceTypeLabel,
        'group': value.group,
        'configuration': value._configuration,
        'disabledConfiguration': value.disabledConfiguration,
        'references': value.references === undefined ? undefined : ((value.references as Array<any>).map(TopologyResourceConfigValidationToJSON)),
        'templateId': value.templateId,
        'tfVars': value.tfVars,
        'isGroup': value.isGroup,
        'groupType': value.groupType,
        'resourcePackId': value.resourcePackId,
        'position': CoordinatesToJSON(value.position),
        'misconfigurations': value.misconfigurations === undefined ? undefined : ((value.misconfigurations as Array<any>).map(MisconfigurationsToJSON)),
    };
}

