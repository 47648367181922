import * as Yup from "yup";

export const tagValidationSchema = () => {
  return Yup.object().shape({
    key: Yup.string().test(
      "key",
      "Key is required with a Value",
      function (key) {
        const { value } = this.parent;
        return Boolean((key && key.trim()) || !(value && value.trim()));
      }
    ),
    value: Yup.string().test(
      "value",
      "Value is required with a Key",
      function (value) {
        const { key } = this.parent;
        return Boolean((value && value.trim()) || !(key && key.trim()));
      }
    ),
  });
};
